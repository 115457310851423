import { CarriagesService } from './../../../services/carriages.service';
import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { AppState } from './../../../../../store/app.state';

@Component({
    selector: 'app-upload-carriages',
    templateUrl: './upload-carriages.component.html',
    styleUrls: ['./upload-carriages.component.scss'],
})
export class UploadCarriagesComponent implements OnInit, OnDestroy {
    startedUpload = false;
    error = null;
    isBusy = false;
    form: FormGroup;
    private uploadSubscription: Subscription;

    constructor(
        public modal: NgbActiveModal,
        private fb: FormBuilder,
        private service: CarriagesService,
        private store: Store<AppState>
    ) {}

    ngOnInit() {
        this.form = this.fb.group({
            carriages_file: [null, [Validators.required]],
        });
    }

    ngOnDestroy(): void {
        if (this.uploadSubscription) {
            this.uploadSubscription.unsubscribe();
        }
    }

    startUpload() {
        this.form.markAllAsTouched();

        if (this.form.invalid) {
            return;
        }
        this.isBusy = true;
        this.error = null;
        this.uploadSubscription = this.service
            .startCarriagesUpload(this.form.value)
            .subscribe(
                () => {
                    this.isBusy = false;
                    this.startedUpload = true;
                },
                error => {
                    this.isBusy = false;
                    this.error = error;
                }
            );
    }

    handleFileUpload(event) {
        this.form.get('carriages_file')?.markAsTouched();
        this.form.get('carriages_file')?.setValue(event.file, {
            emitEvent: true,
            emitModelToViewChange: true,
            emitViewToModelChange: true,
        });
    }
}
