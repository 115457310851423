<ng-container *ngIf="networkQuotation$ | async; let networkQuotation; else loading;">
    <div class="card-body pt-0">
        <div class="row align-items-center mb-4">
            <div class="col">
                <label class="mb-0">Quotation requested from</label>
                <h6>{{ networkQuotation.network_authorization_organization_name }}</h6>
            </div>
            <div class="col text-center">
                <div class="text-left d-inline-block">
                    <label class="mb-0">Their Quotation ID</label>
                    <h6>{{ networkQuotation.requested_quotation_id }}</h6>
                </div>
            </div>
        </div>
        <h5 class="mb-0">Your requests</h5>
    </div>
    <qwyk-quotation-rate-requests-list [outbound]="true" [rateRequests]="networkQuotation.requests">
    </qwyk-quotation-rate-requests-list>
    <div class="card-body py-3">
        <button class="btn btn-primary btn-sm" (click)="onMakeRequest()">
            <fa-icon [icon]="['far', 'plus']" class="mr-1"></fa-icon>
            Make a request
        </button>
    </div>
</ng-container>
<ng-template #loading>
    <div class="card-body text-center pt-0">
        <div *ngIf="error">
            We're sorry, something went wrong, please try again later or contact support.
        </div>
        <div *ngIf="!error">
            <fa-icon [icon]="['far', 'spinner']" [pulse]="true" class="mr-2"></fa-icon>
            Loading network quotation info...
        </div>
    </div>
</ng-template>
