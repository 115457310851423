/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActivitiesResponse } from './../models/user.models';
import { User } from './../../../../domain/global.models';
import { createAction, props } from '@ngrx/store';
import { TimeZone } from '../models/user.models';

export const loadUserData = createAction('[User] Load User Data');
export const loadUserDataSuccess = createAction(
    '[User] Load User Data Success',
    props<{ user: User; timezones?: TimeZone[] }>()
);
export const loadUserDataFailure = createAction(
    '[User] Load User Data Failure'
);

export const updateUserProfile = createAction(
    '[User] Update User Profile',
    props<{ userData: Partial<User> }>()
);
export const updateUserProfileSuccess = createAction(
    '[User] Update User Profile Success',
    props<{ user: User }>()
);
export const updateUserProfileFailure = createAction(
    '[User] Update User Profile Failure',
    props<{ error: any }>()
);

export const loadActivities = createAction(
    '[User] Load Activities',
    props<{ page: number; pageSize: number; append?: boolean }>()
);
export const loadActivitiesSuccess = createAction(
    '[User] Load Activities Success',
    props<{ activitiesInfo: ActivitiesResponse; append?: boolean }>()
);
export const loadActivitiesFailure = createAction(
    '[User] Load Activities Failure'
);
