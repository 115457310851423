<div class="bg-white min-vh-100">
    <ng-container *ngIf="selectedSite$ | async; let site;">
        <div class="container-fluid py-3 pb-5">
            <div class="row justify-content-center">
                <div class="col-12 col-lg-9 col-xxxl-6">
                    <div class="small text-muted mb-3 border-bottom pb-2">
                        <a class="text-muted" [routerLink]="['/']">Qwyk</a>
                        &rsaquo;
                        <a class="text-muted" [routerLink]="['/administration']">{{ 'hub.administration.title' | translate }}</a> <!-- FIXME: link -->
                        &rsaquo;
                        <a class="text-muted" [routerLink]="['./../']">{{ 'hub.menu.side-menu.sites' | translate }}</a>
                        &rsaquo;
                        <a class="text-muted font-weight-bold" [routerLink]="['./']">{{ site.name }}</a>
                    </div>

                    <div class="border-bottom d-flex mb-3 pb-3 align-items-center">
                        <div class="flex-grow-1">
                            <span class="text-muted">
                                <a *ngIf="site.active && !site.enable_cname"
                                    [href]="site.endpoint_identifier | valueMask: sitesMask"
                                    target="_blank">{{ site.endpoint_identifier | valueMask: sitesMask }}
                                </a>
                                <a *ngIf="site.active && site.enable_cname" [href]="'https://' + site.cname"
                                    target="_blank">{{ site.cname }}
                                </a>

                                <span *ngIf="!site.active"
                                    class="text-strikethrough">{{ site.endpoint_identifier | valueMask: sitesMask }}</span>
                                <span class="badge badge-dark ml-1" *ngIf="site.enable_cname">Alias</span>
                            </span>
                        </div>
                    </div>

                    <div class="d-flex flex-row justify-content-between mb-3">
                        <h3 class="font-weight-normal m-0">{{ 'hub.administration.portal-users.site' | translate }}</h3>
                    </div>

                    <qwyk-site-detail #siteDetailComponent [site]="site" (save)="onSave($event)"
                        [saving]="saving$ | async" [error]="error$ | async">
                    </qwyk-site-detail>
                </div>
            </div>
        </div>
    </ng-container>
</div>
