import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommentActivityPayload } from '@qwyk/shared-stores/activities';

@Component({
    selector: 'qwyk-create-comment',
    templateUrl: './create-comment.component.html',
    styleUrls: ['./create-comment.component.scss'],
})
export class CreateCommentComponent {
    @Input() public html = '';
    public isInternal = true;
    public htmlValid = true;
    @Output() save: EventEmitter<CommentActivityPayload> =
        new EventEmitter<CommentActivityPayload>();
    @Input() disabled = false;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() error?: any;

    @Input() saveButtonText = 'hub.activities.comment';
    @Input() showCancel = false;
    @Output() cancel: EventEmitter<void> = new EventEmitter<void>();

    private _saving = false;

    get saving(): boolean {
        return this._saving;
    }

    @Input() set saving(value: boolean) {
        const previous = this._saving;
        setTimeout(() => {
            if (previous && !value && !this.error) {
                this.html = '';
            }
        }, 100);

        this._saving = value;
    }

    public get sanitizedHtml(): string | null {
        return (this.html ?? '').replace(/(<([^>]+)>)/gi, '').trim();
    }

    public onSubmit(): void {
        if (!this.htmlValid) {
            return;
        }
        this.save.emit({
            comment: this.html,
            is_internal: this.isInternal,
        });
    }
}
