<div *ngIf="quotation?.request; let request;" class="card shadow-sm">
    <div class="card-header bg-white border-0 d-flex align-items-center justify-content-between">
        <div class="h5 font-weight-normal m-0">
            {{ 'hub.quotations.details.request' | translate }}
        </div>
        <qwyk-expand-collapse-button
                (clicked)="expanded = !expanded"
                [expanded]="expanded"
                iconSize="lg">
        </qwyk-expand-collapse-button>
    </div>

    <ng-template #locationIcon let-location let-od="od">
        <span ngbTooltip="{{ 'common.' + location.type | translate }}" class="text-secondary ml-2">
            <ng-container *ngIf="location.type === 'port'">
                <ng-container [ngSwitch]="request.transport_mode">
                    <fa-icon *ngSwitchCase="'AIR'"
                             [fixedWidth]="true"
                             [icon]="['fas', od === 'o' ? 'plane-departure' : 'plane-arrival']">
                    </fa-icon>
                    <fa-icon *ngSwitchCase="'OCEAN'" [fixedWidth]="true" [icon]="['fas', 'anchor']">
                    </fa-icon>
                    <fa-icon *ngSwitchCase="'TRUCK'" [fixedWidth]="true"
                             [icon]="['fas', 'warehouse']"></fa-icon>
                    <fa-icon *ngSwitchDefault [fixedWidth]="true" [icon]="['fas', 'map-pin']">
                    </fa-icon>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="location.type !== 'port'">
                <fa-icon [fixedWidth]="true" [icon]="['fas', 'map-pin']"></fa-icon>
            </ng-container>
        </span>
    </ng-template>

    <div *ngIf="expanded" class="row">
        <div class="col-6 border-right pr-0">
            <ul class="list-group list-group-flush">
                <li class="list-group-item list-group-item-heading py-2 bg-light font-weight-bold border-0">
                    {{ 'hub.quotations.details.details' | translate }}
                </li>
                <li class="list-group-item border-0">
                    <div class="row">
                        <div class="col-4">{{ 'hub.quotations.create.fields.modality' | translate }}</div>
                        <div class="col-8">
                            {{ request.transport_mode | titlecase }}
                            <ng-container *ngIf="request.transport_mode !== 'AIR'">
                                {{ request.load_type | transportModeTransform : request.transport_mode | uppercase }}
                            </ng-container>
                        </div>
                    </div>
                </li>
                <li class="list-group-item border-0 bg-light">
                    <div class="row">
                        <div class="col-4">{{ 'hub.quotations.create.fields.origin' | translate }}</div>
                        <div class="col-8">
                            <div class="m-0 d-flex justify-content-start">
                                <a [href]="'https://www.google.com/maps/search/' + request.origin?.name + ',' +
                                       request.origin?.country_code" class="text-wrap text-dark flex-grow-1"
                                   ngbTooltip="{{ 'hub.quotations.details.view-on-gmaps-tooltip' | translate }}"
                                   style="text-underline-style: dotted;"
                                   target="_blank">
                                    {{ request.origin?.name }}
                                </a>
                                <div class="flex-grow-0">
                                    <ng-container
                                        *ngTemplateOutlet="locationIcon, context: {$implicit: request.origin, od: 'o'}">
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>

                <li class="list-group-item border-0">
                    <div class="row">
                        <div class="col-4">{{ 'hub.quotations.create.fields.destination' | translate }}</div>
                        <div class="col-8">
                            <div class="m-0 d-flex justify-content-start">
                                <a [href]="'https://www.google.com/maps/search/' + request.destination?.name + ',' +
                                       request.destination?.country_code" class="text-wrap text-dark flex-grow-1"
                                   ngbTooltip="{{ 'hub.quotations.details.view-on-gmaps-tooltip' | translate }}"
                                   style="text-underline-style: dotted;"
                                   target="_blank">
                                    {{ request.destination?.name }}
                                </a>
                                <div class="flex-grow-0">
                                    <ng-container
                                        *ngTemplateOutlet="locationIcon, context: {$implicit: request.destination, od: 'd'}">
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>

                <li class="list-group-item border-0 bg-light">
                    <div class="row">
                        <div class="col-4">{{ 'hub.quotations.create.fields.currency' | translate }}</div>
                        <div class="col-8">
                            {{ request.currency }}
                        </div>
                    </div>
                </li>

                <li class="list-group-item border-0">
                    <div class="row">
                        <div class="col-4">{{ 'hub.quotations.create.fields.cargo-ready' | translate }}</div>
                        <div class="col-8">
                            {{ request.cargo_ready || '' | amDateFormat: 'L' }}
                        </div>
                    </div>
                </li>

                <li class="list-group-item border-0 bg-light">
                    <div class="row">
                        <div class="col-4">{{ 'hub.quotations.create.fields.commodity' | translate }}</div>
                        <div class="col-8">
                            {{ request.commodity?.description }}
                        </div>
                    </div>
                </li>

                <li *ngIf="request.inco_term" class="list-group-item">
                    <div class="row">
                        <div class="col-4">{{ 'hub.quotations.create.fields.inco-term' | translate }}</div>
                        <div class="col-8">
                            {{ request.inco_term}} - {{ incoTermsMap[request.inco_term] | translate }}
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div class="col-6 pl-0">
            <ul class="list-group list-group-flush">
                <li class="list-group-item list-group-item-heading py-2 bg-light font-weight-bold border-bottom d-flex align-items-center justify-content-between border-0">
                    {{ 'hub.quotations.create.fieldsets.cargo' | translate }}
                    <div class="d-flex align-items center">
                        <div class="form-check form-check-inline small">
                            <input [(ngModel)]="displayUOM" class="form-check-input"
                                   id="displayUOMMetric" name="inlineRadioOptions" type="radio"
                                   style="cursor: pointer;" value="metric">
                            <label class="form-check-label" for="displayUOMMetric"
                                   style="cursor: pointer;">{{ 'common.measurement-systems.metric' | translate }}</label>
                        </div>
                        <div class="form-check form-check-inline small me-0">
                            <input [(ngModel)]="displayUOM" class="form-check-input"
                                   id="displayUOMImperial" name="inlineRadioOptions" type="radio"
                                   style="cursor: pointer;" value="imperial">
                            <label class="form-check-label"
                                   for="displayUOMImperial" style="cursor: pointer;">{{ 'common.measurement-systems.imperial' | translate }}</label>
                        </div>
                    </div>
                </li>
            </ul>

            <div class="card-body pt-0">
                <qwyk-quotation-view-cargo [displayUOM]="displayUOM" [request]="request"></qwyk-quotation-view-cargo>
            </div>

            <ul class="list-group list-group-flush">
                <li class="list-group-item list-group-item-heading py-2 bg-light font-weight-bold border-0">
                    {{ 'hub.quotations.details.requested-segments-and-additionals' | translate }}
                </li>
                <li class="list-group-item">
                    <span [innerHTML]="additionals"></span>
                </li>
            </ul>
        </div>
    </div>
</div>

