<form [formGroup]="form" (submit)="onCreate()">
    <div class="form-group">
        <label for="name">{{ 'common.name' | translate }}</label>
        <input id="name" name="name" type="text" class="form-control" formControlName="name" maxlength="191"
            [ngClass]="{'is-invalid': form.get('name').invalid && form.get('name').touched}" />
        <div class="invalid-feedback">{{ 'hub.settings.users.enter-a-name-at-least-3-char-long' | translate }}</div>
    </div>

    <div class="form-group">
        <label for="email">{{ 'common.email' | translate }}</label>
        <input id="email" name="email" type="text" class="form-control" formControlName="email"
            [ngClass]="{'is-invalid': form.get('email').invalid && form.get('email').touched}" />
        <div class="invalid-feedback">
            <div *ngIf="!form.get('email').errors?.user_not_exists && !form.get('email').errors?.auth0_not_exists">
                {{ 'common.validation.enter-a-valid-email-address' | translate }}.
            </div>
            <div *ngIf="form.get('email').errors?.user_not_exists">
                {{ form.get('email').errors.user_not_exists }}
            </div>
            <div class="card"
                *ngIf="form.get('email').errors?.auth0_not_exists && !form.get('email').errors?.user_not_exists">
                <div class="card-body p-3" *ngIf="form.get('email').errors?.auth0_not_exists; let auth0;">
                    <span>
                        <b>{{ 'hub.settings.users.user-already-exists-part1' | translate : { value: auth0.name } }}</b>
                        {{ 'hub.settings.users.user-already-exists-part2' | translate }}
                    </span>
                    <div class="mt-1">
                        <button class="btn btn-outline-danger btn-sm mr-1" (click)="linkAuth0Sub(auth0.user_id)"
                            type="button">{{ 'common.yes' | translate }}</button>
                        <button class="btn btn-light btn-sm" [routerLink]="['..']" type="button" role="link">{{ 'common.no' | translate }},
                            {{ 'common.cancel' | translate | lowercase }}</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-helper font-weight-bold" *ngIf="form.get('sub').value">
            {{ 'hub.settings.users.we-will-link-to-an-existing-account' | translate }}
        </div>
    </div>

    <div class="form-group">
        <label for="role_id">{{ 'hub.menu.top-menu.role' | translate }}</label>
        <ng-select id="role_id" name="role_id" [items]="roles$ | async" bindValue="id" bindLabel="name"
            formControlName="role_id">
            <ng-template ng-option-tmp let-item="item" let-index="index">
                <div>{{ 'hub.settings.users.roles.' + item.slug | translate }}</div>
                <small class="text-muted">{{ 'hub.settings.users.roles-description.' + item.slug | translate }}</small>
            </ng-template>
        </ng-select>
    </div>

    <div class="form-group">
        <label for="password">{{ 'common.password' | translate }}</label>
        <div class="input-group">
            <input id="password" name="password" type="text" class="form-control" autocomplete="off"
                formControlName="password"
                [ngClass]="{'is-invalid': form.get('password').invalid && form.get('password').touched}"
                maxlength="24" />
            <div class=" input-group-append">
                <button class="btn btn-light border" type="button" ngbTooltip="Generate password"
                    [disabled]="form.get('password').disabled" (click)="generatePassword(16)">
                    <fa-icon [icon]="['far', 'sync']"></fa-icon>
                </button>
            </div>
        </div>
    </div>

    <div class="form-group">
        <label for="password">{{ 'hub.settings.users.confirm-password' | translate }}</label>
        <input type="text" id="password_confirmation" name="password_confirmation"
            formControlName="password_confirmation" class="form-control"
            [ngClass]="{'is-invalid': form.get('password_confirmation').invalid && form.get('password_confirmation').touched}">
        <div class="invalid-feedback">{{ 'hub.settings.users.confirm-password-error' | translate }}</div>
    </div>

    <div *ngIf="!error" class="alert alert-primary small p-2 mt-3">
        <fa-icon [icon]="['far', 'info-circle']" class="mr-1"></fa-icon>
        {{ 'hub.settings.users.create-user-message' | translate }}
    </div>

    <qwyk-form-error [error]="error"></qwyk-form-error>

    <div class="form-group border-top text-right pt-3">
        <button class="btn btn-light mr-2" type="button" role="link" [routerLink]="['..']"
            [disabled]="form.disabled">{{ 'common.cancel' | translate }}</button>
        <button class="btn btn-primary" type="submit" [disabled]="form.disabled">
            <fa-icon [icon]="['far', 'spinner']" [pulse]="true" class="mr-1" *ngIf="saving"></fa-icon>
            {{ 'hub.settings.users.create-user' | translate }}
        </button>
    </div>
</form>
