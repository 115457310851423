import { Component, EventEmitter, Output } from '@angular/core';
import { CustomerUsersService } from '../../services/customer-users.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { MagayaEntity, MagayaEntityListResponse } from '../../models';

@Component({
    selector: 'qwyk-search-magaya-entity-list',
    templateUrl: './search-magaya-entity-list.component.html',
    styleUrls: ['./search-magaya-entity-list.component.scss'],
})
export class SearchMagayaEntityListComponent {
    public loading = true;
    public magayaEntities$: Observable<MagayaEntityListResponse>;
    public searchName: string | null = null;
    public selectedEntity: MagayaEntity;
    @Output() public selectEntity: EventEmitter<MagayaEntity> =
        new EventEmitter<MagayaEntity>();
    private cache = [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private load$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(private service: CustomerUsersService) {
        this.magayaEntities$ = this.load$.pipe(
            debounceTime(200),
            tap(query => {
                this.selectedEntity = null;
                if (!query?.next) {
                    this.cache = [];
                }
            }),
            tap(() => (this.loading = true)),
            switchMap(query => {
                if (null === query) {
                    return of({ data: [], meta: { next: null } });
                }
                return this.service.getMagayaEntities(query);
            }),
            map(response => {
                if (response.data) {
                    this.cache = [...this.cache, ...response.data];
                }
                return {
                    ...response,
                    data: this.cache,
                };
            }),
            tap(() => (this.loading = false))
        );
    }

    loadEntitiesLazy(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        $event: any,
        next: string | null = null,
        name: string | null = null
    ) {
        this.load$.next({ next, name });
    }

    onSearch($event: Event) {
        this.loadEntitiesLazy($event, null, this.searchName);
    }

    onSelectEntity() {
        if (this.selectedEntity) {
            this.selectEntity.emit(this.selectedEntity);
        }
    }
}
