import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {UsersListComponent, UserViewComponent,} from './components';
import {UserExistsGuard} from './guards/user-exists.guard';
import {HubSettingsUsersWithoutRoutingModule} from "./hub-settings-users-without-routing.module";

@NgModule({
    imports: [
        CommonModule,

        RouterModule.forChild([
            {path: '', pathMatch: 'full', component: UsersListComponent},
            {path: 'new', component: UserViewComponent},
            {
                path: ':id',
                component: UserViewComponent,
                canActivate: [UserExistsGuard],
            },
        ]),
        HubSettingsUsersWithoutRoutingModule
    ],
    declarations: [],
    providers: [],
})
export class HubSettingsUsersModule {
}
