import { Injectable } from '@angular/core';
import {
    Router,
    CanActivate,
    CanActivateChild,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { SitesService } from '../services/sites.service';
import * as fromSites from '../+state/sites.reducer';
import * as sitesSelectors from '../+state/sites.selectors';
import * as SitesActions from '../+state/sites.actions';
import { map, take, switchMap, tap, catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class SiteExistsGuard implements CanActivate, CanActivateChild {
    constructor(
        private store: Store<fromSites.SitesPartialState>,
        private service: SitesService,
        private router: Router
    ) {}

    private hasSiteInStore(id: number): Observable<boolean> {
        return this.store.pipe(
            select(sitesSelectors.getSitesEntities),
            map(entities => !!entities[id]),
            take(1)
        );
    }

    private hasSiteInApi(
        id: number,
        next: ActivatedRouteSnapshot
    ): Observable<boolean> {
        return this.service.getSite(id).pipe(
            map(site => SitesActions.loadSiteSuccess({ site })),
            tap(action => this.store.dispatch(action)),
            map(site => !!site),
            catchError(error => {
                this.router.navigate([`/error/${error.status}`], {
                    queryParams: {
                        source: next.pathFromRoot.map(p => p.url).join('/'),
                        errorName: error.statusText,
                    },
                });
                return of(false);
            })
        );
    }

    private hasSite(
        id: number,
        next: ActivatedRouteSnapshot
    ): Observable<boolean> {
        return this.hasSiteInStore(id).pipe(
            switchMap(inStore => {
                if (inStore) {
                    return of(inStore);
                }
                return this.hasSiteInApi(id, next);
            })
        );
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        state: RouterStateSnapshot
    ): Observable<boolean> {
        return this.hasSite(+next.params['id'], next);
    }
    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> {
        return this.canActivate(next, state);
    }
}
