import {SchedulesState} from './../schedules.state';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as carriagesActions from './../actions/carriages.actions';

export const selectFeature = createFeatureSelector<SchedulesState>('schedules');

export const selectCarriagesState = createSelector(
    selectFeature,
    (state: SchedulesState) => state.carriages
);

export const selectCarriages = createSelector(
    selectCarriagesState,
    state => state.carriages
);

export const isLoadingCarriages = createSelector(
    selectCarriagesState,
    state => !state.done && state.action === carriagesActions.loadCarriages.type
);

export const loadingCarriagesError = createSelector(
    selectCarriagesState,
    state =>
        state.done &&
        state.action === carriagesActions.loadCarriages.type &&
        state.error
);

export const getSelectedCarriage = createSelector(
    selectCarriagesState,
    state => {
        if (
            state.done &&
            (state.action === carriagesActions.setSelectedCarriage.type ||
                state.action === carriagesActions.getCarriage.type) &&
            !state.error
        ) {
            return state.selected;
        }
        return null;
    }
);

export const isGettingCarriage = createSelector(
    selectCarriagesState,
    state => !state.done && state.action === carriagesActions.getCarriage.type
);

export const getCarriageError = createSelector(
    selectCarriagesState,
    state =>
        state.done &&
        state.action === carriagesActions.getCarriage.type &&
        state.error
);

export const isSavingCarriage = createSelector(
    selectCarriagesState,
    state =>
        !state.done &&
        (state.action === carriagesActions.createCarriage.type ||
            state.action === carriagesActions.updateCarriage.type)
);

export const saveCarriageFailure = createSelector(
    selectCarriagesState,
    state =>
        state.done &&
        state.error &&
        (state.action === carriagesActions.createCarriage.type ||
            state.action === carriagesActions.updateCarriage.type ||
            state.action === carriagesActions.deleteCarriage.type)
            ? state.error
            : null
);

export const saveCarriageCompleted = createSelector(
    selectCarriagesState,
    state =>
        state.done &&
        !state.error &&
        (state.action === carriagesActions.createCarriage.type ||
            state.action === carriagesActions.updateCarriage.type ||
            state.action === carriagesActions.deleteCarriage.type)
);

export const isDeletingCarriage = createSelector(
    selectCarriagesState,
    state =>
        !state.done && state.action === carriagesActions.deleteCarriage.type
);
