import {RoutingGuideState, SchedulesState} from './../schedules.state';
import {createFeatureSelector, createSelector} from '@ngrx/store';

export const selectFeature = createFeatureSelector<SchedulesState>('schedules');

export const selectRoutingGuideState = createSelector(
    selectFeature,
    (state: SchedulesState) => state.routingGuide
);

export const selectAvailableProviders = createSelector(
    selectRoutingGuideState,
    (state: RoutingGuideState) => state.providers
);

export const selectRoutingGuide = createSelector(
    selectRoutingGuideState,
    (state: RoutingGuideState) => state.routingGuide
);

export const selectCreateItemInfo = createSelector(
    selectRoutingGuideState,
    (state: RoutingGuideState) => {
        return {creating: state.creatingItem, error: state.creatingError};
    }
);

export const selectUpdateItemInfo = createSelector(
    selectRoutingGuideState,
    (state: RoutingGuideState) => {
        return {updating: state.updatingItem, error: state.updatingError};
    }
);

export const selectIsBusyDeleting = createSelector(
    selectRoutingGuideState,
    (state: RoutingGuideState) => state.deletingIndex !== null
);
