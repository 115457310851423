import { Component, OnDestroy } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { QuotationsFacade } from '@qwyk/shared-stores/quotations';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Subscription } from 'rxjs';
import { AlgoliaLocationsService, MasterDataService } from '@qwyk/core';
import { map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import {
    NgbCalendar,
    NgbDateAdapter,
    NgbDateNativeAdapter,
} from '@ng-bootstrap/ng-bootstrap';
import { QuotationsService } from '../../services/quotations.service';
import { Portals } from '@qwyk/models';

@Component({
    selector: 'qwyk-quotation-offer-detail',
    templateUrl: './quotation-offer-detail.component.html',
    styleUrls: ['./quotation-offer-detail.component.scss'],
    providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }],
})
export class QuotationOfferDetailComponent implements OnDestroy {
    offerForm: FormGroup;
    public selectedQuotation$ = this.quotations.selectedQuotations$;
    minValidUntilDate = this.calendar.getToday();
    isSearchingLocations = false;
    locationsSuggestions = [];
    currencies$ = this.masterData.getCurrencies().pipe(
        map(data =>
            data
                .map(e => {
                    e['group'] = e.is_preferred ? undefined : '-';
                    return e;
                })
                .sort((a, b) => (a.is_preferred > b.is_preferred ? -1 : 1))
        ),
        shareReplay()
    );
    carriers$ = this.masterData.getCarriers().pipe(
        map(carriers => {
            const carriersClone = Object.assign([], carriers);

            carriersClone.push({ scac: 'XXXX', name: 'Other' });
            return carriersClone;
        })
    );
    private inputSubscription: Subscription;

    constructor(
        private fb: FormBuilder,
        private quotations: QuotationsFacade,
        private route: ActivatedRoute,
        private masterData: MasterDataService,
        private calendar: NgbCalendar,
        private algolia: AlgoliaLocationsService,
        private quotationsService: QuotationsService,
        private router: Router
    ) {
        this.inputSubscription = combineLatest([
            this.selectedQuotation$,
            route.queryParams,
        ]).subscribe(([quotation, queryParams]) => {
            let copyFromOffer = null;
            if (queryParams.copyOffer) {
                copyFromOffer = (
                    quotation.results as Portals.QuotationOffer[]
                ).find(e => e.id === queryParams.copyOffer);
            }

            this.offerForm = this.fb.group({
                header: this.fb.group({
                    transport_mode: [
                        copyFromOffer
                            ? copyFromOffer.transport_mode
                            : quotation.request.transport_mode,

                        [Validators.required],
                    ],
                    load_type: [
                        copyFromOffer
                            ? copyFromOffer.load_type
                            : quotation.request.load_type,
                        [Validators.required],
                    ],
                    carrier_scac: [
                        copyFromOffer && copyFromOffer.carrier
                            ? copyFromOffer.carrier.scac
                            : null,
                        [Validators.required],
                    ],
                    carrier_name: [
                        copyFromOffer ? copyFromOffer.carrier_name : null,
                        [Validators.required, Validators.maxLength(191)],
                    ],
                    transit_time: [
                        copyFromOffer ? copyFromOffer.transit_time : null,
                        [Validators.min(0)],
                    ],
                    pol: [
                        copyFromOffer ? copyFromOffer.pol : null,
                        [Validators.required],
                    ],
                    pod: [
                        copyFromOffer ? copyFromOffer.pod : null,
                        [Validators.required],
                    ],
                    currency: [
                        copyFromOffer
                            ? copyFromOffer.currency
                            : quotation.request.currency,

                        [Validators.required],
                    ],
                    valid_until: [
                        !!copyFromOffer && !!copyFromOffer.valid_until
                            ? new Date(copyFromOffer.valid_until)
                            : null,
                        [Validators.required],
                    ],
                    remarks: [
                        copyFromOffer ? copyFromOffer.remarks : null,
                        [Validators.maxLength(500)],
                    ],
                    rate_request_id: [queryParams.request_id],
                    rate_request_message: [null],
                }),
                offer_lines: this.fb.array(
                    [],
                    [Validators.required, Validators.min(1)]
                ),
            });

            if (copyFromOffer) {
                for (const line of copyFromOffer.breakdown) {
                    this.offerLinesArray.push(
                        this.fb.group({
                            id: [line.id],
                            segment: [line.segment, [Validators.required]],
                            charge_code: [
                                line.charge_code,
                                [Validators.required],
                            ],
                            charge_description: [
                                line.charge_description,
                                [Validators.required],
                            ],
                            base_currency: [
                                line.base_currency,
                                [Validators.required],
                            ],
                            base_rate: [line.base_rate, [Validators.required]],
                            base_calculation: [
                                line.base_calculation,
                                [Validators.required],
                            ],
                            base_minimum: [
                                line.base_minimum,
                                [Validators.required],
                            ],
                            total_amount: [line.total_amount],
                            exchange_rate: [line.exchange_rate],
                            total_amount_quotation: [
                                line.total_amount_quotation,
                            ],
                        })
                    );
                }
            }
        });
    }

    get offerLinesArray(): FormArray {
        return this.offerForm.get('offer_lines') as FormArray;
    }

    get hasOfferLines(): boolean {
        return this.offerLinesArray && this.offerLinesArray.length > 0;
    }

    ngOnDestroy() {
        this.inputSubscription.unsubscribe();
    }

    onCarrierSelected(e) {
        const carrierName = e && e.scac && e.scac !== 'XXXX' ? e.name : null;
        this.offerForm.get('header.carrier_name').setValue(carrierName);
    }

    onLoadTypeChanged(e) {
        if (
            e.target.value === 'fcl' &&
            this.offerForm.get('header.transport_mode').value === 'AIR'
        ) {
            this.offerForm.get('header.transport_mode').setValue(null);
        }
    }

    public onLocationsAutocomplete(event: string): void {
        if (event) {
            this.isSearchingLocations = true;
            const subscr = this.algolia.getLocationSuggestions(event).subscribe(
                result => {
                    this.locationsSuggestions = result.slice(0, 5);
                    this.isSearchingLocations = false;
                    subscr.unsubscribe();
                },
                () => {
                    this.isSearchingLocations = false;
                    subscr.unsubscribe();
                }
            );
        } else {
            this.locationsSuggestions = null;
        }
    }

    public setPol(location) {
        if (location) {
            this.offerForm.get('header.pol').patchValue({
                locode: location.locode,
                display_name: location.name,
            });
        }
    }

    public setPod(location) {
        if (location) {
            this.offerForm.get('header.pod').patchValue({
                locode: location.locode,
                display_name: location.name,
            });
        }
    }

    public saveOffer() {
        this.offerForm.markAllAsTouched();
        if (this.offerForm.invalid) {
            return;
        }

        const payload = {
            ...this.offerForm.value.header,
            offer_lines: this.offerForm.value.offer_lines,
        };

        if (payload.carrier_scac === 'XXXX') {
            payload.carrier_scac = null;
        }

        this.offerForm.disable();

        this.selectedQuotation$
            .pipe(
                take(1),
                switchMap(quotation =>
                    this.quotationsService
                        .createQuotationOffer(quotation.id, payload)
                        .pipe(
                            tap(quote => {
                                this.quotations.quotationUpdated(quote);
                            })
                        )
                )
            )
            .subscribe(
                () => {
                    this.offerForm.enable();
                    this.router.navigate(['..'], { relativeTo: this.route });
                },
                error => {
                    this.offerForm.enable();
                    // TODO: Better error handling
                    if (error.status === 403) {
                        alert(error.error.message);
                    } else {
                        alert(
                            'We are sorry, something went wrong. Please try again or contact support if the problem persists.'
                        );
                    }
                }
            );
    }
}
