/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAction, props } from '@ngrx/store';
import { common, Hub } from '@qwyk/models';

export const loadCustomerUsers = createAction(
    '[Hub/Administration/CustomerUsers] Load CustomerUsers',
    props<{ query: any }>()
);

export const loadCustomerUsersSuccess = createAction(
    '[Hub/Administration/CustomerUsers] Load CustomerUsers Success',
    props<{
        customerUsers: Hub.CustomerUser[];
        pagination: common.PaginationMeta;
    }>()
);

export const loadCustomerUsersFailure = createAction(
    '[Hub/Administration/CustomerUsers] Load CustomerUsers Failure',
    props<{ error: any }>()
);

export const loadCustomerUserSuccess = createAction(
    '[Hub/Administration/CustomerUsers] Load CustomerUser Success',
    props<{ customerUser: Hub.CustomerUser }>()
);

export const selectCustomerUser = createAction(
    '[Hub/Administration/CustomerUsers] Select CustomerUser',
    props<{ id: string }>()
);

export const createCustomerUser = createAction(
    '[Hub/Administration/CustomerUsers] Create CustomerUser',
    props<{ payload: any }>()
);
export const createCustomerUserSuccess = createAction(
    '[Hub/Administration/CustomerUsers] Create CustomerUser Success',
    props<{ customerUser: Hub.CustomerUser }>()
);
export const createCustomerUserFailure = createAction(
    '[Hub/Administration/CustomerUsers] Create CustomerUser Failure',
    props<{ error: any }>()
);

export const updateCustomerUser = createAction(
    '[Hub/Administration/CustomerUsers] Update CustomerUser',
    props<{ id: string; payload: any }>()
);

export const mutateCustomerUserSuccess = createAction(
    '[Hub/Administration/CustomerUsers] Update CustomerUser Success',
    props<{ customerUser: Hub.CustomerUser }>()
);

export const mutateCustomerUserFailure = createAction(
    '[Hub/Administration/CustomerUsers] Update CustomerUser Failure',
    props<{ error: any }>()
);

export const activateCustomerUser = createAction(
    '[Hub/Administration/CustomerUsers] Activate CustomerUser',
    props<{ id: string; payload: any }>()
);

export const deactivateCustomerUser = createAction(
    '[Hub/Administration/CustomerUsers] Deactivate CustomerUser',
    props<{ id: string; scrub: boolean }>()
);

export const deactivateCustomerUserSuccess = createAction(
    '[Hub/Administration/CustomerUsers] Deactivate CustomerUser Success',
    props<{ id: string }>()
);
