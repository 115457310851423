import {SchedulesState} from './../schedules.state';
import {createFeatureSelector, createSelector} from '@ngrx/store';

export const selectFeature = createFeatureSelector<SchedulesState>('schedules');

export const selectProvidersState = createSelector(
  selectFeature,
  (state: SchedulesState) => state.providers
);

export const selectProviders = createSelector(
  selectProvidersState,
  state => state.providers
);

export const createProviderState = createSelector(
  selectProvidersState,
  state => {
    return { creating: state.creating, errorCreating: state.errorCreating };
  }
);
