import { Component, Input } from '@angular/core';
import { NotificationsEntity } from '../../+state/notifications.models';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'li[qwyk-notifications-list-item]',
    templateUrl: './notifications-list-item.component.html',
    styleUrls: ['./notifications-list-item.component.scss'],
})
export class NotificationsListItemComponent {
    @Input() notification: NotificationsEntity;
    @Input() actionButtonClass = '';

    constructor() {}

    public get routerLink(): string[] | null {
        if (
            this.notification.url &&
            this.notification.url.includes(window.location.origin)
        )
            return [this.notification.url.replace(window.location.origin, '')];

        return null;
    }
}
