<div class="container-fluid p-3 d-flex flex-column justify-content-between min-vh-100 overflow-auto">
    <div>
        <div class="small text-muted mb-3 border-bottom pb-2">
            <a class="text-muted" [routerLink]="['/']">Qwyk</a>
            &rsaquo;
            <a class="text-muted " [routerLink]="['/administration']">{{ 'hub.administration.title' | translate }}</a>
            &rsaquo;
            <a class="text-muted font-weight-bold" [routerLink]="['./']">{{ 'hub.menu.side-menu.sites' | translate }}</a>
        </div>

        <div class="d-flex justify-content-between align-items-center">
            <h3 class="font-weight-normal m-0">{{ 'hub.menu.side-menu.sites' | translate }}</h3>
            <button class="btn btn-outline-primary" [routerLink]="['./new']">
                <fa-icon [icon]="['far', 'plus']" class="mr-1"></fa-icon>
                {{ 'hub.administration.sites.create-site' | translate }}
            </button>
        </div>

        <div class="my-3">
            <p-table [resizableColumns]="true" [paginator]="true" [lazy]="true" [value]="sites$ | async"
                (onLazyLoad)="loadSitesLazy($event)" [loading]="loading$ | async"
                [rows]="(pagination$ | async)?.per_page" [rowHover]="true" [totalRecords]="(pagination$ | async)?.total"
                [first]="(pagination$ | async)?.per_page * ((pagination$ | async)?.current_page - 1)">
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 48px;"></th>
                        <th>{{ 'common.name' | translate }}</th>
                        <th>{{ 'common.address' | translate }}</th>
                        <th>{{ 'hub.administration.sites.color-scheme' | translate }}</th>
                        <th class="text-center" style="width: 165px;">{{ 'common.active' | translate }}</th>
                    </tr>
                    <tr [formGroup]="searchForm">
                        <th></th>
                        <th>
                            <input type="text" formControlName="name" class="form-control form-control-sm"
                                placeholder="{{ 'common.search-by-field' | translate : { value: 'common.name' | translate } }}">
                        </th>
                        <th>
                            <input type="text" formControlName="endpoint_identifier"
                                class="form-control form-control-sm" placeholder="{{ 'common.search-by-field' | translate : { value: 'common.address' | translate } }}">
                        </th>
                        <th></th>
                        <th class="text-center">
                            <button class="btn btn-primary btn-sm" type="submit" (click)="search()"
                                [disabled]="!searchForm.dirty">{{ 'common.search' | translate }}</button>
                            <button class="btn btn-light btn-sm ml-1" type="button"
                                (click)="resetSearch()">{{ 'common.reset' | translate }}</button>
                        </th>
                    </tr>
                </ng-template>

                <ng-template pTemplate="body" let-site>
                    <tr>
                        <td>
                            <img [src]="site.favicon_url" alt="Icon" style="max-width: 24px; max-height: 24px;" />
                        </td>
                        <td>
                            <a [routerLink]="['./', site.id]">{{ site.name }}</a>
                        </td>
                        <td>{{ site.endpoint_identifier }}</td>
                        <td>
                            <div class="d-inline-block" [ngStyle]="{'background-color': site.primary_color}"
                                style="width: 1.5em; height: 1.5em;">
                                &nbsp;</div>
                            <div class="d-inline-block" [ngStyle]="{'background-color': site.secondary_color}"
                                style="width: 1.5em; height: 1.5em;">
                                &nbsp;</div>
                        </td>
                        <td class="text-center">
                            <fa-icon [icon]="['fas', site.active ? 'check' : 'times']" [fixedWidth]="true"
                                [ngClass]="site.active ? 'text-success' : 'text-danger'"></fa-icon>
                        </td>
                    </tr>
                </ng-template>

                <ng-template pTemplate="loadingbody">
                    <tr>
                        <td colspan="5" class="py-4 text-muted text-center">{{ 'common.loading' | translate }}...</td>
                    </tr>
                </ng-template>

                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="5" class="py-4 text-muted text-center">{{ 'common.nothing_found' | translate }}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>

    <div class="card">
        <div class="card-body d-flex align-items-center">
            <div class="mr-4">
                <fa-icon [icon]="['far', 'info-circle']" size="2x" class="text-muted" [fixedWidth]="true"></fa-icon>
            </div>
            <div>
                <div class="font-weight-bold">{{ 'hub.menu.side-menu.sites' | translate }}</div>
                <div>{{ 'hub.administration.sites.sites-info' | translate }}</div>
            </div>
        </div>
    </div>
</div>
