/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { common, Portals } from '@qwyk/models';
import { environment } from '@qwyk/hub/environment';
import { map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class QuotationOffersService {
    constructor(private http: HttpClient) {}

    public getQuotationOffers(
        quotationId: string,
        query: any
    ): Observable<common.PaginatedResponse<Portals.QuotationOffer>> {
        const httpParams = new HttpParams({ fromObject: query });

        return this.http
            .get<common.PaginatedResponse<Portals.QuotationOffer>>(
                `${environment.backendServer}/api/hub/quotations/${quotationId}/offers?${httpParams}`
            )
            .pipe(take(1));
    }

    public getQuotationOffer(
        quotationId: string,
        quotationOfferId: string
    ): Observable<Portals.QuotationOffer> {
        return this.http
            .get<{ data: Portals.QuotationOffer }>(
                `${environment.backendServer}/api/hub/quotations/${quotationId}/offers/${quotationOfferId}`
            )
            .pipe(
                take(1),
                map(response => response.data)
            );
    }

    public calculateOfferLineTotals(quotationId: string, payload: any) {
        return this.http.post<any>(
            `${environment.backendServer}/api/hub/quotations/${quotationId}/calculate_line`,
            payload
        );
    }

    public createQuotationOffer(
        quotationId: string,
        payload: Portals.QuotationOffer
    ): Observable<Portals.QuotationOffer> {
        return this.http
            .post<{ data: Portals.QuotationOffer }>(
                `${environment.backendServer}/api/hub/quotations/${quotationId}/offers`,
                payload
            )
            .pipe(
                take(1),
                map(response => response.data)
            );
    }

    public updateQuotationOffer(
        quotationId: string,
        quotationOfferId: string,
        payload: Portals.QuotationOffer
    ): Observable<Portals.QuotationOffer> {
        return this.http
            .patch<{ data: Portals.QuotationOffer }>(
                `${environment.backendServer}/api/hub/quotations/${quotationId}/offers/${quotationOfferId}`,
                payload
            )
            .pipe(
                take(1),
                map(response => response.data)
            );
    }

    public deleteQuotationOffer(
        quotationId: string,
        quotationOfferId: string
    ): Observable<void> {
        return this.http
            .delete<void>(
                `${environment.backendServer}/api/hub/quotations/${quotationId}/offers/${quotationOfferId}`
            )
            .pipe(take(1));
    }

    public generateQuotationOfferPdf(
        quotationId: string,
        quotationOfferId: string
    ): Observable<Blob> {
        return this.http
            .get(
                `${environment.backendServer}/api/hub/quotations/${quotationId}/offers/${quotationOfferId}/pdf`,
                { responseType: 'blob' }
            )
            .pipe(
                take(1),
                map(
                    response =>
                        new Blob([response], {
                            type: 'application/pdf',
                        })
                )
            );
    }
}
