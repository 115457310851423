import {Portals} from '@qwyk/models';

export enum ActivityType {
    EVENT = 'event',
    COMMENT = 'comment'
}

export interface ActivitiesEntity {
    id: string;
    parent_type: string;
    parent_id: string;
    user: {
        id: string | number;
        name: string;
        email: string;
    } | null;
    type: ActivityType;
    title: string;
    content: string | null;
    is_internal: boolean;
    created_at: string;
    updated_at: string;
}

export interface HasActivities extends Portals.ParentEntity {
}

export interface ActivitiesIndexQuery {
    cursor?: string | null;
    page_size: number | null;
    type?: 'all' | 'event' | 'comment' | null;
}

export interface CommentActivityPayload {
    comment: string;
    is_internal: boolean;
}
