<div class="modal-body">
    <div class="lead">{{ 'hub.schedules.routing-guide.download-routing-guide' | translate }}</div>
    <p class="text-muted" *ngIf="!startedDownload">
        {{ 'hub.schedules.routing-guide.download-routing-guide-text-1' | translate }}
    </p>

    <p *ngIf="startedDownload" class="mt-2">
        {{ 'hub.schedules.routing-guide.started-download' | translate }}
    </p>

    <p *ngIf="error" class="mt-2 text-danger">
        {{ 'common.generic-error-alt-2' | translate }}
    </p>

    <ng-container *ngIf="!startedDownload">
        <button class="btn btn-primary mr-1" (click)="startDownload()" [disabled]="isBusy">
            <fa-icon [icon]="['far', 'spinner']" pulse="true" *ngIf="isBusy"></fa-icon>
            {{ (isBusy ? 'common.starting-download' : 'common.start-download') | translate }}
        </button>
        <button class="btn btn-light" (click)="modal.dismiss()" [disabled]="isBusy">{{ 'common.cancel' | translate }}</button>
    </ng-container>

    <ng-container *ngIf="startedDownload">
        <button class="btn btn-primary mr-1" (click)="modal.close()" [routerLink]="['/schedules', 'tasks']"
            [queryParams]="{tab: 'download'}">{{ 'common.view-progress' | translate }}</button>
        <button class="btn btn-light" (click)="modal.close()">{{ 'common.close' | translate }}</button>
    </ng-container>
</div>
