<div class="modal-header border-bottom-0 px-4 pt-4">
    <div>
        <h4 class="modal-title font-weight-normal m-0" id="modal-basic-title">{{ 'hub.administration.portal-users.select-magaya-entity' | translate }}</h4>
        <p>{{ 'hub.administration.portal-users.select-an-entity-from-magaya-to-link-this-user-to' | translate }}.</p>
    </div>
    <div>
        <a (click)="onCancel()" href="javascript:void(0);">
            <fa-icon [icon]="['far', 'times']" class="text-muted" size="lg"></fa-icon>
        </a>
    </div>
</div>
<div class="modal-body px-4 pb-4">
    <qwyk-search-magaya-entity-list
        (selectEntity)="onSelectEntity($event)"
    ></qwyk-search-magaya-entity-list>
</div>
