import { Component, Input } from '@angular/core';
import { NotificationsEntity } from '../../+state/notifications.models';

@Component({
    selector: 'qwyk-notifications-list',
    templateUrl: './notifications-list.component.html',
    styleUrls: ['./notifications-list.component.scss'],
})
export class NotificationsListComponent {
    @Input()
    public class = '';

    @Input()
    public notifications: NotificationsEntity[];

    @Input()
    public loading: boolean;

    @Input()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public error: any;

    @Input()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    errorWrapperClass: any = 'pb-3';

    @Input()
    itemClass = '';

    @Input()
    actionButtonClass = '';

    constructor() {}
}
