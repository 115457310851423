<div class="card shadow-sm">
    <div class="card-header bg-white border-0 d-flex align-items-center justify-content-between">
        <div class="h5 font-weight-normal m-0">
            {{ 'hub.quotations.details.activity' | translate }}
        </div>
        <div class="d-flex align-items-center">
            <qwyk-activity-feed-control
                *ngIf="expanded"
                [(direction)]="direction"
                [(filter)]="filter"
                class="mr-2"
            >
            </qwyk-activity-feed-control>
            <qwyk-expand-collapse-button
                (clicked)="expanded = !expanded"
                [expanded]="expanded"
                iconSize="lg">
            </qwyk-expand-collapse-button>
        </div>
    </div>
    <qwyk-activities-feed
        *ngIf="parent"
        [direction]="direction"
        [filter]="filter"
        [hidden]="!expanded"
        [parent]="parent"
    ></qwyk-activities-feed>
</div>
