import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HubQuotationsOffersWithoutRoutingModule } from './hub-quotations-offers-without-routing.module';
import {
    QuotationOfferCopyComponent,
    QuotationOfferCreateComponent,
    QuotationsOfferEditComponent,
} from './components';
import { QuotationOfferExistsGuard } from './guards/quotation-offer-exists.guard';

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild([
            {
                path: '',
                redirectTo: 'new',
                pathMatch: 'prefix'
            },
            {
                path: 'new',
                component: QuotationOfferCreateComponent,
            },
            {
                path: ':offerId',
                component: QuotationsOfferEditComponent,
                canActivate: [QuotationOfferExistsGuard],
            },
            {
                path: 'copy/:offerId',
                component: QuotationOfferCopyComponent,
                canActivate: [QuotationOfferExistsGuard],
            },
        ]),
        HubQuotationsOffersWithoutRoutingModule,
    ],
})
export class HubQuotationsOffersModule {}
