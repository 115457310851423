import { Subscription } from 'rxjs';
import { AlgoliaLocationsService } from './../../../../shared/services/algolia-locations.service';
import { PRODUCT_ICONS } from './../../../../../constants/productMappings';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../store/app.state';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import {
    selectAvailableProviders,
    selectRoutingGuide,
    selectCreateItemInfo,
} from '../../../store/selectors/routing-guide.selectors';
import { createRoutingGuideItem } from '../../../store/actions/routing-guide.actions';
import { RoutingGuide } from '../../../store/models/routing-guide.models';

@Component({
    selector: 'app-new-routing-guide-item-modal',
    templateUrl: './new-routing-guide-item-modal.component.html',
    styleUrls: ['./new-routing-guide-item-modal.component.scss'],
})
export class NewRoutingGuideItemModalComponent implements OnInit, OnDestroy {
    providers$ = this.store.select(selectAvailableProviders);
    itemForm: FormGroup;
    productIcons = PRODUCT_ICONS;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    searchLocationResults: any[];
    private infoSubscription: Subscription;
    creating = false;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error: any;
    private submitSubscription: Subscription;
    validationErrors = [];
    @Input() public rowData;

    constructor(
        private fb: FormBuilder,
        private store: Store<AppState>,
        public activeModal: NgbActiveModal,
        private algoliaLocations: AlgoliaLocationsService
    ) {}

    ngOnInit() {
        this.itemForm = this.fb.group({
            origin: [null, [Validators.required]],
            destination: [null, [Validators.required]],
            providers: [[], [Validators.required]],
        });
        if (this.rowData) {
            this.itemForm.controls['origin'].setValue(this.rowData.origin);
            this.itemForm.controls['destination'].setValue(
                this.rowData.destination
            );
            this.itemForm.controls['providers'].setValue(
                this.rowData.providers
            );
        }
        this.infoSubscription = this.store
            .select(selectCreateItemInfo)
            .subscribe(info => {
                if (this.creating && !info.creating && !info.error) {
                    // Creation has been completed without error
                    this.activeModal.close();
                }

                this.creating = info.creating;
                this.error = info.error;

                if (this.error && this.error.status === 422) {
                    // Validation errors have occured, concat all so we can display them properly
                    this.validationErrors = [].concat(
                        ...Object.keys(this.error.error).map(
                            k => this.error.error[k]
                        )
                    );
                    this.error = null;
                }

                // Re-enable the form when we're not creating
                if (!info.creating && this.itemForm.disabled) {
                    this.itemForm.enable();
                }
            });
    }

    ngOnDestroy(): void {
        if (this.infoSubscription) {
            this.infoSubscription.unsubscribe();
        }

        if (this.submitSubscription) {
            this.submitSubscription.unsubscribe();
        }
    }

    /**
     * Hamdles searching for locations
     * @param event the event emitted by the typeahead
     */
    searchLocation(event) {
        const searchSubscription = this.algoliaLocations
            .getLocationSuggestions(event.query)
            .subscribe(results => {
                this.searchLocationResults = results;
                searchSubscription.unsubscribe();
            });
    }

    submit() {
        this.validationErrors = [];
        for (const control in this.itemForm.controls) {
            // eslint-disable-next-line no-prototype-builtins
            if (this.itemForm.controls.hasOwnProperty(control)) {
                this.itemForm.controls[control].markAsTouched();
            }
        }

        if (this.itemForm.invalid) {
            return;
        }

        this.submitSubscription = this.store
            .select(selectRoutingGuide)
            .subscribe((routingGuide: RoutingGuide | null) => {
                this.itemForm.disable();
                if (routingGuide) {
                    this.store.dispatch(
                        createRoutingGuideItem({
                            routingGuideId: routingGuide.id,
                            routingGuideItem: this.itemForm.value,
                        })
                    );
                }
            });
        return;
    }

    onLocationSelect(event, formControlName) {
        this.itemForm.get(formControlName)?.setValue({
            locode: event.locode,
            display_name: event.display_name,
            country_code: event.country_code,
        });
    }
}
