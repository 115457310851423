<div *ngIf="error$ | async; let error" class="px-3 small">
    <ngb-alert [dismissible]="false" type="danger">
        <div>{{ 'common.generic-error-alt' | translate }}</div>
        <span *ngIf="error?.error?.message">"{{ error?.error?.message }}"</span>
    </ngb-alert>
</div>

<qwyk-activities-list-container
    (createComment)="onCreateComment($event)"
    (deleteComment)="onDeleteComment($event)"
    (loadMore)="onLoadMore()"
    (updateComment)="onUpdateComment($event)"
    [activities]="activities$ | async"
    [direction]="direction"
    [error]="error$ | async"
    [filter]="filter"
    [loading]="loading$ | async"
    [pagination]="pagination$ | async"
    [saving]="saving$ | async"
    [userId]="(currentUser$ | async)?.id"
></qwyk-activities-list-container>

