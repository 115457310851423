import { Component, OnDestroy } from '@angular/core';

import { Store } from '@ngrx/store';
import { of, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { AppState } from './../../../../../store/app.state';
import { RoutingGuide } from '../../../store/models/routing-guide.models';
import { RoutingGuideService } from '../../../services/routing-guide.service';
import { selectRoutingGuide } from '../../../store/selectors/routing-guide.selectors';

@Component({
    selector: 'app-download-routing-guide',
    templateUrl: './download-routing-guide.component.html',
    styleUrls: ['./download-routing-guide.component.scss'],
})
export class DownloadRoutingGuideComponent implements OnDestroy {
    startedDownload = false;
    isBusy: boolean;
    error: boolean;
    private downloadRoutingGuideSubscription: Subscription;

    constructor(
        public modal: NgbActiveModal,
        private store: Store<AppState>,
        private service: RoutingGuideService
    ) {}

    ngOnDestroy(): void {
        if (this.downloadRoutingGuideSubscription) {
            this.downloadRoutingGuideSubscription.unsubscribe();
        }
    }

    startDownload() {
        this.isBusy = true;
        this.error = false;
        this.downloadRoutingGuideSubscription = this.store
            .select(selectRoutingGuide)
            .pipe(
                switchMap((rg: RoutingGuide | null) => {
                    if (rg) {
                        return this.service.startRoutingGuideDownload(rg);
                    }
                    return of(null);
                })
            )
            .subscribe(
                () => {
                    this.isBusy = false;
                    this.startedDownload = true;
                },
                () => {
                    this.isBusy = false;
                    this.error = true;
                }
            );
    }
}
