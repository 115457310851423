import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { map } from 'rxjs/operators';
import { CustomerUsersFacade } from '../../+state/customer-users.facade';
import { AuthenticationFacade } from '@qwyk/hub/authentication';

@Component({
    selector: 'qwyk-customer-users-list-widget',
    templateUrl: './customer-users-list-widget.component.html',
    styleUrls: ['./customer-users-list-widget.component.scss'],
})
export class CustomerUsersListWidgetComponent implements OnInit {
    @Input()
    title: string;

    @Input()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    query: any;

    @Input()
    pagination = false;

    @Input()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    nothingFoundTemplate: TemplateRef<any>;

    @Input()
    showApprovalStatus = false;

    @Input()
    isMagayaNetwork = false;

    usersLoading$ = this.customerUsers.loading$;
    pagination$ = this.customerUsers.pagination$;
    users$ = this.customerUsers.allCustomerUsers$.pipe(
        map(users => {
            if (!users) {
                return null;
            }
            if (this.pagination) {
                return users;
            } else {
                return users.slice(0, this.query.pageSize);
            }
        })
    );

    constructor(
        private customerUsers: CustomerUsersFacade,
        private authentication: AuthenticationFacade
    ) {}

    ngOnInit(): void {
        this.customerUsers.loadCustomerUsers(this.query);
    }

    onPageChange(page) {
        this.customerUsers.loadCustomerUsers({
            ...this.query,
            page,
        });
    }
}
