import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetch } from '@nx/angular';
import * as NotificationsActions from './notifications.actions';
import { NotificationsService } from '../notifications.service';
import { map, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromNotifications from './notifications.reducer';
import * as NotificationsSelectors from './notifications.selectors';

@Injectable()
export class NotificationsEffects {
    init$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NotificationsActions.loadNotifications),
            withLatestFrom(this.store.select(NotificationsSelectors.getQuery)),
            fetch({
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                run: (action, _) => {
                    // Your custom service 'load' logic goes here. For now just return a success action...
                    return this.service.getNotifications(action.query).pipe(
                        map(response =>
                            NotificationsActions.loadNotificationsSuccess({
                                notifications: response.data,
                                pagination: response.meta,
                            })
                        )
                    );
                },

                onError: (_action, error) => {
                    console.error('Error', error);
                    return NotificationsActions.loadNotificationsFailure({
                        error,
                    });
                },
            })
        )
    );

    constructor(
        private actions$: Actions,
        private service: NotificationsService,
        private store: Store<fromNotifications.NotificationsPartialState>
    ) {}
}
