import * as userActions from './../actions/user.actions';

import { createReducer, on, Action } from '@ngrx/store';

import { UserState } from './../user.state';
import { User } from '../../../../domain/global.models';

const initialState: UserState = {
    user: null,
    loading: false,
    error: false,
    timezones: [],
    updating: false,
    updateError: null,
    activitiesInfo: null,
    loadingActivities: false,
    activitiesError: false,
};

const reducer = createReducer(
    initialState,

    /** Profile Load */
    on(userActions.loadUserData, state => ({
        ...state,
        loading: true,
        error: false,
    })),
    on(userActions.loadUserDataSuccess, (state, { user, timezones }) => ({
        ...state,
        loading: false,
        user,
        timezones: timezones || state.timezones,
    })),
    on(userActions.loadUserDataFailure, state => ({
        ...state,
        loading: false,
        error: true,
    })),

    /** Profile Update */
    on(userActions.updateUserProfile, (state, { userData }) => {
        // Merge the partial request into the state user so the forms don't reset
        const user: User = { ...state.user, ...userData };
        return {
            ...state,
            user,
            updating: true,
            updateError: null,
        };
    }),
    on(userActions.updateUserProfileSuccess, (state, { user }) => ({
        ...state,
        user,
        updating: false,
    })),
    on(userActions.updateUserProfileFailure, (state, { error }) => ({
        ...state,
        updating: false,
        updateError: error,
    })),

    /** Activities */
    on(userActions.loadActivities, (state, { append }) => ({
        ...state,
        loadingActivities: true,
        activitiesError: false,
        activitiesInfo: append ? state.activitiesInfo : null, // Activities are more fleeting so unless we append we set it back to null
    })),
    on(
        userActions.loadActivitiesSuccess,
        (state, { activitiesInfo, append }) => {
            if (append) {
                activitiesInfo = {
                    data: state.activitiesInfo.data.concat(activitiesInfo.data), // Append is true so we add the new data to the end
                    links: activitiesInfo.links,
                    meta: activitiesInfo.meta,
                };
            }

            return {
                ...state,
                loadingActivities: false,
                activitiesInfo,
            };
        }
    ),
    on(userActions.loadActivitiesFailure, state => ({
        ...state,
        loadingActivities: false,
        activitiesError: true,
    }))
);

export function userReducer(state: UserState | undefined, action: Action) {
    return reducer(state, action);
}
