<div class="card shadow-sm">
    <div class="card-body">
        <div class="d-flex align-items-center">
            <h5 class="m-0">{{ 'hub.quotations.details.offer.edit-offer' | translate }}</h5>
        </div>
        <ngb-alert
            (closed)="onDismissError()"
            *ngIf="error$ | async"
            class="mt-3 mb-0"
            type="danger"
        >
            {{ 'common.generic-error-alt' | translate }}
        </ngb-alert>
    </div>

    <div class="card-body">
        <qwyk-quotations-offer-form
            (save)="onUpdate($event)"
            [disabled]="loading$ | async"
            [quotationOffer]="selectedQuotationOffer$ | async"
        ></qwyk-quotations-offer-form>
    </div>
</div>
