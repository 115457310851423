import { Injectable } from '@angular/core';

import { select, Store, Action } from '@ngrx/store';

import * as fromUsers from './users.reducer';
import * as UsersSelectors from './users.selectors';
import * as UsersActions from './users.actions';
import { Hub } from '@qwyk/models';

@Injectable()
export class UsersFacade {
    loaded$ = this.store.pipe(select(UsersSelectors.getUsersLoading));
    allUsers$ = this.store.pipe(select(UsersSelectors.getAllUsers));
    selectedUsers$ = this.store.pipe(select(UsersSelectors.getSelected));
    loading$ = this.store.pipe(select(UsersSelectors.getUsersLoading));
    pagination$ = this.store.pipe(select(UsersSelectors.getUsersPagination));
    error$ = this.store.pipe(select(UsersSelectors.getUsersError));

    constructor(private store: Store<fromUsers.UsersPartialState>) {}

    private dispatch(action: Action) {
        this.store.dispatch(action);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public loadHubUsers(query: any): void {
        this.dispatch(UsersActions.loadUsers({ query }));
    }

    public selectUser(id: number): void {
        this.dispatch(UsersActions.selectUser({ id }));
    }

    public unselectUser(): void {
        this.dispatch(UsersActions.unselectUser());
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public createUser(payload: any): void {
        this.dispatch(UsersActions.createUser({ payload }));
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public updateUser(id: number, payload: any): void {
        this.dispatch(UsersActions.updateUser({ id, payload }));
    }

    public deactivateUser(user: Hub.HubUser): void {
        this.dispatch(UsersActions.deactivateUser({ user }));
    }

    public activateUser(user: Hub.HubUser): void {
        this.dispatch(UsersActions.activateUser({ user }));
    }
}
