/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { common, Hub } from '@qwyk/models';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { environment } from '@qwyk/hub/environment';

@Injectable({
    providedIn: 'root',
})
export class SitesService {
    constructor(private http: HttpClient) {}

    public getSites(query: any): Observable<common.Pagination<Hub.Site>> {
        let httpQuery = null;
        if (query) {
            httpQuery = new HttpParams({ fromObject: query });
        }
        return this.http
            .get<common.Pagination<Hub.Site>>(
                `${environment.backendServer}/api/hub/administration/sites?${httpQuery}`
            )
            .pipe(take(1));
    }

    public getSite(id: number): Observable<Hub.Site> {
        return this.http
            .get<{ data: Hub.Site }>(
                `${environment.backendServer}/api/hub/administration/sites/${id}`
            )
            .pipe(
                take(1),
                map(response => response.data)
            );
    }

    public validateEndpoint(
        endpoint: string,
        currentSiteId: number
    ): Observable<any> {
        return this.http
            .get<any>(
                `${environment.backendServer}/api/hub/administration/sites/validate_endpoint/${endpoint}/${currentSiteId}`
            )
            .pipe(take(1));
    }

    public createSite(site: Partial<Hub.Site>): Observable<Hub.Site> {
        return this.http
            .post<{ data: Hub.Site }>(
                `${environment.backendServer}/api/hub/administration/sites`,
                site
            )
            .pipe(
                take(1),
                map(response => response.data)
            );
    }

    public updateSite(site: any): Observable<Hub.Site> {
        delete site.contrast_logo_url;
        delete site.logo_url;
        delete site.favicon_url;
        delete site.homepage_hero_url;

        let payload: any | FormData = site;

        // parse the bools to a number for compatibility between JSON and FormData payload
        const bools = [
            'active',
            'new_landingpage_optin',
            'show_rate_carrier_info',
            'is_quotes_hide_schedules',
            'is_quotes_quickbook_enabled',
            'new_landingpage_dark_navbar',
            'new_landingpage_show_navbar',
            'landingpage_require_port_entry_for_door',
            'landingpage_quote_expiration_leeway',
        ];
        bools.forEach(bool => {
            payload[bool] = String(payload[bool] ? +payload[bool] : 0);
        });

        if (
            payload.logo_file ||
            payload.favicon_file ||
            payload.homepage_hero_file ||
            payload.contrast_logo_file
        ) {
            const formData: FormData = new FormData();

            Object.keys(payload).forEach(key => {
                if (
                    ![
                        'logo_file',
                        'logo_url',
                        'contrast_logo_file',
                        'contrast_logo_url',
                        'favicon_file',
                        'favicon_url',
                        'homepage_hero_url',
                        'homepage_hero_file',
                        'homepage_links',
                        'landingpage_header_label',
                        'landingpage_default_additionals',
                        'landingpage_component_order',
                        'id',
                    ].includes(key)
                ) {
                    formData.append(key, (site[key] || '').toString());
                }
            });

            for (const arrayKey of [
                'homepage_links',
                'landingpage_default_additionals',
                'landingpage_component_order',
            ]) {
                if (payload[arrayKey]) {
                    formData.append(
                        `${arrayKey}_array`,
                        JSON.stringify(payload[arrayKey])
                    );
                } else {
                    delete payload[arrayKey];
                }
            }

            for (const mediaItemKey of [
                'logo_file',
                'favicon_file',
                'homepage_hero_file',
                'contrast_logo_file',
            ]) {
                if (payload[mediaItemKey]) {
                    formData.append(
                        mediaItemKey,
                        site[mediaItemKey],
                        site[mediaItemKey].name
                    );
                } else {
                    delete payload[mediaItemKey];
                }
            }

            if (
                !formData.get('homepage_links') &&
                !formData.get('homepage_links_array')
            ) {
                formData.append('homepage_links', '');
            }

            payload = formData;
        } else {
            delete payload.logo_file;
            delete payload.favicon_file;
            delete payload.homepage_hero_file;
            delete payload.contrast_logo_file;
        }

        return this.http
            .post<{ data: Hub.Site }>(
                `${environment.backendServer}/api/hub/administration/sites/${site.id}`,
                payload
            )
            .pipe(
                take(1),
                map(d => d.data)
            );
    }
}
