<p-table
    #table
    (onRowReorder)="onRowReorder($event, table)"
    [resizableColumns]="true"
    [value]="offerLinesArray.controls"
    dataKey="value.id"
>
    <ng-template pTemplate="header">
        <tr>
            <th rowspan="2" style="width: 45px"></th>
            <th pResizableColumn rowspan="2" style="width: 150px">{{ 'hub.quotations.details.offer.columns.segment' | translate }}</th>
            <th pResizableColumn style="min-width: 120px">{{ 'hub.quotations.details.offer.columns.description' | translate }}</th>
            <th pResizableColumn style="width: 110px">{{ 'hub.quotations.create.fields.currency' | translate }}</th>
            <th pResizableColumn>{{ 'hub.quotations.details.offer.columns.calculation' | translate }}</th>
            <th class="text-right" pResizableColumn style="width: 115px">
                {{ 'hub.quotations.details.offer.columns.buying-rate' | translate }}
            </th>
            <th class="text-right" pResizableColumn style="width: 115px">
                {{ 'hub.quotations.details.offer.columns.markup' | translate }}
            </th>
            <th class="text-right" pResizableColumn style="width: 115px">
                {{ 'hub.quotations.details.offer.columns.selling-rate' | translate }}
            </th>
            <th class="text-right" pResizableColumn style="width: 115px">
                {{ 'hub.quotations.details.offer.columns.total-sell' | translate }}
            </th>
            <th rowspan="2" style="width: 100px"></th>
        </tr>
        <tr>
            <th class="font-weight-normal small" colspan="3">{{ 'hub.common.remarks' | translate }}</th>
            <th class="font-weight-normal small text-right">{{ 'hub.common.minimum' | translate }}</th>
            <th class="font-weight-normal small text-right">% {{ 'hub.quotations.details.offer.columns.markup' | translate }}</th>
            <th class="font-weight-normal small text-right">{{ 'hub.common.minimum' | translate }}</th>
            <th class="font-weight-normal small text-right">{{ 'hub.quotations.details.offer.columns.buying' | translate }}</th>
        </tr>
    </ng-template>

    <ng-template let-index="rowIndex" let-rowData pTemplate="body">
        <div
            (deleteRow)="onDeleteRow($event)"
            (editingRow)="onEditingRow($event)"
            (finishEditingRow)="onFinishEditingRow()"
            [chargeCodes]="chargeCodes$ | async"
            [chargeSegments]="chargeSegments"
            [currencies]="currencies$ | async"
            [disabled]="
                (editingRowIndex !== null && editingRowIndex !== index) ||
                disabled
            "
            [editing]="editingRowIndex === index"
            [offerCurrency]="offerCurrency"
            [offerLineGroup]="rowData"
            [pReorderableRow]="index"
            [rateCalculations]="rateCalculations$ | async"
            [rowIndex]="index"
            [quotationId]="quotationId"
            qwyk-offer-lines-row
        ></div>
    </ng-template>

    <ng-template pTemplate="emptymessage">
        <tr>
            <td class="text-center py-3" colspan="10">
                <div>{{ 'common.no-items-yet' | translate : { items: 'hub.quotations.details.offer.columns.charges' | translate | lowercase } }}</div>
                <button
                    (click)="onAddCharge()"
                    type="button"
                    class="btn btn-outline-primary btn-sm mt-3"
                    [disabled]="disabled"
                >
                    <fa-icon [icon]="['far', 'plus']" class="mr-2"></fa-icon>
                    {{ 'common.add-a-item' | translate : { value: 'hub.quotations.details.offer.columns.charge' | translate | lowercase } }}
                </button>
            </td>
        </tr>
    </ng-template>

    <ng-template pTemplate="footer">
        <tr>
            <td colspan="7" rowspan="4" style="vertical-align: top">
                <button
                    (click)="onAddCharge()"
                    [disabled]="editingRowIndex !== null || disabled"
                    class="btn btn-outline-primary"
                    type="button"
                >
                    <fa-icon [icon]="['far', 'plus']" class="mr-2"></fa-icon>
                    {{ 'common.add-a-item' | translate : { value: 'hub.quotations.details.offer.columns.charge' | translate | lowercase } }}
                </button>
            </td>
            <td>{{ 'hub.quotations.details.offer.columns.total-sell' | translate }}</td>
            <td class="font-weight-bold text-right">
                {{ totalAmount | currency: offerCurrency }}
            </td>
            <td rowspan="4"></td>
        </tr>
        <tr>
            <td>{{ 'hub.quotations.details.offer.columns.total-buy' | translate }}</td>
            <td class="font-weight-bold text-right">
                {{ totalBuyingAmount | currency: offerCurrency }}
            </td>
        </tr>
        <tr>
            <td rowspan="2">{{ 'hub.quotations.details.offer.columns.margin' | translate }}</td>
            <td class="font-weight-bold text-right">
                {{ totalAmount - totalBuyingAmount | currency: offerCurrency }}
            </td>
        </tr>
        <tr>
            <td class="font-weight-bold text-right">
                <ng-container *ngIf="totalAmount; else zero">
                    {{
                        (totalAmount - totalBuyingAmount) / totalAmount
                            | percent: '0.2-2'
                    }}
                </ng-container>
                <ng-template #zero>0%</ng-template>
            </td>
        </tr>
    </ng-template>
</p-table>
