import {
    Input,
    Output,
    Component,
    HostBinding,
    EventEmitter,
} from '@angular/core';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import {
    ActivityType,
    ActivitiesEntity,
    CommentActivityPayload,
} from '@qwyk/shared-stores/activities';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: '[qwyk-activity-list-item]',
    templateUrl: './activity-list-item.component.html',
    styleUrls: ['./activity-list-item.component.scss'],
})
export class ActivityListItemComponent {
    @Input() activity!: ActivitiesEntity;
    @Output() updateComment: EventEmitter<{
        activity: ActivitiesEntity;
        payload: CommentActivityPayload;
    }> = new EventEmitter<{
        activity: ActivitiesEntity;
        payload: CommentActivityPayload;
    }>();
    @Output() deleteComment: EventEmitter<ActivitiesEntity> =
        new EventEmitter<ActivitiesEntity>();
    @Input() saving = false;
    @Input() loading = false;
    @Input() canMutate = false;
    @HostBinding('class') _class = 'qwyk-activity-list-item';

    public get icon(): IconName {
        switch (this.activity.type) {
            case ActivityType.COMMENT:
                return 'comment';
            default:
                return 'info-circle';
        }
    }
}
