<div class='card-header bg-white p-4 border-0'>
    <a [routerLink]="['/', 'notifications']" class='h5 card-title m-0 text-dark'>
        {{ 'hub.home.recent-notifications' | translate }}
    </a>
</div>
<qwyk-notifications-list
    [error]="error$ | async"
    [loading]="loading$ | async"
    [notifications]="notifications$ | async"
    actionButtonClass="btn-sm"
    class="list-group-flush border-0"
    errorWrapperClass="px-4"
    itemClass="border-0"
></qwyk-notifications-list>
