<div class="d-inline-block" ngbDropdown placement="bottom-end">
    <button class="btn btn-outline-light text-dark border" id="dropdownBasic1" ngbDropdownToggle type="button">
        {{ 'hub.activities.sort-or-filter' | translate }}
    </button>
    <div aria-labelledby="dropdownBasic1" ngbDropdownMenu style="min-width: 200px;">
        <button (click)="setDirection('desc')" ngbDropdownItem>
            <div class="d-flex">
                <div style="width: 30px;">
                    <fa-icon *ngIf="direction==='desc'" [icon]="['far', 'check']"></fa-icon>
                </div>
                {{ 'hub.activities.sort-options.newest-first' | translate }}
            </div>
        </button>
        <button (click)="setDirection('asc')" ngbDropdownItem>
            <div class="d-flex">
                <div style="width: 30px;">
                    <fa-icon *ngIf="direction==='asc'" [icon]="['far', 'check']"></fa-icon>
                </div>
                {{ 'hub.activities.sort-options.oldest-first' | translate }}
            </div>
        </button>

        <div class="dropdown-divider"></div>

        <button (click)="setFilter('all')" ngbDropdownItem>
            <div class="d-flex">
                <div style="width: 30px;">
                    <fa-icon *ngIf="filter==='all'" [icon]="['far', 'check']"></fa-icon>
                </div>
                {{ 'hub.activities.show-options.all-activity' | translate }}
            </div>
        </button>
        <button (click)="setFilter('comment')" ngbDropdownItem>
            <div class="d-flex">
                <div style="width: 30px;">
                    <fa-icon *ngIf="filter==='comment'" [icon]="['far', 'check']"></fa-icon>
                </div>
                {{ 'hub.activities.show-options.comments-only' | translate }}
            </div>
        </button>
        <button (click)="setFilter('event')" ngbDropdownItem>
            <div class="d-flex">
                <div style="width: 30px;">
                    <fa-icon *ngIf="filter==='event'" [icon]="['far', 'check']"></fa-icon>
                </div>
                {{ 'hub.activities.show-options.events-only' | translate }}
            </div>
        </button>
    </div>
</div>
