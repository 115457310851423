import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { StoreModule } from '@ngrx/store';
import { MomentModule } from 'ngx-moment';
import { EffectsModule } from '@ngrx/effects';
import {
    NgbAlertModule,
    NgbPaginationModule,
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { UiModule } from '@qwyk/ui';
import {
    AddressFormComponent,
    UserInfoTooltipComponent,
} from '@qwyk/ui-standalone';

import * as fromNotifications from './+state/notifications.reducer';
import { NotificationsFacade } from './+state/notifications.facade';
import { NotificationsEffects } from './+state/notifications.effects';
import { components, NotificationsListWidgetComponent } from './components';

@NgModule({
    imports: [
        CommonModule,
        UiModule,
        RouterModule,
        MomentModule,
        NgbAlertModule,
        TranslateModule,
        FontAwesomeModule,
        NgbPaginationModule,
        AddressFormComponent,
        UserInfoTooltipComponent,
        StoreModule.forFeature(
            fromNotifications.NOTIFICATIONS_FEATURE_KEY,
            fromNotifications.reducer
        ),
        EffectsModule.forFeature([NotificationsEffects]),
    ],
    declarations: components,
    providers: [NotificationsFacade],
    exports: [NotificationsListWidgetComponent],
})
export class HubNotificationsWithoutRoutingModule {}
