<div class="notification-list-item">
    <div class="d-flex align-items-center justify-content-between">
        <div class="mr-4">
            <img
                [src]="'https://www.gravatar.com/avatar/' + ((notification.sender_email || '') | md5) + '?d=https%3A%2F%2Fui-avatars.com%2Fapi%2F/' + ((notification?.sender || '??') | urlEncode) + '/64/097aff/ffffff/2//false/true/true'"
                alt="{{ 'common.avatar' | translate }}" class='rounded-circle border' style='width: 24px; height: 24px;'/>
        </div>
        <div class="flex-grow-1">
            <div class="info small text-muted">
                <span *ngIf="notification.sender">{{ notification.sender }} </span>
                <span>{{ notification.created_at | amTimeAgo }}</span>
            </div>
            <div>
                {{ notification.subject }}
            </div>
        </div>
        <div *ngIf="notification.url" class="flex-shrink-0 ml-3">
            <button [ngClass]="actionButtonClass" [routerLink]="routerLink" class="btn btn-primary">
                {{ 'common.view' | translate }}<span class="d-none d-lg-inline-block">&nbsp;{{ 'common.entities.' + notification.entity | lowercase | translate }}</span>
            </button>
        </div>
    </div>
</div>
