<form [formGroup]="form" (submit)="onCreate()">

    <h5 class="font-weight-bold mb-4" id="Account">{{ 'hub.menu.side-menu.account' | translate }}</h5>
    <div class="form-group form-group-lg">
        <label for="email">{{ 'hub.shipment.sections.instructions.subsections.parties-fields.email-address' | translate }}</label>
        <input type="email" id="email" name="email" formControlName="email" placeholder="{{ 'hub.shipment.sections.instructions.subsections.parties-fields.email-address' | translate }}"
            class="form-control" [ngClass]="{'is-invalid': form.get('email').touched && form.get('email').invalid}"
            maxlength="191" />
        <arv-validation-messages for="email"></arv-validation-messages>
    </div>

    <div class="form-group form-group-lg">
        <label for="password">{{ 'common.password' | translate }}</label>
        <div class="input-group">
            <input type="text" id="password" name="password" formControlName="password" placeholder="{{ 'common.password' | translate }}"
                class="form-control"
                [ngClass]="{'is-invalid': form.get('password').touched && form.get('password').invalid}"
                maxlength="36" />
            <div class="input-group-append">
                <button class="btn btn-light border" type="button" (click)="onSetRandomPassword()"
                    ngbTooltip="{{ 'hub.administration.portal-users.randomize-password' | translate }}">
                    <fa-icon [icon]="['far', 'sync']"></fa-icon>
                </button>
            </div>
        </div>
        <arv-validation-messages for="password"></arv-validation-messages>
        <small class="text-muted">{{ 'hub.administration.portal-users.we-ve-generated-a-random-password' | translate }}</small>
    </div>

    <div class="alert alert-primary small p-2 mt-3 mb-5">
        <fa-icon [icon]="['far', 'info-circle']" class="mr-1"></fa-icon>
        {{ 'hub.administration.portal-users.we-ll-email-their-account-details-to-them' | translate }}
    </div>

    <div class="form-group">
        <label for="team_id">{{ 'common.customer' | translate }}</label>
        <ng-select id="team_id" name="team_id" [items]="teams$ | async" bindValue="id" bindLabel="name"
            formControlName="team_id" placeholder="{{ 'hub.administration.portal-users.select-a-customer' | translate }}" (change)="onTeamSelected($event)"
            [ngClass]="{'is-invalid': form.get('team_id').touched && form.get('team_id').invalid}"></ng-select>
        <arv-validation-messages for="team_id"></arv-validation-messages>
    </div>

    <div class="form-group">
        <label for="registered_site_id">{{ 'hub.administration.portal-users.registered-site' | translate }}</label>
        <ng-select id="registered_site_id" name="registered_site_id" [items]="sites$ | async" bindValue="id"
            bindLabel="name" formControlName="registered_site_id" placeholder="{{ 'hub.administration.portal-users.select-a-site' | translate }}"
            [ngClass]="{'is-invalid': form.get('registered_site_id').touched && form.get('registered_site_id').invalid}">
        </ng-select>
        <arv-validation-messages for="registered_site_id"></arv-validation-messages>
        <small class="text-muted"
            *ngIf="!(form.get('registered_site_id').touched && form.get('registered_site_id').invalid)">{{ 'hub.administration.portal-users.which-site-you-select-determines-the-branding-of-emails-they-receive' | translate }}</small>
    </div>

    <div class="form-group">
        <div class="custom-control custom-switch">
            <input class="custom-control-input" type="checkbox" formControlName="restrict_to_registered_site"
                id="restrict_to_registered_site">
            <label class="custom-control-label" for="restrict_to_registered_site">{{ 'hub.administration.portal-users.restrict-to-this-site' | translate }}</label>
        </div>
    </div>

    <hr class="my-5">
    <h5 class="font-weight-bold mb-4" id="profile">{{ 'hub.menu.top-menu.profile' | translate }}</h5>

    <div class="form-group">
        <label for="name">{{ 'common.name' | translate }}</label>
        <input type="text" id="name" name="name" formControlName="name" maxlength="191" class="form-control"
            [ngClass]="{'is-invalid': form.get('name').touched && form.get('name').invalid}" placeholder="{{ 'portal.registration.page1.label-name' | translate }}" />
        <arv-validation-messages for="name"></arv-validation-messages>
    </div>

    <div class="form-row">
        <div class="form-group col-12 col-md-6">
            <label for="company_name">{{ 'common.company' | translate }}</label>
            <input type="text" id="company_name" name="company_name" formControlName="company_name" maxlength="191"
                placeholder="{{ 'portal.registration.page2.placeholder-company' | translate }}" class="form-control"
                [ngClass]="{'is-invalid': form.get('company_name').touched && form.get('company_name').invalid}" />
            <arv-validation-messages for="company_name"></arv-validation-messages>
        </div>
        <div class="form-group col-12 col-md-6">
            <label for="job_title">{{ 'portal.settings.profile.job-title' | translate }}</label>
            <input type="text" id="job_title" name="job_title" formControlName="job_title" maxlength="191"
                placeholder="{{ 'portal.settings.profile.job-title' | translate }}" class="form-control"
                [ngClass]="{'is-invalid': form.get('job_title').touched && form.get('job_title').invalid}" />
            <arv-validation-messages for="job_title"></arv-validation-messages>
        </div>
    </div>

    <div class="form-row">
        <div class="form-group col-12 col-md-7">
            <label for="location">{{ 'portal.events-index.columns.location' | translate }}</label>
            <input type="text" id="location" name="location" formControlName="location" maxlength="191"
                placeholder="{{ 'portal.settings.profile.city-state' | translate }}" class="form-control"
                [ngClass]="{'is-invalid': form.get('location').touched && form.get('location').invalid}" />
            <arv-validation-messages for="location"></arv-validation-messages>
        </div>
        <div class="form-group col-12 col-md-5">
            <label for="country_code">{{ 'common.country' | translate }}</label>
            <ng-select id="country_code" name="country_code" [items]="countries$ | async" [loading]="loadingCountries"
                bindValue="alpha_2_code" bindLabel="short_name" placeholder="{{ 'common.country' | translate }}" formControlName="country_code"
                [ngClass]="{'is-invalid': form.get('country_code').touched && form.get('country_code').invalid}">
                <ng-template ng-option-tmp ng-label-tmp let-item="item">
                    <div class="d-flex justify-content-start align-items-center" *ngIf="item">
                        <div>
                            <img [src]="'https://cdn.qwyk.io/images/flags/r/' + (item.alpha_2_code | lowercase) + '.svg'"
                                [attr.alt]="item.alpha_2_code" style="width: 16px; height: auto; margin-top: -4px;"
                                onerror="this.src='https://cdn.qwyk.io/images/flags/r/united-nations.svg'">
                        </div>
                        <div class="flex-grow-1 ml-1 text-left">{{ item.short_name }}</div>

                    </div>
                </ng-template>
            </ng-select>
            <arv-validation-messages for="country_code"></arv-validation-messages>
        </div>
    </div>

    <div class="form-group">
        <label for="phone_number">{{ 'hub.shipment.sections.instructions.subsections.parties-fields.phone-number' | translate }}</label>
        <input type="text" id="phone_number" name="phone_number" formControlName="phone_number" maxlength="15"
            placeholder="{{ 'hub.shipment.sections.instructions.subsections.parties-fields.phone-number' | translate }}" class="form-control"
            [ngClass]="{'is-invalid': form.get('phone_number').touched && form.get('phone_number').invalid}" />
        <arv-validation-messages for="phone_number"></arv-validation-messages>
    </div>

    <div class="form-group">
        <label>{{ 'portal.settings.profile.marketing' | translate }}</label>
        <div class="custom-control custom-switch">
            <input class="custom-control-input" type="checkbox" formControlName="marketing_optin" id="marketing_optin">
            <label class="custom-control-label" for="marketing_optin">{{ 'hub.administration.portal-users.opted-in-to-marketing-mails' | translate }}</label>
        </div>
    </div>

    <qwyk-form-error [error]="error"></qwyk-form-error>

    <hr />

    <div class="form-group">
        <label>{{ 'hub.administration.portal-users.privacy-compliance' | translate }}</label>
        <div class="custom-control custom-switch">
            <input class="custom-control-input" type="checkbox" formControlName="privacy_compliance" id="privacy_compliance">
            <label class="custom-control-label" for="privacy_compliance">
                {{ 'hub.administration.portal-users.create-user-confirmation-message' | translate }}
                <a href="javascript:void(0);" [ngbTooltip]="complianceTooltipText">
                    <fa-icon [icon]="['far', 'info-circle']"></fa-icon>
                    {{ 'common.learn-more' | translate }}
                </a>
            </label>
        </div>
        <arv-validation-messages for="privacy_compliance"></arv-validation-messages>
    </div>

    <div class="form-group pt-3 text-right border-top">
        <button class="btn btn-light" [routerLink]="['..']" type="button" [disabled]="form.disabled">{{ 'common.cancel' | translate }}</button>
        <button class="btn btn-primary ml-2" [disabled]="form.disabled">
            <fa-icon [icon]="['far', form.enabled ? 'save' : 'spinner']" [pulse]="form.disabled" class="mr-2">
            </fa-icon>
            {{ 'hub.administration.portal-users.create-user' | translate }}
        </button>
    </div>
</form>
