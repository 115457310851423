<div class="modal-header border-bottom-0 px-4 pt-4 pb-1">
    <div>
        <h5 class="modal-title">{{ 'hub.administration.portal-users.impersonate' | translate }} {{ user?.name }}</h5>
        <p>{{ 'hub.administration.portal-users.log-into-your-portal-by-creating-a-session-for-the-selected-user' | translate }}</p>
    </div>
</div>
<div class="modal-body px-4 pt-0">
    <div class="form-group p-fluid">
        <label for="site">{{ 'hub.administration.portal-users.log-on-to' | translate }}:</label>
        <p-dropdown
            [(ngModel)]="selectedSite"
            [options]="sites$ | async"
            id="site"
            optionLabel="name"
            placeholder="{{ 'hub.administration.portal-users.select-a-site' | translate }}"
        >
        </p-dropdown>
    </div>
    <div class="alert alert-danger" *ngIf="error">
        {{ error }}
    </div>
</div>
<div class="modal-footer border-top-0 pt-0 px-4 pb-4">
    <button (click)="onContinue()" class="btn btn-primary">{{ 'common.continue' | translate }}</button>
    <button (click)="onCancel()" class="btn btn-light">{{ 'common.cancel' | translate }}</button>
</div>
