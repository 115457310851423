<div class="modal-header border-bottom-0 px-4 pt-4 pb-1">
    <h5 class="modal-title">Send a spot rate request</h5>
</div>
<form [formGroup]="form" (submit)="onSend()">
    <div class="modal-body px-4 pt-0">
        <p class="text-muted pb-4">Send your network partner a request.</p>

        <div class="form-group">
            <label for="reason">Why would you like a custom rate?</label>
            <ng-select id="reason" formControlName="reason" [items]="reasons" required [clearable]="false"
                       placeholder="Please select a reason"
                       [ngClass]="{'is-invalid': form.get('reason').invalid && form.get('reason').touched}"></ng-select>

        </div>
        <div class="form-group">
            <label for="remarks">Any remarks you'd like to add?</label>
            <textarea id="remarks" name="remarks" formControlName="remarks" class="form-control" rows="3"
                      placeholder="Enter some optional remarks to help us deliver you the perfect response." maxlength="255"
                      [ngClass]="{'is-invalid': form.get('remarks').invalid && form.get('remarks').touched}"></textarea>

        </div>

        <div class="alert alert-danger" *ngIf="error">Something went wrong, please try again or contact support
            if the
            problem persists.</div>
    </div>
    <div class="modal-footer border-top-0 pt-0 px-4 pb-4">
        <button class="confirm btn btn-primary" type="submit" [disabled]="form.disabled">
            <fa-icon [icon]="['far', busy ? 'spinner' : 'paper-plane']" class="mr-2"
                     [pulse]="busy">
            </fa-icon>
            Send
        </button>
        <button class="dismiss btn btn-light" type="button" (click)="onCancel()"
                [disabled]="form.disabled">Cancel</button>
    </div>
</form>
