import {
    Component,
    ElementRef,
    HostListener,
    Input,
    OnInit,
    ViewChild,
} from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { AuthenticationFacade } from '@qwyk/hub/authentication';

import { HelpModalComponent } from '../help-modal/help-modal.component';
import { MenuSideBarService } from '../../services/menu-side-bar.service';

@Component({
    selector: 'app-side-bar',
    templateUrl: './side-bar.component.html',
    styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent implements OnInit {
    public user$ = this.authentication.user$;

    @ViewChild('wrapper', { static: true }) wrapper: ElementRef;
    @ViewChild('scrollContainer', { static: true }) scrollContainer: ElementRef;
    @ViewChild('scrollContainerContent', { static: true })
    scrollContainerContent: ElementRef;
    @ViewChild('staticContainer', { static: true }) staticContainer: ElementRef;
    public shouldScrollContainerScroll = false;

    @Input() public isMagayaNetwork = false;

    constructor(
        public menuSideBar: MenuSideBarService,
        private authentication: AuthenticationFacade,
        private component: ElementRef,
        private modal: NgbModal
    ) {}

    ngOnInit(): void {
        this.scrollContainerContentEl.onresize = () => this.onWindowResize();
        // this.scrollContainerContentEl.on
        setTimeout(() => {
            this.onWindowResize();
        }, 50);
    }

    private get scrollContainerEl(): HTMLElement {
        return this.scrollContainer.nativeElement;
    }

    private get scrollContainerContentEl(): HTMLElement {
        return this.scrollContainerContent.nativeElement;
    }

    private get staticContainerEl(): HTMLElement {
        return this.staticContainer.nativeElement;
    }

    private get wrapperEl(): HTMLElement {
        return this.wrapper.nativeElement;
    }

    public observeScrollContainerContent() {}

    /*
    public onScrollContainerContentResized(e: ResizedEvent) {
        if (e.newRect !== e.oldRect) {
            this.onWindowResize();
        }
    }
    */

    @HostListener('window:resize')
    public onWindowResize() {
        if (
            this.scrollContainerContentEl.clientHeight >
            this.wrapperEl.clientHeight - this.staticContainerEl.clientHeight
        ) {
            this.shouldScrollContainerScroll = true;
        } else {
            this.shouldScrollContainerScroll = false;
        }
    }

    private inspectLog() {
        console.log([
            this.scrollContainerEl.clientHeight,
            this.scrollContainerContentEl.clientHeight,
            this.staticContainerEl.clientHeight,
            this.wrapperEl.clientHeight,
            this.wrapperEl.clientHeight - this.staticContainerEl.clientHeight,
        ]);
    }

    public onShowHelpCenter() {
        this.modal.open(HelpModalComponent, {
            centered: true,
            size: 'xl',
        });
    }
}
