import { environment } from '@qwyk/hub/environment';
import {
    CarriagesResponse,
    Carriage,
} from './../store/models/carriages.models';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class CarriagesService {
    constructor(private http: HttpClient) {}

    /**
     * Gets a paged result of the carriages for the logged on user's organization .
     * @param page the results page to get.
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getCarriages(page: number, query?: any): Observable<CarriagesResponse> {
        // Build the queryParams
        const params = new URLSearchParams();
        params.set('page', page.toString());

        if (query) {
            Object.keys(query).forEach(key => {
                params.set(key, query[key]);
            });
        }

        return this.http
            .get<CarriagesResponse>(
                `${
                    environment.schedulesBackend
                }/api/carriages?${params.toString()}`
            )
            .pipe(take(1));
    }

    /**
     * Gets a specific carriage from the server.
     * @param id the id of the carriage to get
     */
    getCarriage(id: number): Observable<Carriage> {
        return this.http
            .get<Carriage>(
                `${environment.schedulesBackend}/api/carriages/${id}`
            )
            .pipe(take(1));
    }

    /**
     * Sends a request to the server to create a new carriage.
     * @param carriage The data for the carriage to be created
     */
    createCarriage(carriage: Carriage): Observable<Carriage> {
        return this.http
            .post<Carriage>(
                `${environment.schedulesBackend}/api/carriages/`,
                carriage
            )
            .pipe(take(1));
    }

    /**
     * Updates a carriage on the server.
     * @param carriage The carriage to update
     */
    updateCarriage(carriage: Carriage): Observable<Carriage> {
        return this.http
            .post<Carriage>(
                `${environment.schedulesBackend}/api/carriages/${carriage.id}`,
                carriage
            )
            .pipe(take(1));
    }

    /**
     * Deletes a carriage from the server.
     * @param carriage The carriage to delete.
     */
    deleteCarriage(carriage: Carriage): Observable<void> {
        return this.http
            .delete<void>(
                `${environment.schedulesBackend}/api/carriages/${carriage.id}`
            )
            .pipe(take(1));
    }

    /**
     * Starts carriages download
     * @returns Observable with the HTTP request.
     */
    startCarriagesDownload(): Observable<void> {
        return this.http
            .post<void>(
                `${environment.schedulesBackend}/api/carriages/download`,
                null
            )
            .pipe(take(1));
    }

    startCarriagesUpload(formValue: {
        carriages_file: File;
    }): Observable<void> {
        const payload: FormData = new FormData();
        payload.append(
            'file',
            formValue.carriages_file,
            formValue.carriages_file.name
        );

        return this.http
            .post<void>(
                `${environment.schedulesBackend}/api/carriages/upload`,
                payload
            )
            .pipe(take(1));
    }
}
