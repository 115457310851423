<div class="bg-white min-vh-100">
    <div class="container-fluid py-3 pb-5">
        <div class="row justify-content-center">
            <div class="col-12 col-lg-9 col-xxxl-6">
                <div class="small text-muted mb-3 border-bottom pb-2">
                    <a class="text-muted" [routerLink]="['/']">Qwyk</a>
                    &rsaquo;
                    <a class="text-muted" [routerLink]="['/administration']">{{ 'hub.administration.title' | translate }}</a>
                    &rsaquo;
                    <a class="text-muted" [routerLink]="['./../']">{{ 'hub.administration.portal-users.title' | translate }}</a>
                    &rsaquo;
                    <a class="text-muted font-weight-bold" [routerLink]="['./']"
                       *ngIf="selectedCustomerUser$ | async; let user; else newUser">{{ user.name }}</a>
                    <ng-template #newUser>
                        <a class="text-muted font-weight-bold" [routerLink]="['./']">{{ 'common.new' | translate }}</a>
                    </ng-template>
                </div>

                <div class="border-bottom d-flex mb-3 pb-3 align-items-center"
                     *ngIf="selectedCustomerUser$ | async; let user;">
                    <div class="flex-grow-1">
                        <span class="text-muted">
                            <span *ngIf="!user.email_verified" class="badge badge-outline-warning mr-2"
                                  ngbTooltip="{{ 'hub.administration.portal-users.email-verification-is-pending' | translate }}" style="font-size: 100%;">
                                <fa-icon [icon]="['fas', 'envelope']"></fa-icon>
                                {{ 'hub.administration.portal-users.email-pending' | translate }}
                            </span>
                            <span *ngIf="user.email_verified && !user.approved" class="badge badge-outline-danger mr-2"
                                  ngbTooltip="{{ 'hub.administration.portal-users.this-user-needs-to-be-approved-before-they-can-access-your-portal' | translate }}"
                                  style="font-size: 100%;">
                                <fa-icon [icon]="['fas', 'shield-check']"></fa-icon>
                                {{ 'hub.administration.portal-users.approval-pending' | translate }}
                            </span>
                            <ng-container *ngIf="user.approved">
                                <fa-icon [icon]="['fas', 'shield-check']" class="text-success mr-1"></fa-icon>
                                {{ 'portal.invoices.index-page.index.search.invoice-index-approval-status-options.approved' | translate }} {{ user.approved_at | amTimeAgo }} {{ 'common.by' | translate }} {{ user.approved_by }}
                            </ng-container>
                        </span>
                    </div>
                </div>

                <div class="d-flex flex-row justify-content-between mb-3">
                    <h3 class="font-weight-normal m-0">{{ 'hub.administration.portal-users.portal-user' | translate }}</h3>
                </div>

                <qwyk-customer-user-detail
                    #customerUserComponent
                    *ngIf="selectedCustomerUser$ | async; let user; else customerUserComponent"
                    [user]="user" (save)="onUpdate($event)" (deactivate)="onDeactivate($event)"
                    (activate)="onActivate($event)" [error]="error$ | async"
                    [isMagayaNetwork]="isMagayaNetwork$ | async"
                    [saving]="saving$ | async"
                >
                </qwyk-customer-user-detail>
                <ng-template #customerUserComponent>
                    <qwyk-customer-user-create
                        (save)="onCreate($event)"
                        [saving]="saving$ | async"
                        [error]="error$ | async"
                    >
                    </qwyk-customer-user-create>
                </ng-template>
            </div>
        </div>
    </div>
</div>
