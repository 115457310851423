<form (submit)="onSave()" [formGroup]="form">
    <div class="form-group">
        <label for="name">{{ 'common.name' | translate }}</label>
        <input [ngClass]="{'is-invalid': form.get('name').invalid && form.get('name').touched}" class="form-control"
               formControlName="name" id="name" maxlenght="40" name="name"
               type="text"/>
        <div class="invalid-feedback">{{ 'common.validation.enter-a-name' | translate }}</div>
    </div>

    <div class="form-group">
        <label for="description">{{ 'common.description' | translate }}</label>
        <textarea class="form-control" formControlName="description" id="description" maxlength="191"
                  name="description" placeholder="{{ 'hub.administration.sites.describe-your-site' | translate }}..." rows="4"></textarea>
    </div>

    <div class="form-group">
        <label for="active">{{ 'common.active' | translate }}</label>
        <div class="custom-control custom-switch">
            <input class="custom-control-input" formControlName="active" id="active" name="active" type="checkbox">
            <label class="custom-control-label" for="active"></label>
        </div>
    </div>

    <hr class="my-5">
    <h5 class="font-weight-bold mb-4" id="hosting">{{ 'hub.administration.sites.hosting' | translate }}</h5>

    <ng-container>
        <div class="form-group">
            <label for="endpoint_identifier">{{ 'common.address' | translate }}</label>
            <div
                [ngClass]="{'is-invalid': form.get('endpoint_identifier').invalid && form.get('endpoint_identifier').touched}"
                class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text">{{ siteAddressPrefix }}</span>
                </div>
                <input
                    [ngClass]="{'is-invalid': form.get('endpoint_identifier').invalid && form.get('endpoint_identifier').touched}"
                    class="form-control" formControlName="endpoint_identifier"
                    id="endpoint_identifier" maxlength="30" name="endpoint_identifier"
                    type="text"/>
                <div class="input-group-append">
                    <span class="input-group-text">{{ siteAddressSuffix }}</span>
                </div>
            </div>
            <div class="invalid-feedback">
                <div *ngIf="!form.get('endpoint_identifier').errors?.validated_endpoint">
                    {{ 'common.validation.enter-an-address' | translate }}
                </div>
                <div *ngIf="form.get('endpoint_identifier').errors?.validated_endpoint">
                    {{ form.get('endpoint_identifier').errors.validated_endpoint }}
                </div>
            </div>
            <small class="form-text text-muted">
                <span class="strong">{{ 'common.warning' | translate }}: </span>
                <span>
                    {{ 'hub.administration.sites.warning-message-part1' | translate }}
                    <strong>{{ 'hub.administration.sites.warning-message-part2' | translate }}</strong>
                    {{ 'hub.administration.sites.warning-message-part3' | translate }}
                </span>
            </small>
        </div>

        <div class="form-group">
            <label for="cname">{{ 'hub.administration.sites.alias' | translate }}
                <h5 class="badge badge-primary ml-1 font-weight-normal" ngbTooltip="{{ 'hub.administration.sites.premium' | translate }}">
                    <fa-icon [icon]="['fas', 'award']"></fa-icon>
                </h5>
            </label>
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text">https://</span>
                </div>
                <input class="form-control bg-white" formControlName="cname" id="cname" name="cname" type="text"/>
            </div>
            <small class="form-text text-muted">{{ 'hub.administration.sites.setup-by-qwyk' | translate }} <a
                    href="https://docs.qwykportals.com/developer-guides/custom-domain"
                    target="_blank">https://docs.qwykportals.com/developer-guides/custom-domain</a></small>
        </div>

        <div class="form-group">
            <label for="alternative_homepage">{{ 'hub.administration.sites.alternative-homepage' | translate }}</label>
            <input
                [ngClass]="{'is-invalid': form.get('alternative_homepage').invalid && form.get('alternative_homepage').touched}"
                class="form-control" formControlName="alternative_homepage" id="alternative_homepage"
                name="alternative_homepage" placeholder="{{ 'hub.administration.sites.site-address-placeholder' | translate }}"
                type="url"/>
            <div class="invalid-feedback">{{ 'hub.administration.sites.enter-a-valid-site' | translate }}</div>
            <small class="form-text text-muted">
                {{ 'hub.administration.sites.alternative-homepage-message' | translate }}
            </small>
        </div>
    </ng-container>

    <hr class="my-5">
    <h5 class="font-weight-bold mb-4" id="colors">{{ 'hub.administration.sites.colors' | translate }}</h5>

    <ng-container>
        <div class="form-row">
            <div *ngFor="let c of colorsArray" class="form-group col-6 col-md-4 col-lg-3">
                <label [attr.for]="c + '_color'">{{ 'hub.administration.sites.colors-schema.' + c | translate }}</label>
                <div *ngIf="form.get(c + '_color'); let control"
                     [ngClass]="{'is-invalid': control.invalid && control.touched}"
                     class="input-group mr-2">
                    <div class="input-group-prepend">
                        <p-colorPicker [formControlName]="c + '_color'" [ngModel]="control?.value">
                        </p-colorPicker>
                    </div>
                    <input [attr.id]="c + '_color'" [attr.name]="c + '_color'" [formControlName]="c + '_color'"
                           [ngClass]="{'is-invalid': control.invalid && control.touched}" [ngModel]="control?.value"
                           class="form-control"
                           maxLength="7"
                           type="text">
                </div>
                <div class="invalid-feedback">{{ 'hub.administration.sites.enter-a-valid-color-hex' | translate }}</div>
                <small class="form-text text-muted">
                    <a (click)="setColorToDefault(c)" href="javascript:void(0);">{{ 'hub.administration.sites.set-to-default-color' | translate }}</a>
                </small>
            </div>
        </div>
    </ng-container>

    <hr class="my-5">
    <h5 class="font-weight-bold mb-4" id="fonts">{{ 'hub.administration.sites.fonts' | translate }}</h5>

    <ng-container>
        <div class="form-group form-row">
            <label class="col-2 col-form-label" for="font_headers">{{ 'hub.administration.sites.headers' | translate }}</label>
            <div class="col">
                <ng-select [clearable]="false" [items]="fontsArray"
                           [ngClass]="{'is-invalid': form.get('font_headers').invalid && form.get('font_headers').touched}"
                           formControlName="font_headers"
                           id="font_headers"
                           name="font_headers"></ng-select>
                <div class="invalid-feedback">{{ 'hub.administration.sites.select-a-font' | translate }}</div>
            </div>
            <div class="col">
                <ng-select [clearable]="false" [items]="fontWeightsArray"
                           [ngClass]="{'is-invalid': form.get('font_weight_headings').invalid && form.get('font_weight_headings').touched}"
                           bindLabel="title" bindValue="value" formControlName="font_weight_headings"
                           id="font_weight_headings"
                           name="font_weight_headings">
                </ng-select>
                <div class="invalid-feedback">{{ 'hub.administration.sites.select-a-font-weight' | translate }}</div>
            </div>
            <label class="col col-form-label text-center">
                <h5 [ngStyle]="{'font-family': form.get('font_headers').value, 'font-weight': form.get('font_weight_headings').value}"
                    class="m-0">
                    {{ 'common.preview' | translate }}
                </h5>
            </label>
        </div>

        <div class="form-group form-row">
            <label class="col-2 col-form-label" for="font_sans_serif">{{ 'hub.administration.sites.body' | translate }}</label>
            <div class="col">
                <ng-select [clearable]="false" [items]="fontsArray"
                           [ngClass]="{'is-invalid': form.get('font_sans_serif').invalid && form.get('font_sans_serif').touched}"
                           formControlName="font_sans_serif" id="font_sans_serif"
                           name="font_sans_serif">
                </ng-select>
                <div class="invalid-feedback">{{ 'hub.administration.sites.select-a-font' | translate }}</div>
            </div>
            <div class="col">
                <ng-select [clearable]="false" [items]="fontWeightsArray"
                           [ngClass]="{'is-invalid': form.get('font_weight_normal').invalid && form.get('font_weight_normal').touched}"
                           bindLabel="title" bindValue="value" formControlName="font_weight_normal"
                           id="font_weight_normal"
                           name="font_weight_normal">
                </ng-select>
                <div class="invalid-feedback">{{ 'hub.administration.sites.select-a-font-weight' | translate }}</div>
            </div>
            <label
                [ngStyle]="{'font-family': form.get('font_sans_serif').value, 'font-weight': form.get('font_weight_normal').value}"
                class="col col-form-label text-center">{{ 'common.preview' | translate }}</label>
        </div>
    </ng-container>

    <hr class="my-5">
    <h5 class="font-weight-bold mb-4" id="media">{{ 'hub.administration.sites.media' | translate }}</h5>

    <ng-container>
        <div class="form-group">
            <label>{{ 'hub.administration.sites.favicon' | translate }}
                <qwyk-info text="{{ 'hub.administration.sites.favicon-info' | translate }}"></qwyk-info>
            </label>
            <div>
                <img [src]="form.get('favicon_url').value"
                     class="mr-3" style="max-height: 24px; max-width: 24px; height: 24px; width: 24px;"/>
                <qwyk-file-upload (fileSelected)="uploadFile($event)" [disabled]="form.disabled" [maxSize]="100000"
                                  accept="image/*" buttonClass="btn-outline-primary btn-sm mt-2" name="favicon">
                </qwyk-file-upload>
            </div>
        </div>

        <div class="form-group">
            <label>{{ 'hub.administration.sites.logo' | translate }}</label>
            <div>
                <img [src]="form.get('logo_url').value" style="max-height: 80px; max-width: 200px;"/>

            </div>
            <qwyk-file-upload (fileSelected)="uploadFile($event)" [disabled]="form.disabled" [maxSize]="500000"
                              accept="image/*" buttonClass="btn-outline-primary btn-sm mt-2" name="logo">
            </qwyk-file-upload>
        </div>

        <div class="form-group">
            <label>{{ 'hub.administration.sites.contrasting-logo' | translate }}</label>
            <div>
                <div class="bg-dark text-white d-inline-block p-2">
                    <img [src]="form.get('contrast_logo_url').value"
                         alt="{{ 'hub.administration.sites.contrasting-logo-placeholder' | translate }}"
                         style="max-height: 80px; max-width: 200px;"/>
                </div>
            </div>
            <qwyk-file-upload (fileSelected)="uploadFile($event)" [disabled]="form.disabled" [maxSize]="500000"
                              accept="image/*" buttonClass="btn-outline-primary btn-sm mt-2" name="contrast_logo">
            </qwyk-file-upload>
        </div>

        <div class="form-group">
            <label>{{ 'hub.administration.sites.homepage-hero-image' | translate }}</label>
            <div>
                <img [src]="form.get('homepage_hero_url').value" style="max-height: 337.5px; max-width: 600px;"/>
            </div>
            <qwyk-file-upload (fileSelected)="uploadFile($event)" [disabled]="form.disabled" [maxSize]="1000000"
                              accept="image/*" buttonClass="btn-outline-primary btn-sm mt-2" name="homepage_hero">
            </qwyk-file-upload>
        </div>
    </ng-container>

    <hr class="my-5">
    <h5 class="font-weight-bold mb-4" id="titles">{{ 'hub.administration.sites.titles' | translate }}</h5>

    <ng-container>
        <div class="form-group">
            <label for="page_title">{{ 'hub.administration.sites.browser-title' | translate }}</label>
            <input [ngClass]="{'is-invalid': form.get('page_title').invalid && form.get('page_title').touched}"
                   class="form-control" formControlName="page_title" id="page_title" maxlength="100"
                   name="page_title" placeholder="{{ 'hub.administration.sites.browser-title-placeholder' | translate }}"
                   type="text">
            <div class="invalid-feedback">{{ 'hub.administration.sites.enter-a-browser-title' | translate }}</div>
        </div>

        <div class="form-group row">
            <div class="col">
                <label for="navbar_brand_title">{{ 'hub.administration.sites.branding-title' | translate }}</label>
                <input
                    [ngClass]="{'is-invalid': form.get('navbar_brand_title').invalid && form.get('navbar_brand_title').touched}"
                    class="form-control" formControlName="navbar_brand_title"
                    id="navbar_brand_title" maxlength="191"
                    name="navbar_brand_title" placeholder="{{ 'hub.administration.sites.branding-title-placeholder' | translate }}"
                    type="text">
                <div class="invalid-feedback">{{ 'hub.administration.sites.enter-a-branding-title' | translate }}</div>
            </div>
            <div class="col">
                <label>{{ 'common.preview' | translate }}</label>
                <div>
                    <h4 [innerHtml]="form.get('navbar_brand_title').value" class="m-0 mt-1"></h4>
                </div>
            </div>
        </div>
    </ng-container>

    <hr class="my-5">
    <h5 class="font-weight-bold mb-4" id="email">{{ 'common.email' | translate }}</h5>

    <ng-container>
        <div class="form-group">
            <label for="mailing_from_name">{{ 'hub.administration.sites.from-name' | translate }}</label>
            <input
                    [ngClass]="{'is-invalid': form.get('mailing_from_name').invalid && form.get('mailing_from_name').touched}"
                    class="form-control" formControlName="mailing_from_name" id="mailing_from_name"
                    maxlength="60" name="mailing_from_name" placeholder="{{ 'hub.administration.sites.from-name-placeholder' | translate }}"
                    type="text">
            <div class="invalid-feedback">{{ 'hub.administration.portal-users.enter-a-name' | translate }}</div>
        </div>

        <div class="form-group">
            <label for="mailing_company_name">{{ 'portal.registration.page2.placeholder-company' | translate }}</label>
            <input
                    [ngClass]="{'is-invalid': form.get('mailing_company_name').invalid && form.get('mailing_company_name').touched}"
                    class="form-control" formControlName="mailing_company_name"
                    id="mailing_company_name" maxlength="60" name="mailing_company_name"
                    placeholder="{{ 'hub.administration.sites.company-name-placeholder' | translate }}"
                    type="text">
            <div class="invalid-feedback">{{ 'hub.administration.portal-users.enter-a-name' | translate }}</div>
        </div>

        <div class="form-group">
            <label for="notifications_reply_to_address">{{ 'hub.administration.sites.reply-to-address' | translate }}</label>
            <input
                    [ngClass]="{'is-invalid': form.get('notifications_reply_to_address').invalid && form.get('notifications_reply_to_address').touched}"
                    class="form-control" formControlName="notifications_reply_to_address"
                    id="notifications_reply_to_address" maxlength="191" name="notifications_reply_to_address"
                    placeholder="{{ 'hub.administration.sites.reply-to-address-placeholder' | translate }}"
                    type="text">
            <div class="invalid-feedback">{{ 'hub.settings.account.enter-a-valid-email-address' | translate }}</div>
        </div>
    </ng-container>

    <hr class="my-5">
    <h5 class="font-weight-bold mb-4" id="new_landing_page">
        {{ 'hub.administration.sites.landing-page' | translate }}
    </h5>

    <ng-container>
        <div *ngIf="!form.get('new_landingpage_optin').value" class="form-group">
            <label for="new_landingpage_optin">{{ 'hub.administration.sites.opt-in' | translate }}</label>
            <div class="custom-control custom-switch">
                <input class="custom-control-input" formControlName="new_landingpage_optin" id="new_landingpage_optin"
                       name="new_landingpage_optin" type="checkbox">
                <label class="custom-control-label" for="new_landingpage_optin"></label>
            </div>
        </div>

        <ng-container *ngIf="form.get('new_landingpage_optin').value">
            <div class="form-group">
                <label for="new_landingpage_title_text">{{ 'hub.administration.sites.page-title' | translate }}</label>
                <input
                    [ngClass]="{'is-invalid': form.get('new_landingpage_title_text').invalid && form.get('new_landingpage_title_text').touched}"
                    class="form-control" formControlName="new_landingpage_title_text"
                    id="new_landingpage_title_text" maxlength="191"
                    name="new_landingpage_title_text" placeholder="{{ 'hub.administration.sites.page-title-placeholder' | translate }}"
                    type="url"/>
                <div class="invalid-feedback">{{ 'hub.administration.sites.enter-a-valid-page-title' | translate }}.</div>
            </div>

            <div class="form-group">
                <label for="new_landingpage_title_text">{{ 'hub.administration.sites.page-subtitle' | translate }}</label>
                <textarea
                    [ngClass]="{'is-invalid': form.get('new_landingpage_teaser_text').invalid && form.get('new_landingpage_teaser_text').touched}"
                    class="form-control" formControlName="new_landingpage_teaser_text"
                    id="new_landingpage_teaser_text" maxlength="2000" name="new_landingpage_teaser_text"
                    placeholder="{{ 'hub.administration.sites.page-subtitle-placeholder' | translate }}" rows="3"
                    type="url"></textarea>
                <div class="invalid-feedback">{{ 'hub.administration.sites.enter-a-valid-page-subtitle' | translate }}</div>
            </div>

            <ul #nav="ngbNav" class="nav-tabs" ngbNav>
                <li ngbNavItem>
                    <a ngbNavLink>{{ 'hub.administration.sites.features' | translate }}</a>
                    <ng-template ngbNavContent>
                        <div class="form-group">
                            <label for="new_landingpage_show_navbar">{{ 'hub.administration.sites.show-navigation-bar-links' | translate }}</label>
                            <div class="custom-control custom-switch">
                                <input class="custom-control-input" formControlName="new_landingpage_show_navbar"
                                       id="new_landingpage_show_navbar"
                                       name="new_landingpage_show_navbar" type="checkbox">
                                <label class="custom-control-label" for="new_landingpage_show_navbar"></label>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="new_landingpage_dark_navbar">{{ 'hub.administration.sites.dark-navigation-bar' | translate }}</label>
                            <div class="custom-control custom-switch">
                                <input class="custom-control-input" formControlName="new_landingpage_dark_navbar"
                                       id="new_landingpage_dark_navbar"
                                       name="new_landingpage_show_navbar" type="checkbox">
                                <label class="custom-control-label" for="new_landingpage_dark_navbar"></label>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="show_rate_carrier_info">{{ 'hub.administration.sites.show-actual-carrier-on-quote' | translate }}</label>
                            <div class="custom-control custom-switch">
                                <input class="custom-control-input" formControlName="show_rate_carrier_info"
                                       id="show_rate_carrier_info"
                                       name="show_rate_carrier_info" type="checkbox">
                                <label class="custom-control-label" for="show_rate_carrier_info"></label>
                            </div>
                            <div class="help-text small text-muted">{{ 'hub.administration.sites.shows-the-actual-carrier-name' | translate }}
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="is_quotes_hide_schedules">{{ 'hub.administration.sites.hide-schedules-from-quote' | translate }}</label>
                            <div class="custom-control custom-switch">
                                <input class="custom-control-input" formControlName="is_quotes_hide_schedules"
                                       id="is_quotes_hide_schedules"
                                       name="is_quotes_hide_schedules" type="checkbox">
                                <label class="custom-control-label" for="is_quotes_hide_schedules"></label>
                            </div>
                            <div class="help-text small text-muted">{{ 'hub.administration.sites.disable-the-ability-to-see' | translate }}
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="is_quotes_quickbook_enabled">{{ 'hub.administration.sites.enable-quick-booking-from-quote' | translate }}</label>
                            <div class="custom-control custom-switch">
                                <input class="custom-control-input" formControlName="is_quotes_quickbook_enabled"
                                       id="is_quotes_quickbook_enabled"
                                       name="is_quotes_quickbook_enabled" type="checkbox">
                                <label class="custom-control-label" for="is_quotes_quickbook_enabled"></label>
                            </div>
                            <div class="help-text small text-muted">
                                {{ 'hub.administration.sites.quick-booking-help-text' | translate }}
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="landingpage_require_port_entry_for_door">{{ 'hub.administration.sites.require-port-entry-for-door-quotes' | translate }}</label>
                            <div class="custom-control custom-switch">
                                <input class="custom-control-input"
                                       formControlName="landingpage_require_port_entry_for_door"
                                       id="landingpage_require_port_entry_for_door"
                                       name="landingpage_require_port_entry_for_door" type="checkbox">
                                <label class="custom-control-label"
                                       for="landingpage_require_port_entry_for_door"></label>
                            </div>
                            <div class="help-text small text-muted">
                                {{ 'hub.administration.sites.requires-the-entry-of-a-port' | translate }}
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="landingpage_quote_expiration_leeway">{{ 'hub.administration.sites.quote-expiration-leeway' | translate }}</label>
                            <div class="input-group">
                                <input
                                    class="form-control"
                                    formControlName="landingpage_quote_expiration_leeway"
                                    id="landingpage_quote_expiration_leeway"
                                    min="0"
                                    step="1"
                                    style="max-width: 100px;"
                                    type="number"
                                />
                                <div class="input-group-append">
                                    <span class="input-group-text">{{ 'common.days' | translate }}</span>
                                </div>
                            </div>
                            <arv-validation-messages
                                for="landingpage_quote_expiration_leeway">
                                <arv-validation-message key="required"></arv-validation-message>
                                <arv-validation-message key="min">
                                    <p class="invalid-feedback">{{ 'common.validation.minValue' | translate : { min: 0 } }}</p>
                                </arv-validation-message>
                            </arv-validation-messages>
                            <div class="help-text small text-muted">
                                {{ 'hub.administration.sites.number-of-days-after' | translate }}
                            </div>
                        </div>
                        <div class="form-group">
                            <label>{{ 'hub.administration.sites.component-order' | translate }}</label>
                            <p-orderList [dragdrop]="true" [listStyle]="{'max-height':'30rem'}"
                                         [value]="site.landingpage_component_order" controlsPosition="right">
                                <ng-template let-component pTemplate="item">
                                    <div class="component-item">
                                        <div class="component-list-detail">
                                            <p class="mb-0">{{ component | titlecase }}</p>
                                        </div>
                                    </div>
                                </ng-template>
                            </p-orderList>
                            <div class="help-text small text-muted">
                                {{ 'hub.administration.sites.component-order-help-text' | translate }}.
                            </div>
                        </div>
                    </ng-template>
                </li>

                <li ngbNavItem>
                    <a ngbNavLink>{{ 'hub.administration.sites.defaults' | translate }}</a>
                    <ng-template ngbNavContent>
                        <div class="form-row">
                            <div class="form-group col-12 col-lg-6">
                                <label for="landingpage_default_origin_type">{{ 'hub.administration.sites.default-origin-type' | translate }}</label>
                                <ng-select [clearable]="false" [items]="locationTypes"
                                           bindValue="value" formControlName="landingpage_default_origin_type"
                                           id="landingpage_default_origin_type"
                                           [ngClass]="{'is-invalid': form.get('landingpage_default_origin_type').invalid && form.get('landingpage_default_origin_type').touched}"
                                >
                                    <ng-template let-item="item" ng-label-tmp>
                                        {{ item.label | translate }}
                                    </ng-template>
                                    <ng-template let-item="item" ng-option-tmp>
                                        {{ item.label | translate }}
                                    </ng-template>
                                </ng-select>
                                <div class="invalid-feedback">{{ 'hub.administration.sites.select-an-item' | translate }}</div>
                            </div>

                            <div class="form-group col-12 col-lg-6">
                                <label for="landingpage_default_destination_type">{{ 'hub.administration.sites.default-destination-type' | translate }}</label>
                                <ng-select [clearable]="false" [items]="locationTypes" bindValue="value"
                                           formControlName="landingpage_default_destination_type"
                                           id="landingpage_default_destination_type"
                                           [ngClass]="{'is-invalid': form.get('landingpage_default_destination_type').invalid && form.get('landingpage_default_destination_type').touched}"
                                >
                                    <ng-template let-item="item" ng-label-tmp>
                                        {{ item.label | translate }}
                                    </ng-template>
                                    <ng-template let-item="item" ng-option-tmp>
                                        {{ item.label | translate }}
                                    </ng-template>
                                </ng-select>
                                <div class="invalid-feedback">{{ 'hub.administration.sites.select-an-item' | translate }}</div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="landingpage_default_additionals">{{ 'hub.administration.sites.default-additionals' | translate }}</label>
                            <ng-select [items]="additionals"
                                       [multiple]="true"
                                       bindValue="value"
                                       formControlName="landingpage_default_additionals"
                                       id="landingpage_default_additionals">
                                <ng-template let-item="item" ng-label-tmp>
                                    {{ item.label | translate }}
                                </ng-template>
                                <ng-template let-item="item" ng-option-tmp>
                                    {{ item.label | translate }}
                                </ng-template>
                            </ng-select>
                        </div>

                        <div class="form-group">
                            <label for="landingpage_default_currency">{{ 'hub.administration.sites.default-currency' | translate }}</label>
                            <ng-select [clearable]="true"
                                       [items]="currencies$ | async"
                                       [multiple]="false"
                                       bindLabel="code"
                                       bindValue="code"
                                       formControlName="landingpage_default_currency"
                                       id="landingpage_default_currency"></ng-select>
                        </div>

                    </ng-template>
                </li>

                <li ngbNavItem>
                    <a ngbNavLink>{{ 'hub.administration.sites.custom-header' | translate }}</a>
                    <ng-template ngbNavContent>

                        <div class="form-group">
                            <label for="landingpage_header_label">{{ 'hub.administration.sites.header-label' | translate }}</label>
                            <input
                                [ngClass]="{'is-invalid': form.get('landingpage_header_label').invalid && form.get('landingpage_header_label').touched}"
                                class="form-control"
                                formControlName="landingpage_header_label"
                                id="landingpage_header_label"
                                maxlength="191"
                                name="landingpage_header_label"
                                placeholder="{{ 'hub.administration.sites.header-label' | translate }}"
                            />
                            <div class="invalid-feedback">{{ 'hub.administration.sites.enter-a-valid-header-label' | translate }}.</div>
                        </div>

                        <ng-container formArrayName="homepage_links">
                            <div *ngFor="let item of homepageLinksArray.controls; let idx = index;"
                                 [formGroupName]="idx"
                                 class="form-group form-row">
                                <div class="col-3">
                                    <input [attr.id]="'homepage_links.' + idx + '.label'"
                                           [attr.name]="'homepage_links.' + idx + '.label'"
                                           [ngClass]="{'is-invalid': form.get('homepage_links.' + idx + '.label').invalid && form.get('homepage_links.' + idx + '.label').touched}"
                                           class="form-control form-control-sm"
                                           formControlName="label"
                                           maxlength="40"
                                           placeholder="{{ 'hub.administration.sites.enter-a-link-title' | translate }}..."
                                           type="text">
                                    <div class="invalid-feedback">{{ 'hub.administration.sites.enter-a-valid-label' | translate }}</div>
                                </div>
                                <div class="col">
                                    <input [attr.id]="'homepage_links.' + idx + '.url'"
                                           [attr.name]="'homepage_links.' + idx + '.url'"
                                           [ngClass]="{'is-invalid': form.get('homepage_links.' + idx + '.url').invalid && form.get('homepage_links.' + idx + '.url').touched}"
                                           class="form-control form-control-sm"
                                           formControlName="url"
                                           placeholder="{{ 'hub.administration.sites.enter-a-link-url' | translate }}..."
                                           type="url">
                                    <div class="invalid-feedback">{{ 'hub.administration.sites.enter-a-valid-url' | translate }}</div>
                                </div>
                                <div class="col d-flex align-items-start flex-column">
                                    <div class="form-control h-auto border-0 p-0 m-1 d-flex align-items-center"
                                    [ngClass]="{'is-invalid': form.get('homepage_links.' + idx + '.approve').invalid && form.get('homepage_links.' + idx + '.approve').touched}"
                                    >
                                        <p-checkbox
                                            formControlName="approve"
                                            [attr.name]="'homepage_links.' + idx + '.approve'"
                                            [binary]="true"
                                            [inputId]="'homepage_links.' + idx + '.approve'">
                                        </p-checkbox>
                                        <label class="m-0 mx-2" [for]="'homepage_links.' + idx + '.approve'">{{ 'hub.administration.sites.approve' | translate }}?</label>
                                        <qwyk-info text="{{ 'hub.administration.sites.link-disclaimer' | translate }}"></qwyk-info>
                                    </div>
                                    <div class="invalid-feedback">{{ 'common.validation.required' | translate : { field: 'hub.administration.sites.approve' | translate | lowercase } }}</div>
                                </div>
                                <div class="col-auto">
                                    <button (click)="removeHomepageLink(idx)" [disabled]="form.disabled"
                                            class="btn btn-outline-danger btn-sm" type="button">
                                        <fa-icon [icon]="['far', 'trash']"></fa-icon>
                                    </button>
                                </div>
                            </div>
                        </ng-container>

                        <button (click)="addHomepageLink(null)"
                                [disabled]="homepageLinksArray.length >= 5 || form.disabled"
                                class="btn btn-primary btn-sm"
                                type="button">
                            <fa-icon [icon]="['far', 'plus']" class="mr-2"></fa-icon>
                            {{ 'hub.administration.sites.add-link' | translate }}
                        </button>

                        <div class="form-group mt-3">
                            <label for="homepage_links_color">{{ 'common.color' | translate }}</label>
                            <ng-select
                                [clearable]="false"
                                [items]="['text-primary', 'text-secondary', 'text-success', 'text-info', 'text-warning', 'text-danger', 'text-light', 'text-dark', 'text-black', 'text-white']"
                                [ngClass]="{'is-invalid': form.get('homepage_links_color').invalid && form.get('homepage_links_color').touched}"
                                formControlName="homepage_links_color"
                                id="homepage_links_color"
                                name="homepage_links_color">
                                <ng-template let-item="item" ng-label-tmp>
                                    {{ 'hub.administration.sites.colors-schema.' + item.replace('text-', '') | translate }}
                                </ng-template>
                                <ng-template let-item="item" ng-option-tmp>
                                    {{ 'hub.administration.sites.colors-schema.' + item.replace('text-', '') | translate }}
                                </ng-template>
                            </ng-select>
                            <div class="invalid-feedback">{{ 'hub.administration.sites.select-a-color' | translate }}</div>
                        </div>

                        <div class="form-group">
                            <label for="more_info_link">{{ 'hub.administration.sites.landing-page-more-info-link' | translate }}</label>
                            <input [ngClass]="{'is-invalid': form.get('more_info_link').invalid && form.get('more_info_link').touched}"
                                   class="form-control" formControlName="more_info_link" id="more_info_link"
                                   type="url">
                            <div class="invalid-feedback">{{ 'hub.administration.sites.enter-a-valid-url' | translate }}</div>
                        </div>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="nav" class="pt-3 mb-3 px-3 border-left border-right border-bottom"></div>
        </ng-container>
    </ng-container>

    <hr class="my-5">
    <h5 class="font-weight-bold mb-4" id="legal">{{ 'hub.administration.sites.legal' | translate }}</h5>

    <ng-container>
        <div class="form-group">
            <label for="terms_and_conditions_url">{{ 'portal.booking.create-booking.terms-conditions' | translate | titlecase }}</label>
            <div class="input-group">
                <input
                    [ngClass]="{'is-invalid': form.get('terms_and_conditions_label').invalid && form.get('terms_and_conditions_label').touched}"
                    class="form-control w-33" formControlName="terms_and_conditions_label"
                    id="terms_and_conditions_label"
                    maxlength="30"
                    name="terms_and_conditions_label" placeholder="{{ 'common.label' | translate }}"
                    type="text"/>
                <input
                    [ngClass]="{'is-invalid': form.get('terms_and_conditions_url').invalid && form.get('terms_and_conditions_url').touched}"
                    class="form-control" formControlName="terms_and_conditions_url"
                    id="terms_and_conditions_url" maxlength="255"
                    name="terms_and_conditions_url" placeholder="{{ 'hub.administration.sites.enter-a-link-to-your-terms-and-conditions' | translate }}"
                    type="url"/>
            </div>
            <div class="invalid-feedback">{{ 'hub.administration.sites.enter-a-valid-url' | translate }}</div>
        </div>

        <div class="form-group">
            <label for="privacy_policy_url">{{ 'common.privacy-policy' | translate }}</label>
            <div class="input-group">
                <input
                    [ngClass]="{'is-invalid': form.get('privacy_policy_label').invalid && form.get('privacy_policy_label').touched}"
                    class="form-control w-33" formControlName="privacy_policy_label" id="privacy_policy_label"
                    maxlength="30"
                    name="privacy_policy_label" placeholder="{{ 'common.label' | translate }}"
                    type="text"/>
                <input
                    [ngClass]="{'is-invalid': form.get('privacy_policy_url').invalid && form.get('privacy_policy_url').touched}"
                    class="form-control" formControlName="privacy_policy_url" id="privacy_policy_url"
                    maxlength="255" name="privacy_policy_url" placeholder="{{ 'hub.administration.sites.privacy-policy-placeholder' | translate }}"
                    type="url"/>
            </div>
            <div class="invalid-feedback">{{ 'hub.administration.sites.enter-a-valid-url' | translate }}</div>
        </div>

        <div class="form-group">
            <label for="cookie_policy_url">{{ 'hub.administration.sites.cookie-policy' | translate }}</label>
            <div class="input-group">
                <input
                    [ngClass]="{'is-invalid': form.get('terms_and_conditions_label').invalid && form.get('terms_and_conditions_label').touched}"
                    class="form-control w-33" formControlName="cookie_policy_label" id="cookie_policy_label"
                    maxlength="30"
                    name="cookie_policy_label" placeholder="{{ 'common.label' | translate }}"
                    type="text"/>
                <input
                    [ngClass]="{'is-invalid': form.get('cookie_policy_url').invalid && form.get('cookie_policy_url').touched}"
                    class="form-control" formControlName="cookie_policy_url" id="cookie_policy_url"
                    maxlength="255" name="cookie_policy_url" placeholder="{{ 'hub.administration.sites.cookie-policy-placeholder' | translate }}"
                    type="url"/>
            </div>
            <div class="invalid-feedback">{{ 'hub.administration.sites.enter-a-valid-url' | translate }}</div>
        </div>

        <div class="form-group">
            <label for="booking_terms_url">{{ 'hub.administration.sites.booking-terms' | translate }}</label>
            <div class="input-group">
                <input
                    [ngClass]="{'is-invalid': form.get('booking_terms_url').invalid && form.get('booking_terms_url').touched}"
                    class="form-control" formControlName="booking_terms_url" id="booking_terms_url"
                    maxlength="255" name="booking_terms_url" placeholder="{{ 'hub.administration.sites.booking-terms-placeholder' | translate }}"
                    type="url"/>
            </div>
            <div class="invalid-feedback">{{ 'hub.administration.sites.enter-a-valid-url' | translate }}</div>
        </div>
    </ng-container>

    <hr class="my-5">
    <div class="mb-5">
        <h5 class="font-weight-bold mb-4" id="analytics">{{ 'hub.administration.sites.analytics' | translate }}
            <span class="badge badge-primary ml-1 font-weight-normal" ngbTooltip="{{ 'hub.administration.sites.premium' | translate }}">
                <fa-icon [icon]="['fas', 'award']"></fa-icon>
            </span>
        </h5>

        <ng-container>
            <div class="form-group">
                <label for="ga_tracking_id">{{ 'hub.administration.sites.google-analytics-tracking-id' | translate }}</label>
                <input
                    [ngClass]="{'is-invalid': form.get('ga_tracking_id').invalid && form.get('ga_tracking_id').touched}"
                    class="form-control" formControlName="ga_tracking_id" id="ga_tracking_id"
                    maxlength="15" name="ga_tracking_id" placeholder="UA-00000000-1"
                    type="text">
                <div class="help-text small text-muted">{{ 'hub.administration.sites.tracking-id-help-text' | translate }}</div>
            </div>
        </ng-container>
    </div>

    <hr class="my-5">
    <div class="mb-5">
        <h5 class="font-weight-bold mb-0" id="scripts">{{ 'hub.administration.sites.site-scripts' | translate }}
            <span class="badge badge-primary ml-1 font-weight-normal" ngbTooltip="{{ 'hub.administration.sites.premium' | translate }}">
                <fa-icon [icon]="['fas', 'award']"></fa-icon>
            </span>
        </h5>
        <p class="mb-4 text-muted">
            {{ 'hub.administration.sites.site-scripts-help-text' | translate }}
            {{ 'hub.administration.sites.contact' | translate }} <a href="mailto:support@qwyk.io?Subject=Site injectable script">support@qwyk.io</a> {{ 'hub.administration.sites.contact-end' | translate }}.
        </p>

        <ng-container *ngFor="let script of site.injectable_scripts; let idx = index;">
            <hr *ngIf="idx > 0"/>
            <div class="form-row">
                <div class="col-6">
                    <label>{{ 'common.name' | translate }}</label>
                    <input [value]="script.name" class="form-control" disabled="true" type="text"/>
                </div>
                <div class="col-6">
                    <label>{{ 'common.type' | translate }}</label>
                    <input [value]="script.type" class="form-control" disabled="true" type="text"/>
                </div>
                <div class="col-4">
                    <div class="custom-control custom-switch my-3">
                        <input [attr.disabled]="true" [attr.id]="'scripthref'+idx" [ngModelOptions]="{standalone: true}"
                               [ngModel]="script.is_href || false" class="custom-control-input"
                               type="checkbox">
                        <label [attr.for]="'scripthref'+idx" class="custom-control-label">Src</label>
                    </div>
                </div>
                <div class="col-4">
                    <div class="custom-control custom-switch my-3">
                        <input [attr.disabled]="true" [attr.id]="'scriptasync'+idx"
                               [ngModelOptions]="{standalone: true}"
                               [ngModel]="script.async || false" class="custom-control-input"
                               type="checkbox">
                        <label [attr.for]="'scriptasync'+idx" class="custom-control-label">Async</label>
                    </div>
                </div>
                <div class="col-4">
                    <div class="custom-control custom-switch my-3">
                        <input [attr.disabled]="true" [attr.id]="'scriptdefer'+idx"
                               [ngModelOptions]="{standalone: true}"
                               [ngModel]="script.defer || false" class="custom-control-input"
                               type="checkbox">
                        <label [attr.for]="'scriptdefer'+idx" class="custom-control-label">Defer</label>
                    </div>
                </div>
                <div class="col-12">
                    <textarea class="form-control" disabled="true" rows="5">{{ script.script }}</textarea>
                </div>
            </div>
        </ng-container>
    </div>

    <qwyk-form-error [error]="error"></qwyk-form-error>

    <div class="form-group border-top text-right pt-3">
        <button [disabled]="form.disabled" [routerLink]="['..']" class="btn btn-light" type="button">{{ 'common.cancel' | translate }}</button>
        <button [disabled]="form.disabled" class="btn btn-primary ml-2">
            <fa-icon [icon]="['far', form.enabled ? 'save' : 'spinner']" [pulse]="form.disabled" class="mr-2"></fa-icon>
            {{ 'hub.administration.sites.save-site' | translate }}
        </button>
    </div>
</form>
