<div class="container-fluid p-3 d-flex flex-column justify-content-between min-vh-100 overflow-auto">
    <div>
        <div class="small text-muted mb-3 border-bottom pb-2">
            <a class="text-muted" [routerLink]="['/']">Qwyk</a>
            &rsaquo;
            <a class="text-muted" [routerLink]="['/settings']">{{ 'portal.menu.side-menu.settings' | translate }}</a>
            &rsaquo;
            <a class="text-muted font-weight-bold" [routerLink]="['./']">{{ 'hub.settings.users.hub-users' | translate }}</a>
        </div>

        <div class="d-flex justify-content-between align-items-center">
            <h3 class="font-weight-normal m-0">{{ 'hub.settings.users.hub-users' | translate }}</h3>
            <button class="btn btn-outline-primary" [routerLink]="['./new']">
                <fa-icon [icon]="['far', 'plus']" class="mr-1"></fa-icon>
                {{ 'hub.settings.users.create-hub-user' | translate }}
            </button>
        </div>

        <div class="mt-3">
            <p-table [resizableColumns]="true" [paginator]="true" [lazy]="true" [value]="users$ | async"
                [loading]="loading$ | async" [rows]="(pagination$ | async)?.per_page" [rowHover]="true"
                [totalRecords]="(pagination$ | async)?.total" (onLazyLoad)="loadUsersLazy($event)"
                [first]="(pagination$ | async)?.per_page * ((pagination$ | async)?.current_page - 1)">
                <ng-template pTemplate="header">
                    <tr>
                        <th>{{ 'portal.registration.page1.label-username' | translate }}</th>
                        <th>{{ 'portal.registration.page1.label-name' | translate }}</th>
                        <th>{{ 'portal.events-index.columns.location' | translate }}</th>
                        <th>{{ 'hub.menu.top-menu.role' | translate }}</th>
                        <th style="width: 165px;">{{ 'common.active' | translate }}</th>
                    </tr>
                    <tr [formGroup]="searchForm">
                        <th class="font-weight-normal">
                            <input type="email" pInputText class="p-inputtext-sm" formControlName="email"
                                placeholder="{{ 'common.filter-by-field' | translate : { value: 'common.email' | translate | lowercase } }}" [style]="{'width':'100%'}" />
                        </th>
                        <th class="font-weight-normal">
                            <input type="text" pInputText class="p-inputtext-sm" formControlName="name"
                                placeholder="{{ 'common.filter-by-field' | translate : { value: 'common.name' | translate | lowercase } }}" [style]="{'width':'100%'}" />
                        </th>
                        <th>
                            <input type="text" pInputText class="p-inputtext-sm" formControlName="location"
                                placeholder="{{ 'common.filter-by-field' | translate : { value: 'portal.events-index.columns.location' | translate | lowercase } }}" [style]="{'width':'100%'}" [style]="{'width':'100%'}" />
                        </th>
                        <th class="font-weight-normal">
                            <p-dropdown [options]="roles$ | async" appendTo="body" [style]="{'width':'100%'}"
                                formControlName="role_id" placeholder="{{ 'common.select-a' | translate }} {{ 'hub.menu.top-menu.role' | translate }}" styleClass="p-inputtext-sm">
                            </p-dropdown>
                        </th>
                        <th class="py-1">
                            <button class="btn btn-primary mr-1 btn-sm" type="submit" (click)="search()"
                                [disabled]="!searchForm.dirty">{{ 'common.search' | translate }}</button>
                            <button class="btn btn-light btn-sm" type="button" (click)="resetSearch()">{{ 'common.reset' | translate }}</button>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-user>
                    <tr>
                        <td>
                            <a [routerLink]="[user.id]">
                                <img style="width: 20px; height: 20px; margin-top: -2px"
                                    class="rounded-circle border mr-2"
                                    [src]="'https://www.gravatar.com/avatar/' + (user.email | md5) + '?d=https%3A%2F%2Fui-avatars.com%2Fapi%2F/' + (user.name | urlEncode) + '/24/097aff/ffffff/2//false/true/true'">
                                {{ user.email }}
                            </a>
                        </td>
                        <td>
                            {{ user.name }}
                        </td>
                        <td>
                            {{ user.location }}
                        </td>
                        <td>
                            {{ 'hub.settings.users.roles.' + user.role | translate }}
                        </td>
                        <td class="text-center">
                            <fa-icon
                                [icon]="['fas', user.active ? 'check' : 'times']"
                                [fixedWidth]="true"
                                [ngClass]="
                                user.active ? 'text-success' : 'text-danger'
                            "
                            ></fa-icon>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="loadingbody">
                    <tr>
                        <td colspan="5" class="py-4 text-muted text-center">
                            {{ 'common.loading' | translate }}...
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="5" class="py-4 text-muted text-center">{{ 'common.nothing_found' | translate }}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>

    <div class="card">
        <div class="card-body d-flex align-items-center">
            <div class="mr-4">
                <fa-icon [icon]="['far', 'info-circle']" size="2x" class="text-muted" [fixedWidth]="true"></fa-icon>
            </div>
            <div>
                <div class="font-weight-bold">{{ 'hub.settings.users.hub-users' | translate }}</div>
                <div>
                    {{ 'hub.settings.users.hub-users-message' | translate }}
                </div>
            </div>
        </div>
    </div>
</div>
