/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';

import { select, Store, Action } from '@ngrx/store';
import { Hub } from '@qwyk/models';
import * as CustomerUsersActions from './customer-users.actions';
import * as fromCustomerUsers from './customer-users.reducer';
import * as CustomerUsersSelectors from './customer-users.selectors';

@Injectable()
export class CustomerUsersFacade {
    loaded$ = this.store.pipe(
        select(CustomerUsersSelectors.getCustomerUsersLoaded)
    );
    loading$ = this.store.pipe(
        select(CustomerUsersSelectors.getCustomerUsersLoading)
    );
    pagination$ = this.store.pipe(
        select(CustomerUsersSelectors.getCustomerUsersPagination)
    );
    error$ = this.store.pipe(
        select(CustomerUsersSelectors.getCustomerUsersError)
    );
    allCustomerUsers$ = this.store.pipe(
        select(CustomerUsersSelectors.getAllCustomerUsers)
    );
    selectedCustomerUsers$ = this.store.pipe(
        select(CustomerUsersSelectors.getSelected)
    );

    constructor(
        private store: Store<fromCustomerUsers.CustomerUsersPartialState>
    ) {}

    public loadCustomerUsers(query: any) {
        this.dispatch(CustomerUsersActions.loadCustomerUsers({ query }));
    }

    public selectCustomerUser(id: string) {
        this.dispatch(CustomerUsersActions.selectCustomerUser({ id }));
    }

    public createCustomerUser(payload: any) {
        this.dispatch(CustomerUsersActions.createCustomerUser({ payload }));
    }

    public updateCustomerUser(id: string, payload: any) {
        this.dispatch(CustomerUsersActions.updateCustomerUser({ id, payload }));
    }

    public activateCustomerUser(user: Hub.CustomerUser) {
        this.dispatch(
            CustomerUsersActions.activateCustomerUser({
                id: user.id,
                payload: user,
            })
        );
    }

    public deactivateCustomerUser(id: string, scrub: boolean) {
        this.dispatch(
            CustomerUsersActions.deactivateCustomerUser({ id, scrub })
        );
    }

    private dispatch(action: Action) {
        this.store.dispatch(action);
    }
}
