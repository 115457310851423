<qwyk-breadcrumbs [entityIdValue]="quotation.friendly_id" [url]="url" [app]="'hub'"></qwyk-breadcrumbs>
<div class="border-bottom d-flex py-2 mb-3 align-items-center">
    <div class="flex-grow-1">
        <qwyk-quotation-status-badge
            [filled]="true"
            [status]="quotation.status"
            class="mr-2"></qwyk-quotation-status-badge>
        <span class="text-muted">
            {{ 'common.customer' | translate }}:
            <a [ngbPopover]="headerUserPopoverContent" href="javascript:void(0)" placement="bottom"
                triggers="mouseenter:mouseleave">
                <img
                    [src]="'https://www.gravatar.com/avatar/' + (quotation.owner.email | md5) + '?d=https%3A%2F%2Fui-avatars.com%2Fapi%2F/' + (quotation.owner.name | urlEncode) + '/24/097aff/ffffff/2//false/true/true'"
                    class="rounded-circle border"
                    style="width: 20px; height: 20px; margin-top: -4px">
                {{ quotation.owner.name }}
            </a>
            <ng-template #headerUserPopoverContent>
                <qwyk-user-info-tooltip [user]="quotation.owner"></qwyk-user-info-tooltip>
            </ng-template>
            {{ 'common.created' | translate }}
            <span [ngbTooltip]="quotation.request.created_at | amLocal | amDateFormat: 'LLL'"
                class="text-underline-dashed" placement="bottom">
                {{ quotation.request.created_at | amLocal | amTimeAgo }}
            </span>
            <ng-container *ngIf="quotation.creator">
                {{ 'common.by' | translate }}
                <a [ngbPopover]="ownerUserPopoverContent" href="javascript:void(0)"
                    placement="bottom" triggers="mouseenter:mouseleave">
                    <img
                        [src]="'https://www.gravatar.com/avatar/' + (quotation.creator.email | md5) + '?d=https%3A%2F%2Fui-avatars.com%2Fapi%2F/' + (quotation.creator.name | urlEncode) + '/24/097aff/ffffff/2//false/true/true'"
                        class="rounded-circle border"
                        style="width: 20px; height: 20px; margin-top: -4px">
                    {{ quotation.creator.name }}</a>
                <ng-template #ownerUserPopoverContent>
                    <qwyk-user-info-tooltip [user]="quotation.creator"></qwyk-user-info-tooltip>
                </ng-template>
            </ng-container>
        </span>
    </div>

    <div class="d-flex align-items-center">
        <div class="ml-2">
            <div class="btn-group mr-3">
                <button (click)="publish.emit(quotation)" *ngIf="quotation.is_draft"
                        [disabled]="busy || isPolling"
                        class="btn btn-outline-primary"
                        ngbTooltip="{{ 'hub.quotations.details.publish-quotation-tooltip' | translate }}">
                    <fa-icon *ngIf="busy" [icon]="['far', 'spinner']" [pulse]="busy"
                             class="mr-2"></fa-icon>
                    {{ 'hub.quotations.details.publish-quotation' | translate }}
                </button>
                <button (click)="download.emit(quotation)" *ngIf="!quotation.is_draft"
                        [disabled]="busy || isPolling" class="btn btn-outline-primary"
                        ngbTooltip="{{ 'hub.quotations.details.download-quotation-tooltip' | translate }}">
                    <fa-icon [icon]="['far', !busy ? 'file-download' : 'spinner']" [pulse]="busy"
                             class="mr-2"></fa-icon>
                    {{ 'common.download' | translate }}
                </button>

                <div aria-label="Button group with nested dropdown" class="btn-group" ngbDropdown
                     placement="bottom-end" role="group">
                    <button class="btn btn-outline-primary dropdown-toggle-split px-3"
                            ngbDropdownToggle></button>
                    <div class="dropdown-menu" ngbDropdownMenu>
                        <button [disabled]="busy || isPolling" [queryParams]="{'copy': true}"
                                [routerLink]="['..', 'new', quotation.id, 'copy']"
                                ngbDropdownItem>
                            <fa-icon [fixedWidth]="true" [icon]="['far', !busy ? 'copy' : 'spinner']" [pulse]="busy"
                                     class="mr-2"></fa-icon>
                            {{ 'hub.quotations.details.copy-quotation' | translate }}
                        </button>
                        <button (click)="close.emit(quotation)" *ngIf="quotation.status === 'open'"
                                [disabled]="busy || isPolling"
                                ngbDropdownItem>
                            <fa-icon [fixedWidth]="true" [icon]="['far', busy ? 'spinner' : 'do-not-enter']"
                                     [pulse]="busy"
                                     class="mr-2"></fa-icon>
                            {{ 'hub.quotations.details.close-quotation' | translate }}
                        </button>
                        <button (click)="delete.emit(quotation)" *ngIf="quotation.is_draft"
                                [disabled]="busy || isPolling"
                                class="text-danger" ngbDropdownItem>
                            <fa-icon [fixedWidth]="true" [icon]="['far', busy ? 'spinner' : 'trash']" [pulse]="busy"
                                     class="mr-2"></fa-icon>
                            {{ 'hub.quotations.details.delete-quotation' | translate }}
                        </button>
                        <ng-container *ngIf="isMasterAdmin">
                            <div class="dropdown-divider"></div>
                            <a [queryParams]="{'transactionable_id': quotation.id, 'transactionable_type': 'App\\Models\\Portals\\Quotations\\Quotation'}"
                               [routerLink]="['/console', 'logs', 'connection-transactions']"
                               class="btn btn-outline-primary"
                               ngbDropdownItem target="_blank">
                                <fa-icon [fixedWidth]="true" [icon]="['far', 'list']" class="mr-2"></fa-icon>
                                {{ 'hub.quotations.details.auto-rate-logs' | translate }}
                            </a>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="d-flex flex-row justify-content-between mb-3">
    <h3 class="font-weight-normal m-0">
        {{ 'common.quotations.quotation' | translate | titlecase }}
        <span *ngIf="quotation.friendly_id" class="text-muted">
            {{ quotation.friendly_id }}
        </span>
    </h3>
</div>
