<form *ngIf="form" [formGroup]="form">
    <h5 class="font-weight-bold mb-4" id="account">{{ 'hub.menu.side-menu.account' | translate }}</h5>

    <div *ngIf="isMagayaNetwork" class="form-group">
        <label for="username">{{ 'portal.registration.page1.label-username' | translate }}</label>
        <input class="form-control" formControlName="username" id="username" name="username" type="text"/>
    </div>

    <div class="form-group">
        <label for="email">{{ 'common.email' | translate }}</label>
        <input class="form-control" formControlName="email" id="email" name="email" type="email"/>
    </div>

    <div *ngIf="!isMagayaNetwork" class="form-group">
        <label for="team_id">{{ 'common.customer' | translate }}</label>
        <ng-select [items]="teams$ | async" [loading]="loadingTeams"
                   [ngClass]="{'is-invalid': form.get('team_id').touched && form.get('team_id').invalid}"
                   bindLabel="name" bindValue="id"
                   formControlName="team_id" id="team_id"
                   name="team_id"
                   placeholder="{{ 'common.customer' | translate }}">
        </ng-select>
        <arv-validation-messages for="team_id"></arv-validation-messages>
    </div>

    <div *ngIf="isMagayaNetwork" class="form-group">
        <label for="magaya_customer_name">{{ 'hub.administration.portal-users.magaya-entity' | translate }}</label>
        <div class="input-group">
            <input [ngClass]="{'is-invalid': form.get('magaya_customer_name').touched && form.get('magaya_customer_name').invalid}"
                   [readonly]="true"
                   class="form-control"
                   formControlName="magaya_customer_name" id="magaya_customer_name" placeholder="{{ 'hub.administration.portal-users.click-search-to-select' | translate }}"
                   type="text"/>
            <span class="input-group-append">
                <button
                    (click)="onSearchForMagayaEntity()"
                    class="btn btn-outline-primary"
                    ngbTooltip="{{ 'hub.administration.portal-users.click-to-search-for-an-entity-in-magaya-to-link-this-user-to' | translate }}"
                >
                    <fa-icon [icon]="['far', 'search']" class="mr-2"></fa-icon>
                    {{ 'common.search' | translate }}
                </button>
            </span>
        </div>
        <arv-validation-messages for="magaya_customer_name"></arv-validation-messages>
    </div>

    <div class="form-group">
        <label for="registered_site_id">{{ 'hub.administration.portal-users.site' | translate }}</label>
        <ng-select [items]="sites$ | async" [loading]="loadingSites"
                   [ngClass]="{'is-invalid': form.get('registered_site_id').touched && form.get('registered_site_id').invalid}"
                   bindLabel="name" bindValue="id" formControlName="registered_site_id" id="registered_site_id"
                   name="registered_site_id"
                   placeholder="{{ 'hub.administration.portal-users.registered-site' | translate }}"></ng-select>
        <arv-validation-messages for="registered_site_id"></arv-validation-messages>
    </div>

    <div class="form-group">
        <div class="custom-control custom-switch">
            <input class="custom-control-input" formControlName="restrict_to_registered_site"
                   id="restrict_to_registered_site"
                   type="checkbox">
            <label class="custom-control-label" for="restrict_to_registered_site">{{ 'hub.administration.portal-users.restrict-to-this-site' | translate }}</label>
        </div>
    </div>

    <hr class="my-5">
    <h5 class="font-weight-bold m-0" id="profile">{{ 'hub.menu.top-menu.profile' | translate }}</h5>

    <p class="text-muted  mb-4">{{ 'hub.administration.portal-users.these-fields-are-visible-to-the-user' | translate }}</p>
    <div class="form-group">
        <label for="name">{{ 'common.name' | translate }}</label>
        <input [ngClass]="{'is-invalid': form.get('name').touched && form.get('name').invalid}" class="form-control"
               formControlName="name" id="name" maxlength="191" name="name"
               placeholder="{{ 'portal.registration.page1.label-name' | translate }}"
               type="text"/>
        <arv-validation-messages for="name"></arv-validation-messages>
    </div>

    <div class="form-row">
        <div class="form-group col-12 col-md-6">
            <label for="company_name">{{ 'common.company' | translate }}</label>
            <input [ngClass]="{'is-invalid': form.get('company_name').touched && form.get('company_name').invalid}"
                   class="form-control" formControlName="company_name" id="company_name" maxlength="191"
                   name="company_name" placeholder="{{ 'portal.registration.page2.placeholder-company' | translate }}"
                   type="text"/>
            <arv-validation-messages for="company_name"></arv-validation-messages>
        </div>
        <div class="form-group col-12 col-md-6">
            <label for="job_title">{{ 'portal.settings.profile.job-title' | translate }}</label>
            <input [ngClass]="{'is-invalid': form.get('job_title').touched && form.get('job_title').invalid}"
                   class="form-control" formControlName="job_title" id="job_title" maxlength="191"
                   name="job_title" placeholder="{{ 'portal.settings.profile.job-title' | translate }}"
                   type="text"/>
            <arv-validation-messages for="job_title"></arv-validation-messages>
        </div>
    </div>

    <div class="form-row">
        <div class="form-group col-12 col-md-7">
            <label for="location">{{ 'portal.events-index.columns.location' | translate }}</label>
            <input [ngClass]="{'is-invalid': form.get('location').touched && form.get('location').invalid}"
                   class="form-control" formControlName="location" id="location" maxlength="191"
                   name="location" placeholder="{{ 'portal.settings.profile.city-state' | translate }}"
                   type="text"/>
            <arv-validation-messages for="location"></arv-validation-messages>
        </div>
        <div class="form-group col-12 col-md-5">
            <label for="country_code">{{ 'common.country' | translate }}</label>
            <ng-select [items]="countries$ | async" [loading]="loadingCountries"
                       [ngClass]="{'is-invalid': form.get('country_code').touched && form.get('country_code').invalid}"
                       bindLabel="short_name"
                       bindValue="alpha_2_code" formControlName="country_code" id="country_code"
                       name="country_code"
                       placeholder="{{ 'common.country' | translate }}">
                <ng-template let-item="item" ng-label-tmp ng-option-tmp>
                    <div *ngIf="item" class="d-flex justify-content-start align-items-center">
                        <div>
                            <img [attr.alt]="item.alpha_2_code"
                                 [src]="'https://cdn.qwyk.io/images/flags/r/' + (item.alpha_2_code | lowercase) + '.svg'"
                                 onerror="this.src='https://cdn.qwyk.io/images/flags/r/united-nations.svg'"
                                 style="width: 16px; height: auto; margin-top: -4px;">
                        </div>
                        <div class="flex-grow-1 ml-1 text-left">{{ item.short_name }}</div>
                    </div>
                </ng-template>
            </ng-select>
            <arv-validation-messages for="country_code"></arv-validation-messages>
        </div>
    </div>

    <div class="form-group">
        <label for="phone_number">{{ 'hub.shipment.sections.instructions.subsections.parties-fields.phone-number' | translate }}</label>
        <input [ngClass]="{'is-invalid': form.get('phone_number').touched && form.get('phone_number').invalid}"
               class="form-control" formControlName="phone_number" id="phone_number" maxlength="15"
               name="phone_number" placeholder="{{ 'hub.shipment.sections.instructions.subsections.parties-fields.phone-number' | translate }}"
               type="text"/>
        <arv-validation-messages for="phone_number"></arv-validation-messages>
    </div>

    <div class="form-group">
        <label>{{ 'portal.settings.profile.marketing' | translate }}</label>
        <div class="custom-control custom-switch">
            <input class="custom-control-input" formControlName="marketing_optin" id="marketing_optin" type="checkbox">
            <label class="custom-control-label" for="marketing_optin">{{ 'hub.administration.portal-users.opted-in-to-marketing-mails' | translate }}</label>
        </div>
    </div>

    <ng-container *ngIf="(permissions$ | async) as permissions">
        <ng-container *ngIf="(teams$ | async) as teams">
            <div *ngIf="!isMagayaNetwork">
                <hr class="my-5">
                <h5 class="font-weight-bold m-0" id="permissions">{{ 'common.permissions' | translate }}</h5>
                <p class="text-muted mb-4">{{ 'hub.administration.portal-users.manage-user-permissions' | translate }}.</p>
                <qwyk-customer-user-permissions [permissions]="permissions" [user]="user"></qwyk-customer-user-permissions>
            </div>
        </ng-container>
    </ng-container>

    <qwyk-form-error [error]="error"></qwyk-form-error>

    <div class="form-group border-top pt-3 d-flex align-items-center justify-content-end">
        <div *ngIf="!user.approved">
            <button (click)="onActivate()" [disabled]="form.disabled" class="btn btn-outline-success" type="button">
                {{ 'hub.administration.portal-users.approve-access' | translate }}
            </button>
            <button (click)="onDeactivate()" [disabled]="form.disabled" class="btn btn-outline-danger ml-2"
                    type="button">
                {{ 'hub.administration.portal-users.reject-access' | translate }}
            </button>
        </div>
        <div *ngIf="user.approved">
            <button (click)="onDeactivate()" [disabled]="form.disabled" class="btn btn-outline-danger" type="button">
                {{ 'hub.administration.portal-users.deactivate-user' | translate }}
            </button>
        </div>
        <div class="flex-grow-1 text-right">
            <button [disabled]="form.disabled" [routerLink]="['..']" class="btn btn-light" type="button">{{ 'common.cancel' | translate }}</button>
            <button (click)="onSave()" *ngIf="user.approved" [disabled]="form.disabled" class="btn btn-primary ml-2">
                <fa-icon [icon]="['far', form.enabled ? 'save' : 'spinner']" [pulse]="form.disabled" class="mr-2">
                </fa-icon>
                {{ 'hub.administration.portal-users.save-user' | translate }}
            </button>
        </div>
    </div>
</form>

