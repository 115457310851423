<!-- <th></th>Í -->
<th>
    <p-autoComplete [(ngModel)]="originFilter" [forceSelection]="true" class="p-fluid"
        [suggestions]="searchLocationResults" (completeMethod)="searchLocation($event)" field="display_name"
        inputStyleClass="form-control form-control-sm">
        <ng-template let-value pTemplate="item">
            <div class="autocomplete-list-location p-2" [innerHtml]="value.highlight">
            </div>
        </ng-template>
    </p-autoComplete>
</th>
<th>
    <p-autoComplete [(ngModel)]="destinationFilter" [forceSelection]="true" class="p-fluid"
        [suggestions]="searchLocationResults" (completeMethod)="searchLocation($event)" field="display_name"
        inputStyleClass="form-control form-control-sm">
        <ng-template let-value pTemplate="item">
            <div class="autocomplete-list-location p-2" [innerHtml]="value.highlight">
            </div>
        </ng-template>
    </p-autoComplete>
</th>
<th>
    <select [(ngModel)]="providerFilter" class="form-control form-control-sm">
        <option [ngValue]="null"></option>
        <option [ngValue]="p" *ngFor="let p of providers;">{{ p.name }}</option>
    </select>
</th>
<th></th>