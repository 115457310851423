/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { QuotationOffersService } from '../../services/quotation-offers.service';
import { take } from 'rxjs/operators';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: '[qwyk-offer-lines-row]',
    templateUrl: './offer-lines-row.component.html',
    styleUrls: ['./offer-lines-row.component.scss'],
})
export class OfferLinesRowComponent {
    @Input() rowIndex: number;
    @Input() offerLineGroup: FormGroup;
    @Input() index: number;
    @Input() editing: boolean;
    @Input() currencies: { code: string; name: string }[];
    @Input() rateCalculations: any[];
    @Input() chargeCodes: any[];
    @Input() chargeSegments: { value: string; label: string }[];
    @Input() offerCurrency: string;
    @Input() disabled = false;
    @Input() quotationId: null;
    public rowChanging = false;

    @Output() editingRow: EventEmitter<{
        index: number;
        data: any;
    }> = new EventEmitter();
    @Output() finishEditingRow: EventEmitter<number> = new EventEmitter();
    @Output() deleteRow: EventEmitter<number> = new EventEmitter();
    public readonly CHARGE_CODE_OTHER = 'OTH';
    private editingRowData = null;

    constructor(private service: QuotationOffersService) {}

    public get baseCurrency(): string | null {
        return this.offerLineGroup?.get('base_currency')?.value;
    }

    public onChargeCodeChanged(e) {
        if (!e?.value || e.value.code === this.CHARGE_CODE_OTHER) {
            this.offerLineGroup.get('charge_description').setValue(null);
        } else {
            this.offerLineGroup
                .get('charge_description')
                .setValue(e.value.description);
        }
    }

    public onSellingRateInput() {
        const values = this.offerLineGroup.value;
        this.offerLineGroup
            .get('base_markup_rate')
            .setValue(values.base_rate - values.base_buying_rate);
    }

    public onMarginRateInput() {
        const values = this.offerLineGroup.value;
        this.offerLineGroup
            .get('base_rate')
            .setValue(values.base_buying_rate + values.base_markup_rate);
    }

    public onBuyingRateInput() {
        const values = this.offerLineGroup.value;
        if (values.base_rate >= values.base_buying_rate) {
            this.onSellingRateInput();
        } else {
            this.onMarginRateInput();
        }
    }

    public onEditRow(): void {
        this.editingRowData = { ...this.offerLineGroup.value };
        this.editingRow.emit({
            index: this.rowIndex,
            data: this.offerLineGroup,
        });
    }

    public onCancelRowEdit(): void {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        setTimeout(_ => {
            if (this.editingRowData) {
                this.offerLineGroup.patchValue(this.editingRowData);
                this.editingRowData = null;
                this.rowChanging = false;
            } else {
                // Row was added, we need to delete it to cancel out.
                this.onDeleteRow();
            }
            this.finishEditingRow.emit(this.rowIndex);
        }, 10);
    }

    public onCompleteRowEdit(): void {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        setTimeout(_ => {
            if (this.offerLineGroup.invalid) {
                return;
            }
            this.service
                .calculateOfferLineTotals(this.quotationId, {
                    ...this.offerLineGroup.value,
                    offer_currency: this.offerCurrency,
                })
                .pipe(take(1))
                .subscribe(
                    result => {
                        this.offerLineGroup.patchValue(result);
                        this.finishEditingRow.emit(this.rowIndex);
                    },
                    error => {
                        console.error(error);
                    },
                    () => {
                        console.log('complete');
                    }
                );
            this.rowChanging = false;
        }, 10);
    }

    public onDeleteRow(): void {
        this.deleteRow.emit(this.rowIndex);
    }

    public onChangingRow(): void {
        this.rowChanging = true;
    }
}
