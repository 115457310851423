<div class="modal-body">
  <div class="lead">
    Create a custom provider
  </div>
  <div class="text-muted">Set up a new provider, custom to your organization, that you can upload schedules for and
    attach to your routing guide.</div>
  <input type="text" class="d-none" />
  <form [formGroup]="createProviderForm" (submit)="submit()" class="qw-form mt-4">
    <div class="form-group">
      <label for="name">Name</label>
      <input id="name" type="text" class="form-control mw-100" formControlName="name" maxLength="50"
        [ngClass]="{'is-invalid': validationErrors?.name || (createProviderForm.get('name').touched && createProviderForm.get('name').invalid)}" />
      <div class="invalid-feedback">
        <div *ngIf="createProviderForm.get('name').invalid">Please enter the provider's name.</div>
        <div *ngFor="let err of validationErrors?.name">{{ err }}</div>
      </div>
    </div>
    <div class="form-group">
      <label for="product">Product</label>
      <select id="product" class="form-control" formControlName="product"
        [ngClass]="{'is-invalid': validationErrors?.product || (createProviderForm.get('product').touched && createProviderForm.get('product').invalid)}">
        <option value disabled [attr.selected] class="d-none"></option>
        <option value="air">Air</option>
        <option value="fcl">FCL</option>
        <option value="lcl">LCL</option>
      </select>
      <div class="invalid-feedback">
        <div *ngIf="createProviderForm.get('product').invalid">Please select a valid product.</div>
        <div *ngFor="let err of validationErrors?.product">{{ err }}</div>
      </div>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="checkbox" formControlName="active" id="active">
      <label class="form-check-label" for="active">
        Activate
      </label>
    </div>
    <div class="form-group mt-5 mb-0">
      <div class="text-danger mb-2" *ngIf="error && !validationErrors">There was an error creating this provider, please
        try again or contact support if the
        problem persists.</div>
      <button class="btn btn-primary mr-1" type="submit" [disabled]="createProviderForm.disabled">
        <ng-container *ngIf="!isCreating; else creating;">
          Create provider
        </ng-container>
      </button>
      <button class="btn btn-light" type="button" (click)="cancel()"
        [disabled]="createProviderForm.disabled">Cancel</button>
    </div>
  </form>
</div>

<ng-template #creating>
  <fa-icon [icon]="['far', 'spinner']" pulse="true" class="mr-1"></fa-icon>Creating Provider
</ng-template>
