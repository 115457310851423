import { Component, Input, OnInit } from '@angular/core';

import { LocalStorageService } from '@qwyk/core';
import { HasActivities } from '@qwyk/shared-stores/activities';

@Component({
    selector: 'qwyk-activity-feed-card',
    templateUrl: './activity-feed-card.component.html',
    styleUrls: ['./activity-feed-card.component.scss'],
})
export class ActivityFeedCardComponent implements OnInit {
    @Input() parent?: HasActivities;
    @Input() cardStateKey = 'dfp.hub.activity-feed.collapsed';

    private readonly directionStateKey = 'dfp.hub.activity-feed.direction';
    private readonly filterStateKey = 'dfp.hub.activity-feed.filter';

    private _direction: 'asc' | 'desc' = 'asc';
    get direction(): 'asc' | 'desc' {
        return this._direction;
    }
    set direction(value: 'asc' | 'desc') {
        this._direction = value;
        this.localStorage.setString(this.directionStateKey, value);
    }

    private _expanded = true;
    get expanded(): boolean {
        return this._expanded;
    }
    @Input() set expanded(value: boolean) {
        this._expanded = value;
        this.localStorage.setBool(this.cardStateKey, value);
    }

    private _filter: 'all' | 'event' | 'comment' = 'all';
    get filter(): 'all' | 'event' | 'comment' {
        return this._filter;
    }
    @Input() set filter(value: 'all' | 'event' | 'comment') {
        this._filter = value;
        this.localStorage.setString(this.filterStateKey, value);
    }

    constructor(private localStorage: LocalStorageService) {}

    ngOnInit() {
        this._expanded = this.localStorage.getBool(this.cardStateKey, true);
        this._direction = (this.localStorage.getString(
            this.directionStateKey
        ) ?? 'asc') as 'asc' | 'desc';
        this._filter = (this.localStorage.getString(this.filterStateKey) ??
            'all') as 'all' | 'event' | 'comment';
    }
}
