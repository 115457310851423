import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SiteExistsGuard } from './guards/site-exists.guard';
import {
    SitesListComponent,
    SiteViewComponent,
    SiteCreateComponent,
} from './components';
import { CanComponentDeactivateGuard } from '@qwyk/core';
import { HubAdministrationSitesWithoutRoutingModule } from './hub-administration-sites-without-routing.module';

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild([
            { path: '', pathMatch: 'full', component: SitesListComponent },
            {
                path: 'new',
                component: SiteCreateComponent,
                canDeactivate: [CanComponentDeactivateGuard],
            },
            {
                path: ':id',
                component: SiteViewComponent,
                canActivate: [SiteExistsGuard],
                canDeactivate: [CanComponentDeactivateGuard],
            },
        ]),
        HubAdministrationSitesWithoutRoutingModule,
    ],
    declarations: [],
    providers: [],
    exports: [],
})
export class HubAdministrationSitesModule {}
