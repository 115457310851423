/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { common, Hub } from '@qwyk/models';
import { Observable } from 'rxjs';
import { environment } from '@qwyk/hub/environment';
import { map, take } from 'rxjs/operators';
import { MagayaEntityListResponse } from '../models';

@Injectable({
    providedIn: 'root',
})
export class CustomerUsersService {
    constructor(private http: HttpClient) {}

    getCustomerUsers(
        query: any
    ): Observable<common.PaginatedResponse<Hub.CustomerUser>> {
        let httpQuery = null;
        if (query) {
            httpQuery = new HttpParams({ fromObject: query });
        }
        return this.http
            .get<common.Pagination<Hub.CustomerUser>>(
                `${environment.backendServer}/api/hub/administration/users?${httpQuery}`
            )
            .pipe(take(1));
    }

    getCustomerUser(id: string): Observable<any> {
        return this.http
            .get<{ data: any }>(
                `${environment.backendServer}/api/hub/administration/users/${id}`
            )
            .pipe(
                take(1),
                map(response => response.data)
            );
    }

    createCustomerUser(payload: any): Observable<Hub.CustomerUser> {
        return this.http
            .post<{ data: Hub.CustomerUser }>(
                `${environment.backendServer}/api/hub/administration/users`,
                payload
            )
            .pipe(
                take(1),
                map(response => response.data)
            );
    }

    updateCustomerUser(id: string, payload: any): Observable<Hub.CustomerUser> {
        return this.http
            .post<{ data: Hub.CustomerUser }>(
                `${environment.backendServer}/api/hub/administration/users/${id}`,
                payload
            )
            .pipe(
                take(1),
                map(response => response.data)
            );
    }

    activateCustomerUser(
        id: string,
        payload: any
    ): Observable<Hub.CustomerUser> {
        return this.http
            .post<{ data: Hub.CustomerUser }>(
                `${environment.backendServer}/api/hub/administration/users/${id}/activate`,
                payload
            )
            .pipe(
                take(1),
                map(response => response.data)
            );
    }

    deactivateCustomerUser(id: string, scrub: boolean): Observable<void> {
        return this.http
            .delete<void>(
                `${
                    environment.backendServer
                }/api/hub/administration/users/${id}${
                    scrub ? '?scrub=true' : ''
                }`
            )
            .pipe(take(1));
    }

    getTeams(query: any): Observable<Hub.CustomerTeam[]> {
        const params = new HttpParams({ fromObject: query });
        return this.http
            .get<{ data: Hub.CustomerTeam[]; count: any }>(
                `${environment.backendServer}/api/hub/administration/teams?${params}`
            )
            .pipe(
                take(1),
                map(response => response.data)
            );
    }

    public getSites(query: any): Observable<Hub.Site[]> {
        let httpQuery = null;
        if (query) {
            httpQuery = new HttpParams({ fromObject: query });
        }
        return this.http
            .get<common.Pagination<Hub.Site>>(
                `${environment.backendServer}/api/hub/administration/sites?${httpQuery}`
            )
            .pipe(
                take(1),
                map(response => response.data)
            );
    }

    public getImpersonationToken(id: string): Observable<any> {
        return this.http
            .get<any>(
                `${environment.backendServer}/api/hub/administration/users/${id}/get-token`
            )
            .pipe(take(1));
    }

    public getMagayaEntities(query: any): Observable<MagayaEntityListResponse> {
        for (const key of Object.keys(query)) {
            if (!query[key]) {
                delete query[key];
            } else {
                query[key] = query[key].toString();
            }
        }
        const httpParams = new HttpParams({ fromObject: query });
        return this.http
            .get<MagayaEntityListResponse>(
                `${environment.backendServer}/api/hub/administration/users/magaya-entities?${httpParams}`
            )
            .pipe(take(1));
    }

    public getHierarchy(user: Hub.CustomerUser): Observable<any[]> {
        return this.http
            .get<any[]>(
                `${environment.backendServer}/api/hub/administration/users/${user.id}/hierarchy`
            )
            .pipe(take(1));
    }

    public getPermissions(user: Hub.CustomerUser): Observable<any[]> {
        return this.http
            .get<any[]>(
                `${environment.backendServer}/api/hub/administration/users/${user.id}/permissions`
            )
            .pipe(take(1));
    }
}
