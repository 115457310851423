<div class="modal-body">
    <div class="lead">{{ 'hub.schedules.routing-guide.upload-routing-guide' | translate }}</div>
    <p class="text-muted" *ngIf="!startedUpload">
        {{ 'hub.schedules.routing-guide.upload-your-previously-downloaded-routing-guide' | translate }}
    </p>
    <form [formGroup]="form" (submit)="startUpload()" *ngIf="!startedUpload">
        <div class="d-flex flex-row align-items-center">
            <app-media-uploader name="routingguide" class="mr-1 flex-grow-1"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                (fileSelected)="handleFileUpload($event)" [disabled]="startedUpload" [maxSize]="10">
            </app-media-uploader>
            <span class="d-inline-block"
                style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis">{{ form.get('routing_guide_file').value ? form.get('routing_guide_file').value['name'] : null }}</span>
        </div>
        <div *ngIf="form.get('routing_guide_file').invalid && form.get('routing_guide_file').touched"
            class="small text-danger">
            {{ 'common.validation.please-select-a-valid-file' | translate }}
        </div>

        <p *ngIf="error" class="mt-2 text-danger">
            {{ 'common.generic-error-alt-2' | translate }}
        </p>

        <div class="mt-3">
            <button class="btn btn-primary mr-1" [disabled]="isBusy" type="submit">
                <fa-icon [icon]="['far', 'spinner']" pulse="true" *ngIf="isBusy"></fa-icon>
                {{ (isBusy ? 'common.starting-upload' : 'common.start-upload') | translate }}
            </button>
            <button class="btn btn-light" (click)="modal.dismiss()" [disabled]="isBusy" type="button">{{ 'common.cancel' | translate }}</button>
        </div>
    </form>

    <p *ngIf="startedUpload" class="mt-2">
        {{ 'hub.schedules.routing-guide.your-upload-has-started' | translate }}
    </p>

    <ng-container *ngIf="startedUpload">
        <button class="btn btn-primary mr-1" type="button" (click)="modal.close()"
            [routerLink]="['/schedules', 'tasks']" [queryParams]="{tab: 'upload'}">{{ 'common.view-progress' | translate }}</button>
        <button class="btn btn-light" (click)="modal.close()" type="button">{{ 'common.close' | translate }}</button>
    </ng-container>
</div>
