import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, timer } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { TasksService } from '../../../services/tasks.service';
import { fadeInOutAnimation } from './../../../../../helpers/reusableAnimations';

@Component({
    selector: 'app-import-job',
    templateUrl: './import-job.component.html',
    styleUrls: ['./import-job.component.scss'],
    animations: [fadeInOutAnimation],
})
export class ImportJobComponent implements OnInit {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    importJob$: Observable<any>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    errors$: Observable<any>;
    lastRefresh: Date;
    _errorsPage = 1;
    errorsPage$: BehaviorSubject<number> = new BehaviorSubject(
        this._errorsPage
    );

    constructor(
        private route: ActivatedRoute,
        private service: TasksService,
        private toastr: ToastrService
    ) {}

    ngOnInit() {
        this.importJob$ = timer(1, 30e3).pipe(
            switchMap(() => this.route.params),
            switchMap(params => {
                return this.service.getImportJob(params.id);
            }),
            tap(() => {
                this.lastRefresh = new Date();
            })
        );

        this.errors$ = this.errorsPage$.pipe(
            switchMap(page =>
                this.route.params.pipe(map(params => ({ id: params.id, page })))
            ),
            switchMap(params => {
                return this.service.getImportJobErrors(params.id, params.page);
            }),
            tap(result => {
                this.errorsPage = result.meta.current_page;
            })
        );
    }

    get nextRefresh(): number {
        return (moment().diff(this.lastRefresh, 'seconds') / 30) * 100;
    }

    public downloadImportJobFile(task) {
        task.downloading = true;

        const downloadSubscription = this.service
            .getImportJobFileLink(task.id)
            .subscribe(
                result => {
                    window.open(result.url, 'blank');

                    task.downloading = false;
                    downloadSubscription.unsubscribe();
                },
                () => {
                    task.downloading = false;
                    downloadSubscription.unsubscribe();
                    this.toastr.error(
                        'Please try again or contact support if the problem persists',
                        // tslint:disable-next-line: quotemark
                        "We're sorry, something went wrong downloading your routing guide."
                    );
                }
            );
    }

    public set errorsPage(page: number) {
        if (page !== this._errorsPage) {
            this._errorsPage = page;
            this.errorsPage$.next(page);
        }
    }

    public get errorsPage(): number {
        return this._errorsPage;
    }
}
