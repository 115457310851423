/* eslint-disable @typescript-eslint/no-explicit-any */
import { Provider } from './../models/providers.models';
import { RoutingGuideItem } from './../models/routing-guide.models';
import { createAction, props } from '@ngrx/store';
import {
    RoutingGuide,
    RoutingGuideItemsResponse,
} from '../models/routing-guide.models';

export const loadRoutingGuide = createAction(
    '[Schedules RG] Load Routing Guide',
    props<{ product: string; pageSize: number }>()
);
export const loadRoutingGuideSuccess = createAction(
    '[Schedules RG] Load Routing Guide Success',
    props<{
        routingGuide: RoutingGuide;
        providers: Provider[];
        pageSize?: number;
    }>()
);
export const loadRoutingGuideFailure = createAction(
    '[Schedules RG] Load Routing Guide Failure'
);

export const loadRoutingGuideItems = createAction(
    '[Schedules RG] Load Routing Guide Items',
    props<{
        routingGuideId: number | undefined;
        page?: number;
        pageSize?: number;
    }>()
);
export const loadRoutingGuideItemsSuccess = createAction(
    '[Schedules RG] Load Routing Guide Items Success',
    props<{ items: RoutingGuideItemsResponse }>()
);
export const loadRoutingGuideItemsFailure = createAction(
    '[Schedules RG] Load Routing Guide Items Failure'
);

export const createRoutingGuideItem = createAction(
    '[Schedules RG] Create Routing Guide Item',
    props<{ routingGuideId: number; routingGuideItem: RoutingGuideItem }>()
);
export const createRoutingGuideItemSuccess = createAction(
    '[Schedules RG] Create Routing Guide Item Success',
    props<{
        createdRoutingGuideItem: RoutingGuideItem;
    }>()
);
export const createRoutingGuideItemFailed = createAction(
    '[Schedules RG] Create Routing Guide Item Failed',
    props<{ error: any }>()
);

export const updateRoutingGuideItem = createAction(
    '[Schedules RG] Update Routing Guide Item',
    props<{ routingGuideItem: RoutingGuideItem }>()
);
export const updateRoutingGuideItemSuccess = createAction(
    '[Schedules RG] Update Routing Guide Item Success',
    props<{
        updatedRoutingGuideItem: RoutingGuideItem;
    }>()
);
export const updateRoutingGuideItemFailure = createAction(
    '[Schedules RG] Update Routing Guide Item Failure',
    props<{ error: any }>()
);

export const deleteRoutingGuideItem = createAction(
    '[Schedules RG] Delete Routing Guide Item',
    props<{ routingGuideItem: RoutingGuideItem }>()
);

export const deleteRoutingGuideItemStarted = createAction(
    '[Schedules RG] Delete Routing Guide Item Started',
    props<{ routingGuideItem: RoutingGuideItem }>()
);

export const deleteRoutingGuideItemSuccess = createAction(
    '[Schedules RG] Delete Routing Guide Item Success'
);

export const deleteRoutingGuideItemFailure = createAction(
    '[Schedules RG] Delete Routing Guide Item Failure'
);
