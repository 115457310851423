import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
    ActivitiesEntity,
    CommentActivityPayload,
} from '@qwyk/shared-stores/activities';
import { common } from '@qwyk/models';

@Component({
    selector: 'qwyk-activities-list-container',
    templateUrl: './activities-list-container.component.html',
    styleUrls: ['./activities-list-container.component.scss'],
})
export class ActivitiesListContainerComponent {
    @Input() activities: ActivitiesEntity[] = [];
    @Input() loading = false;
    @Input() saving = false;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() error?: any;
    @Input() pagination?: common.CursorPaginationMeta;
    @Output() createComment: EventEmitter<CommentActivityPayload> =
        new EventEmitter<CommentActivityPayload>();
    @Output() updateComment: EventEmitter<{
        activity: ActivitiesEntity;
        payload: CommentActivityPayload;
    }> = new EventEmitter<{
        activity: ActivitiesEntity;
        payload: CommentActivityPayload;
    }>();
    @Output() deleteComment: EventEmitter<ActivitiesEntity> =
        new EventEmitter<ActivitiesEntity>();
    @Input() userId?: string | number;
    @Input() direction?: 'asc' | 'desc';
    @Output() loadMore: EventEmitter<void> = new EventEmitter<void>();
    @Input() filter?: string | null;

    onCreateComment(payload: CommentActivityPayload) {
        this.createComment.emit(payload);
    }
}
