import {Component, OnInit} from '@angular/core';
// noinspection ES6PreferShortImport
import {NotificationsFacade} from "./../../+state/notifications.facade";
import {map} from "rxjs/operators";

@Component({
    selector: 'qwyk-notifications-list-widget',
    templateUrl: './notifications-list-widget.component.html',
    styleUrls: ['./notifications-list-widget.component.scss']
})
export class NotificationsListWidgetComponent implements OnInit {

    public notifications$ = this.notifications.allNotifications$.pipe(map(notifications => {
        if (!notifications) {
            return notifications;
        }
        return notifications.slice(0, 5);
    }));
    public loading$ = this.notifications.loading$;
    public error$ = this.notifications.error$;
    public pagination$ = this.notifications.pagination$;
    private query = {page: 1, pageSize: 5};

    constructor(private notifications: NotificationsFacade) {
    }

    ngOnInit(): void {
        this.loadQuotations(1);
    }

    private loadQuotations(page: number): void {
        if (page) {
            this.query.page = page;
        }

        this.notifications.loadNotifications(this.query);
    }
}
