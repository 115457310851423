import { createReducer, on, Action } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import { common, Hub } from '@qwyk/models';

import * as UsersActions from './users.actions';

export const USERS_FEATURE_KEY = 'users';

export interface State extends EntityState<Hub.HubUser> {
    selectedId?: string | number; // which Users record has been selected
    loading: boolean; // has the Users list been loaded
    error?: string | null; // last none error (if any)
    pagination?: common.PaginationMeta;
}

export interface UsersPartialState {
    readonly [USERS_FEATURE_KEY]: State;
}

export const usersAdapter: EntityAdapter<Hub.HubUser> =
    createEntityAdapter<Hub.HubUser>();

export const initialState: State = usersAdapter.getInitialState({
    // set initial required properties
    loading: false,
});

const usersReducer = createReducer(
    initialState,
    on(UsersActions.loadUsers, state => ({
        ...state,
        loading: true,
        error: null,
    })),
    on(UsersActions.loadUsersSuccess, (state, { users, pagination }) =>
        usersAdapter.setAll(users, { ...state, loading: false, pagination })
    ),
    on(
        UsersActions.loadUsersFailure,
        UsersActions.loadUserFailure,
        (state, { error }) => ({
            ...state,
            error,
            loading: false,
        })
    ),
    on(UsersActions.loadUser, state => ({
        ...state,
        loading: true,
        error: null,
    })),
    on(UsersActions.loadUserSuccess, (state, { user }) =>
        usersAdapter.setOne(user, {
            ...state,
            loading: false,
            error: null,
        })
    ),
    on(
        UsersActions.createUser,
        UsersActions.updateUser,
        UsersActions.deactivateUser,
        UsersActions.activateUser,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        (state, _) => ({
            ...state,
            loading: true,
            error: null,
        })
    ),
    on(UsersActions.createUserSuccess, (state, { user }) =>
        usersAdapter.setOne(user, {
            ...state,
            loading: false,
        })
    ),
    on(UsersActions.updateUserSuccess, (state, { user }) =>
        usersAdapter.updateOne(
            {
                id: user.id,
                changes: user,
            },
            {
                ...state,
                loading: false,
            }
        )
    ),
    on(
        UsersActions.deactivateUserSuccess,
        UsersActions.activateUserSuccess,
        (state, { user }) =>
            usersAdapter.updateOne(
                { id: user.id, changes: user },
                { ...state, loading: false }
            )
    ),
    on(
        UsersActions.createUserFailure,
        UsersActions.updateUserFailure,
        UsersActions.deactivateUserFailure,
        UsersActions.activateUserFailure,
        (state, { error }) => ({
            ...state,
            loading: false,
            error,
        })
    ),
    on(UsersActions.selectUser, (state, { id }) => ({
        ...state,
        selectedId: id,
    })),
    on(UsersActions.unselectUser, state => ({
        ...state,
        selectedId: null,
    }))
);

export function reducer(state: State | undefined, action: Action) {
    return usersReducer(state, action);
}
