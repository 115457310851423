import { Component, Input } from '@angular/core';
import { Portals } from '@qwyk/models';

@Component({
    selector: 'qwyk-quotation-offer-list-expansion-item',
    templateUrl: './quotation-offer-list-expansion-item.component.html',
    styleUrls: ['./quotation-offer-list-expansion-item.component.scss'],
})
export class QuotationOfferListExpansionItemComponent {
    @Input() quotationOffer: Portals.QuotationOffer;
    public expand: boolean;

    public get detailSwitchId(): string {
        return `totalsDetailed${this.quotationOffer.id}`;
    }
}
