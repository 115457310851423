import { CustomerUserExistsGuard } from './customer-user-exists.guard';
import { select, Store } from '@ngrx/store';
import * as fromCustomerUsers from '../+state/customer-users.reducer';
import { CustomerUsersService } from '../services/customer-users.service';
import {
    ActivatedRouteSnapshot,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import * as customerUsersSelectors from '../+state/customer-users.selectors';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { AuthenticationFacade } from '@qwyk/hub/authentication';
import { Hub } from '@qwyk/models';

@Injectable({
    providedIn: 'root',
})
export class MagayaUserGuard extends CustomerUserExistsGuard {
    isMagayaNetwork: boolean;
    user: Hub.CustomerUser;

    constructor(
        store: Store<fromCustomerUsers.CustomerUsersPartialState>,
        service: CustomerUsersService,
        router: Router,
        private authentication: AuthenticationFacade
    ) {
        super(store, service, router);
    }

    public canActivate(
        next: ActivatedRouteSnapshot,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        state: RouterStateSnapshot
    ): Observable<boolean> {
        const id = this.getEntityKey(next);
        return this.getUserFromStore(id, next);
    }

    public canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> {
        return this.canActivate(next, state);
    }

    private getUserFromApi(
        id: string,
        next: ActivatedRouteSnapshot
    ): Observable<boolean> {
        return this.service.getCustomerUser(id).pipe(
            map(customerUser => {
                this.user = customerUser;
                if (this.user.approved && this.isMagayaNetwork) {
                    this.redirect(next, 403, 'Forbidden');
                    return false;
                }
                return true;
            }),
            catchError(error => {
                this.redirect(next, error.status, error.statusText);
                return of(true);
            })
        );
    }

    private getUserFromStore(id: string, next: ActivatedRouteSnapshot) {
        this.authentication.user$
            .pipe(
                map(u => u.is_organization_magaya_network),
                take(1)
            )
            .subscribe(
                isMagayaNetwork => (this.isMagayaNetwork = isMagayaNetwork)
            );
        return this.store.pipe(
            select(customerUsersSelectors.getCustomerUsersEntities),
            switchMap(entities => {
                this.user = entities[id];
                if (!this.user) {
                    return this.getUserFromApi(id, next);
                } else if (this.user.approved && this.isMagayaNetwork) {
                    this.redirect(next, 403, 'Forbidden');
                    return of(false);
                }
                return of(true);
            }),
            take(1)
        );
    }

    private redirect(next: ActivatedRouteSnapshot, errorCode, errorName): void {
        this.router.navigate([`/error/${errorCode}`], {
            queryParams: {
                source: next.pathFromRoot.map(p => p.url).join('/'),
                errorName: errorName,
            },
        });
    }
}
