import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    CUSTOMERUSERS_FEATURE_KEY,
    State,
    CustomerUsersPartialState,
    customerUsersAdapter,
} from './customer-users.reducer';

// Lookup the 'CustomerUsers' feature state managed by NgRx
export const getCustomerUsersState = createFeatureSelector<
    CustomerUsersPartialState,
    State
>(CUSTOMERUSERS_FEATURE_KEY);

const { selectAll, selectEntities } = customerUsersAdapter.getSelectors();

export const getCustomerUsersLoaded = createSelector(
    getCustomerUsersState,
    (state: State) => state.loaded
);

export const getCustomerUsersLoading = createSelector(
    getCustomerUsersState,
    (state: State) => state.loading
);

export const getCustomerUsersPagination = createSelector(
    getCustomerUsersState,
    (state: State) => state.pagination
);

export const getCustomerUsersError = createSelector(
    getCustomerUsersState,
    (state: State) => state.error
);

export const getAllCustomerUsers = createSelector(
    getCustomerUsersState,
    (state: State) => selectAll(state)
);

export const getCustomerUsersEntities = createSelector(
    getCustomerUsersState,
    (state: State) => selectEntities(state)
);

export const getSelectedId = createSelector(
    getCustomerUsersState,
    (state: State) => state.selectedId
);

export const getSelected = createSelector(
    getCustomerUsersEntities,
    getSelectedId,
    (entities, selectedId) => selectedId && entities[selectedId]
);
