import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { QwykValidators } from '@qwyk/core';

import {
    UserNotExistsValidator,
    Auth0UserNotExistsValidator,
} from '../../validators';
import { UsersService } from '../../services/users.service';

@Component({
    selector: 'qwyk-user-create',
    templateUrl: './user-create.component.html',
    styleUrls: ['./user-create.component.scss'],
})
export class UserCreateComponent {
    private _saving = false;
    @Input() public error = null;

    @Output()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public create: EventEmitter<any> = new EventEmitter();

    @Input()
    public set saving(saving: boolean) {
        this._saving = saving;
        setTimeout(() => {
            if (saving) {
                this.form.disable();
            } else {
                this.form.enable();
                if (this.form.get('sub').value) {
                    this.form.get('password').disable();
                    this.form.get('email').disable();
                    this.form.get('password_confirmation').disable();
                }
            }
        }, 0);
    }

    public get saving(): boolean {
        return this._saving;
    }

    public form: FormGroup = this.fb.group({
        name: [
            null,
            [
                Validators.required,
                Validators.maxLength(191),
                Validators.minLength(3),
            ],
        ],
        email: [
            null,
            [Validators.required, Validators.email],
            [
                new UserNotExistsValidator(this.service, this.translate),
                new Auth0UserNotExistsValidator(this.service),
            ],
        ],
        role_id: [null, [Validators.required]],
        sub: [null],
        password: [
            null,
            [
                Validators.required,
                Validators.minLength(12),
                Validators.maxLength(24),
            ],
        ],
        password_confirmation: [
            null,
            [
                Validators.required,
                QwykValidators.matchOtherValidator('password'),
            ],
        ],
    });

    public rolesLoading = true;
    public roles$ = this.service
        .getRoles()
        .pipe(tap(() => (this.rolesLoading = false)));

    constructor(
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private service: UsersService,
        private translate: TranslateService
    ) {}

    public onCreate() {
        this.form.markAllAsTouched();
        if (this.form.invalid) {
            return;
        }

        const payload = {
            ...this.form.value,
            email: this.form.get('email').value,
        };
        this.create.emit(payload);
    }

    public linkAuth0Sub(sub: string) {
        this.form.get('sub').setValue(sub);
        this.form.get('email').disable();
        this.form.get('email').clearAsyncValidators();
        this.form.get('password').disable();
        this.form.get('password').clearAsyncValidators();
        this.form.get('password_confirmation').disable();
        this.form.get('password_confirmation').clearAsyncValidators();
    }

    public generatePassword(length = 10) {
        const chars =
            'abcdefghijklmnpqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ0123456789!!!@@@###$$$';
        let pwd = '';
        for (let i = 0; i < length; i++) {
            let newChar = null;
            while (newChar === null || pwd.includes(newChar)) {
                newChar = chars[Math.floor(Math.random() * chars.length)];
            }
            pwd += newChar;
        }
        this.form.get('password').setValue(pwd);
        this.form.get('password_confirmation').setValue(pwd);
    }
}
