import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { LocationSelectComponent } from './components/location-select/location-select.component';
import { LocationSelectPanelComponent } from './components/location-select-panel/location-select-panel.component';

@NgModule({
    declarations: [LocationSelectComponent, LocationSelectPanelComponent],
    imports: [CommonModule, FormsModule, ReactiveFormsModule],
    exports: [LocationSelectComponent],
})
export class ControlsModule {}
