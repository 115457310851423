<ng-container *ngIf="magayaEntities$ | async; let response">
    <div class="form-inline mb-3">
        <input [(ngModel)]="searchName" class="form-control" id="name" placeholder="{{ 'hub.administration.search-by-name' | translate }}" type="text"/>
        <button (click)="onSearch($event)" [disabled]="loading" class="btn btn-primary ml-2">{{ 'common.search' | translate }}</button>
    </div>
    <p-table
        (onLazyLoad)="loadEntitiesLazy($event)"
        [(selection)]="selectedEntity"
        [lazy]="true"
        [loading]="loading"
        [scrollable]="true"
        [value]="response.data"
        dataKey="guid" scrollHeight="300px" selectionMode="single"
    >
        <ng-template let-columns pTemplate="header">
            <tr>
                <th style="width: 200px;">{{ 'common.type' | translate }}</th>
                <th>{{ 'common.name' | translate }}</th>
            </tr>
        </ng-template>

        <ng-template let-columns="columns" let-entity let-rowIndex="rowIndex" pTemplate="body">
            <tr [pSelectableRow]="entity" style="height:34px">
                <td style="width: 200px;">{{ entity.type | slugToTitle }}</td>
                <td>{{ entity.name }}</td>
            </tr>
            <tr *ngIf="rowIndex === response.data.length - 1 && response?.meta?.next">
                <td class="text-center" colspan="2">
                    <button (click)="loadEntitiesLazy($event, response?.meta?.next)" class="btn btn-sm btn-primary">
                        {{ 'common.load-more' | translate }}
                    </button>
                </td>
            </tr>
        </ng-template>

        <ng-template let-columns pTemplate="loadingbody">
            <tr>
                <td
                    class="py-4 text-muted text-center"
                    colspan="2"
                >
                    {{ 'common.loading' | translate }}...
                </td>
            </tr>
        </ng-template>
    </p-table>

    <div *ngIf="selectedEntity" class="mt-5 d-flex align-items-center justify-content-between">
        <div>
        </div>
        <button (click)="onSelectEntity()" [disabled]="loading" class="btn btn-primary">
            {{ 'common.continue' | translate }}
            <fa-icon [icon]="['far', loading ? 'spinner' : 'arrow-right']" [pulse]="loading" class="ml-2"></fa-icon>
        </button>
    </div>
</ng-container>
