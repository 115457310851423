<div class="col-lg-10 offset-lg-1 col-xl-8 offset-xl-2 p-0" [@fadeInOut]>
    <div class="head mt-3 mb-5 d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
            <button class="btn btn-sm btn-light" [routerLink]="['../../']" [queryParams]="{tab: 'upload'}">
                <fa-icon [icon]="['far', 'arrow-alt-left']"></fa-icon>
            </button>
            <fa-icon [icon]="['fad', 'tasks-alt']" size="2x" class="text-primary mr-3" fixedWidth="true"></fa-icon>
            <div>
                <span class="lead">Upload Task</span>
                <p class="text-muted mb-0">View the log of an upload task.</p>
            </div>
        </div>
    </div>

    <ng-container *ngIf="importJob$ | async; let importJob; else loading">
        <div [@fadeInOut] class="pb-5">
            <div class="px-1 d-flex justify-content-between align-items-end">
                <h6>Task info</h6>
                <span class="text-muted small pb-1">
                    <ngb-progressbar type="secondary" [value]="nextRefresh" height="12px">
                        <span class="px-1">
                            Next refresh {{ lastRefresh| amAdd: 30 : 'seconds' | amTimeAgo }}
                        </span>
                    </ngb-progressbar>
                </span>
            </div>
            <ul class="list-group">
                <li class="list-group-item d-flex flex-row justify-content-between">
                    <div class="strong text-muted">Status</div>
                    <div>
                        <div [ngSwitch]="importJob.status | lowercase" class="mr-2">
                            <fa-icon *ngSwitchCase="'completed'" [icon]="['far', 'check']" class="text-success"
                                size="lg" [fixedWidth]="true">
                            </fa-icon>
                            <fa-icon *ngSwitchCase="'failed'" size="lg" [icon]="['far', 'exclamation-triangle']"
                                class="text-danger" [fixedWidth]="true"></fa-icon>
                            <fa-icon *ngSwitchDefault [icon]="['far', 'spinner']" pulse="true" size="lg"
                                [fixedWidth]="true">
                            </fa-icon>
                            {{ importJob.status | titlecase }}
                        </div>

                    </div>
                </li>
                <li class="list-group-item d-flex flex-row justify-content-between">
                    <div class="strong text-muted">Type</div>
                    <div>{{ importJob.type | slugToTitle }}</div>
                </li>
                <li class="list-group-item d-flex flex-row justify-content-between"
                    *ngFor="let a of importJob.attributes | objectToKVP">
                    <div class="strong text-muted">{{ a.key | slugToTitle }}</div>
                    <div>{{ a.value | slugToTitle }}</div>
                </li>
                <li class="list-group-item d-flex flex-row justify-content-between align-items-center">
                    <div class="strong text-muted">File name</div>
                    <div>
                        {{ importJob.filename }}
                        <button class="btn btn-light btn-sm ml-1" *ngIf="importJob.has_file"
                            [disabled]="importJob.downloading" ngbTooltip="Download original file"
                            (click)="downloadImportJobFile(importJob)">
                            <fa-icon [icon]="['far', importJob.downloading ? 'spinner' : 'file-download']"
                                [pulse]="importJob.downloading"></fa-icon>
                        </button>
                    </div>
                </li>
                <li class="list-group-item d-flex flex-row justify-content-between">
                    <div class="strong text-muted">Started</div>
                    <div [ngbTooltip]="importJob.created_at | amDateFormat:'lll'" tooltipClass="small">
                        {{ importJob.created_at | amLocal | amTimeAgo }}
                    </div>
                </li>
                <li class="list-group-item d-flex flex-row justify-content-between">
                    <div class="strong text-muted">Updated</div>
                    <div [ngbTooltip]="importJob.updated_at | amDateFormat:'lll'" tooltipClass="small">
                        {{ importJob.updated_at| amLocal | amTimeAgo  }}</div>
                </li>

                <li class="list-group-item d-flex flex-row justify-content-between"
                    *ngIf="importJob.status === 'completed' || importJob.status === 'failed'">
                    <div class="strong text-muted">Run time</div>
                    <div>
                        {{ importJob.updated_at | amDifference: importJob.created_at : 'seconds': true | number:'1.0-0' }}
                        seconds
                    </div>
                </li>
            </ul>

            <h6 class="mt-5 px-1">Log messages</h6>
            <ul class="list-group">
                <li class="list-group-item d-flex flex-row justify-content-between align-items-center"
                    *ngFor="let message of importJob.messages">
                    <div class="mr-1" [ngClass]="{'text-danger': message.is_error}">{{ message.message }}</div>
                    <div class=" flex-shrink-0 small" [ngbTooltip]="message.created_at | amDateFormat:'lll'"
                        tooltipClass="small">{{ message.created_at | amTimeAgo }}</div>
                </li>
            </ul>
            <div *ngIf="importJob.error_count > 0">
                <h6 class="mt-5 px-1">Validation errors</h6>
                <div *ngIf="errors$ | async; let errors; else loading">
                    <ul class="list-group">
                        <li class="list-group-item" *ngFor="let error of errors.data">
                            <div class="small">{{ error.row_number }}: {{ error.attribute }}</div>
                            <div *ngFor="let e of error.errors">
                                {{ e }}
                            </div>
                        </li>
                    </ul>
                    <div class="small text-center">
                        {{ errors.meta.from }} to {{ errors.meta.to }} of {{ errors.meta.total }}
                    </div>
                    <ngb-pagination [pageSize]="errors.meta.per_page" [collectionSize]="errors.meta.total"
                        [(page)]="errorsPage" class="d-flex justify-content-center mt-3" size="sm">
                    </ngb-pagination>
                </div>
            </div>
        </div>
    </ng-container>


</div>

<ng-template #loading>
    <div class="text-center text-muted">
        <fa-icon [icon]="['far', 'spinner']" pulse="true" size="3x"></fa-icon>
    </div>
</ng-template>
