import { Component, OnDestroy, OnInit } from '@angular/core';

import { of, Subject, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { Hub } from '@qwyk/models';
import { environment } from '@qwyk/hub/environment';
import { SitesFacade } from '@qwyk/hub/administration/sites';

import { CustomerUsersService } from '../../services/customer-users.service';

@Component({
    selector: 'qwyk-customer-user-impersonate-modal',
    templateUrl: './customer-user-impersonate-modal.component.html',
    styleUrls: ['./customer-user-impersonate-modal.component.scss'],
})
export class CustomerUserImpersonateModalComponent
    implements OnInit, OnDestroy
{
    public user: Hub.CustomerUser;
    public sites$ = this.sites.allSites$.pipe(
        tap(sites =>
            sites && sites.length > 0 ? (this.selectedSite = sites[0]) : null
        )
    );
    public selectedSite: Hub.Site;
    public error = null;
    private impersonate$: Subject<{
        user: Hub.CustomerUser;
        site: Hub.Site;
    }> = new Subject<{ user: Hub.CustomerUser; site: Hub.Site }>();
    private impersonateSubscription: Subscription;

    constructor(
        private sites: SitesFacade,
        private modal: NgbActiveModal,
        private translate: TranslateService,
        private service: CustomerUsersService
    ) {
        sites.loadSites({ pageSize: 100000, active: true });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private static buildUrl(token: any, site: Hub.Site): string | null {
        try {
            const host = site.enable_cname
                ? `https://${site.cname}`
                : environment.portalSitesMask.replace(
                      '{0}',
                      site.endpoint_identifier
                  );
            const route = '/auth/with-token';
            return `${host}${route}?token=${token.access_token}&token_type=${token.token_type}&expires_in=${token.expires_in}`;
        } catch (e) {
            console.error(e);
            return null;
        }
    }

    ngOnInit(): void {
        this.impersonateSubscription = this.impersonate$
            .pipe(
                switchMap(data =>
                    this.service.getImpersonationToken(data.user.id).pipe(
                        map(token =>
                            CustomerUserImpersonateModalComponent.buildUrl(
                                token,
                                data.site
                            )
                        ),
                        catchError(error => {
                            this.error = error?.error?.message;
                            return of(null);
                        })
                    )
                ),
                catchError(() => {
                    this.error = this.translate.instant(
                        'common.unexpected-error'
                    );
                    return of(null);
                })
            )
            .subscribe(url => {
                if (url) {
                    window.open(url, '_blank');
                    this.onCancel();
                }
            });
    }

    ngOnDestroy(): void {
        this.impersonateSubscription.unsubscribe();
    }

    onContinue() {
        this.error = null;
        if (!this.selectedSite || !this.user) {
            this.error = this.translate.instant(
                'hub.administration.portal-users.user-unknown-or-no-site-selected'
            );
            return;
        }
        this.impersonate$.next({ user: this.user, site: this.selectedSite });
    }

    onCancel() {
        this.modal.dismiss();
    }
}
