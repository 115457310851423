import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { environment } from '@qwyk/hub/environment';

import {
    RoutingGuide,
    RoutingGuideItem,
    RoutingGuideItemsResponse,
} from '../store/models/routing-guide.models';

@Injectable({
    providedIn: 'root',
})
export class RoutingGuideService {
    /**
     * Fetches the organization's routing guide for the provided product
     * @param product the product
     */
    getRoutingGuide(product: string): Observable<RoutingGuide> {
        return this.http.get<RoutingGuide>(
            `${environment.schedulesBackend}/api/routingguides/${product}`
        );
    }

    /**
     * Fetches the items for the provided routing guide
     *
     * @param routingGuideId the id of the routing guide
     * @param page the page of routing guide items (default = 1)
     * @param pageSize the size of each page (default = 20)
     */
    getRoutingGuideItems(
        routingGuideId: number | undefined,
        page = 1,
        pageSize = 20 // DEFAULT = 20
    ): Observable<RoutingGuideItemsResponse> {
        return this.http.get<RoutingGuideItemsResponse>(
            `${environment.schedulesBackend}/api/routingguides/${routingGuideId}/items?page=${page}&pageSize=${pageSize}`
        );
    }

    createRoutingGuideItem(
        routingGuideId: number,
        routingGuideItem: RoutingGuideItem
    ): Observable<RoutingGuideItem> {
        return this.http.post<RoutingGuideItem>(
            `${environment.schedulesBackend}/api/routingguides/${routingGuideId}/items`,
            routingGuideItem
        );
    }

    updateRoutingGuideItem(routingGuideItem: RoutingGuideItem) {
        return this.http.post<RoutingGuideItem>(
            `${environment.schedulesBackend}/api/routingguides/${routingGuideItem.routing_guide_id}/items/${routingGuideItem._id}`,
            routingGuideItem
        );
    }

    deleteRoutingGuideItem(routingGuideItem: RoutingGuideItem) {
        return this.http.delete<void>(
            `${environment.schedulesBackend}/api/routingguides/${routingGuideItem.routing_guide_id}/items/${routingGuideItem._id}`
        );
    }

    /**
     * Starts a new routing guide download task on the server
     * @param routingGuide the routing guide to download
     */
    startRoutingGuideDownload(routingGuide: RoutingGuide) {
        return this.http
            .post<void>(
                `${environment.schedulesBackend}/api/routingguides/${routingGuide.id}/download`,
                null
            )
            .pipe(take(1));
    }

    startRoutingGuideUpload(
        routingGuide: RoutingGuide,
        formValue: { routing_guide_file: File }
    ) {
        const payload: FormData = new FormData();
        payload.append(
            'file',
            formValue.routing_guide_file,
            formValue.routing_guide_file.name
        );

        return this.http
            .post<void>(
                `${environment.schedulesBackend}/api/routingguides/${routingGuide.id}/upload`,
                payload
            )
            .pipe(take(1));
    }

    simulateRoutingGuide(payload: {
        product: string;
        origin: string;
        destination: string;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }): Observable<any> {
        return (
            this.http
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .get<any>(
                    `${environment.schedulesBackend}/api/routingguides/${payload.product}/simulate/${payload.origin}/${payload.destination}`
                )
                .pipe(take(1))
        );
    }

    constructor(private http: HttpClient) {}
}
