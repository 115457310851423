import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'qwyk-activity-feed-control',
    templateUrl: './activity-feed-control.component.html',
    styleUrls: ['./activity-feed-control.component.scss']
})
export class ActivityFeedControlComponent {

    @Input() public direction?: 'asc' | 'desc';
    @Output() public directionChange: EventEmitter<'asc' | 'desc'> = new EventEmitter<"asc" | "desc">();
    @Input() public filter?: 'all' | 'comment' | 'event';
    @Output() public filterChange: EventEmitter<'all' | 'comment' | 'event'> = new EventEmitter<'all' | 'comment' | 'event'>();

    public setDirection(direction: 'asc' | 'desc'): void {
        this.directionChange.emit(direction);
    }

    public setFilter(filter: 'all' | 'comment' | 'event'): void {
        this.filterChange.emit(filter);
    }

}
