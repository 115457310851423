import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'valueMask',
})
export class ValueMaskPipe implements PipeTransform {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    transform(value: any, masked: string): any {
        return masked.replace('{0}', value);
    }
}
