import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { common } from '@qwyk/models';
import { Observable } from 'rxjs';
import { NotificationsEntity } from './+state/notifications.models';
import { environment } from '@qwyk/hub/environment';
import { take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class NotificationsService {
    constructor(private http: HttpClient) {}

    public getNotifications(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        query: any
    ): Observable<common.PaginatedResponse<NotificationsEntity>> {
        const $query = new HttpParams({ fromObject: query });

        return this.http
            .get<common.PaginatedResponse<NotificationsEntity>>(
                `${environment.backendServer}/api/hub/notifications?${$query}`
            )
            .pipe(take(1));
    }
}
