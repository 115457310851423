<div class="bg-white min-vh-100">
    <div class="container-fluid py-3 pb-5">
        <div class="row justify-content-center">
            <div class="col-12 col-lg-9 col-xxxl-6">
                <div class="small text-muted border-bottom pb-2 mb-3">
                    <a class="text-muted" [routerLink]="['/']">Qwyk</a>
                    &rsaquo;
                    <a class="text-muted" [routerLink]="['/administration']">{{ 'hub.administration.title' | translate }}</a>
                    &rsaquo;
                    <a class="text-muted" [routerLink]="['./..']">{{ 'hub.menu.side-menu.sites' | translate }}</a>
                    &rsaquo;
                    <a class="text-muted font-weight-bold" [routerLink]="['./']">{{ 'common.new' | translate }}</a>
                </div>

                <div class="d-flex flex-row justify-content-between mb-3">
                    <h3 class="font-weight-normal m-0">{{ 'hub.administration.sites.create-site' | translate }}</h3>
                </div>

                <form [formGroup]="form" (submit)="submit()">
                    <div class="form-group">
                        <label for="name">{{ 'common.name' | translate }}</label>
                        <input id="name" name="name" type="text" class="form-control" placeholder="{{ 'hub.administration.portal-users.enter-a-name' | translate }}..."
                            formControlName="name" maxlength="40"
                            [ngClass]="{'is-invalid': form.get('name').invalid && form.get('name').touched}" />
                        <div class="invalid-feedback">{{ 'hub.administration.portal-users.enter-a-name' | translate }}</div>
                    </div>

                    <div class="form-group">
                        <label for="endpoint_identifier">{{ 'common.address' | translate }}</label>
                        <div class="input-group"
                            [ngClass]="{'is-invalid': form.get('endpoint_identifier').invalid && form.get('endpoint_identifier').touched}">
                            <div class="input-group-prepend">
                                <span class="input-group-text">{{ siteAddressPrefix }}</span>
                            </div>
                            <input id="endpoint_identifier" name="endpoint_identifier" type="text" class="form-control"
                                formControlName="endpoint_identifier" maxlength="30" placeholder="{{ 'hub.administration.sites.your-site' | translate }}..."
                                [ngClass]="{'is-invalid': form.get('endpoint_identifier').invalid && form.get('endpoint_identifier').touched}">
                            <div class="input-group-append">
                                <span class="input-group-text">
                                    {{ siteAddressSuffix }}
                                </span>
                            </div>
                        </div>
                        <div class="invalid-feedback">
                            <div *ngIf="!form.get('endpoint_identifier').errors?.validated_endpoint">
                                {{ 'common.validation.enter-an-address' | translate }}
                            </div>
                            <div *ngIf="form.get('endpoint_identifier').errors?.validated_endpoint">
                                {{ form.get('endpoint_identifier').errors.validated_endpoint }}
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="description">{{ 'common.description' | translate }}</label>
                        <textarea id="description" name="description" class="form-control"
                            placeholder="{{ 'hub.administration.sites.description-placeholder' | translate }}..." rows="4" maxlength="191"
                            formControlName="description"
                            [ngClass]="{'is-invalid': form.get('description').invalid && form.get('description').touched}"></textarea>
                    </div>

                    <qwyk-form-error [error]="error$ | async"></qwyk-form-error>

                    <div class="form-group border-top pt-3 text-right">
                        <button class="btn btn-light mr-2" type="button" [routerLink]="['..']"
                            [disabled]="form.disabled">{{ 'common.cancel' | translate }}</button>
                        <button class="btn btn-primary" type="submit" [disabled]="form.disabled">
                            {{ 'common.continue' | translate }}
                            <fa-icon [icon]="['far', !form.disabled ? 'arrow-right' : 'spinner']" class="ml-2"
                                [pulse]="form.disabled"></fa-icon>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
