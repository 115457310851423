import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ActivitiesEntity, CommentActivityPayload} from '@qwyk/shared-stores/activities';

@Component({
    selector: 'qwyk-activities-list',
    templateUrl: './activities-list.component.html',
    styleUrls: ['./activities-list.component.scss'],
})
export class ActivitiesListComponent {
    @Input() activities: ActivitiesEntity[] = [];
    @Output() updateComment: EventEmitter<{ activity: ActivitiesEntity, payload: CommentActivityPayload }> =
        new EventEmitter<{ activity: ActivitiesEntity; payload: CommentActivityPayload }>();
    @Output() deleteComment: EventEmitter<ActivitiesEntity> = new EventEmitter<ActivitiesEntity>();
    @Output() loadMore: EventEmitter<void> = new EventEmitter<void>();
    @Input() saving = false;
    @Input() loading = false;
    @Input() canLoadMore = false;
    @Input() userId?: string | number;
    @Input() direction?: 'asc' | 'desc';
}
