import { Component, Input } from '@angular/core';

import { Language } from '../../language.model';
import { PortalsTranslateService } from './../../portals-translate.service';

@Component({
    selector: 'qwyk-language-picker',
    templateUrl: './language-picker.component.html',
    styleUrls: ['./language-picker.component.scss'],
})
export class LanguagePickerComponent {
    @Input() public wrapperClass = null;
    @Input() public buttonClass = 'btn-link';
    @Input() public buttonTextClass = 'text-white';
    @Input() public container = null;
    @Input() public placement = 'bottom-left';
    @Input() public template: 'list-item' | 'button' | 'select' = 'button';
    @Input() public showSelectedLanguage = false;
    @Input() public showFlags = true;

    constructor(private translate: PortalsTranslateService) {}

    public get currentLanguage(): string {
        return this.translate.currentLanguage;
    }

    public get availableLanguages(): Language[] {
        return this.translate.availableLanguages.sort((a, b) =>
            a.name > b.name ? 1 : -1
        );
    }

    public get currentLanguageFull(): Language | null {
        return this.translate.availableLanguages.find(
            e => e.key === this.currentLanguage
        );
    }

    public get isTranslationEnabled(): boolean {
        return this.translate.isTranslationsEnabled;
    }

    public setLanguage(language: Language): void {
        this.translate.useLanguage(language.key);
    }
}
