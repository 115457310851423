/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAction, props } from '@ngrx/store';
import { common, Hub } from '@qwyk/models';

export const loadUsers = createAction(
    '[Hub/Settings/Users] Load Users',
    props<{ query: any }>()
);

export const loadUsersSuccess = createAction(
    '[Hub/Settings/Users] Load Users Success',
    props<{ users: Hub.HubUser[]; pagination: common.PaginationMeta }>()
);

export const loadUsersFailure = createAction(
    '[Hub/Settings/Users] Load Users Failure',
    props<{ error: any }>()
);

export const loadUser = createAction(
    '[Hub/Settings/Users] Load User',
    props<{ id: number }>()
);

export const loadUserSuccess = createAction(
    '[Hub/Settings/Users] Load User Success',
    props<{ user: Hub.HubUser }>()
);
export const loadUserFailure = createAction(
    '[Hub/Settings/Users] Load User Failure',
    props<{ error: any }>()
);

export const createUser = createAction(
    '[Hub/Settings/Users] Create User',
    props<{ payload: any }>()
);
export const createUserSuccess = createAction(
    '[Hub/Settings/Users] Create User Success',
    props<{ user: Hub.HubUser }>()
);
export const createUserFailure = createAction(
    '[Hub/Settings/Users] Create User Failure',
    props<{ error: any }>()
);

export const updateUser = createAction(
    '[Hub/Settings/Users] Update User',
    props<{ id: number; payload: any }>()
);
export const updateUserSuccess = createAction(
    '[Hub/Settings/Users] Update User Success',
    props<{ user: Hub.HubUser }>()
);
export const updateUserFailure = createAction(
    '[Hub/Settings/Users] Update User Failure',
    props<{ error: any }>()
);

export const deactivateUser = createAction(
    '[Hub/Settings/Users] Deactivate User',
    props<{ user: Hub.HubUser }>()
);
export const deactivateUserSuccess = createAction(
    '[Hub/Settings/Users] Deactivate User Success',
    props<{ user: Hub.HubUser }>()
);
export const deactivateUserFailure = createAction(
    '[Hub/Settings/Users] Deactivate User Failure',
    props<{ error: any }>()
);

export const activateUser = createAction(
    '[Hub/Settings/Users] Activate User',
    props<{ user: Hub.HubUser }>()
);
export const activateUserSuccess = createAction(
    '[Hub/Settings/Users] Activate User Success',
    props<{ user: Hub.HubUser }>()
);
export const activateUserFailure = createAction(
    '[Hub/Settings/Users] Activate User Failure',
    props<{ error: any }>()
);

export const selectUser = createAction(
    '[Hub/Settings/User] Select User',
    props<{ id: number }>()
);

export const unselectUser = createAction('[Hub/Settings/User] Unselect User');
