import { Component, Input, OnInit } from '@angular/core';
import { Hub } from '@qwyk/models';
import { CustomerUsersService } from '../../services/customer-users.service';
import { of } from 'rxjs';
import { CustomerPermissions } from '@qwyk/core';
import { SlugToTitlePipe } from '@qwyk/ui';
import { catchError, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'qwyk-customer-user-permissions',
    templateUrl: './customer-user-permissions.component.html',
    styleUrls: ['./customer-user-permissions.component.scss'],
})
export class CustomerUserPermissionsComponent implements OnInit {
    @Input() user: Hub.CustomerUser;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() permissions: any[];
    public hierarchy$ = of([]);
    public slugToTitle = new SlugToTitlePipe();
    private readonly _permission_list = CustomerPermissions as object;
    public readonly PERMISSION_LIST: { label: string; value: string }[] =
        Object.keys(this._permission_list)
            .map(e => {
                return {
                    label: this.translate.instant(
                        'hub.administration.portal-users.permissions.' +
                            e.toLowerCase()
                    ),
                    value: this._permission_list[e],
                };
            })
            .sort((a, b) => (a.value > b.value ? 1 : -1));

    public readonly FILTERED_PERMISSION_LIST = this.PERMISSION_LIST.filter(
        e => !e.value.endsWith(':create') && !e.value.startsWith('attachments:')
    );

    public loading = true;
    public error = false;

    constructor(
        private translate: TranslateService,
        private service: CustomerUsersService
    ) {}

    ngOnInit(): void {
        this.hierarchy$ = this.service.getHierarchy(this.user).pipe(
            catchError(() => {
                this.error = true;
                return of(null);
            }),
            tap(() => (this.loading = false))
        );
    }
}
