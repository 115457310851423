import {createFeatureSelector, createSelector} from '@ngrx/store';
import {
    NOTIFICATIONS_FEATURE_KEY,
    notificationsAdapter,
    NotificationsPartialState,
    State,
} from './notifications.reducer';


// Lookup the 'Notifications' feature state managed by NgRx
export const getNotificationsState = createFeatureSelector<NotificationsPartialState,
    State>(NOTIFICATIONS_FEATURE_KEY);

const {selectAll, selectEntities} = notificationsAdapter.getSelectors();

export const getNotificationsLoading = createSelector(
    getNotificationsState,
    (state: State) => state.loading
);

export const getNotificationsError = createSelector(
    getNotificationsState,
    (state: State) => state.error
);

export const getAllNotifications = createSelector(
    getNotificationsState,
    (state: State) => selectAll(state)
);

export const getNotificationsEntities = createSelector(
    getNotificationsState,
    (state: State) => selectEntities(state)
);

export const getSelectedId = createSelector(
    getNotificationsState,
    (state: State) => state.selectedId
);

export const getSelected = createSelector(
    getNotificationsEntities,
    getSelectedId,
    (entities, selectedId) => selectedId && entities[selectedId]
);

export const getPagination = createSelector(
    getNotificationsState,
    (state: State) => state.pagination
);

export const getError = createSelector(
    getNotificationsState,
    (state: State) => state.error
);

export const getQuery = createSelector(
    getNotificationsState,
    (state: State) => state.query
);
