<div [ngClass]="{'flex-column-reverse': direction === 'desc'}" class="d-flex flex-column">
    <qwyk-activities-list
        (deleteComment)="deleteComment.emit($event)"
        (loadMore)="loadMore.emit()"
        (updateComment)="updateComment.emit($event)"
        [activities]="activities"
        [canLoadMore]="!!pagination?.cursor"
        [direction]="direction"
        [loading]="loading"
        [saving]="saving"
        [userId]="userId"
    ></qwyk-activities-list>

    <div
        *ngIf="(!activities || activities.length === 0) && !loading"
        class="p-3 text-center">
        <div>{{ 'common.no-item-to-display' | translate : { value : !filter || filter === 'all' ? 'activity' : (filter + 's') } }}</div>
        <div *ngIf="filter && filter !== 'all'">{{ 'common.try-removing-the-filter' | translate : { value : filter } }}</div>
    </div>

    <div *ngIf="loading" class="p-3 text-center">
        <fa-icon [icon]="['far', 'spinner']" [pulse]="true"></fa-icon>
    </div>

    <div class="p-3">
        <qwyk-create-comment
            (save)="onCreateComment($event)"
            [disabled]="saving || loading"
            [saving]="saving"
        ></qwyk-create-comment>
    </div>
</div>
