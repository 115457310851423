import { Component, Input } from '@angular/core';
import { FormArray, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MasterDataService } from '@qwyk/core';
import { map, shareReplay } from 'rxjs/operators';
import { masterdata, Portals } from '@qwyk/models';
import { QuotationsService } from '../../services/quotations.service';

@Component({
    selector: 'qwyk-quotation-offer-lines',
    templateUrl: './quotation-offer-lines.component.html',
    styleUrls: ['./quotation-offer-lines.component.scss'],
})
export class QuotationOfferLinesComponent {
    @Input() formGroup: FormGroup;
    @Input() quotation: Portals.QuotationEntity;
    rowTotalCurrency = 'row';
    editingRows = {};
    private clonedOfferLines = {};

    chargeSegments = [
        { value: 'pickup', label: 'Pickup' },
        { value: 'origin', label: 'Origin' },
        { value: 'freight', label: 'Freight' },
        { value: 'destination', label: 'Destination' },
        { value: 'delivery', label: 'Delivery' },
    ];

    chargeCodes$ = this.masterData.getRateChargeCodes().pipe(shareReplay());

    rateCalculations$ = this.masterData
        .getRateCalculations()
        .pipe(shareReplay());

    currencies$ = this.masterData.getCurrencies().pipe(
        map(data =>
            data
                .map(e => {
                    e['group'] = e.is_preferred ? undefined : '-';
                    return e;
                })
                .sort((a, b) => (a.is_preferred > b.is_preferred ? -1 : 1))
        ),
        shareReplay()
    );
    constructor(
        private masterData: MasterDataService,
        private fb: FormBuilder,
        private quotationsService: QuotationsService
    ) {}

    onAddChargeLine() {
        const id = this.formGroup.get('offer_lines').value.length;
        (this.formGroup.get('offer_lines') as FormArray).push(
            this.fb.group({
                id: [id],
                segment: [null, [Validators.required]],
                charge_code: [null, [Validators.required]],
                charge_description: [null, [Validators.required]],
                base_currency: [
                    this.formGroup.get('header.currency').value,
                    [Validators.required],
                ],
                base_rate: [null, [Validators.required]],
                base_calculation: [null, [Validators.required]],
                base_minimum: [0, [Validators.required]],
                total_amount: [null],
                exchange_rate: [null],
                total_amount_quotation: [null],
            })
        );

        this.editingRows[id] = true;
    }

    get formArray() {
        return this.formGroup.get('offer_lines') as FormArray;
    }

    onRemoveOfferLine(index) {
        (this.formGroup.get('offer_lines') as FormArray).removeAt(index);
    }

    onEditOfferLine(index, id, offerLine) {
        this.clonedOfferLines[id] = { ...offerLine.value };
    }

    onSaveOfferLine(index, id, offerLine) {
        offerLine.patchValue({
            total_amount: null,
            exchange_rate: null,
            total_amount_quotation: null,
        });
        const payload = {
            ...offerLine.value,
            offer_currency: this.formGroup.get('header.currency').value,
        };
        this.quotationsService
            .calculateLineTotal(this.quotation.id, payload)
            .subscribe(result => {
                offerLine.patchValue(result);
            });
        delete this.clonedOfferLines[id];
    }

    onCancelEditOfferLine(index, id, offerLine) {
        const originalOfferLine = this.clonedOfferLines[id];
        if (originalOfferLine) {
            offerLine.patchValue(originalOfferLine);
            delete this.clonedOfferLines[id];
        } else {
            this.onRemoveOfferLine(index);
        }
    }

    get isEditingRows() {
        return Object.keys(this.editingRows).length > 0;
    }

    get totalAmount() {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const array: any[] = this.formArray.value;
        return array.reduce(
            (prev, curr) => (prev += curr.total_amount_quotation),
            0
        );
    }

    onChargeCodeChanged(e, index) {
        let valueToSet = null;
        if (e) {
            valueToSet = e.description;
        }
        this.formGroup
            .get(`offer_lines.${index}.charge_description`)
            .setValue(valueToSet);
    }

    rateCalculationComparer = (
        a: masterdata.RateCalculation,
        b: masterdata.RateCalculation
    ) => {
        if (!a || !b) {
            return null;
        }
        return a.id === b.id;
        // tslint:disable-next-line: semicolon
    };
}
