<ng-container *ngIf="isTranslationEnabled">

    <ng-container [ngSwitch]="template">
        <li *ngSwitchCase="'list-item'"
            [container]="container"
            [ngClass]="wrapperClass"
            [ngbPopover]="languagePopContent"
            [ngbTooltip]="languageToolTipContent"
            popoverClass="language-popover">
            <a [ngClass]="buttonClass" class="d-flex align-items-center justify-content-center h-100" role="button">
                <fa-icon [icon]="['far', 'globe']" [ngClass]="buttonTextClass" size="lg"></fa-icon>
                <span *ngIf="showSelectedLanguage" class="ml-2 no-underline">{{ currentLanguageFull?.name }}</span>
            </a>
        </li>

        <div *ngSwitchCase="'button'" [ngClass]="wrapperClass">
            <button
                [container]="container"
                [ngClass]="buttonClass"
                [ngbPopover]="languagePopContent"
                [ngbTooltip]="languageToolTipContent"
                class="btn"
                popoverClass="language-popover">
            <span [ngClass]="buttonTextClass">
                <fa-icon [icon]="['far', 'globe']"></fa-icon>
                <span *ngIf="showSelectedLanguage" class="ml-2 no-underline">{{ currentLanguageFull?.name }}</span>
            </span>
            </button>
        </div>

        <ng-container *ngSwitchCase="'select'">
            <select
                class="form-control clear-styles"
                placement="right"
                container="body"
                [ngModel]="currentLanguageFull"
                (ngModelChange)="setLanguage($event)"
                ngbTooltip="{{ 'translation.language-picker.click-to-edit-your-language-here' | translate }}"
            >
                <option
                    *ngFor="let language of availableLanguages"
                    [ngValue]="language"
                    >
                    {{ language.name }}
                </option>
            </select>
        </ng-container>
    </ng-container>

    <ng-template #languagePopContent>
        <ul class="list-group list-group-flush">
            <button
                (click)="setLanguage(language)"
                *ngFor="let language of availableLanguages"
                [ngClass]="{'font-weight-bold': currentLanguage === language.key}"
                class="list-group-item list-group-item-action border-0"
                type="button"
            >
                <img *ngIf="showFlags"
                    [attr.alt]="language.name"
                    [src]="'https://cdn.qwyk.io/images/flags/r/' + (language.flag | lowercase) + '.svg'"
                    class="mr-2"
                    style="width: 16px; height: auto; margin-top: -2px;">
                {{ language.name }}
            </button>
        </ul>
    </ng-template>

    <ng-template #languageToolTipContent>
        <div>{{ 'translation.language-picker.tooltip' | translate }}</div>
        <div *ngIf="currentLanguage !== 'en'" class="small">(Change language)</div>
    </ng-template>

</ng-container>
