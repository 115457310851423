import {Component, Input} from '@angular/core';
import {ActivitiesEntity} from '@qwyk/shared-stores/activities';

@Component({
    selector: 'qwyk-activity-event-list-item',
    templateUrl: './activity-event-list-item.component.html',
    styleUrls: ['./activity-event-list-item.component.scss']
})
export class ActivityEventListItemComponent {
    @Input() activity!: ActivitiesEntity;
}
