import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetch } from '@nx/angular';
import * as CustomerUsersActions from './customer-users.actions';
import { CustomerUsersService } from '../services/customer-users.service';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class CustomerUsersEffects {
    loadCustomerUsers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CustomerUsersActions.loadCustomerUsers),
            fetch({
                run: action => {
                    // Your custom service 'load' logic goes here. For now just return a success action...
                    return this.service.getCustomerUsers(action.query).pipe(
                        map(result =>
                            CustomerUsersActions.loadCustomerUsersSuccess({
                                customerUsers: result.data,
                                pagination: result.meta,
                            })
                        )
                    );
                },

                onError: (action, error) => {
                    console.error(error);
                    return CustomerUsersActions.loadCustomerUsersFailure({
                        error,
                    });
                },
            })
        )
    );

    createCustomerUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CustomerUsersActions.createCustomerUser),
            switchMap(action =>
                this.service.createCustomerUser(action.payload).pipe(
                    map(customerUser =>
                        CustomerUsersActions.createCustomerUserSuccess({
                            customerUser,
                        })
                    ),
                    catchError(error =>
                        of(
                            CustomerUsersActions.createCustomerUserFailure({
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    activateCustomerUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CustomerUsersActions.activateCustomerUser),
            switchMap(action =>
                this.service
                    .activateCustomerUser(action.id, action.payload)
                    .pipe(
                        map(customerUser =>
                            CustomerUsersActions.mutateCustomerUserSuccess({
                                customerUser,
                            })
                        ),
                        catchError(error =>
                            of(
                                CustomerUsersActions.mutateCustomerUserFailure({
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    updateCustomerUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CustomerUsersActions.updateCustomerUser),
            switchMap(action =>
                this.service.updateCustomerUser(action.id, action.payload).pipe(
                    map(customerUser =>
                        CustomerUsersActions.mutateCustomerUserSuccess({
                            customerUser,
                        })
                    ),
                    catchError(error =>
                        of(
                            CustomerUsersActions.mutateCustomerUserFailure({
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    deactivateCustomerUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CustomerUsersActions.deactivateCustomerUser),
            switchMap(action =>
                this.service
                    .deactivateCustomerUser(action.id, action.scrub)
                    .pipe(
                        map(() =>
                            CustomerUsersActions.deactivateCustomerUserSuccess({
                                id: action.id,
                            })
                        ),
                        catchError(error =>
                            of(
                                CustomerUsersActions.mutateCustomerUserFailure({
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    mutateCustomerUserSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    CustomerUsersActions.createCustomerUserSuccess,
                    CustomerUsersActions.mutateCustomerUserSuccess,
                    CustomerUsersActions.deactivateCustomerUserSuccess
                ),
                tap(() => {
                    this.router.navigate(['/administration', 'portal-users']);
                })
            ),
        { dispatch: false }
    );

    constructor(
        private actions$: Actions,
        private service: CustomerUsersService,
        private router: Router
    ) {}
}
