import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class MenuSideBarService {
    private _shown = false;
    constructor() {}

    public get shown(): boolean {
        return this._shown;
    }

    public toggle(): void {
        this._shown = !this._shown;
    }

    public hide(): void {
        this._shown = false;
    }

    public show(): void {
        this._shown = true;
    }
}
