import {
    loadProviders,
    loadProvidersSuccess,
    loadProvidersFailure,
    activateProvider,
    deactivateProviderFailure,
    deactivateProvider,
    activateProviderFailure,
    deleteProvider,
    deleteProviderFailure,
    createProviderSuccess,
    createProvider,
    createProviderFailure,
    createProviderCancelled,
} from './../actions/providers.actions';
import { createReducer, on, Action } from '@ngrx/store';
import { ProvidersState } from '../schedules.state';

const initialState: ProvidersState = {
    providers: [],
    loading: false,
    error: false,
    creating: false,
    errorCreating: false,
};

const reducer = createReducer(
    initialState,

    /* Load Providers */
    on(loadProviders, state => ({
        ...state,
        loading: true,
        error: false,
    })),
    on(loadProvidersSuccess, (state, { providers }) => ({
        ...state,
        loading: false,
        providers,
    })),
    on(loadProvidersFailure, state => ({
        ...state,
        loading: false,
        error: true,
    })),

    /* Create Provider */
    on(createProvider, state => ({
        ...state,
        creating: true,
        errorCreating: null,
    })),
    on(createProviderSuccess, (state, { provider }) => ({
        ...state,
        creating: false,
        providers: [...state.providers, provider].sort((a, b) => {
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
                return 1;
            }
            return -1;
        }),
    })),
    on(createProviderFailure, (state, { error }) => ({
        ...state,
        creating: false,
        errorCreating: error,
    })),
    on(createProviderCancelled, state => ({
        ...state,
        creating: false,
        errorCreating: null,
    })),

    /* (De)activate Provider */
    on(activateProvider, deactivateProviderFailure, (state, { provider }) => {
        return {
            ...state,
            providers: state.providers.map(e => {
                if (e.id === provider.id) {
                    e.active = true;
                }
                return e;
            }),
        };
    }),

    on(deactivateProvider, activateProviderFailure, (state, { provider }) => {
        return {
            ...state,
            providers: state.providers.map(e => {
                if (e.id === provider.id) {
                    e.active = false;
                }
                return e;
            }),
        };
    }),

    /* Delete Provider */
    on(deleteProvider, (state, { provider }) => ({
        ...state,
        providers: state.providers.filter(e => e.id !== provider.id),
    })),

    on(deleteProviderFailure, (state, { provider }) => ({
        ...state,
        providers: [...state.providers, provider].sort((a, b) => {
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
                return 1;
            }
            return -1;
        }),
    }))
);

export function providersReducer(
    state: ProvidersState | undefined,
    action: Action
) {
    return reducer(state, action);
}
