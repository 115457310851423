/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, Injector } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

// eslint-disable-next-line @nx/enforce-module-boundaries
import {
    AuthenticationService,
    AuthenticationFacade,
} from '@qwyk/hub/authentication';
import { environment } from '@qwyk/hub/environment';

import { versions } from '../../environments/versions';
@Injectable({
    providedIn: 'root',
})
export class StartupService {
    constructor(
        private auth: AuthenticationService,
        private injector: Injector,
        private authentication: AuthenticationFacade
    ) {}

    load(): Promise<any> {
        // eslint-disable-next-line no-restricted-syntax
        console.debug('[Hub] App startup');
        console.log(
            `%cPowered by QWYK`,
            'color: #007bff; font-family:sans-serif; font-size:2em;'
        );
        console.log(
            `%chttps://www.qwyk.io`,
            'color: #007bff; font-family:sans-serif; font-size:1.2em;'
        );
        console.log(
            `%cFound a bug: support@qwyk.io\nVersion: ${versions.version}\nBranch: ${versions.branch}\nRevision: ${versions.revision}`,
            'color: #007bff; padding-top: 0.5em; padding-bottom: 0.5em; display: block; font-family:sans-serif; font-size:1em;'
        );

        Object.defineProperty(console, 'dumpEnv', {
            value() {
                return environment;
            },
        });
        // eslint-disable-next-line no-restricted-syntax
        console.debug(
            'Defined console properties: \n  dumpEnv(): dumps app environment to console'
        );

        this.authentication.setup();
        const waitForSubject = new BehaviorSubject(false);

        this.authentication.authenticated$.subscribe(loaded => {
            if (loaded) {
                // eslint-disable-next-line no-restricted-syntax
                console.debug('[Hub] App startup completed');
                waitForSubject.complete();
            }
        });

        return waitForSubject.toPromise();
    }
}
