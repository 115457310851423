import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetch } from '@nx/angular';
import * as UsersActions from './users.actions';
import { UsersService } from '../services/users.service';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class UsersEffects {
    loadUsers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UsersActions.loadUsers),
            fetch({
                run: action => {
                    return this.service.getHubUsers(action.query).pipe(
                        map(({ data, meta }) =>
                            UsersActions.loadUsersSuccess({
                                users: data,
                                pagination: meta,
                            })
                        )
                    );
                },

                onError: (action, error) => {
                    console.error(error);
                    return UsersActions.loadUsersFailure({ error });
                },
            })
        )
    );

    loadUsersFailure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(UsersActions.loadUsersFailure),
                tap(() => {
                    this.toastr.error(
                        'Please reload the page or contact support if the problem persists',
                        'Oops, something went wrong'
                    );
                })
            ),
        {
            dispatch: false,
        }
    );

    createUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UsersActions.createUser),
            switchMap(action =>
                this.service.createHubUser(action.payload).pipe(
                    map(user => UsersActions.createUserSuccess({ user })),
                    catchError(error =>
                        of(UsersActions.createUserFailure({ error }))
                    )
                )
            )
        )
    );

    updateUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UsersActions.updateUser),
            switchMap(action =>
                this.service.updateHubUser(action.id, action.payload).pipe(
                    map(user => UsersActions.updateUserSuccess({ user })),
                    catchError(error =>
                        of(UsersActions.updateUserFailure({ error }))
                    )
                )
            )
        )
    );

    deactivateUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UsersActions.deactivateUser),
            switchMap(action =>
                this.service.deactivateHubUser(action.user.id).pipe(
                    map(() =>
                        UsersActions.deactivateUserSuccess({
                            user: action.user,
                        })
                    ),
                    catchError(error =>
                        of(UsersActions.deactivateUserFailure({ error }))
                    )
                )
            )
        )
    );

    activateUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UsersActions.activateUser),
            switchMap(action =>
                this.service.activateHubUser(action.user.id).pipe(
                    map(() =>
                        UsersActions.activateUserSuccess({ user: action.user })
                    ),
                    catchError(error =>
                        of(UsersActions.activateUserFailure({ error }))
                    )
                )
            )
        )
    );

    cruUserSuccess = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    UsersActions.updateUserSuccess,
                    UsersActions.createUserSuccess
                ),
                tap(action => {
                    const type =
                        action.type === UsersActions.createUserSuccess.type
                            ? 'created'
                            : 'updated';

                    this.toastr.success(
                        `User '${action.user.email}' was ${type}.`
                    );
                    this.router.navigate(['/settings/users']);
                })
            ),
        {
            dispatch: false,
        }
    );

    statusUserSuccess = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    UsersActions.activateUserSuccess,
                    UsersActions.deactivateUserSuccess
                ),
                tap(action => {
                    const type =
                        action.type === UsersActions.activateUserSuccess.type
                            ? 'activated'
                            : 'deactivated';
                    this.toastr.success(`User was ${type}.`);
                    this.router.navigate(['/settings/users']);
                })
            ),
        {
            dispatch: false,
        }
    );

    constructor(
        private actions$: Actions,
        private service: UsersService,
        private router: Router,
        private toastr: ToastrService
    ) {}
}
