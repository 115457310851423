<div class="modal-body">
    <div class="lead">Download carriages</div>
    <p class="text-muted" *ngIf="!startedDownload">
        You can download your carriages to Excel and work on them in there. Then use the Upload from
        Excel option to update the system with the changes you have made.
    </p>

    <p *ngIf="startedDownload" class="mt-2">
        Your download has started, this may take some time, we will email you the
        link to the file when it has been completed.
    </p>

    <p *ngIf="error" class="mt-2 text-danger">
        We're sorry, something went wrong. Please try again or contact support if the problem persists.
    </p>
    <ng-container *ngIf="!startedDownload">
        <button class="btn btn-primary mr-1" (click)="startDownload()" [disabled]="isBusy">
            <fa-icon [icon]="['far', 'spinner']" pulse="true" *ngIf="isBusy"></fa-icon>
            Start{{ isBusy ? 'ing' : null}} download
        </button>
        <button class="btn btn-light" (click)="modal.dismiss()" [disabled]="isBusy">Cancel</button>
    </ng-container>
    <ng-container *ngIf="startedDownload">
        <button class="btn btn-primary mr-1" (click)="modal.close()" [routerLink]="['/schedules', 'tasks']"
            [queryParams]="{tab: 'download'}">View progress</button>
        <button class="btn btn-light" (click)="modal.close()">Close</button>
    </ng-container>
</div>