import {Component, OnInit} from '@angular/core';
import {SitesFacade} from '../../+state/sites.facade';
import {environment} from '@qwyk/hub/environment';
import {map} from 'rxjs/operators';

@Component({
    selector: 'qwyk-sites-list-widget',
    templateUrl: './sites-list-widget.component.html',
    styleUrls: ['./sites-list-widget.component.scss'],
})
export class SitesListWidgetComponent implements OnInit {
    portalSitesMask = environment.portalSitesMask;
    portalSitesMaskStripped = environment.portalSitesMask.replace(
        /http[a-zA-z]*:\/\//i,
        ''
    );
    sitesLoading$ = this.sites.loading$;
    sites$ = this.sites.allSites$.pipe(
        map(sites => {
            if (!sites) {
                return null;
            }
            const limit = 3;
            const refSites = sites
                .filter(a => a.active)
                .sort((a, b) => (a.name > b.name ? 1 : -1));
            return {
                sites: refSites.slice(0, limit),
                more: Math.max(refSites.length - limit, 0),
            };
        })
    );

    constructor(private sites: SitesFacade) {}

    ngOnInit(): void {
        this.sites.loadSites({ pageSize: 1000 });
    }
}
