import { ActivatedRoute } from '@angular/router';
import { TasksService } from './../../../services/tasks.service';
import { Component, OnInit } from '@angular/core';
import { fadeInOutAnimation } from './../../../../../helpers/reusableAnimations';
import { Observable, timer, of } from 'rxjs';
import { switchMap, catchError, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-tasks-index',
    templateUrl: './tasks-index.component.html',
    styleUrls: ['./tasks-index.component.scss'],
    animations: [fadeInOutAnimation],
})
export class TasksIndexComponent implements OnInit {
    queryParams$ = this.route.queryParams;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    importJobs$: Observable<any>;
    importJobsError: boolean;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    exportJobs$: Observable<any>;
    exportJobsError: boolean;

    constructor(
        private service: TasksService,
        private toastr: ToastrService,
        public route: ActivatedRoute
    ) {}

    ngOnInit() {
        // Fetches Export jobs from the server every 30 seconds with an initial delay of 1ms.
        this.exportJobs$ = timer(1, 30e3).pipe(
            tap(() => (this.exportJobsError = false)),
            switchMap(() => this.service.getExportJobs()),
            catchError(() => {
                this.exportJobsError = true;
                return of([]);
            })
        );

        this.importJobs$ = timer(1, 30e3).pipe(
            tap(() => (this.importJobsError = false)),
            switchMap(() => this.service.getImportJobs()),
            catchError(() => {
                this.importJobsError = true;
                return of([]);
            })
        );
    }

    downloadExportJob(exportJob) {
        exportJob.downloading = true;
        const downloadSubscription = this.service
            .getExportJobDownloadLink(exportJob.id)
            .subscribe(
                result => {
                    window.open(result.url, 'blank');
                    exportJob.downloading = false;
                    downloadSubscription.unsubscribe();
                },
                () => {
                    exportJob.downloading = false;
                    downloadSubscription.unsubscribe();
                    this.toastr.error(
                        'Please try again or contact support if the problem persists',
                        // tslint:disable-next-line: quotemark
                        "We're sorry, something went wrong downloading your routing guide."
                    );
                }
            );
    }
}
