<div
    class="min-vh-100 container-fluid bg-light overflow-auto px-3 px-lg-5 py-2 py-lg-4 d-flex flex-column justify-content-between">
    <div *ngIf="user$ | async; let user;">
        <ng-container *ngIf="user.is_trial">
            <div *ngIf="(user.trial_ends_on | amDifference:today:'days') + 1; let daysLeft"
                    [ngClass]="{'alert-success small p-2': daysLeft - 1 > 7, 'alert-warning': daysLeft - 1 <= 7, 'alert-danger': daysLeft - 1 < 4}"
                    class="alert d-flex align-items-center">
                <div *ngIf="daysLeft - 1 <= 7" class="mr-3">
                    <fa-icon [icon]="['far', 'clock']" size="lg"></fa-icon>
                </div>
                <div>
                    <b>{{ daysLeft - 1 }} {{ 'hub.home.days-left-in-free-trial' | translate }}.</b>
                    <div *ngIf="daysLeft - 1 <= 7">
                        {{ 'hub.home.your-trial-ends' | translate : { value: user.trial_ends_on | amDateFormat: 'L LT' } }}
                        {{ 'hub.home.please-contact' | translate }}
                        <a href="mailto:sales@qwyk.io">sales@qwyk.io</a>
                        {{ 'hub.home.to-activate-your-subscription' | translate }}
                    </div>
                </div>
            </div>
        </ng-container>

        <ngb-alert
            *ngIf="magayaConnectionStatus$ | async; let connectionStatus"
            [type]="connectionStatus.severity === 'high' ? 'danger' : connectionStatus.severity === 'medium' ? 'warning' : 'info'"
            class="d-flex justify-content-between align-items-center" [dismissible]="false">
            <div>
                <div class="strong">{{ 'hub.home.magaya-connection-issue' | translate }}</div>
                <div>
                    {{ connectionStatus.message }}
                </div>
            </div>
            <div>
                <a
                    href="https://knowledge.magaya.com/#/article/Update_Extensions/EN" target="_blank"
                    class="btn"
                    [ngClass]="connectionStatus.severity === 'high' ? 'btn-danger' : connectionStatus.severity === 'medium' ? 'btn-warning' : 'btn-primary'"
                >{{ 'common.read-more' | translate }}</a>
            </div>
        </ngb-alert>

        <div class="mb-4 d-flex justify-content-between align-items-center">
            <div class="text-truncate">
                <h4 id="hubHomepageWelcomeTitle">
                    {{ greeting | translate }}, {{ user.name }}
                    <ul class="navbar-nav d-inline-flex"
                        *ngIf="user.organization_features?.language_picker?.value"
                    >
                        <qwyk-language-picker
                            [buttonTextClass]="'text-primary fa-xs'"
                            [showFlags]="false"
                            buttonClass="nav-link"
                            template="list-item">
                        </qwyk-language-picker>
                    </ul>
                </h4>
                <h5 class="font-weight-normal" id="hubHomepageWelcomeSubtitle">
                    <fa-icon [icon]="['far', 'rocket-launch']" class="mr-2 text-secondary"></fa-icon>
                    <span class="text-muted" id="hubHomepageWelcomeSubtitleText">
                        {{ 'hub.home.welcome-text' | translate : { value: 'Digital Freight Portal' } }}
                    </span>
                </h5>
            </div>

            <div class="d-none d-lg-block">
                <div class="small">
                    <fa-icon [icon]="['far', 'question-circle']" class="mr-2"></fa-icon>
                    {{ 'hub.home.need-help' | translate }}
                </div>
                <button (click)="onRequestHelp()" class="btn btn-outline-primary">
                    {{ 'hub.home.open-help-center' | translate }}
                </button>
            </div>
        </div>

        <div *ngIf="false" class="card shadow-sm mb-3 border-primary" style="background-color:
        rgba(9, 122, 255, 0.15);">
            <div class="card-body d-flex align-items-center">
                <div>
                    <fa-icon [icon]="['far', 'star-exclamation']" [size]="expandNews ? '2x' : 'lg'"
                             class="text-secondary"></fa-icon>
                </div>
                <div [ngClass]="expandNews? 'ml-4': 'ml-2'">
                    <div class="font-weight-bold">
                        New: major landing page and quoting update.
                        <a (click)="expandNews = !expandNews" *ngIf="!expandNews" href="javascript:void(0);">Read
                            more</a>
                    </div>
                    <div *ngIf="expandNews">
                        We've redesigned your portal's landing page, preview it at
                        <a class="font-weight-bold" href="https://my.digitalfreight.company" target="_blank"
                           title="Preview">my.digitalfreight.company.</a>
                        <p class="mb-0">Go to Administration / Sites and select 'Opt in' in the New Landing Page section
                            to activate
                            this design for your portal.</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="card shadow-sm mb-4">
            <div class="card-body row">
                <ng-container *ngIf="user.profile_completeness < 1">
                    <ng-container *ngTemplateOutlet="userProfileCompleteness, context: {$implicit: user}">
                    </ng-container>
                </ng-container>

                <div class="col-6 col-lg-3">
                    <div class="text-center p-4">
                        <div class="text-secondary" style="min-height: 80px;">
                            <fa-icon [fixedWidth]="true" [icon]="['fad', 'hand-holding-usd']" size="4x"></fa-icon>
                        </div>
                        <h6 class="mt-1 mb-0">
                            <a [routerLink]="['/quotations']" title="Quotations">{{ 'hub.home.view-quotations' | translate }}</a>
                        </h6>
                    </div>
                </div>

                <div class="col-6 col-lg-3">
                    <div class="text-center p-4">
                        <div class="text-secondary" style="min-height: 80px;">
                            <fa-icon [fixedWidth]="true" [icon]="['fad', 'box-alt']" size="4x"></fa-icon>
                        </div>
                        <h6 class="mt-1 mb-0">
                            <a [routerLink]="['/shipments']" title="Shipments">{{ 'hub.home.view-shipments' | translate }}</a>
                        </h6>
                    </div>
                </div>

                <div class="col-6 col-lg-3">
                    <div class="text-center p-4">
                        <div class="text-secondary" style="min-height: 80px;">
                            <fa-icon [fixedWidth]="true" [icon]="['fad', 'door-open']" size="4x"></fa-icon>
                        </div>
                        <h6 class="mt-1 mb-0">
                            <a [routerLink]="['/administration']" title="Sites">{{ 'hub.home.manage-your-portal' | translate }}</a>
                        </h6>
                    </div>
                </div>

                <ng-container *ngIf="user.profile_completeness === 1">
                    <ng-container *ngTemplateOutlet="userProfileCompleteness, context: {$implicit: user}">
                    </ng-container>
                </ng-container>
            </div>
        </div>

        <div class="row row-cols-1 row-cols-lg-2 row-cols-xxl-3 row-cols-xxxxl-4 align-items-stretch">
            <div class="col mb-4 d-flex flex-column">
                <div class="mb-4 flex-fill">
                    <qwyk-customer-users-list-widget
                        [nothingFoundTemplate]="qsulwNothingFoundTemplate"
                        [query]="{
                            pageSize: 5,
                            tag: 'unapproved',
                            sort: 'customer_users.created_at',
                            sort_dir: 'desc'
                        }"
                        [showApprovalStatus]="true" class="card shadow-sm h-100" title="{{ 'hub.home.user-approvals' | translate }}">
                    </qwyk-customer-users-list-widget>
                    <ng-template #qsulwNothingFoundTemplate>
                        <div class="d-flex align-items-center">
                            <fa-icon [icon]="['far', 'sun']" class="mr-4" size="2x" style="color: goldenrod;"></fa-icon>
                            <div>
                                <b>{{ 'hub.home.all-caught-up' | translate }}</b>, {{ 'hub.home.no-users-pending-for-approval' | translate }}
                            </div>
                        </div>
                    </ng-template>
                </div>
                <qwyk-sites-list-widget class="card shadow-sm max-h-50"></qwyk-sites-list-widget>
            </div>
            <div class="col mb-4">
                <qwyk-quotation-list-widget class="card shadow-sm h-100"></qwyk-quotation-list-widget>
            </div>

            <div class="col mb-4">
                <qwyk-notifications-list-widget class="card shadow-sm h-100"></qwyk-notifications-list-widget>
            </div>
        </div>
    </div>

    <div class="flex-grow-1 flex-fill d-flex align-items-end">
        <div class="small text-muted">
            {{ 'common.client-version' | translate }} {{ versionInfo.version }}
            {{ 'common.rev' | translate }}. {{versionInfo.revision}}@{{ versionInfo.branch }}
            {{ versionInfo.date | amDateFormat: 'L' }}
        </div>
    </div>
</div>

<ng-template #userProfileCompleteness let-user>
    <div class="col-6 col-lg-3">
        <div class="text-center p-4">
            <div style="min-height: 80px;">
                <circle-progress [animationDuration]="300" [animation]="true" [innerStrokeColor]="'#f8f9fa'"
                                 [innerStrokeWidth]="5"
                                 [outerStrokeColor]="user.profile_completeness < 1 ? '#ff097c' : '#28a745'"
                                 [outerStrokeWidth]="5" [percent]="user.profile_completeness * 100" [radius]="30"
                                 [responsive]="false"
                                 [showSubtitle]="false" [showTitle]="true" [space]="-5" titleColor="#6c757d">
                </circle-progress>
            </div>
            <h6 class="mt-1 mb-0">
                <a [routerLink]="['/user/profile']" title="Profile">
                    {{ 'hub.home.manage-your-profile' | translate : { value: (user.profile_completeness < 1 ? 'common.complete'
                    : 'common.view') | translate } }}
                </a>
            </h6>
        </div>
    </div>
</ng-template>
