import {
    FormGroup,
    UntypedFormGroup,
    UntypedFormControl,
} from '@angular/forms';
import { Component, OnInit, OnDestroy } from '@angular/core';

import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';

import { Language } from '@qwyk/portals/translation';
import { environment } from '@qwyk/portals/environment';

import {
    loadUserData,
    updateUserProfile,
} from '../../store/actions/user.actions';
import { UserState } from '../../store/user.state';
import { AppState } from './../../../../store/app.state';
import { selectUserState } from './../../store/selectors/user.selectors';
import { fadeInOutAnimation } from '../../../../helpers/reusableAnimations';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
    animations: [fadeInOutAnimation],
})
export class ProfileComponent implements OnInit, OnDestroy {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formsUpdating = false;
    validationErrors = null;

    aboutForm = new UntypedFormGroup({
        organization_name: new UntypedFormControl(null, []),
        job_title: new UntypedFormControl(null, []),
        location: new UntypedFormControl(null, []),
    });
    settingsForm = new UntypedFormGroup({
        language: new UntypedFormControl(null, []),
        timezone: new UntypedFormControl(null, []),
        measurements: new UntypedFormControl(null, []),
    });

    userStateSubscription: Subscription;
    userState$: Observable<UserState>;

    private _availableLanguages: Language[] = [];

    constructor(
        private toastr: ToastrService,
        private store: Store<AppState>
    ) {}

    public get availableLanguages(): Language[] {
        return this._availableLanguages;
    }

    ngOnInit() {
        this.store.dispatch(loadUserData());
        this.userState$ = this.store.select(selectUserState);

        this._availableLanguages = environment.translation.languages.filter(
            e => e.enabled
        );
        this.userStateSubscription = this.userState$.subscribe(state => {
            this.validationErrors = null;
            if (state.user) {
                // User is set in state, fill the forms' values
                this.setFormValuesFromState(state);
            }

            if (state.updating) {
                // Forms are updating, disable the forms to prevent changes
                this.aboutForm.disable();
                this.settingsForm.disable();
            } else if (this.formsUpdating) {
                // Form has completed updating

                // Check if we encountered an error
                if (state.updateError) {
                    this.handleErrors(state);
                } else {
                    // No error, notify the user of success.
                    this.toastr.success('Profile settings saved!');
                }

                // Re-enable the forms since we allow user input again
                this.aboutForm.enable();
                this.settingsForm.enable();
                this.aboutForm.get('organization_name')?.disable(); // Organization name needs to be re-disabled.
            }

            this.formsUpdating = state.updating;
        });
    }

    ngOnDestroy(): void {
        if (this.userStateSubscription) {
            this.userStateSubscription.unsubscribe();
        }
    }

    /**
     * Submits about form updates to the server
     */
    submitAboutForm(): void {
        if (!this.formValidates(this.aboutForm)) {
            return;
        }

        const userData = this.aboutForm.value;
        delete userData.organization_name;

        this.store.dispatch(updateUserProfile({ userData }));
    }

    /**
     * Submits settings form to the server
     */
    submitSettingsForm(): void {
        if (!this.formValidates(this.settingsForm)) {
            return;
        }

        const userData = this.settingsForm.value;

        this.store.dispatch(updateUserProfile({ userData }));
    }

    /**
     * Returns whether the form validates and sets all controls to touched so errors can be shown
     * @param form the form to validate
     * @returns true if validates
     */
    private formValidates(form: FormGroup): boolean {
        Object.keys(form.controls).forEach(field => {
            const control = form.get(field);
            control?.markAsTouched({ onlySelf: true });
        });

        return form.valid;
    }

    /**
     * Handles errors that might have occured when updating the profile
     * @param state the current user state
     */
    private handleErrors(state: UserState): void {
        if (state.updateError.status && state.updateError.status === 422) {
            this.validationErrors = state.updateError.error.errors;
        }
        this.toastr.error(
            'There was an error saving, please fix any errors displayed or try again. Contact support if the problem persists.',
            'Error saving profile'
        );
    }

    /**
     * Sets the forms according to the latest user state
     * @param state the current user state
     */
    private setFormValuesFromState(state: UserState): void {
        if (state.user) {
            this.aboutForm
                .get('organization_name')
                ?.setValue(state.user.organization_name, { disabled: true });
            this.aboutForm.get('job_title')?.setValue(state.user.job_title);
            this.aboutForm.get('location')?.setValue(state.user.location);
            this.settingsForm.get('timezone')?.setValue(state.user.timezone);
            this.settingsForm
                .get('measurements')
                ?.setValue(state.user.measurements);
            this.settingsForm.get('language')?.setValue(state.user.language);
        }
    }
}
