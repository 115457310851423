<p-table [paginator]="true" [rowHover]="true" [rows]="10" [sortOrder]="1" [value]="offers" dataKey="id"
         sortField="total_amount">
    <ng-template pTemplate="header">
        <tr>
            <th style="width: 3em"></th>
            <th colspan="5">
                Offer
            </th>
            <th *ngIf="displayBuyingInfo" class="text-right">
                Buying Amount
            </th>
            <th class="text-right" pSortableColumn="total_amount">
                Total Amount
                <p-sortIcon field="total_amount"></p-sortIcon>
            </th>
            <th style="width: 95px;">
            </th>
        </tr>
    </ng-template>

    <ng-template let-expanded="expanded" let-offer pTemplate="body">
        <tr [ngClass]="{'font-weight-bold': expanded, 'row-highlight': offer.id === highlightOfferId}">
            <td>
                <a [pRowToggler]="offer" href="#">
                    <i [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></i>
                </a>
            </td>
            <td colspan="5">
                <div class="d-flex align-items-start start">
                    <div>
                        <div class="font-weight-bold">{{ offer.carrier_name }}
                            <span *ngIf="offer.rate_name" class="font-weight-normal"> - {{
                                offer.rate_name }}</span>
                            <span *ngIf="offer.rate_source_name"
                                  class="badge badge-light ml-2">
                                {{ offer.rate_source_name | slugToTitle }}
                            </span>
                        </div>
                        <div>{{ offer.pol.display_name }} - {{ offer.pod.display_name }}</div>
                        <div>
                            <span>{{ offer.valid_from ? (offer.valid_from | amDateFormat: 'D MMMM') : 'No date' }}</span>
                            -
                            <span>{{ offer.valid_until ? (offer.valid_until | amDateFormat: 'D MMMM') : 'No date' }}</span>
                        </div>
                    </div>
                </div>
            </td>
            <td *ngIf="displayBuyingInfo" class="text-right">
                <div>{{ offer.total_buying | currency:offer.currency }}</div>
                <div>
                    Margin: {{ offer.total_margin | currency: offer.currency }}
                    <span *ngIf="offer.total_margin !== 0 && null !== offer.total_amount"
                          class="text-muted">({{ offer.total_margin / offer.total_amount * 100 | number: '1.0-0' }}
                        %)</span>
                </div>
            </td>
            <td class="text-right">
                <div class="font-weight-bold">{{ offer.total_amount | currency:offer.currency }}</div>
                <div>{{ offer.transit_time }} days</div>
            </td>
            <td class="text-center">
                <button [disabled]="!editable" [queryParams]="{'copyOffer': offer.id}" [routerLink]="['offer']"
                        class="btn btn-light btn-sm border-0" ngbTooltip="Copy offer">
                    <fa-icon [icon]="['far', 'clone']"></fa-icon>
                </button>
                <button (click)="onDownloadOfferPdf(quotation, offer)" *ngIf="!quotation?.is_draft"
                        [disabled]="!editable" class="btn btn-light btn-sm ml-1 border-0"
                        ngbTooltip="Download PDF with this specific offer">
                    <fa-icon [icon]="['far', 'file-pdf']"></fa-icon>
                </button>
                <button (click)="onDeleteOffer(quotation, offer)" *ngIf="quotation?.is_draft"
                        [disabled]="!editable" class="btn btn-outline-danger btn-sm ml-1 border-0"
                        ngbTooltip="Delete offer">
                    <fa-icon [icon]="['far', 'trash']"></fa-icon>
                </button>
            </td>
        </tr>
    </ng-template>

    <ng-template let-offer pTemplate="rowexpansion">
        <tr class="small text-muted">
            <td></td>
            <td [attr.colspan]="displayBuyingInfo ? 1 : 2">Description</td>
            <td class="text-right">Base rate</td>
            <td style="width: 120px;">Calculation</td>
            <td class="text-right">Total Amount</td>
            <td *ngIf="displayBuyingInfo" class="text-right">Total Buying</td>
            <td *ngIf="displayBuyingInfo" class="text-right">Margin</td>
            <td class="text-right">Total Amount Conv</td>
            <td></td>
        </tr>
        <ng-container *ngFor="let group of offer.grouped_breakdown;">
            <tr>
                <td [attr.rowspan]="1 + group.lines.length"></td>
                <td [attr.colspan]="displayBuyingInfo ? 8 : 7"
                    class="font-weight-bold">
                    {{ group.group | titlecase }}
                </td>
            </tr>
            <tr *ngFor="let charge of group.lines; let chargeIndex = index" class="small">
                <td [attr.colspan]="displayBuyingInfo ? 1 : 2"
                    class="pl-3 font-weight-bold">
                    <div>{{ charge.charge_description }}</div>
                    <div class="font-weight-normal text-muted">{{ charge.remarks }}</div>
                </td>
                <td class="text-right">{{ charge.base_rate | currency: charge.base_currency }}</td>
                <td>{{ charge.base_calculation.name }}</td>
                <td class="text-right">{{ charge.total_amount | currency: charge.base_currency }}</td>
                <td *ngIf="displayBuyingInfo" class="text-right">
                    {{ charge.total_buy_amount_quotation | currency: offer.currency }}
                </td>
                <td *ngIf="displayBuyingInfo"
                    class="text-right">
                    <span *ngIf="charge.margin_amount">
                        {{ charge.margin_amount | currency: offer.currency }}
                    </span>
                </td>
                <td class="text-right font-weight-bold">
                    {{ charge.total_amount_quotation | currency: offer.currency }}
                </td>
                <td></td>
            </tr>
            <tr>
                <td [attr.colspan]="displayBuyingInfo ? 9 : 8"></td>
            </tr>
        </ng-container>

        <ng-container *ngIf="offer.remarks">
            <tr>
                <td rowspan="2"></td>
                <td [attr.colspan]="displayBuyingInfo ? 8 : 7" class="font-weight-bold">Remarks</td>
            </tr>
            <tr>
                <td [attr.colspan]="displayBuyingInfo ? 7 : 6" class="small">
                    <span [innerHTML]="offer.remarks | lineBreaks"></span>
                </td>
                <td></td>
            </tr>
            <tr>
                <td [attr.colspan]="displayBuyingInfo ? 9 : 8"></td>
            </tr>
        </ng-container>
    </ng-template>

    <ng-template pTemplate="emptymessage">
        <tr>
            <td [attr.colspan]="displayBuyingInfo ? 9 : 8" class="text-center">{{ 'common.no_items_found_short' | translate : { value: 'hub.quotations.details.offers' | translate } }} </td>
        </tr>
    </ng-template>
</p-table>
