import { Component, Input } from '@angular/core';

@Component({
    selector: 'qwyk-quotation-status-badge',
    templateUrl: './quotation-status-badge.component.html',
    styleUrls: ['./quotation-status-badge.component.scss'],
})
export class QuotationStatusBadgeComponent {
    @Input() status: string;
    @Input() filled = false;

    constructor() {}
}
