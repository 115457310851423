import { UploadCarriagesComponent } from './../upload-carriages/upload-carriages.component';
import { DownloadCarriagesComponent } from './../download-carriages/download-carriages.component';
import { AlgoliaLocationsService } from './../../../../shared/services/algolia-locations.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import * as carriagesActions from '../../../store/actions/carriages.actions';
import * as carriagesSelectors from '../../../store/selectors/carriages.selectors';
import { ActivatedRoute, Router } from '@angular/router';
import { AppState } from './../../../../../store/app.state';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import {
    fadeInOutAnimation,
    fadeInAnimation,
} from './../../../../../helpers/reusableAnimations';
import {
    CarriagesResponse,
    Carriage,
} from '../../../store/models/carriages.models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-carriages',
    templateUrl: './carriages.component.html',
    styleUrls: ['./carriages.component.scss'],
    animations: [fadeInOutAnimation, fadeInAnimation],
})
export class CarriagesComponent implements OnInit {
    //#region Observables
    carriages$: Observable<CarriagesResponse | null> = this.store.select(
        carriagesSelectors.selectCarriages
    );
    loading$: Observable<boolean> = this.store.select(
        carriagesSelectors.isLoadingCarriages
    );
    error$: Observable<boolean> = this.store.select(
        carriagesSelectors.loadingCarriagesError
    );
    //#endregion

    //#region Public variables
    public queryForm: FormGroup = this.initializeQueryForm();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public searchLocationResults: any[];
    //#endregion

    //#region Private variables
    // tslint:disable-next-line: variable-name
    private page_ = 1;
    //#endregion

    constructor(
        private store: Store<AppState>,
        private router: Router,
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private algoliaLocations: AlgoliaLocationsService,
        private modalService: NgbModal
    ) {}

    public ngOnInit() {
        this.store.dispatch(
            carriagesActions.loadCarriages({ page: this.page_ })
        );
    }

    public openCarriage(carriage: Carriage) {
        this.store.dispatch(carriagesActions.setSelectedCarriage({ carriage }));
        this.router.navigate(['./', carriage.id], { relativeTo: this.route });
    }

    public queryFormSubmit() {
        if (this.queryForm.invalid) {
            return;
        }

        // Make the query
        const query = this.makeQuery();

        // Set the page to 1.
        this.page_ = 1;

        // Dispatch the load request.
        this.store.dispatch(
            carriagesActions.loadCarriages({ page: this.page_, query })
        );
    }

    public queryFormReset() {
        this.page_ = 1;

        this.store.dispatch(
            carriagesActions.loadCarriages({ page: this.page_ })
        );
    }

    public set page(page: number) {
        if (this.page_ !== page && !isNaN(page)) {
            this.page_ = page;
            const query = this.makeQuery();
            this.store.dispatch(
                carriagesActions.loadCarriages({ page, query })
            );
        }
    }

    public get page(): number {
        return this.page_;
    }

    /**
     * Handles searching for locations
     * @param event the event emitted by the typeahead
     */
    public searchLocation(event): void {
        const searchSubscription = this.algoliaLocations
            .getLocationSuggestions(event.query)
            .subscribe(results => {
                this.searchLocationResults = results;
                searchSubscription.unsubscribe();
            });
    }

    /**
     * Handles a location getting selected in one of the autocomplete controls
     * @param event The select event emitted by the control
     * @param formControlName The formcontrol this was fired on.
     */
    public onLocationSelect(event, formControlName): void {
        this.queryForm.get(formControlName)?.setValue({
            locode: event.locode,
            display_name: event.display_name,
        });
    }

    /**
     * Handles a clear request on one of the autocomplete controls
     * @param event The clear event
     * @param formControlName The control on which the event fired.
     */
    public onLocationClear(event, formControlName): void {
        this.queryForm.get(formControlName)?.patchValue(null);
    }

    /**
     * Opens download carriages modal
     */
    public openDownloadCarriagesModal() {
        this.modalService.open(DownloadCarriagesComponent, {
            centered: true,
        });
    }

    /**
     * Opens upload carriages modal
     */
    public openUploadCarriagesModal() {
        this.modalService.open(UploadCarriagesComponent, {
            centered: true,
        });
    }

    private makeQuery() {
        const query = {};
        if (this.queryForm.get('schedule_type')?.value) {
            query['schedule_type'] = this.queryForm.get('schedule_type')?.value;
        }

        if (this.queryForm.get('origin')?.value) {
            query['origin_locode'] =
                this.queryForm.get('origin')?.value['locode'];
        }

        if (this.queryForm.get('destination')?.value) {
            query['destination_locode'] =
                this.queryForm.get('destination')?.value['locode'];
        }

        return Object.keys(query).length > 0 ? query : null;
    }

    private initializeQueryForm(): FormGroup {
        return this.fb.group({
            schedule_type: [null],
            origin: [null],
            destination: [null],
        });
    }
}
