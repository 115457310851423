<div class='card-header bg-white p-4 border-0'>
    <a [routerLink]="['/', 'quotations']" class='h5 card-title m-0 text-dark'>
        {{ 'hub.home.quotation-requests' | translate }}
    </a>
</div>
<ul class='list-group list-group-flush border-0'>
    <ng-container *ngIf='(quotationsLoading$ | async) !== true'>
        <ng-container *ngIf='quotations$ | async; let quotations'>
            <li *ngFor='let quotation of quotations'
                class='list-group-item list-group-item-action d-flex align-items-center border-0 p-4'
                style='cursor: pointer;' [routerLink]="['/quotations', quotation.id]">
                <div class='mr-4'>
                    <img
                        [src]="'https://www.gravatar.com/avatar/' + ((quotation.owner?.email || '') | md5) + '?d=https%3A%2F%2Fui-avatars.com%2Fapi%2F/' + ((quotation.owner?.name || '?') | urlEncode) + '/64/097aff/ffffff/2//false/true/true'"
                        alt="{{ 'common.avatar' | translate }}" style='width: 24px; height: 24px;' class='rounded-circle border' />
                </div>
                <div class='flex-grow-1'>
                    <h6 class='h6 m-0'>
                        {{ quotation.request.transport_mode | titlecase }}
                        <ng-container
                            *ngIf="quotation.request.transport_mode !== 'AIR'">
                            {{ quotation.request.load_type | uppercase}}
                        </ng-container>
                    </h6>
                    <div>
                        {{ quotation.request.origin.name }} - {{ quotation.request.destination.name }}
                    </div>
                    <!--                    <div>{{ quotation.last_rate_request?.creator?.name }}: {{ quotation.last_rate_request?.reason }}-->
                    <!--                    </div>--> <!-- FIXME: -->
                </div>
            </li>
            <li class='list-group-item border-0 text-muted p-4'
                *ngIf='quotations.length === 0 && (quotationsLoading$ | async) !== true'>
                <div class='d-flex align-items-center'>
                    <fa-icon [icon]="['far', 'sun']" size='2x' style='color: goldenrod;' class='mr-4'></fa-icon>
                    <div>
                        <b>{{ 'hub.home.all-caught-up' | translate }}</b>, {{ 'hub.home.no-pending-rate-requests' | translate }}
                    </div>
                </div>
            </li>
            <ng-container *ngIf='pagination$ | async; let paging'>
                <li class='list-group-item border-0 text-right' *ngIf='paging.total > paging.to'>
                    <a [routerLink]="['/quotations']" title='Quotations'>
                        {{ 'common.view' | translate }} {{ paging.total - paging.to }} {{ 'common.more' | translate }}
                        <fa-icon [icon]="['far', 'arrow-right']" class='ml-1'></fa-icon>
                    </a>
                </li>
            </ng-container>
        </ng-container>
    </ng-container>
    <li class='list-group-item border-0 p-4' *ngIf='(quotationsLoading$ | async) === true'>
        <qwyk-loading-indicator [horizontal]='true' size='sm'></qwyk-loading-indicator>
    </li>
</ul>
