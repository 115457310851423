import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Portals } from '@qwyk/models';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: '[qwyk-quotations-offer-list-item]',
    templateUrl: './quotations-offer-list-item.component.html',
    styleUrls: ['./quotations-offer-list-item.component.scss'],
})
export class QuotationsOfferListItemComponent {
    @Input() quotationOffer: Portals.QuotationOffer;
    @Input() expanded: boolean;

    @Output()
    delete: EventEmitter<Portals.QuotationOffer> = new EventEmitter<Portals.QuotationOffer>();

    @Output()
    generatePdf: EventEmitter<Portals.QuotationOffer> = new EventEmitter<Portals.QuotationOffer>();

    @Output()
    toggleExpand: EventEmitter<Portals.QuotationOffer> = new EventEmitter<Portals.QuotationOffer>();

    public get allowChange(): boolean {
        return (this.quotationOffer.shipment_ids?.length || 0) === 0;
    }

    public onDelete(): void {
        this.delete.emit(this.quotationOffer);
    }

    public onGeneratePdf(): void {
        this.generatePdf.emit(this.quotationOffer);
    }

    public onToggleExpand(): void {
        this.toggleExpand.emit(this.quotationOffer);
    }
}
