import { Injectable } from '@angular/core';

import { select, Store, Action } from '@ngrx/store';

import { Hub } from '@qwyk/models';

import * as fromSites from './sites.reducer';
import * as SitesActions from './sites.actions';
import * as SitesSelectors from './sites.selectors';

@Injectable()
export class SitesFacade {
    loaded$ = this.store.pipe(select(SitesSelectors.getSitesLoaded));
    loading$ = this.store.pipe(select(SitesSelectors.getSitesLoading));
    allSites$ = this.store.pipe(select(SitesSelectors.getAllSites));
    selectedSites$ = this.store.pipe(select(SitesSelectors.getSelected));
    pagination$ = this.store.pipe(select(SitesSelectors.getSitesPagination));
    error$ = this.store.pipe(select(SitesSelectors.getSitesError));

    constructor(private store: Store<fromSites.SitesPartialState>) {}

    public loadSites(query?) {
        this.dispatch(SitesActions.loadSites({ query }));
    }

    public selectSite(id: number) {
        this.dispatch(SitesActions.selectSite({ id }));
    }

    public createSite(site: Partial<Hub.Site>) {
        this.dispatch(SitesActions.createSite({ site }));
    }

    public updateSite(site: Hub.Site) {
        this.dispatch(SitesActions.updateSite({ site }));
    }

    private dispatch(action: Action) {
        this.store.dispatch(action);
    }
}
