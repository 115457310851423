import { Component, OnDestroy } from '@angular/core';
import { map } from 'rxjs/operators';
import { Portals } from '@qwyk/models';
import { Subscription } from 'rxjs';
// noinspection ES6PreferShortImport (Circular dependency)
import { QuotationOffersFacade } from './../../+state/quotation-offers.facade';
import { ActivatedRoute } from '@angular/router';
import { QuotationOfferViewBase } from '../quotation-offer-view-base';
import { QuotationsFacade } from '@qwyk/shared-stores/quotations';

@Component({
    selector: 'qwyk-quotation-offer-copy',
    templateUrl: './quotation-offer-copy.component.html',
    styleUrls: ['./quotation-offer-copy.component.scss'],
})
export class QuotationOfferCopyComponent
    extends QuotationOfferViewBase
    implements OnDestroy
{
    public openRateRequests$ = this.quotations.selectedQuotations$.pipe(
        map(quotation => quotation.rate_requests),
        map(rate_requests =>
            rate_requests ? rate_requests.filter(e => e.open === true) : []
        )
    );
    public selectedQuotationOffer$ =
        this.quotationOffers.selectedQuotationOffers$.pipe(
            map((offer: Portals.QuotationOffer) => {
                offer.id = null;
                offer.valid_until = offer.valid_until
                    ? new Date(offer.valid_until.toString())
                    : null;
                offer.valid_from = offer.valid_from
                    ? new Date(offer.valid_from.toString())
                    : null;
                return offer;
            })
        );
    private actionsSubscription: Subscription;

    constructor(
        public quotations: QuotationsFacade,
        protected quotationOffers: QuotationOffersFacade,
        private route: ActivatedRoute
    ) {
        super(quotationOffers);
        this.actionsSubscription = this.route.params.subscribe(params => {
            this.quotations.selectQuotation(params.id);
            this.quotationOffers.selectQuotationOffer(params.offerId);
        });
    }

    ngOnDestroy(): void {
        this.actionsSubscription.unsubscribe();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public onCreate(payload: any): void {
        this.quotationOffers.createQuotationOffer(
            payload.quotation_id,
            payload
        );
    }
}
