<td>
    <button (click)="onToggleExpand()" class="btn btn-sm" type="button">
        <fa-icon
            [fixedWidth]="true"
            [icon]="['far', 'angle-right']"
            [rotate]="expanded ? 90 : 0"
        ></fa-icon>
    </button>
</td>

<td class="py-3">
    <h6 class="m-0">{{ quotationOffer.carrier_name || 'Unknown carrier' }}</h6>
    <div>{{ quotationOffer.rate_name }}</div>
</td>

<td class="py-3">
    <div>{{ quotationOffer.pol.name }} - {{ quotationOffer.pod.name }}</div>
    <div>
        <ng-container *ngIf="quotationOffer.valid_from; else noDate">
            {{ quotationOffer.valid_from | amUtc | amDateFormat: 'LL' }}
        </ng-container>
        -
        <ng-container *ngIf="quotationOffer.valid_until; else noDate">
            {{ quotationOffer.valid_until | amUtc | amDateFormat: 'LL' }}
        </ng-container>
    </div>
    <ng-template #noDate>...</ng-template>
</td>

<td class="py-3 text-right">
    <div>
        {{ quotationOffer.total_buying | currency: quotationOffer.currency }}
    </div>
</td>

<td class="py-3 text-right">
    <div>
        {{ quotationOffer.total_amount | currency: quotationOffer.currency }}
    </div>
</td>

<td class="py-3 text-right">
    <div>
        {{ quotationOffer.total_margin | currency: quotationOffer.currency }}
    </div>
    <div *ngIf="quotationOffer.total_amount !== 0">
        {{
            quotationOffer.total_margin / quotationOffer.total_amount | percent:"0.2-2"
        }}
    </div>
</td>

<td class="py-3 text-center">
    <div class="d-inline-block" container="body" ngbDropdown>
        <button class="btn" id="dropdownBasic1" ngbDropdownToggle>
            <fa-icon
                [fixedWidth]="true"
                [icon]="['far', 'ellipsis-v']"
            ></fa-icon>
        </button>

        <div aria-labelledby="dropdownBasic1" ngbDropdownMenu>
            <div *ngIf="!allowChange" class="small text-muted" ngbDropdownItem>
                <i>{{ 'hub.quotations.details.some-options-disabled' | translate }}</i>
            </div>
            <button
                [disabled]="!allowChange"
                ngbTooltip="
                    {{ (!allowChange ? 'hub.quotations.details.offer-cannot-be-edited-tooltip' : null) | translate }}"
                [routerLink]="['./offers', quotationOffer.id]"
                ngbDropdownItem
            >
                <fa-icon
                    [fixedWidth]="true"
                    [icon]="['far', 'pencil']"
                    class="mr-2"
                ></fa-icon>
                {{ 'common.edit' | translate }}
            </button>
            <button
                (click)="onDelete()"
                [disabled]="!allowChange"
                ngbTooltip="{{ (!allowChange ? 'hub.quotations.details.offer-cannot-be-deleted-tooltip' : null) | translate }}"
                ngbDropdownItem
            >
                <fa-icon
                    [fixedWidth]="true"
                    [icon]="['far', 'trash']"
                    class="mr-2"
                ></fa-icon>
                {{ 'common.delete' | translate }}
            </button>
            <button
                [routerLink]="['./offers', 'copy', quotationOffer.id]"
                ngbDropdownItem
            >
                <fa-icon
                    [fixedWidth]="true"
                    [icon]="['far', 'copy']"
                    class="mr-2"
                ></fa-icon>
                {{ 'common.copy' | translate }}
            </button>
            <button (click)="onGeneratePdf()" ngbDropdownItem>
                <fa-icon
                    [fixedWidth]="true"
                    [icon]="['far', 'file-pdf']"
                    class="mr-2"
                ></fa-icon>
                {{ 'common.download' | translate }}
            </button>
        </div>
    </div>
</td>
