<div class="col-lg-10 offset-lg-1 col-xl-10 offset-xl-1 p-0" [@fadeInOut]>
    <div class="head mt-3 mb-5 d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center flex-shrink-1">
            <fa-icon [icon]="['fad', 'truck']" size="2x" class="text-primary mr-3" fixedWidth="true"></fa-icon>
            <div>
                <span class="lead">Carriages</span>
                <p class="text-muted mb-0">View and configure your pre- and on-carriages to be appended to your
                    schedules.</p>
            </div>
        </div>
        <div>
            <div class="btn-group" role="group" aria-label="Basic example">
                <button class="btn btn-primary text-nowrap" [routerLink]="['./new']">
                    Add new carriage
                </button>
                <div ngbDropdown class="btn-group" role="group">
                    <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>
                        <fa-icon [icon]="['far', 'tools']" fixedWidth="true">
                        </fa-icon>
                    </button>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                        <ul class="list-group list-group-flush" style="min-width: 200px;">
                            <button class="list-group-item list-group-item-action"
                                (click)="openDownloadCarriagesModal()">
                                <fa-icon [icon]="['far', 'file-download']" size="lg" fixedWidth="true"></fa-icon>
                                Download to Excel
                            </button>
                            <button class="list-group-item list-group-item-action" (click)="openUploadCarriagesModal()">
                                <fa-icon [icon]="['far', 'file-upload']" size="lg" fixedWidth="true"></fa-icon>
                                Upload from Excel
                            </button>
                            <button class="list-group-item list-group-item-action"
                                [routerLink]="['/schedules', 'tasks']" [queryParams]="{tab: 'upload'}">
                                <fa-icon [icon]="['far', 'archive']" size="lg" fixedWidth="true"></fa-icon>
                                View upload logs
                            </button>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ng-container *ngIf="(loading$ | async) && (carriages$ | async) === false">
        <div class="text-center text-muted">
            <fa-icon [icon]="['far', 'spinner']" size="3x" pulse="true"></fa-icon>
        </div>
    </ng-container>
    <ng-container *ngIf="(error$ | async) && (carriages$ | async) === false">
        <div class="text-center text-danger">
            <fa-icon [icon]="['far', 'exclamation-triangle']" size="2x" class="mb-2"></fa-icon>
            <p>There was an error, please reload the page, or contact support if the problem persists</p>
        </div>
    </ng-container>
    <ng-container *ngIf="carriages$ | async; let carriages" [@fadeIn]>
        <div>
            <form [formGroup]="queryForm" class="qw-form" (submit)="queryFormSubmit()" (reset)="queryFormReset()">
                <div class="form-row">
                    <div class="form-group col">
                        <label>Type</label>
                        <select class="form-control form-control-sm mw-100" formControlName="schedule_type">
                            <option [ngValue]="null">All</option>
                            <option [ngValue]="'pre'">Precarriage</option>
                            <option [ngValue]="'on'">Oncarriage</option>
                        </select>
                    </div>
                    <div class="form-group col">
                        <label>Origin</label>
                        <div>
                            <p-autoComplete formControlName="origin" [forceSelection]="true" class="p-fluid"
                                [suggestions]="searchLocationResults" (completeMethod)="searchLocation($event)"
                                field="display_name" inputStyleClass="form-control form-control-sm mw-100"
                                (onSelect)="onLocationSelect($event, 'origin')"
                                (onClear)="onLocationClear($event, 'origin')">
                                <ng-template let-value pTemplate="item">
                                    <div class="autocomplete-list-location p-2" [innerHtml]="value.highlight">
                                    </div>
                                </ng-template>
                            </p-autoComplete>
                        </div>
                    </div>
                    <div class="form-group col">
                        <label>Destination</label>
                        <div>
                            <p-autoComplete formControlName="destination" [forceSelection]="true" class="p-fluid"
                                [suggestions]="searchLocationResults" (completeMethod)="searchLocation($event)"
                                field="display_name" inputStyleClass="form-control form-control-sm mw-100"
                                (onSelect)="onLocationSelect($event, 'destination')"
                                (onClear)="onLocationClear($event, 'destination')">
                                <ng-template let-value pTemplate="item">
                                    <div class="autocomplete-list-location p-2" [innerHtml]="value.highlight">
                                    </div>
                                </ng-template>
                            </p-autoComplete>
                        </div>
                    </div>
                    <div class="form-group col-auto d-flex align-items-end">
                        <button class="btn btn-primary btn-sm mr-2" type="submit"
                            [disabled]="(loading$ | async)">Search</button>
                        <button class="btn btn-light btn-sm" type="reset" [disabled]="(loading$ | async)">Reset</button>
                    </div>
                </div>
            </form>
        </div>
        <ul class="list-group">
            <li class="list-group-item list-group-item-action d-flex" *ngFor="let carriage of carriages.data" [@fadeIn]
                (click)="openCarriage(carriage)">
                <div class="flex-grow-1 flex-shrink-1 flex-basis-1">
                    <fa-icon [icon]="['far', carriage.schedule_type === 'on' ? 'ship': 'industry-alt']"
                        [fixedWidth]="true" class="mr-1"
                        [ngClass]="carriage.schedule_type === 'on' ? 'text-primary': 'text-secondary'">
                    </fa-icon>{{ carriage.origin.display_name }}
                </div>
                <div class="flex-grow-1 flex-shrink-1 flex-basis-1">
                    <fa-icon [icon]="['far', carriage.schedule_type === 'pre' ? 'ship': 'industry-alt']"
                        [fixedWidth]="true" class="mr-1"
                        [ngClass]="carriage.schedule_type === 'pre' ? 'text-primary': 'text-secondary'">
                    </fa-icon>
                    {{ carriage.destination.display_name }}
                </div>
            </li>
            <div class="text-center text-muted p-3" *ngIf="!carriages.data || carriages.data.length === 0">
                Nothing found
            </div>
            <div class="loading-overlay rounded text-secondary d-flex align-items-center justify-content-center"
                [@fadeInOut] *ngIf="loading$ | async">
                <fa-icon [icon]="['far', 'spinner']" pulse="true" size="2x"></fa-icon>
            </div>
        </ul>
        <div class="small text-muted text-center">{{ carriages.meta.from || 0 }} to {{ carriages.meta.to || 0 }} of
            {{ carriages.meta.total }}</div>
        <ngb-pagination [@fadeIn] *ngIf="carriages.meta.total > carriages.meta.per_page" [(page)]="page"
            [collectionSize]="carriages.meta.total" [pageSize]="carriages.meta.per_page" size="sm"
            class="d-flex justify-content-center mt-3">
        </ngb-pagination>
    </ng-container>
</div>
