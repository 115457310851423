import { Component, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { SitesFacade } from '../../+state/sites.facade';
import { ActivatedRoute } from '@angular/router';
import { Hub } from '@qwyk/models';
import { environment } from '@qwyk/hub/environment';
import { CanComponentDeactivate } from '@qwyk/core';
import { SiteDetailComponent } from '../site-detail/site-detail.component';

@Component({
    selector: 'qwyk-site-view',
    templateUrl: './site-view.component.html',
    styleUrls: ['./site-view.component.scss'],
})
export class SiteViewComponent implements OnDestroy, CanComponentDeactivate {
    private actionsSubscription: Subscription;
    public sitesMask = environment.portalSitesMask;
    public selectedSite$ = this.sites.selectedSites$;
    public saving$ = this.sites.loading$;
    public error$ = this.sites.error$;
    @ViewChild('siteDetailComponent', { static: false })
    siteDetailComponent: SiteDetailComponent;

    constructor(private sites: SitesFacade, private route: ActivatedRoute) {
        this.actionsSubscription = this.route.params.subscribe(params => {
            this.sites.selectSite(+params.id);
        });
    }

    ngOnDestroy(): void {
        this.actionsSubscription.unsubscribe();
    }

    canDeactivate() {
        if (
            this.siteDetailComponent &&
            this.siteDetailComponent.canDeactivate
        ) {
            return this.siteDetailComponent.canDeactivate();
        }
        return true;
    }

    public onSave(site: Hub.Site) {
        this.sites.updateSite(site);
    }
}
