import { Injectable } from '@angular/core';
import algoliasearch, { SearchClient, SearchIndex } from 'algoliasearch';
import {} from 'algoliasearch';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@qwyk/hub/environment';

@Injectable({
    providedIn: 'root',
})
export class AlgoliaLocationsService {
    private index: SearchIndex;
    private client: SearchClient;

    constructor() {
        this.client = algoliasearch(
            environment.utils.Algolia.applicationId,
            environment.utils.Algolia.adminApiKey
        );

        this.index = this.client.initIndex('locations');
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getLocationSuggestions(query: string): Observable<any> {
        const observable = from(this.index.search(query));

        return observable.pipe(
            map(response => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                return response.hits.map((e: any) => {
                    e.display_name = `${e.display_name}, ${e.country_name}`;
                    e.highlight = `${e._highlightResult.display_name.value}, ${e._highlightResult.country_name.value}`;
                    return e;
                });
            })
        );
    }
}
