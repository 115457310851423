import { Component, Input, OnInit } from '@angular/core';
import { Portals } from '@qwyk/models';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { QuotationsService } from '../../services/quotations.service';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { QuotationNetworkRateRequestComponent } from '../quotation-network-rate-request/quotation-network-rate-request.component';

@Component({
    selector: 'qwyk-quotation-network-info',
    templateUrl: './quotation-network-info.component.html',
    styleUrls: ['./quotation-network-info.component.scss'],
})
export class QuotationNetworkInfoComponent implements OnInit {
    @Input() quotation: Portals.QuotationEntity;

    public loadNetworkQuotation$: BehaviorSubject<Portals.QuotationEntity> =
        new BehaviorSubject<Portals.QuotationEntity>(null);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public networkQuotation$: Observable<any>;
    public error = false;

    constructor(private service: QuotationsService, private modal: NgbModal) {
        this.networkQuotation$ = this.loadNetworkQuotation$.pipe(
            tap(() => console.log('he!')),
            filter(input => input !== null),
            switchMap(quotation =>
                this.service.getNetworkQuotationInfo(quotation).pipe(
                    take(1),
                    catchError(error => {
                        this.error = true;
                        console.error(error);
                        return of(null);
                    })
                )
            )
        );
    }

    ngOnInit(): void {
        this.loadNetworkQuotation$.next(this.quotation);
    }

    public onMakeRequest() {
        const modalRef = this.modal.open(QuotationNetworkRateRequestComponent, {
            centered: true,
            keyboard: false,
            backdrop: 'static',
        });

        const component: QuotationNetworkRateRequestComponent =
            modalRef.componentInstance;
        const subscription = component.send$
            .pipe(
                tap(() => {
                    component.busy = true;
                    component.error = false;
                }),
                switchMap(request =>
                    this.service
                        .createNetworkQuotationRequest(this.quotation, request)
                        .pipe(
                            take(1),
                            catchError(error => {
                                console.error(error);
                                component.error = true;
                                return of(null);
                            })
                        )
                )
            )
            .subscribe(result => {
                component.busy = false;
                if (result) {
                    this.loadNetworkQuotation$.next(this.quotation);
                    modalRef.close();
                }
            });

        modalRef.result.finally(() => {
            subscription.unsubscribe();
        });
    }
}
