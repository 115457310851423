import {
    AbstractControl,
    AsyncValidator,
    ValidationErrors,
} from '@angular/forms';
import { Observable, of } from 'rxjs';
import {
    catchError,
    debounceTime,
    delay,
    map,
    switchMap,
} from 'rxjs/operators';
import { UsersService } from '../services/users.service';

export class Auth0UserNotExistsValidator implements AsyncValidator {
    constructor(private service: UsersService) {}

    validate(
        control: AbstractControl
    ): Promise<ValidationErrors> | Observable<ValidationErrors> {
        return of(control).pipe(
            debounceTime(200),
            delay(200),
            switchMap(ctrl => {
                return this.service.validateAuth0NotExists(ctrl.value).pipe(
                    map(() => {
                        return null;
                    }),
                    catchError(err => {
                        return of({
                            auth0_not_exists: err.error,
                        });
                    })
                );
            })
        );
    }
}
