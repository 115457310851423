import { createReducer, on, Action } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import * as CustomerUsersActions from './customer-users.actions';
import { common, Hub } from '@qwyk/models';

export const CUSTOMERUSERS_FEATURE_KEY = 'customerUsers';

export interface State extends EntityState<Hub.CustomerUser> {
    selectedId?: string | number; // which CustomerUsers record has been selected
    loaded: boolean; // has the CustomerUsers list been loaded
    loading: boolean;
    error?: string | null; // last none error (if any)
    pagination?: common.PaginationMeta;
}

export interface CustomerUsersPartialState {
    readonly [CUSTOMERUSERS_FEATURE_KEY]: State;
}

export const customerUsersAdapter: EntityAdapter<Hub.CustomerUser> =
    createEntityAdapter<Hub.CustomerUser>();

export const initialState: State = customerUsersAdapter.getInitialState({
    // set initial required properties
    loaded: false,
    loading: false,
});

const customerUsersReducer = createReducer(
    initialState,
    on(CustomerUsersActions.loadCustomerUsers, state => ({
        ...state,
        loaded: false,
        loading: true,
        error: null,
    })),
    on(
        CustomerUsersActions.loadCustomerUsersSuccess,
        (state, { customerUsers, pagination }) =>
            customerUsersAdapter.setAll(customerUsers, {
                ...state,
                loaded: true,
                loading: false,
                pagination,
            })
    ),
    on(CustomerUsersActions.loadCustomerUsersFailure, (state, { error }) => ({
        ...state,
        error,
        loading: false,
    })),
    on(
        CustomerUsersActions.loadCustomerUserSuccess,
        (state, { customerUser }) =>
            customerUsersAdapter.addOne(customerUser, {
                ...state,
            })
    ),
    on(CustomerUsersActions.selectCustomerUser, (state, { id }) => ({
        ...state,
        selectedId: id,
    })),
    // eslint-disable-next-line no-empty-pattern
    on(CustomerUsersActions.createCustomerUser, (state, {}) => ({
        ...state,
        loading: true,
        error: null,
    })),
    on(
        CustomerUsersActions.createCustomerUserSuccess,
        (state, { customerUser }) =>
            customerUsersAdapter.addOne(customerUser, {
                ...state,
                loading: false,
            })
    ),
    on(
        CustomerUsersActions.updateCustomerUser,
        CustomerUsersActions.activateCustomerUser,
        // eslint-disable-next-line no-empty-pattern
        (state, {}) => ({
            ...state,
            loading: true,
            error: null,
        })
    ),
    // eslint-disable-next-line no-empty-pattern
    on(CustomerUsersActions.deactivateCustomerUser, (state, {}) => ({
        ...state,
        loading: true,
        error: null,
    })),
    on(
        CustomerUsersActions.mutateCustomerUserSuccess,
        (state, { customerUser }) =>
            customerUsersAdapter.updateOne(
                { id: customerUser.id, changes: customerUser },
                {
                    ...state,
                    loading: false,
                }
            )
    ),
    on(CustomerUsersActions.deactivateCustomerUserSuccess, (state, { id }) =>
        customerUsersAdapter.removeOne(id, {
            ...state,
            loading: false,
        })
    ),
    on(
        CustomerUsersActions.createCustomerUserFailure,
        CustomerUsersActions.mutateCustomerUserFailure,
        (state, { error }) => ({
            ...state,
            loading: false,
            error,
        })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return customerUsersReducer(state, action);
}
