import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ActivitiesEntity, CommentActivityPayload} from '@qwyk/shared-stores/activities';

@Component({
    selector: 'qwyk-activity-comment-list-item',
    templateUrl: './activity-comment-list-item.component.html',
    styleUrls: ['./activity-comment-list-item.component.scss']
})
export class ActivityCommentListItemComponent {
    @Input() activity!: ActivitiesEntity;
    @Output() update: EventEmitter<{ activity: ActivitiesEntity, payload: CommentActivityPayload }> = new EventEmitter<{ activity: ActivitiesEntity; payload: CommentActivityPayload }>();
    @Output() delete: EventEmitter<ActivitiesEntity> = new EventEmitter<ActivitiesEntity>();
    public editing = false;
    @Input() saving = false;
    @Input() loading = false;
    @Input() canMutate = false;

    onUpdateComment(payload: CommentActivityPayload) {
        this.update.emit({
            activity: this.activity,
            payload
        });
    }

    onDeleteComment() {
        this.delete.emit(this.activity);
    }
}
