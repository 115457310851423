import {
    trigger,
    transition,
    animate,
    style,
    state,
} from '@angular/animations';

export const fadeInAnimation = trigger('fadeIn', [
    state('*', style({ opacity: 1 })),
    transition(':enter', [style({ opacity: 0 }), animate(500)]),
]);

export const fadeInDelayAnimation = trigger('fadeInDelay', [
    state('*', style({ opacity: 1 })),
    transition(':enter', [style({ opacity: 0 }), animate('500ms 500ms')]),
]);

export const fadeInOutAnimation = trigger('fadeInOut', [
    state('*', style({ opacity: 1 })),
    transition(':enter', [style({ opacity: 0 }), animate(500)]),
    transition(':leave', [animate(500, style({ opacity: 0 }))]),
]);

export const expandCollapseAnimation = trigger('expandCollapse', [
    state('*', style({ 'max-height': '1000px' })),
    transition(':enter', [style({ 'max-height': 0 }), animate(500)]),
    transition(':leave', [animate(500, style({ 'max-height': '1000px' }))]),
]);

export const expandCollapse2 = trigger('expandCollapse2', [
    state(
        '*',
        style({
            'overflow-y': 'hidden',
            height: '*',
        })
    ),
    state(
        'void',
        style({
            height: '0',
            'overflow-y': 'hidden',
        })
    ),
    transition('* => void', animate('250ms ease-out')),
    transition('void => *', animate('250ms ease-in')),
]);

export const primeTableRowExpansion = trigger('rowExpansionTrigger', [
    state(
        'void',
        style({
            transform: 'translateX(-10%)',
            opacity: 0,
        })
    ),
    state(
        'active',
        style({
            transform: 'translateX(0)',
            opacity: 1,
        })
    ),
    transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
]);

export const slideAnimation = trigger('slide', [
    state('main', style({ transform: 'translateX(0)' })),
    state('other', style({ transform: 'translateX(-50%)' })),
    transition('* => *', animate(300)),
]);

export const threePageSlideAnimation = trigger('slide3', [
    state('1', style({ transform: 'translateX(0)' })),
    state('2', style({ transform: 'translateX(-33.34%)' })),
    state('3', style({ transform: 'translateX(-66.67%)' })),
    transition('* => *', animate(300)),
]);

export const delayLeaveAnimation = trigger('delayLeave', [
    transition('* => delay', [animate('300ms ease-in', style({}))]),
]);

export const slideDown = trigger('slideDown', [
    transition(':enter', [
        style({ transform: 'translateY(-2.1rem)' }),
        animate('200ms', style({ transform: 'translateY(0)' })),
    ]),
]);
