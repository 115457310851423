import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Portals } from '@qwyk/models';

@Component({
    selector: 'qwyk-quotation-detail-header',
    templateUrl: './quotation-detail-header.component.html',
    styleUrls: ['./quotation-detail-header.component.scss'],
})
export class QuotationDetailHeaderComponent {
    @Input() quotation!: Portals.QuotationEntity;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() url!: any;
    @Input() busy = false;
    @Input() isPolling = false;
    @Input() isMasterAdmin = false;

    @Output() delete: EventEmitter<Portals.QuotationEntity> =
        new EventEmitter<Portals.QuotationEntity>();
    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() close: EventEmitter<Portals.QuotationEntity> =
        new EventEmitter<Portals.QuotationEntity>();
    @Output() download: EventEmitter<Portals.QuotationEntity> =
        new EventEmitter<Portals.QuotationEntity>();
    @Output() publish: EventEmitter<Portals.QuotationEntity> =
        new EventEmitter<Portals.QuotationEntity>();
}
