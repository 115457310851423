import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Portals } from '@qwyk/models';
import { QuotationsFacade } from '@qwyk/shared-stores/quotations';
import { SimpleModalComponent } from '@qwyk/ui-standalone';
import { QuotationsService } from '../../services/quotations.service';

@Component({
    selector: 'qwyk-quotation-rate-requests-list',
    templateUrl: './quotation-rate-requests-list.component.html',
    styleUrls: ['./quotation-rate-requests-list.component.scss'],
})
export class QuotationRateRequestsListComponent {
    @Input() rateRequests: Portals.QuotationRateRequest[];
    @Input() outbound = false;

    constructor(
        private modal: NgbModal,
        private service: QuotationsService,
        private quotations: QuotationsFacade
    ) {}

    public closeRequest(rateRequest: Portals.QuotationRateRequest) {
        const modalRef = this.modal.open(SimpleModalComponent, {
            centered: true,
        });

        const ci = modalRef.componentInstance;

        ci.header = 'Close Rate Request without Offer';
        ci.dismissAction = 'Cancel';
        ci.confirmAction = 'Continue';
        ci.inputLabel = 'Provide a close reason:';
        ci.showInput = true;
        ci.inputType = 'textarea';
        ci.inputPlaceholder = 'Close reason...';
        ci.inputRequired = true;

        modalRef.result.then(
            reason => {
                const sub = this.service
                    .closeQuotationRateRequest(rateRequest, { reason })
                    .subscribe(
                        quotation => {
                            this.quotations.quotationUpdated(quotation);
                            if (sub) {
                                sub.unsubscribe();
                            }
                        },
                        () => {
                            alert(
                                'Something went wrong, please try again or contact support if the problem persists.'
                            );
                            if (sub) {
                                sub.unsubscribe();
                            }
                        }
                    );
            },
            () => {}
        );
    }
}
