import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { Portals } from '@qwyk/models';

import * as QuotationOffersActions from './quotation-offers.actions';
import * as QuotationOffersSelectors from './quotation-offers.selectors';

@Injectable()
export class QuotationOffersFacade {
    /**
     * Combine pieces of state using createSelector,
     * and expose them as observables through the facade.
     */
    loading$ = this.store.pipe(
        select(QuotationOffersSelectors.getQuotationOffersLoading)
    );
    allQuotationOffers$ = this.store.pipe(
        select(QuotationOffersSelectors.getAllQuotationOffers)
    );
    selectedQuotationOffers$ = this.store.pipe(
        select(QuotationOffersSelectors.getSelected)
    );
    error$ = this.store.pipe(
        select(QuotationOffersSelectors.getQuotationOffersError)
    );
    pagination$ = this.store.pipe(
        select(QuotationOffersSelectors.getQuotationOffersPagination)
    );

    constructor(private store: Store) {}

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    init(quotationId: string, query: any): void {
        this.store.dispatch(
            QuotationOffersActions.loadQuotationOffers({ quotationId, query })
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    selectQuotationOffer(selectedId: any) {
        this.store.dispatch(
            QuotationOffersActions.selectQuotationOffer({ selectedId })
        );
    }

    public createQuotationOffer(
        quotationId: string,
        payload: Portals.QuotationOffer
    ): void {
        this.store.dispatch(
            QuotationOffersActions.createQuotationOffer({
                quotationId,
                payload,
            })
        );
    }

    public updateQuotationOffer(
        quotationId: string,
        quotationOfferId: string,
        payload: Portals.QuotationOffer
    ): void {
        this.store.dispatch(
            QuotationOffersActions.updateQuotationOffer({
                quotationId,
                quotationOfferId,
                payload,
            })
        );
    }

    public deleteQuotationOffer(
        quotationId: string,
        quotationOfferId: string
    ): void {
        this.store.dispatch(
            QuotationOffersActions.deleteQuotationOffer({
                quotationId,
                quotationOfferId,
            })
        );
    }

    public generateQuotationOfferPdf(
        quotationId: string,
        quotationOfferId: string
    ): void {
        this.store.dispatch(
            QuotationOffersActions.generateQuotationOfferPdf({
                quotationId,
                quotationOfferId,
            })
        );
    }

    public dismissError(): void {
        this.store.dispatch(QuotationOffersActions.dismissError());
    }

    public reset(): void {
        this.store.dispatch(QuotationOffersActions.reset());
    }
}
