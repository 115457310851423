import { Component } from '@angular/core';
import { SitesFacade } from '../../+state/sites.facade';
import { LazyLoadEvent } from 'primeng/api';
import { FormBuilder } from '@angular/forms';

@Component({
    selector: 'qwyk-sites-list',
    templateUrl: './sites-list.component.html',
    styleUrls: ['./sites-list.component.scss'],
})
export class SitesListComponent {
    sites$ = this.sites.allSites$;
    loading$ = this.sites.loading$;
    pagination$ = this.sites.pagination$;
    pageSize = 25;

    searchForm = this.fb.group({
        name: [null],
        endpoint_identifier: [null],
    });

    constructor(private sites: SitesFacade, private fb: FormBuilder) {}

    loadSitesLazy(event: LazyLoadEvent): void {
        setTimeout(() => {
            let page = 1;
            if (event.first && event.rows) {
                page = event.first / event.rows + 1;
            }
            this.search(page);
        }, 50);
    }

    public search(page = 1) {
        const searchValue = this.searchForm.value;
        Object.keys(searchValue).forEach(key => {
            if (searchValue[key] === null) {
                delete searchValue[key];
            }
        });
        const query = {
            ...searchValue,
            pageSize: this.pageSize,
            page,
        };
        this.sites.loadSites(query);
    }

    public resetSearch() {
        this.searchForm.reset();
        this.search();
    }
}
