/* eslint-disable @nx/enforce-module-boundaries */
import { Router } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { StoreModule } from '@ngrx/store';
import { ToastrModule } from 'ngx-toastr';
import * as Sentry from '@sentry/angular';
import { EffectsModule } from '@ngrx/effects';
import { JwtModule } from '@auth0/angular-jwt';
import { SidebarModule } from 'primeng/sidebar';
import { TooltipModule } from 'primeng/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { SegmentModule } from 'ngx-segment-analytics';
import { MomentModule, TimeAgoPipe } from 'ngx-moment';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxLaravelEchoModule } from 'ngx-laravel-echo';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NgCircleProgressModule } from 'ng-circle-progress';
import {
    FaIconLibrary,
    FontAwesomeModule,
} from '@fortawesome/angular-fontawesome';
import { ReactiveValidationModule } from 'angular-reactive-validation';

import {
    TranslateConfigRoot,
    PortalsTranslationModule,
} from '@qwyk/portals/translation';
import { UiModule } from '@qwyk/ui';
import { environment } from '@qwyk/hub/environment';
import { AppEffects, appReducer } from '@qwyk/hub/store';
import { CoreModule, VersionCheckerService } from '@qwyk/core';
import { HubQuotationsWithoutRoutingModule } from '@qwyk/hub/quotations';
import { HubNotificationsWithoutRoutingModule } from '@qwyk/hub/notifications';
import { HubAuthenticationWithoutRoutingModule } from '@qwyk/hub/authentication';
import { HubAdministrationSitesWithoutRoutingModule } from '@qwyk/hub/administration/sites';
import { HubAdministrationCustomerUsersWithoutRoutingModule } from '@qwyk/hub/administration/customer-users';

import {
    tokenGetter,
    SentryErrorHandler,
    startupServiceFactory,
} from './bootstrap';
import loadIcons from './helpers/iconsLoader';
import { AppComponent } from './app.component';
import { versions } from '../environments/versions';
import { DEPLOY_URL } from '../environments/deployUrl';
import { AppRoutingModule } from './app-routing.module';
import { UserModule } from './modules/user/user.module';
import { StartupService } from './services/startup.service';
import { SharedModule } from './modules/shared/shared.module';
import { PageTitleService } from './services/page-title.service';
import { SchedulesModule } from './modules/schedules/schedules.module';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { HomepageComponent } from './components/homepage/homepage.component';
import { IndexNewComponent } from './components/index-new/index-new.component';
import { HelpModalComponent } from './components/help-modal/help-modal.component';
import { TrialExpiredComponent } from './components/trial-expired/trial-expired.component';
import { ResourceErrorPageComponent } from './components/resource-error-page/resource-error-page.component';
import { NotificationsSidebarComponent } from './components/notifications-sidebar/notifications-sidebar.component';

@NgModule({
    declarations: [
        AppComponent,
        SideBarComponent,
        IndexNewComponent,
        HomepageComponent,
        HelpModalComponent,
        TrialExpiredComponent,
        ResourceErrorPageComponent,
        NotificationsSidebarComponent,
    ],
    imports: [
        CoreModule.forRoot(environment),
        HubAuthenticationWithoutRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        SchedulesModule,
        SharedModule,
        UserModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        FontAwesomeModule,
        SidebarModule,
        UiModule,
        TooltipModule,
        MomentModule,
        NgxChartsModule,
        HubQuotationsWithoutRoutingModule,
        HubNotificationsWithoutRoutingModule,
        HubAdministrationSitesWithoutRoutingModule,
        HubAdministrationCustomerUsersWithoutRoutingModule,
        JwtModule.forRoot({
            config: {
                tokenGetter,
                allowedDomains: [
                    'hub2.test',
                    'api.next.qwykportals.com',
                    'api.qwykportals.com',
                    'api.next.qwykschedules.com',
                    'api.qwykschedules.com',
                    'schedules2.test',
                    'api.nxt.qwykportals.com',
                    'api.nxt.qwykschedules.com',
                    'hub-server.test',
                    'api.beta.qwykportals.com',
                    'api.beta.qwykschedules.com',
                    'api.dfp.qwykportals.com',
                    'localhost:9090',
                ], // FIXME: Put this in config...
                disallowedRoutes: [],
            },
        }),
        StoreModule.forRoot(
            {
                app: appReducer,
            },
            {
                runtimeChecks: {
                    strictStateImmutability: false,
                    strictActionImmutability: false,
                },
            }
        ),
        EffectsModule.forRoot([AppEffects]),
        StoreDevtoolsModule.instrument({ logOnly: environment.production }),
        ToastrModule.forRoot({
            positionClass: 'toast-top-right',
            maxOpened: 4,
            progressBar: true,
            closeButton: true,
        }),
        NgCircleProgressModule.forRoot({}),
        NgxLaravelEchoModule.forRoot(environment.utils.Echo),
        ReactiveValidationModule.forRoot({
            displayValidationMessageWhen: control => {
                return control.touched;
            },
        }),
        PortalsTranslationModule.forRoot({
            translationsDeployUrl: `${DEPLOY_URL}assets/i18n/`,
        }),
        TranslateModule.forRoot(TranslateConfigRoot),
        SegmentModule.forRoot(environment.utils.Segment),
    ],
    providers: [
        StartupService,
        TimeAgoPipe,
        {
            provide: APP_INITIALIZER,
            useFactory: startupServiceFactory,
            deps: [StartupService],
            multi: true,
        },
        { provide: ErrorHandler, useClass: SentryErrorHandler },
        { provide: Sentry.TraceService, deps: [Router], useValue: undefined },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(
        library: FaIconLibrary,
        titleService: PageTitleService,
        versionChecker: VersionCheckerService
    ) {
        loadIcons(library);
        titleService.init();
        if (environment.production) {
            versionChecker.init(versions.version);
        }
    }
}
