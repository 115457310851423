import { ActivitiesResponse } from './../store/models/user.models';
import { User } from './../../../domain/global.models';
import { Resource } from './../../../domain/generic.models';
import { environment } from '@qwyk/hub/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { TimeZone } from '../store/models/user.models';

/**
 * Injectable
 */
@Injectable({
    providedIn: 'root',
})
export class UserService {
    constructor(private http: HttpClient) {}

    getTimeZones(): Observable<TimeZone[]> {
        return this.http
            .get<Resource<TimeZone[]>>(
                `${environment.backendServer}/api/masters/timezones`
            )
            .pipe(
                take(1),
                map(res => res.data),
                map(data => {
                    return data.sort((a, b) => {
                        if (a.identifier > b.identifier) {
                            return 1;
                        }
                        return -1;
                    });
                })
            );
    }

    /**
     * Gets user profile
     * @returns  Resource<Profile>
     */
    getUser(): Observable<User> {
        return this.http
            .get<Resource<User>>(
                `${environment.backendServer}/api/hub/organization/users/me`
            )
            .pipe(
                take(1),
                map(res => res.data)
            );
    }

    /**
     * Updates the user profile
     * @returns Resource<Profile>
     */
    updateUser(value: Partial<User>): Observable<User> {
        return this.http
            .post<Resource<User>>(
                `${environment.backendServer}/api/hub/organization/users/me`,
                value
            )
            .pipe(
                take(1),
                map(res => res.data)
            );
    }

    /**
     * Gets Activities data for the current user
     */
    getUserActivities(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        _page = 1,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        _perPage = 5
    ): Observable<ActivitiesResponse | null> {
        return of(null);
    }
}
