import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    SITES_FEATURE_KEY,
    State,
    SitesPartialState,
    sitesAdapter,
} from './sites.reducer';

// Lookup the 'Sites' feature state managed by NgRx
export const getSitesState = createFeatureSelector<SitesPartialState, State>(
    SITES_FEATURE_KEY
);

const { selectAll, selectEntities } = sitesAdapter.getSelectors();

export const getSitesLoaded = createSelector(
    getSitesState,
    (state: State) => state.loaded
);

export const getSitesError = createSelector(
    getSitesState,
    (state: State) => state.error
);

export const getAllSites = createSelector(getSitesState, (state: State) =>
    selectAll(state)
);

export const getSitesEntities = createSelector(getSitesState, (state: State) =>
    selectEntities(state)
);

export const getSelectedId = createSelector(
    getSitesState,
    (state: State) => state.selectedId
);

export const getSelected = createSelector(
    getSitesEntities,
    getSelectedId,
    (entities, selectedId) => selectedId && entities[selectedId]
);

export const getSitesLoading = createSelector(
    getSitesState,
    (state: State) => state.loading
);
export const getSitesPagination = createSelector(
    getSitesState,
    (state: State) => state.pagination
);
