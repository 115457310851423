import { CreateProviderComponent } from '../create-provider/create-provider.component';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, map, switchMap } from 'rxjs/operators';
import {
    fadeInAnimation,
    fadeInOutAnimation,
} from './../../../../../helpers/reusableAnimations';
import {
    activateProvider,
    deactivateProvider,
    deleteProvider,
    loadProviders,
} from '../../../store/actions/providers.actions';
import { Provider } from '../../../store/models/providers.models';
import { AppState } from '../../../../../store/app.state';
import { ProvidersState } from '../../../store/schedules.state';
import {
    selectProviders,
    selectProvidersState,
} from '../../../store/selectors/providers.selectors';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-providers',
    templateUrl: './providers.component.html',
    styleUrls: ['./providers.component.scss'],
    animations: [fadeInOutAnimation, fadeInAnimation],
})
export class ProvidersComponent implements OnInit, OnDestroy {
    providersState$: Observable<ProvidersState> =
        this.store.select(selectProvidersState);
    providers: Provider[];
    isError = false;
    isLoading = false;

    filterForm: FormGroup;
    providersSubscription: Subscription;
    filtersSubscription: Subscription;

    paging = {
        pageSize: 10,
        activePage: 1,
        inactivePage: 1,
    };

    constructor(
        private fb: FormBuilder,
        private store: Store<AppState>,
        private modalService: NgbModal
    ) {}

    ngOnInit() {
        // Setup the filter form.
        this.setupFilterForm();

        // Subscribe to provider state events.
        this.providersSubscription = this.providersState$.subscribe(result => {
            this.providers = result.providers;
            this.isLoading = result.loading;
            this.isError = result.error;
        });

        // Dispatch the loadProviders action to the store so it will pull the providers from the server.
        this.store.dispatch(loadProviders());
    }

    ngOnDestroy() {
        // Unsub from the created subscriptions.
        if (this.providersSubscription) {
            this.providersSubscription.unsubscribe();
        }

        if (this.filtersSubscription) {
            this.filtersSubscription.unsubscribe();
        }
    }

    /**
     * Sends an update to the server to activate the provider for the user's organization
     * @param provider the provider to be activated
     */
    public activate(provider: Provider) {
        this.store.dispatch(activateProvider({ provider }));
    }

    /**
     * Sends an update to the server to deactivate the provider for the user's organization
     * @param provider the provider to deactivate
     */
    public deactivate(provider: Provider) {
        this.store.dispatch(deactivateProvider({ provider }));
    }

    /**
     * Sends a delete to the server, only for custom providers.
     * @param provider the provider to be deleted.
     */
    public delete(provider: Provider) {
        this.store.dispatch(deleteProvider({ provider }));
    }

    public openCreateProviderModal() {
        this.modalService.open(CreateProviderComponent, {
            centered: true,
            backdrop: 'static',
            keyboard: false,
        });
    }

    /**
     * Setups filter form
     */
    private setupFilterForm() {
        this.filterForm = this.fb.group({
            name: [null],
            product: [''],
        });

        this.filtersSubscription = this.filterForm.valueChanges
            .pipe(
                debounceTime(200),
                switchMap(filter => {
                    return this.store.select(selectProviders).pipe(
                        map(providers => {
                            if (!providers) {
                                return [];
                            }
                            return providers.filter(p => {
                                const filterName = filter.name || '';
                                return (
                                    p.name
                                        .toLowerCase()
                                        .includes(filterName.toLowerCase()) &&
                                    (p.product === filter.product ||
                                        filter.product === '')
                                );
                            });
                        })
                    );
                })
            )
            .subscribe(res => {
                this.providers = res;
            });
    }

    /**
     * Gets active providers
     */
    get activeProviders(): Provider[] {
        if (!this.providers) {
            return [];
        }

        return this.providers.filter(e => e.active === true);
    }

    /**
     * Gets inactive providers
     */
    get inactiveProviders(): Provider[] {
        if (!this.providers) {
            return [];
        }

        return this.providers.filter(e => e.active === false);
    }
}
