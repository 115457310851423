import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from '@qwyk/hub/environment';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import { versions } from './environments/versions';
import { CaptureConsole as SentryCaptureConsoleIntegration } from '@sentry/integrations';
import { EventHint } from '@sentry/browser';
import { Event } from '@sentry/types';
import { isArray } from 'rxjs/internal-compatibility';

Sentry.init({
    enabled: environment.sentry.enabled,
    dsn: environment.sentry.dsn,
    environment: environment.name,
    release: versions.version,
    integrations: [
        new Integrations.BrowserTracing({
            tracingOrigins: environment.sentry.traceOrigins,
            routingInstrumentation: Sentry.routingInstrumentation,
        }),
        new SentryCaptureConsoleIntegration({
            levels: ['error'],
        }),
    ],
    tracesSampleRate: environment.sentry.tracesSampleRate,
    beforeSend: (
        event: Event,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        hint?: EventHint
    ): PromiseLike<Event | null> | Event | null => {
        if (
            typeof event?.message === 'string' &&
            event?.message.includes('[object Object]') &&
            event?.extra?.arguments &&
            isArray(event.extra.arguments) &&
            event?.extra?.arguments.length > 0 &&
            event.extra.arguments[0]?.message
        ) {
            event.message = event.extra.arguments[0].message;
        }
        return event;
    },
});

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));
