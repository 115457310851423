import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UsersFacade } from '../../+state/users.facade';
import { AuthenticationFacade } from '@qwyk/hub/authentication';

@Component({
    selector: 'qwyk-user-view',
    templateUrl: './user-view.component.html',
    styleUrls: ['./user-view.component.scss'],
})
export class UserViewComponent implements OnDestroy {
    private actionsSubscription: Subscription;
    public selectedUser$ = this.users.selectedUsers$;
    public loading$ = this.users.loading$;
    public error$ = this.users.error$;
    public user$ = this.authentication.user$;

    constructor(
        private route: ActivatedRoute,
        private users: UsersFacade,
        private router: Router,
        private authentication: AuthenticationFacade
    ) {
        this.actionsSubscription = this.route.params.subscribe(params => {
            this.users.selectUser(+params.id);
        });
    }

    ngOnDestroy(): void {
        this.users.unselectUser();
        this.actionsSubscription.unsubscribe();
    }

    public onCreate(payload) {
        this.users.createUser(payload);
    }

    public onUpdate({ id, payload }) {
        this.users.updateUser(id, payload);
    }

    public onUpdateHubUserStatus({ user, action }) {
        switch (action) {
            case 'activate':
                this.users.activateUser(user);
                break;
            case 'deactivate':
                this.users.deactivateUser(user);
                break;
        }
    }
}
