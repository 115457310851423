import { Component } from '@angular/core';

import { interval } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { filter, switchMap, take } from 'rxjs/operators';

import { MagayaConnectionServiceService } from '@qwyk/core';
import { AuthenticationFacade } from '@qwyk/hub/authentication';

import { versions } from '../../../environments/versions';
import { fadeInAnimation } from '../../helpers/reusableAnimations';
import { HelpModalComponent } from '../help-modal/help-modal.component';

@Component({
    selector: 'app-homepage',
    templateUrl: './homepage.component.html',
    styleUrls: ['./homepage.component.scss'],
    animations: [fadeInAnimation],
})
export class HomepageComponent {
    public today = new Date();
    public expandNews = false;
    public versionInfo = versions;

    public user$ = this.authentication.user$;
    public magayaConnectionStatus$ = interval(200).pipe(
        take(1),
        switchMap(() =>
            this.magayaConnectionService.getMagayaConnectionStatus().pipe(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                filter((status: any) =>
                    ['low', 'medium', 'high'].includes(status.severity)
                )
            )
        )
    );

    constructor(
        private modal: NgbModal,
        private authentication: AuthenticationFacade,
        private magayaConnectionService: MagayaConnectionServiceService
    ) {}

    get greeting(): string {
        const greetings = [
            [0, 4, 'portal.common.greetings.good-night'],
            [5, 11, 'portal.common.greetings.good-morning'], // Store messages in an array
            [12, 17, 'portal.common.greetings.good-afternoon'],
            [18, 22, 'portal.common.greetings.good-evening'],
            [23, 24, 'portal.common.greetings.good-night'],
        ];
        const hr = new Date().getHours();

        for (const greeting of greetings) {
            if (hr >= greeting[0] && hr <= greeting[1]) {
                return greeting[2] as string;
            }
        }
        return 'common.hello';
    }

    public onRequestHelp() {
        this.modal.open(HelpModalComponent, { centered: true, size: 'xl' });
    }
}
