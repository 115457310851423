import {
    AsyncValidator,
    AbstractControl,
    ValidationErrors,
} from '@angular/forms';
import { Observable, of } from 'rxjs';
import { SitesService } from '../services/sites.service';
import {
    switchMap,
    delay,
    debounceTime,
    catchError,
    map,
} from 'rxjs/operators';

export class EndpointAvailableValidator implements AsyncValidator {
    constructor(
        private service: SitesService,
        private currentSiteId: number = null
    ) {}

    validate(
        control: AbstractControl
    ): Promise<ValidationErrors> | Observable<ValidationErrors> {
        return of(control).pipe(
            debounceTime(200),
            delay(200),
            switchMap(ctrl => {
                return this.service
                    .validateEndpoint(ctrl.value, this.currentSiteId)
                    .pipe(
                        map(() => {
                            return null;
                        }),
                        catchError(error => {
                            if (error.status === 422) {
                                return of({
                                    validated_endpoint:
                                        error.error.errors[
                                            'endpoint_identifier'
                                        ][0],
                                });
                            }
                            return of({
                                validated_endpoint: 'Validation error.',
                            });
                        })
                    );
            })
        );
    }
}
