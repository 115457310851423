import {Injectable} from '@angular/core';
import {
    ActivitiesEntity,
    ActivitiesIndexQuery,
    ActivitiesServiceBase,
    CommentActivityPayload,
    HasActivities
} from '@qwyk/shared-stores/activities';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {common} from '@qwyk/models';
import {environment} from '@qwyk/hub/environment';
import {map, take} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ActivitiesService extends ActivitiesServiceBase {

    constructor(private http: HttpClient) {
        super();
    }

    public loadActivities(parent: HasActivities, query: ActivitiesIndexQuery):
        Observable<common.CursorPaginatedResponse<ActivitiesEntity>> {

        let params = new HttpParams({fromObject: {}});
        if (query.page_size) {
            params = params.append('page_size', query.page_size);
        }
        if (query.type && query.type !== 'all') {
            params = params.append('type', query.type);
        }

        if (query.cursor) {
            params = params.append('cursor', query.cursor)
        }


        return this.http.get<common.CursorPaginatedResponse<ActivitiesEntity>>(
            `${environment.backendServer}/api/hub/${parent.type}/${parent.id}/activity?${params}`
        ).pipe(take(1));
    }

    public createComment(parent: HasActivities, payload: CommentActivityPayload): Observable<ActivitiesEntity> {
        return this.http.post<{ data: ActivitiesEntity }>(
            `${environment.backendServer}/api/hub/${parent.type}/${parent.id}/activity/comment`,
            payload
        ).pipe(map(e => e.data))
    }

    public updateComment(parent: HasActivities, activity: ActivitiesEntity, payload: CommentActivityPayload): Observable<ActivitiesEntity> {
        return this.http.patch<{ data: ActivitiesEntity }>(
            `${environment.backendServer}/api/hub/${parent.type}/${parent.id}/activity/comment/${activity.id}`,
            payload
        ).pipe(map(e => e.data))
    }

    public deleteComment(parent: HasActivities, activity: ActivitiesEntity): Observable<void> {
        return this.http.delete<void>(
            `${environment.backendServer}/api/hub/${parent.type}/${parent.id}/activity/comment/${activity.id}`
        );
    }
}
