import { Provider } from './../store/models/providers.models';
import { environment } from '@qwyk/hub/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ProvidersService {
    constructor(private http: HttpClient) {}

    /**
     * Gets all providers, including an indication whether it's active for the user's organization.
     * @returns an observable containing the providers
     */
    getProviders(): Observable<Provider[]> {
        return this.http.get<Provider[]>(
            `${environment.schedulesBackend}/api/providers`
        );
    }

    getActiveProviders(product: string): Observable<Provider[]> {
        return this.http.get<Provider[]>(
            `${environment.schedulesBackend}/api/providers/active/${product}`
        );
    }

    /**
     * Creates provider
     * @param provider partial provider object
     * @returns provider an observable with the newly created provider
     */
    createProvider(provider: Partial<Provider>): Observable<Provider> {
        return this.http.post<Provider>(
            `${environment.schedulesBackend}/api/providers`,
            provider
        );
    }

    /**
     * Activates provider for the user's organization
     * @param provider the provider to be activated.
     */
    activateProvider(provider: Provider): Observable<void> {
        return this.http.post<void>(
            `${environment.schedulesBackend}/api/providers/${provider.id}/activate`,
            null
        );
    }

    /**
     * Deactivates provider for the user's organization
     * @param provider  the provider to be deactivated
     */
    deactivateProvider(provider: Provider): Observable<void> {
        return this.http.post<void>(
            `${environment.schedulesBackend}/api/providers/${provider.id}/deactivate`,
            null
        );
    }

    /**
     * Deletes customer provider
     * @param provider the provider to be deleted
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deleteProvider(provider: Provider): Observable<any> {
        return this.http.delete(
            `${environment.schedulesBackend}/api/providers/${provider.id}`
        );
    }
}
