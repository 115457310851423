<div class="bg-light min-vh-100">
    <ng-container *ngIf="selectedQuotation$ | async; let quotation;">
        <div class="container-fluid py-3 pb-5">
            <div class="row justify-content-center">
                <div class="col-12 col-xxxl-10">
                    <qwyk-quotation-detail-header
                        (close)="close($event)"
                        (delete)="delete($event)"
                        (download)="download($event)"
                        (publish)="publish($event, null)"
                        [busy]="busy"
                        [isMasterAdmin]="(isMasterAdmin$ | async) === true"
                        [isPolling]="isPolling"
                        [quotation]="quotation"
                        [url]="url$ | async"
                    >
                    </qwyk-quotation-detail-header>
                    <div class="mb-3">
                        <qwyk-quotation-request-details
                            [quotation]="quotation"
                        ></qwyk-quotation-request-details>
                    </div>
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </ng-container>
</div>
