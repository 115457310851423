<ng-container *ngIf="hierarchy$ | async; let hierarchy; else loadview">
    <p-treeTable [value]="hierarchy">
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 200px;">{{ 'common.company' | translate }}</th>
                <th>{{ 'common.permissions' | translate }}</th>
            </tr>
        </ng-template>

        <ng-template let-rowData let-rowNode pTemplate="body">
            <tr>
                <td>
                    <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                    {{ rowNode.node.name }}
                </td>
                <td class="p-fluid">
                    <p-multiSelect
                        [(ngModel)]="permissions[rowNode.node.id]"
                        [options]="rowNode.node.id === user.team_id ? PERMISSION_LIST : FILTERED_PERMISSION_LIST"
                        appendTo="body"
                        class="w-100"
                        optionLabel="label"
                        optionValue="value">
                    </p-multiSelect>
                </td>
            </tr>
        </ng-template>
    </p-treeTable>
</ng-container>

<ng-template #loadview>
    <div class="text-center">
        <p *ngIf="loading" class="title">{{ 'common.loading' | translate }}...</p>
        <div *ngIf="!loading && error" class="title">
            <h6>{{ 'common.something_went_wrong' | translate }}...</h6>
            <p>{{ 'common.try_again' | translate }}.</p>
        </div>
    </div>
</ng-template>
