import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import * as QuotationOffersActions from './quotation-offers.actions';
import { common, Portals } from '@qwyk/models';

export const QUOTATIONOFFERS_FEATURE_KEY = 'quotationOffers';

export interface State extends EntityState<Portals.QuotationOffer> {
    selectedId?: string | number; // which QuotationOffers record has been selected
    loading: boolean; // is the QuotationOffers list loading
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error?: any | null; // last known error (if any)
    pagination?: common.PaginationMeta;
}

export interface QuotationOffersPartialState {
    readonly [QUOTATIONOFFERS_FEATURE_KEY]: State;
}

export const quotationOffersAdapter: EntityAdapter<Portals.QuotationOffer> =
    createEntityAdapter<Portals.QuotationOffer>();

export const initialState: State = quotationOffersAdapter.getInitialState({
    // set initial required properties
    loading: false,
});

const quotationOffersReducer = createReducer(
    initialState,
    on(
        QuotationOffersActions.loadQuotationOffers,
        QuotationOffersActions.createQuotationOffer,
        QuotationOffersActions.updateQuotationOffer,
        QuotationOffersActions.deleteQuotationOffer,
        QuotationOffersActions.generateQuotationOfferPdf,
        state => ({
            ...state,
            loading: true,
            error: null,
        })
    ),
    on(
        QuotationOffersActions.loadQuotationOffersSuccess,
        (state, { quotationOffers, pagination }) =>
            quotationOffersAdapter.setAll(quotationOffers, {
                ...state,
                loading: false,
                pagination,
            })
    ),
    on(
        QuotationOffersActions.loadQuotationOffersFailure,
        QuotationOffersActions.createQuotationOfferFailure,
        QuotationOffersActions.updateQuotationOfferFailure,
        QuotationOffersActions.deleteQuotationOfferFailure,
        QuotationOffersActions.generateQuotationOfferPdfFailure,
        (state, { error }) => ({ ...state, error, loading: false })
    ),
    on(
        QuotationOffersActions.loadQuotationOfferSuccess,
        (state, { quotationOffer }) =>
            quotationOffersAdapter.addOne(quotationOffer, {
                ...state,
                loading: false,
            })
    ),
    on(
        QuotationOffersActions.selectQuotationOffer,
        (state, { selectedId }) => ({
            ...state,
            selectedId: selectedId,
        })
    ),
    on(
        QuotationOffersActions.createQuotationOfferSuccess,
        (state, { quotationOffer }) =>
            quotationOffersAdapter.addOne(quotationOffer, {
                ...state,
                loading: false,
            })
    ),
    on(
        QuotationOffersActions.updateQuotationOfferSuccess,
        (state, { quotationOffer }) =>
            quotationOffersAdapter.setOne(quotationOffer, {
                ...state,
                loading: false,
            })
    ),
    on(
        QuotationOffersActions.deleteQuotationOfferSuccess,
        (state, { quotationOfferId }) =>
            quotationOffersAdapter.removeOne(quotationOfferId, {
                ...state,
                loading: false,
            })
    ),
    on(QuotationOffersActions.generateQuotationOfferPdfSuccess, state => ({
        ...state,
        loading: false,
    })),
    on(QuotationOffersActions.dismissError, state => ({
        ...state,
        error: null,
    })),
    on(QuotationOffersActions.reset, () => initialState)
);

export function reducer(state: State | undefined, action: Action) {
    return quotationOffersReducer(state, action);
}
