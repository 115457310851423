import { AppState } from '../../../../../store/app.state';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { createProviderState } from '../../../store/selectors/providers.selectors';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import {
    createProvider,
    createProviderCancelled,
} from '../../../store/actions/providers.actions';

@Component({
    selector: 'app-create-provider',
    templateUrl: './create-provider.component.html',
    styleUrls: ['./create-provider.component.scss'],
})
export class CreateProviderComponent implements OnInit, OnDestroy {
    createProviderForm: FormGroup;
    createState$: Observable<{
        creating: boolean;
        errorCreating: boolean;
    }> = this.store.select(createProviderState);
    createStateSubscription: Subscription;
    isCreating = false;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error: any;
    validationErrors = null;

    constructor(
        public activeModal: NgbActiveModal,
        private store: Store<AppState>,
        private fb: FormBuilder
    ) {}

    ngOnInit() {
        this.createProviderForm = this.fb.group({
            name: [
                null,
                [
                    Validators.required,
                    Validators.minLength(1),
                    Validators.maxLength(50),
                ],
            ],
            product: [null, [Validators.required]],
            active: [true, []],
        });

        this.createStateSubscription = this.createState$.subscribe(state => {
            // Handle modal closure when the state moves from creating to not creating, but no error has occured.
            if (this.isCreating && !state.creating && !state.errorCreating) {
                this.activeModal.close();
            }

            this.isCreating = state.creating;
            this.error = state.errorCreating;
            this.validationErrors = null;

            // Handle form enable
            if (state.creating) {
                this.createProviderForm.disable();
            } else {
                this.createProviderForm.enable();
            }

            // Handle 422 validation errors
            if (
                this.error &&
                // eslint-disable-next-line no-prototype-builtins
                this.error.hasOwnProperty('status') &&
                this.error.status === 422
            ) {
                this.validationErrors = this.error.error;
            }
        });
    }

    ngOnDestroy(): void {
        if (this.createStateSubscription) {
            this.createStateSubscription.unsubscribe();
        }
    }

    submit() {
        Object.keys(this.createProviderForm.controls).forEach(field => {
            const control = this.createProviderForm.get(field);
            control?.markAsTouched({ onlySelf: true });
        });

        if (this.createProviderForm.invalid) {
            return;
        }

        this.store.dispatch(
            createProvider({ provider: this.createProviderForm.value })
        );
    }

    cancel() {
        this.store.dispatch(createProviderCancelled());

        this.activeModal.dismiss();
    }
}
