<h5 class="font-weight-bold mb-3">Create new offer</h5>
<form [formGroup]="offerForm" (submit)="saveOffer()">
    <div formGroupName="header">
        <ng-container *ngIf="selectedQuotation$ | async; let quotation">
            <ng-container *ngIf="quotation.rate_requests | arrayFilter:'open':true; let requests">
                <div class="row" *ngIf="requests && requests.length > 0">
                    <div class="form-group col-5">
                        <label>Respond to rate request</label>
                        <ng-select [items]="requests" bindValue="id" formControlName="rate_request_id">
                            <ng-template ng-option-tmp let-request="item">
                                <div>
                                    <span class="badge mr-1"
                                        [ngClass]="request.open ? 'badge-warning' : 'badge-success'">
                                        {{request.open ? 'Open' : 'Closed' }}</span>
                                    <span class="small text-muted">{{ request.creator.name }} requested on
                                        {{ request.created_at | amDateFormat: 'L LT'}}</span>
                                </div>
                                <div>{{ request.reason }}</div>
                                <div *ngIf="request.remarks">{{ request.remarks }}</div>
                            </ng-template>
                            <ng-template ng-label-tmp let-request="item">
                                {{ request.creator.name }} | {{ request.created_at | amDateFormat: 'L LT' }}
                            </ng-template>
                        </ng-select>
                    </div>
                </div>
                <div class="row" *ngIf="!!offerForm.get('header.rate_request_id').value">
                    <div class="form-group col-5">
                        <label>Message</label>
                        <textarea rows="3" max="255" class="form-control"
                            formControlName="rate_request_message"></textarea>
                    </div>
                </div>
                <hr>
            </ng-container>
        </ng-container>
        <div class="row">



            <div class="form-group col-5">
                <label>Product</label>
                <div class="input-group">
                    <div class="btn-group btn-group-toggle w-100" ngbRadioGroup name="radioBasic"
                        formControlName="load_type" (change)="onLoadTypeChanged($event)">
                        <label ngbButtonLabel class="w-50"
                            [ngClass]="(true || hasOfferLines) && offerForm.get('header.load_type').value === 'fcl' ? 'btn-primary' : 'btn-outline-primary'">
                            <input ngbButton type="radio" value="fcl" [disabled]="true || hasOfferLines">
                            <fa-icon [icon]="['fas', 'fcl' | loadTypeIcon]"></fa-icon> Full load
                        </label>
                        <label ngbButtonLabel class="btn-outline-primary w-50"
                            [ngClass]="(true || hasOfferLines) && offerForm.get('header.load_type').value === 'lcl' ? 'btn-primary' : 'btn-outline-primary'">
                            <input ngbButton type="radio" value="lcl" [disabled]="true || hasOfferLines">
                            <fa-icon [icon]="['fas', 'lcl' | loadTypeIcon]"></fa-icon> Less-than-full
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-5">
                <label>Transport mode</label>
                <div class="input-group">
                    <div class="btn-group btn-group-toggle w-100" ngbRadioGroup name="radioBasic"
                        formControlName="transport_mode">
                        <label ngbButtonLabel class=" w-33"
                            [ngClass]="hasOfferLines && offerForm.get('header.transport_mode').value === 'AIR' ? 'btn-primary' : 'btn-outline-primary'"
                            *ngIf="offerForm.get('header.load_type').value !== 'fcl'">
                            <input ngbButton type="radio" value="AIR" [disabled]="hasOfferLines">
                            <fa-icon [icon]="['far', 'AIR' | transportModeIcon]" class="mr-1"></fa-icon>Air
                        </label>
                        <label ngbButtonLabel class="w-33"
                            [ngClass]="hasOfferLines && offerForm.get('header.transport_mode').value === 'OCEAN' ? 'btn-primary' : 'btn-outline-primary'">
                            <input ngbButton type="radio" value="OCEAN" [disabled]="hasOfferLines">
                            <fa-icon [icon]="['far', 'OCEAN' | transportModeIcon]" class="mr-1"></fa-icon>Ocean
                        </label>
                        <label ngbButtonLabel class="w-33"
                            [ngClass]="hasOfferLines && offerForm.get('header.transport_mode').value === 'TRUCK' ? 'btn-primary' : 'btn-outline-primary'">
                            <input ngbButton type="radio" value="TRUCK" [disabled]="hasOfferLines">
                            <fa-icon [icon]="['far', 'TRUCK' | transportModeIcon]" class="mr-1"></fa-icon>Road
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-5">
                <label>Carrier</label>
                <div class="input-group"
                    [ngClass]="{'is-invalid': (offerForm.get('header.carrier_scac').invalid && offerForm.get('header.carrier_scac').touched) || (offerForm.get('header.carrier_name').invalid && offerForm.get('header.carrier_name').touched)}">
                    <ng-select [items]="carriers$ | async" bindValue="scac" bindLabel="name"
                        formControlName="carrier_scac" placeholder="Select a carrier"
                        (change)="onCarrierSelected($event)" [clearable]="false"
                        [ngClass]="{'is-invalid': offerForm.get('header.carrier_scac').invalid && offerForm.get('header.carrier_scac').touched}">
                    </ng-select>
                    <input type="text" formControlName="carrier_name" class="form-control" placeholder="Carrier name"
                        *ngIf="offerForm.get('header.carrier_scac').value === 'XXXX'" maxlength="191"
                        [ngClass]="{'is-invalid': offerForm.get('header.carrier_name').invalid && offerForm.get('header.carrier_name').touched}" />
                </div>
                <div class="invalid-feedback">Select or enter a carrier</div>
            </div>
        </div>
        <div class="row">
            <div class="form-group col">
                <label>POL</label>
                <qwyk-location-typeahead id="pol" labelProperty="display_name" placeholder="Port of Loading"
                    (inputChanged)="onLocationsAutocomplete($event)" [suggestions]="locationsSuggestions"
                    [searching]="isSearchingLocations" formControlName="pol"
                    [invalid]="offerForm.get('header.pol').invalid && offerForm.get('header.pol').touched">
                    <ng-template qwykLocationTypeaheadItem let-item="item">
                        <div>
                            <span [innerHTML]="item._highlightResult.display_name.value"></span>
                            (<span [innerHTML]="item._highlightResult.locode.value"></span>)
                        </div>
                        <small>
                            <span [innerHTML]="item._highlightResult.country_name.value"></span>
                        </small>
                    </ng-template>
                    <ng-template qwykLocationTypeaheadPlaceholder let-item="item">
                        <div>
                            <span>{{ item.display_name }}</span>
                            ({{item.locode}})
                        </div>
                        <small>{{ item.country_name }}</small>
                    </ng-template>
                </qwyk-location-typeahead>
                <div class="invalid-feedback">Select a Port of Loading</div>
                <div *ngIf="selectedQuotation$ | async; let quotation"><a href="javascript:void(0)" class="small"
                        (click)="setPol(quotation.request.origin)">Copy from request</a>
                </div>
            </div>
            <div class="form-group col">
                <label>POD</label>
                <qwyk-location-typeahead id="pod" labelProperty="display_name" placeholder="Port of Discharge"
                    (inputChanged)="onLocationsAutocomplete($event)" [suggestions]="locationsSuggestions"
                    [searching]="isSearchingLocations" formControlName="pod"
                    [invalid]="offerForm.get('header.pod').invalid && offerForm.get('header.pod').touched">
                    <ng-template qwykLocationTypeaheadItem let-item="item">
                        <div>
                            <span [innerHTML]="item._highlightResult.display_name.value"></span>
                            (<span [innerHTML]="item._highlightResult.locode.value"></span>)
                        </div>
                        <small>
                            <span [innerHTML]="item._highlightResult.country_name.value"></span>
                        </small>
                    </ng-template>
                    <ng-template qwykLocationTypeaheadPlaceholder let-item="item">
                        <div>
                            <span>{{ item.display_name }}</span>
                            ({{item.locode}})
                        </div>
                        <small>{{ item.country_name }}</small>
                    </ng-template>
                </qwyk-location-typeahead>
                <div class="invalid-feedback">Select a Port of Discharge</div>
                <div *ngIf="selectedQuotation$ | async; let quotation"><a href="javascript:void(0)" class="small"
                        (click)="setPod(quotation.request.destination)">Copy from request</a>
                </div>
            </div>
            <div class="form-group col-3">
                <label>Transit time</label>
                <input type="number" formControlName="transit_time" class="form-control" placeholder="Transit time"
                    min="0"
                    [ngClass]="{'is-invalid': offerForm.get('header.transit_time').invalid && offerForm.get('header.transit_time').touched}" />
                <div class="invalid-feedback">Enter a valid transit time</div>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-4">
                <label>Currency</label>
                <ng-select [items]="currencies$ | async" bindLabel="code" bindValue="code" groupBy="group"
                    placeholder="Currency" formControlName="currency" [clearable]="false" [readonly]="hasOfferLines"
                    [ngClass]="{'is-invalid': offerForm.get('header.currency').invalid && offerForm.get('header.currency').touched}">
                    <ng-template ng-optgroup-tmp let-item="item">
                        <div class="border-bottom"></div>
                    </ng-template>
                    <ng-template ng-label-tmp let-item="item">
                        {{ item.code }} - {{ item.name}}
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item">
                        {{ item.code }} - {{ item.name }}
                    </ng-template>
                </ng-select>
                <div class="invalid-feedback">Select a Currency</div>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-4">
                <label>Valid until</label>
                <div class="input-group"
                    [ngClass]="{'is-invalid': offerForm.get('header.valid_until').invalid && offerForm.get('header.valid_until').touched}">
                    <input class="form-control" placeholder="yyyy-mm-dd" name="valid_until" ngbDatepicker
                        autocomplete="off" #validUntilDate="ngbDatepicker" (click)="validUntilDate.toggle()"
                        formControlName="valid_until" [minDate]="minValidUntilDate"
                        [ngClass]="{'is-invalid': offerForm.get('header.valid_until').invalid && offerForm.get('header.valid_until').touched}">
                    <div class="input-group-append">
                        <button class="btn btn-secondary calendar" (click)="validUntilDate.toggle()" type="button">
                            <fa-icon [icon]="['far', 'calendar']"></fa-icon>
                        </button>
                    </div>
                </div>
                <div class="invalid-feedback">Select a Validity</div>
            </div>
        </div>
        <div class="form-group">
            <label>Charges</label>
            <qwyk-quotation-offer-lines [formGroup]="offerForm" [quotation]="selectedQuotation$ | async"
                [ngClass]="{'is-invalid': offerForm.get('offer_lines').invalid && offerForm.get('offer_lines').touched}">
            </qwyk-quotation-offer-lines>
            <div class="invalid-feedback">Add at least 1 charge</div>
        </div>
        <div class="form-group">
            <label>Remarks</label>
            <textarea formControlName="remarks" class="form-control" rows="4" maxlength="500"
                [ngClass]="{'is-invalid': offerForm.get('header.remarks').invalid && offerForm.get('header.remarks').touched}"></textarea>
            <div class="invalid-feedback">Remarks can be at most 500 characters</div>
        </div>
    </div>
    <div class="form-group">
        <button class="btn btn-primary" type="submit" [disabled]="offerForm.disabled">Save</button>
        <button class="btn btn-light ml-1" type="button" [routerLink]="['../']"
            [disabled]="offerForm.disabled">Cancel</button>
    </div>
</form>
<pre>{{ offerForm.value | json }}</pre>
