<div [ngClass]="errorWrapperClass">
    <ngb-alert *ngIf="error" type="danger">
        {{ 'common.generic-error-alt' | translate }}
    </ngb-alert>
</div>

<ul [ngClass]="class" class="list-group">
    <li *ngFor="let notification of notifications"
        [actionButtonClass]="actionButtonClass"
        [ngClass]="itemClass"
        [notification]="notification"
        class="list-group-item list-group-item-action p-4"
        qwyk-notifications-list-item>
    </li>
    <li *ngIf="!loading && (!notifications || !notifications.length)"
        class="list-group-item p-4 text-center no-hover text-muted">
        <fa-icon
            [icon]="['fas', 'sun']"
            class="sun-icon"
            size="2x"
            style="color: gold;">
        </fa-icon>
        <p class="m-0 mt-2">{{ 'hub.home.inbox-zero' | translate }}</p>
    </li>
    <li *ngIf="loading" class="list-group-item p-4 text-center no-hover text-muted">
        <fa-icon
            [icon]="['far', 'radar']"
            [pulse]="true"
            size="lg"
        ></fa-icon>
        <p class="m-0">{{ 'common.your-transactions-are-being-loaded' | translate : { value: 'hub.menu.top-menu.notifications' | translate } }}</p>
    </li>
</ul>
<div class="small text-muted text-center">{{ 'hub.home.notifications-will-be-kept-for-30-days' | translate }}</div>
