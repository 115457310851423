<div class="m-3 mb-5">
    <div>
        <div class="flex-grow-1">
            <div class="d-flex align-items-center justify-content-between">
                <div>
                    <h6 class="m-0">{{ 'hub.quotations.details.offer.offer-validity' | translate }}</h6>
                    <p>
                        <span>
                            {{
                                quotationOffer.valid_from
                                ? (quotationOffer.valid_from
                                    | amDateFormat: 'D MMMM YYYY')
                                : ('common.item_not_provided' | translate : { field: 'common.date' })
                            }}
                        </span>
                            -
                        <span>
                            {{
                                quotationOffer.valid_until
                                ? (quotationOffer.valid_until
                                    | amDateFormat: 'D MMMM YYYY')
                                : ('common.item_not_provided' | translate : { field: 'common.date' })
                            }}
                        </span>
                    </p>
                </div>
                <div>
                    <h6 class="m-0">{{ 'hub.quotations.create.fields.commodity' | translate }}</h6>
                    <p>
                        <span>
                            {{ quotationOffer.commodity || ('common.undefined' | translate) }}
                        </span>
                    </p>
                </div>

                <div>
                    <h6 class="m-0">{{ 'hub.quotations.details.offer.columns.service' | translate }}</h6>
                    <p>
                        <span>
                            {{ quotationOffer.service || ('common.undefined' | translate) }}
                        </span>
                    </p>
                </div>

                <div>
                    <div class="d-flex justify-content-between">
                        <div *ngIf="quotationOffer.service_level" class="mr-5">
                            <h6 class="m-0">{{ 'hub.quotations.details.offer.columns.service-level' | translate }}</h6>
                            <span >
                                {{ quotationOffer.service_level  }}
                            </span>
                        </div>
                        <div>
                            <h6 class="m-0">{{ 'hub.quotations.details.offer.columns.service' | translate }}</h6>
                            <span>
                                {{ quotationOffer.service || ('common.undefined' | translate) }}
                            </span>
                        </div>
                    </div>
                </div>

                <div *ngIf="quotationOffer.pol_via">
                    <h6 class="m-0">{{ 'hub.quotations.details.offer.columns.origin-via' | translate }}</h6>
                    <p>
                        <span>
                            {{ quotationOffer.pol_via?.display_name || ('common.undefined' | translate) }}
                        </span>
                    </p>
                </div>

                <div *ngIf="quotationOffer.pod_via">
                    <h6 class="m-0">{{ 'hub.quotations.details.offer.columns.destination-via' | translate }}</h6>
                    <p>
                        <span>
                            {{ quotationOffer.pod_via?.display_name || ('common.none' | translate) }}
                        </span>
                    </p>
                </div>
            </div>
        </div>

        <hr class="pb-3">

        <p class="custom-control custom-switch text-right">
            <input
                [(ngModel)]="expand"
                [attr.id]="detailSwitchId"
                class="custom-control-input"
                name="totalsDetailed"
                type="checkbox"
            />
            <label [attr.for]="detailSwitchId" class="custom-control-label">
                {{ 'hub.quotations.details.offer.columns.detailed-view' | translate }}
            </label>
        </p>
    </div>

    <ng-container *ngIf="quotationOffer.offer_lines; let offer_lines">
        <p-table [value]="offer_lines">
            <ng-template pTemplate="header">
                <tr>
                    <th>{{ 'hub.quotations.details.offer.columns.segment' | translate }}</th>
                    <th>{{ 'hub.quotations.details.offer.columns.freight' | translate }}</th>
                    <th style="width: 160px">{{ 'hub.quotations.details.offer.columns.calculation' | translate }}</th>
                    <th class="text-right">{{ 'hub.quotations.details.offer.columns.rate' | translate }}</th>
                    <th class="text-right">{{ 'portal.invoices.index-page.index.detail.summary.total-amount' | translate }}</th>
                    <th style="width: 150px">{{ 'hub.quotations.create.fields.currency' | translate }}</th>
                    <th class="text-right">{{ 'hub.quotations.details.offer.columns.total-amount-converted' | translate }}</th>
                </tr>
            </ng-template>

            <ng-template let-offer_line pTemplate="body">
                <tr>
                    <td class="py-3">{{ offer_line.segment | titlecase }}</td>
                    <td class="py-3">{{ offer_line.charge_description }}</td>
                    <td [attr.rowspan]="expand ? 2 : 1" class="py-3">
                        {{ offer_line.base_calculation.name }}
                    </td>
                    <td [attr.rowspan]="expand ? 2 : 1" class="py-3 text-right">
                        <div *ngIf="expand">
                            <div>
                                <small>{{ 'hub.quotations.details.offer.columns.base-buying-rate' | translate }}</small>
                                {{
                                    offer_line.base_buying_rate | currency: offer_line.base_currency
                                }}
                            </div>
                            <div class="text-left sum-sign-vertical">+</div>
                            <div>
                                <small>{{ 'hub.quotations.details.offer.columns.base-markup-rate' | translate }}</small>
                                {{
                                offer_line.base_markup_rate
                                    | currency: offer_line.base_currency
                                }}
                            </div>
                            <hr/>
                        </div>
                        <div>
                            {{
                            offer_line.base_rate
                                | currency: offer_line.base_currency
                            }}
                        </div>
                    </td>
                    <td [attr.rowspan]="expand ? 2 : 1" class="py-3 text-right">
                        <div *ngIf="expand">
                            <div>
                                <small>{{ 'hub.quotations.details.offer.columns.total-buying-amount' | translate }}</small>
                                {{ offer_line.total_buying_amount | currency: offer_line.base_currency }}
                            </div>
                            <div class="text-left sum-sign-vertical">+</div>
                            <div>
                                <small>{{ 'hub.quotations.details.offer.columns.total-margin-amount' | translate}}</small>
                                {{ offer_line.total_margin_amount | currency: offer_line.base_currency }}
                            </div>
                            <hr/>
                        </div>
                        <div>
                            {{ offer_line.total_amount | currency: offer_line.base_currency }}
                        </div>
                    </td>
                    <td [attr.rowspan]="expand ? 2 : 1" class="py-3">
                        <div class="text-left">
                            {{ offer_line.base_currency }}
                            <span
                                *ngIf="
                                offer_line.base_currency !==
                                quotationOffer.currency
                                "
                                class="text-right"
                            >
                                = {{ quotationOffer.currency }}
                                {{ offer_line.exchange_rate }}</span
                            >
                        </div>
                    </td>
                    <td [attr.rowspan]="expand ? 2 : 1" class="py-3 text-right">
                        <div *ngIf="expand">
                            <div>
                                <small>{{ 'hub.quotations.details.offer.columns.quotation-amount' | translate }}</small>
                                {{ offer_line.total_buying_amount_quotation | currency: quotationOffer.currency }}
                            </div>
                            <div class="text-left sum-sign-vertical">+</div>
                            <div>
                                <small>{{ 'hub.quotations.details.offer.columns.quotation-margin' | translate }}</small>
                                {{ offer_line.total_margin_amount_quotation | currency: quotationOffer.currency }}
                            </div>
                            <hr/>
                        </div>
                        <div>
                            {{ offer_line.total_amount_quotation | currency: quotationOffer.currency }}
                        </div>
                    </td>
                </tr>

                <tr *ngIf="expand">
                    <td colspan="2">
                        {{ 'hub.common.remarks' | translate }}:
                        {{
                            offer_line.remarks
                            ? offer_line.remarks
                            : ('common.no_item' | translate : { value: 'hub.common.remarks' | translate | lowercase })
                        }}
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </ng-container>

    <div *ngIf="quotationOffer.rate_source_name">
        <span class="mr-1">{{ 'hub.quotations.details.offer.columns.rate-source' | translate }}: </span>
        <span class="badge badge-warning rounded-pill">
            {{ quotationOffer.rate_source_name }}
        </span>
    </div>

    <div class="mt-3">
        <h6 class="m-0">{{ 'hub.common.remarks' | translate }}</h6>
        <p
            *ngIf="quotationOffer.remarks as remarks; else noRemarks"
            [innerHTML]="remarks | lineBreaks"
            class="text-left"
        ></p>
        <ng-template #noRemarks>
            <p>{{ 'hub.quotations.details.offer.no-remarks' | translate }}</p>
        </ng-template>
    </div>
</div>
