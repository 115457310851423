<div class="card shadow-sm">
    <div class="card-body">
        <div class="d-flex align-items-center">
            <h5 class="m-0">{{ 'hub.quotations.details.offer.copy-offer' | translate }}</h5>
        </div>
        <ngb-alert
            (closed)="onDismissError()"
            *ngIf="error$ | async; let error"
            class="mt-3 mb-0"
            type="danger"
        >
            {{ 'common.generic-error-alt' | translate }}
            <div
                class="font-weight-bold"
                *ngIf="error?.status === 403 && error?.error?.message"
            >
                {{ error?.error?.message }}
            </div>
        </ngb-alert>
    </div>

    <div class="card-body">
        <qwyk-quotations-offer-form
            [quotationOffer]="selectedQuotationOffer$ | async"
            (save)="onCreate($event)"
            [disabled]="loading$ | async"
            [openRateRequests]="openRateRequests$ | async"
        ></qwyk-quotations-offer-form>
    </div>
</div>
