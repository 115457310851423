import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    QUOTATIONOFFERS_FEATURE_KEY,
    State,
    QuotationOffersPartialState,
    quotationOffersAdapter,
} from './quotation-offers.reducer';

// Lookup the 'QuotationOffers' feature state managed by NgRx
export const getQuotationOffersState = createFeatureSelector<
    QuotationOffersPartialState,
    State
>(QUOTATIONOFFERS_FEATURE_KEY);

const { selectAll, selectEntities } = quotationOffersAdapter.getSelectors();

export const getQuotationOffersLoading = createSelector(
    getQuotationOffersState,
    (state: State) => state.loading
);

export const getQuotationOffersError = createSelector(
    getQuotationOffersState,
    (state: State) => state.error
);

export const getQuotationOffersPagination = createSelector(
    getQuotationOffersState,
    (state: State) => state.pagination
);

export const getAllQuotationOffers = createSelector(
    getQuotationOffersState,
    (state: State) => selectAll(state)
);

export const getQuotationOffersEntities = createSelector(
    getQuotationOffersState,
    (state: State) => selectEntities(state)
);

export const getSelectedId = createSelector(
    getQuotationOffersState,
    (state: State) => state.selectedId
);

export const getSelected = createSelector(
    getQuotationOffersEntities,
    getSelectedId,
    (entities, selectedId) => selectedId && entities[selectedId]
);
