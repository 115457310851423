import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { EntityExistsGuard } from '@qwyk/core';
import { Observable, of } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { UsersService } from '../services/users.service';
import * as UsersActions from '../+state/users.actions';
import * as fromUsers from '../+state/users.reducer';
import * as usersSelectors from '../+state/users.selectors';

@Injectable({
    providedIn: 'root',
})
export class UserExistsGuard extends EntityExistsGuard<number> {
    constructor(
        private store: Store<fromUsers.UsersPartialState>,
        private service: UsersService,
        private router: Router
    ) {
        super();
    }

    protected getEntityKey(next: ActivatedRouteSnapshot): number {
        return +next.params['id'];
    }

    protected hasEntityInStore(id: number): Observable<boolean> {
        return this.store.pipe(
            select(usersSelectors.getUsersEntities),
            map(entities => !!entities[id]),
            take(1)
        );
    }

    protected hasEntityInApi(
        id: number,
        next: ActivatedRouteSnapshot
    ): Observable<boolean> {
        return this.service.getHubUser(id).pipe(
            map(user => UsersActions.loadUserSuccess({ user })),
            tap(action => this.store.dispatch(action)),
            map(user => !!user),
            catchError(error => {
                this.router.navigate([`/error/${error.status}`], {
                    queryParams: {
                        source: next.pathFromRoot.map(p => p.url).join('/'),
                        errorName: error.statusText,
                    },
                });
                return of(false);
            })
        );
    }
}
