import { FormBuilder } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

import { concat, Observable, of, Subject } from 'rxjs';
import { LazyLoadEvent, SelectItem } from 'primeng/api';
import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    map,
    switchMap,
    tap,
} from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

import { Hub } from '@qwyk/models';
import { LocalStorageService } from '@qwyk/core';
import { AuthenticationFacade } from '@qwyk/hub/authentication';

import { CustomerUsersFacade } from '../../+state/customer-users.facade';
import { CustomerUsersService } from '../../services/customer-users.service';
import { CustomerUserImpersonateModalComponent } from '../customer-user-impersonate-modal/customer-user-impersonate-modal.component';

@Component({
    selector: 'qwyk-customer-users-list',
    templateUrl: './customer-users-list.component.html',
    styleUrls: ['./customer-users-list.component.scss'],
})
export class CustomerUsersListComponent implements OnInit {
    public loading$ = this.customerUsers.loading$;
    public customerUsers$ = this.customerUsers.allCustomerUsers$;
    public pagination$ = this.customerUsers.pagination$;
    public isMagayaNetwork$ = this.authentication.user$.pipe(
        map(u => u && u.is_organization_magaya_network)
    );
    public isTableView = true;
    pageSize = 25;

    public searchForm = this.fb.group({
        name: [null],
        email: [null],
        team_id: [null],
        tag: [null],
        sort: ['name'],
        sort_dir: ['asc'],
    });
    public columns: SelectItem[] = [
        {
            label: 'common.name',
            value: {
                field: 'name',
                header: 'common.name',
                default: true,
                disabled: true,
                filter: true,
                sort: true,
                sort_key: 'email',
            },
            disabled: true,
        },
        {
            label: 'common.email',
            value: {
                field: 'email',
                header: 'common.email',
                default: true,
                filter: true,
                sort: true,
                sort_key: 'email',
            },
        },
        {
            label: 'portal.registration.page1.label-username',
            value: {
                field: 'username',
                header: 'portal.registration.page1.label-username',
                default: true,
                disabled: true,
                filter: true,
                sort: false,
                sort_key: 'username',
            },
            disabled: false,
        },
        {
            label: 'common.customer',
            value: {
                field: 'team_name',
                header: 'common.customer',
                default: true,
                filter: true,
                sort: true,
                sort_key: 'customer_teams.name',
            },
        },
        {
            label: 'portal.shipment.search.placeholder.company',
            value: {
                field: 'company_name',
                header: 'portal.shipment.search.placeholder.company',
                default: false,
                filter: false,
                sort: true,
                sort_key: 'company_name',
            },
        },
        {
            label: 'portal.settings.profile.job-title',
            value: {
                field: 'job_title',
                header: 'portal.settings.profile.job-title',
                default: false,
                filter: false,
                sort: true,
                sort_key: 'job_title',
            },
        },
        {
            label: 'portal.cargo-items.columns.location',
            value: {
                field: 'location',
                header: 'portal.cargo-items.columns.location',
                default: false,
                filter: false,
                sort: true,
                sort_key: 'location',
            },
        },
        {
            label: 'common.country',
            value: {
                field: 'country.short_name',
                header: 'common.country',
                default: false,
                filter: false,
                sort: false,
                sort_key: null,
                nested: ['country', 'short_name'],
            },
        },
        {
            label: 'hub.shipment.sections.instructions.subsections.parties-fields.phone-number',
            value: {
                field: 'phone_number',
                header: 'hub.shipment.sections.instructions.subsections.parties-fields.phone-number',
                default: false,
                filter: false,
                sort: true,
                sort_key: 'phone_number',
            },
        },
        {
            label: 'portal.invoices.columns.created-at',
            value: {
                field: 'created_at',
                header: 'portal.invoices.columns.created-at',
                default: false,
                filter: false,
                sort: true,
                sort_key: 'created_at',
            },
        },
        {
            label: 'hub.administration.portal-users.last-login-at',
            value: {
                field: 'last_login_at',
                header: 'hub.administration.portal-users.last-login-at',
                default: false,
                filter: false,
                sort: true,
                sort_key: 'last_login_at',
            },
        },
        {
            label: 'hub.administration.portal-users.approved-by',
            value: {
                field: 'approved_by',
                header: 'hub.administration.portal-users.approved-by',
                default: false,
                filter: false,
                sort: false,
                sort_key: 'last_login_at',
            },
        },
        {
            label: 'hub.administration.portal-users.marketing-mails',
            value: {
                field: 'marketing_optin',
                header: 'hub.administration.portal-users.marketing-mails',
                default: false,
                filter: false,
                sort: false,
                sort_key: 'last_login_at',
            },
        },
        {
            label: 'common.active',
            value: {
                field: 'activated',
                header: 'common.active',
                default: true,
                filter: true,
                sort: true,
                sort_key: 'activated',
            },
        },
    ];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public customers$: Observable<any[]>;
    public customersLoading = false;
    public customerInput$ = new Subject<string>();

    constructor(
        private fb: FormBuilder,
        private modal: NgbModal,
        private translate: TranslateService,
        private service: CustomerUsersService,
        private localStorage: LocalStorageService,
        private customerUsers: CustomerUsersFacade,
        private authentication: AuthenticationFacade
    ) {}

    private _selectedColumns = [];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public get selectedColumns(): any[] {
        return this._selectedColumns;
    }

    public set selectedColumns(value) {
        this._selectedColumns = value;
        this.saveTableCols();
    }

    ngOnInit(): void {
        this.translateColumns();
        this.restoreTableCols();
        this.loadCustomers();
    }

    public loadCustomerUsersLazy(event: LazyLoadEvent) {
        setTimeout(() => {
            let page = 1;

            this.pageSize =
                !event.rows || event.rows < 25 ? this.pageSize : event.rows;

            if (event.first && event.rows) {
                page = event.first / event.rows + 1;
            }
            if (event.sortField) {
                this.searchForm.patchValue({
                    sort: event.sortField || 'name',
                    sort_dir: event.sortOrder === 1 ? 'asc' : 'desc',
                });
            }

            this.search(page);
        }, 50);
    }

    public search(page = 1) {
        const searchValue = this.searchForm.value;
        Object.keys(searchValue).forEach(key => {
            if (searchValue[key] === null) {
                delete searchValue[key];
            }
        });
        const query = {
            ...searchValue,
            pageSize: this.pageSize,
            page,
        };

        this.customerUsers.loadCustomerUsers(query);
    }

    public resetSearch() {
        this.searchForm.reset();
        this.search(1);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public onTableColReorder(e): void {
        this.saveTableCols();
    }

    onImpersonateUser(user: Hub.CustomerUser) {
        const modalRef = this.modal.open(
            CustomerUserImpersonateModalComponent,
            {
                size: 'md',
                centered: true,
            }
        );

        const component =
            modalRef.componentInstance as CustomerUserImpersonateModalComponent;
        component.user = user;
    }

    private loadCustomers() {
        this.customers$ = concat(
            this.service.getTeams({}),
            this.customerInput$.pipe(
                debounceTime(200),
                distinctUntilChanged(),
                tap(() => (this.customersLoading = true)),
                switchMap(term =>
                    this.service.getTeams({ pageSize: 25, name: term }).pipe(
                        catchError(() => of([])),
                        tap(() => (this.customersLoading = false))
                    )
                )
            )
        );
    }

    private translateColumns(): void {
        this.columns.forEach(col => {
            col.label = this.translate.instant(col.label);
            col.value.header = this.translate.instant(col.value.header);
        });
    }

    /**
     * Saves table column layout to localstorage.
     * @private
     */
    private saveTableCols(): void {
        this.localStorage.setObject(
            'administration.portal-users.table-layout.v3',
            this._selectedColumns
        );
    }

    /**
     * Restores table column layout from localstorage.
     * @private
     */
    private restoreTableCols(): void {
        let columns = null;

        try {
            columns = this.localStorage.getObject(
                'administration.portal-users.table-layout.v3'
            );
        } catch {
            /* empty */
        }

        if (!columns) {
            columns = this.columns
                .filter(e => e.value.default)
                .map(e => e.value);
        }
        this._selectedColumns = columns;
    }
}
