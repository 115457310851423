import { ProvidersService } from './../../services/providers.service';
import {
    catchError,
    map,
    mergeMap,
    switchMap,
    first,
    tap,
} from 'rxjs/operators';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import * as routingGuideActions from './../actions/routing-guide.actions';
import { Injectable } from '@angular/core';
import { of, forkJoin } from 'rxjs';
import { RoutingGuideService } from '../../services/routing-guide.service';
import { ToastrService } from 'ngx-toastr';
import { AppState } from './../../../../store/app.state';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class RoutingGuideEffects {
    loadRoutingGuide$ = createEffect(() =>
        this.actions$.pipe(
            ofType(routingGuideActions.loadRoutingGuide),
            switchMap(action => {
                const routingGuideRequest = this.service
                    .getRoutingGuide(action.product)
                    .pipe(first());
                const providersRequest = this.providersService
                    .getActiveProviders(action.product)
                    .pipe(first());

                return forkJoin({
                    routingGuide: routingGuideRequest,
                    providers: providersRequest,
                }).pipe(
                    map(res =>
                        routingGuideActions.loadRoutingGuideSuccess({
                            routingGuide: res.routingGuide,
                            providers: res.providers,
                            pageSize: action.pageSize,
                        })
                    ),
                    catchError(() =>
                        of(routingGuideActions.loadRoutingGuideFailure())
                    )
                );
            })
        )
    );

    loadRoutingGuideSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(routingGuideActions.loadRoutingGuideSuccess),
            switchMap(action => {
                return of(
                    routingGuideActions.loadRoutingGuideItems({
                        routingGuideId: action.routingGuide.id,
                        pageSize: action.pageSize,
                    })
                );
            })
        )
    );

    loadRoutingGuideFailure = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    routingGuideActions.loadRoutingGuideFailure,
                    routingGuideActions.loadRoutingGuideItemsFailure
                ),
                tap(() => {
                    this.toastrService.error(
                        this.translate.instant('common.try-again-and-refresh'),
                        this.translate.instant(
                            'hub.schedules.routing-guide.error-while-loading-routing-guide'
                        )
                    );
                })
            ),
        { dispatch: false }
    );

    loadRoutingGuideItems$ = createEffect(() =>
        this.actions$.pipe(
            ofType(routingGuideActions.loadRoutingGuideItems),
            mergeMap(action =>
                this.service
                    .getRoutingGuideItems(
                        action.routingGuideId,
                        action.page,
                        action.pageSize
                    )
                    .pipe(
                        map(items =>
                            routingGuideActions.loadRoutingGuideItemsSuccess({
                                items,
                            })
                        ),
                        catchError(() =>
                            of(
                                routingGuideActions.loadRoutingGuideItemsFailure()
                            )
                        )
                    )
            )
        )
    );

    createRoutingGuideItem$ = createEffect(() =>
        this.actions$.pipe(
            ofType(routingGuideActions.createRoutingGuideItem),
            mergeMap(action =>
                this.service
                    .createRoutingGuideItem(
                        action.routingGuideId,
                        action.routingGuideItem
                    )
                    .pipe(
                        map(createdRoutingGuideItem =>
                            routingGuideActions.createRoutingGuideItemSuccess({
                                createdRoutingGuideItem,
                            })
                        ),
                        catchError(error =>
                            of(
                                routingGuideActions.createRoutingGuideItemFailed(
                                    { error }
                                )
                            )
                        )
                    )
            )
        )
    );

    updateRoutingGuideItem$ = createEffect(() =>
        this.actions$.pipe(
            ofType(routingGuideActions.updateRoutingGuideItem),
            mergeMap(action =>
                this.service
                    .updateRoutingGuideItem(action.routingGuideItem)
                    .pipe(
                        map(updatedRoutingGuideItem =>
                            routingGuideActions.updateRoutingGuideItemSuccess({
                                updatedRoutingGuideItem,
                            })
                        ),
                        catchError(error =>
                            of(
                                routingGuideActions.updateRoutingGuideItemFailure(
                                    {
                                        error,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );

    deleteRoutingGuideItem$ = createEffect(() =>
        this.actions$.pipe(
            ofType(routingGuideActions.deleteRoutingGuideItem),
            mergeMap(action => {
                this.store$.dispatch(
                    routingGuideActions.deleteRoutingGuideItemStarted({
                        routingGuideItem: action.routingGuideItem,
                    })
                );

                return this.service
                    .deleteRoutingGuideItem(action.routingGuideItem)
                    .pipe(
                        map(() =>
                            routingGuideActions.deleteRoutingGuideItemSuccess()
                        ),
                        catchError(() =>
                            of(
                                routingGuideActions.deleteRoutingGuideItemFailure()
                            )
                        )
                    );
            })
        )
    );

    constructor(
        private actions$: Actions,
        private store$: Store<AppState>,
        private translate: TranslateService,
        private service: RoutingGuideService,
        private toastrService: ToastrService,
        private providersService: ProvidersService
    ) {}
}
