import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import * as NotificationsActions from './notifications.actions';
import { NotificationsEntity } from './notifications.models';
import { common } from '@qwyk/models';

export const NOTIFICATIONS_FEATURE_KEY = 'notifications';

export interface State extends EntityState<NotificationsEntity> {
    selectedId?: string | number; // which Notifications record has been selected
    loading: boolean; // has the Notifications list been loaded
    error?: string | null; // last known error (if any)
    pagination?: common.PaginationMeta;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    query: any;
}

export interface NotificationsPartialState {
    readonly [NOTIFICATIONS_FEATURE_KEY]: State;
}

export const notificationsAdapter: EntityAdapter<NotificationsEntity> =
    createEntityAdapter<NotificationsEntity>();

export const initialState: State = notificationsAdapter.getInitialState({
    // set initial required properties
    loading: false,
    query: {},
});

const notificationsReducer = createReducer(
    initialState,
    on(NotificationsActions.loadNotifications, (state, { query }) => ({
        ...state,
        loading: true,
        error: null,
        query: {
            ...state.query,
            ...query,
        },
    })),
    on(
        NotificationsActions.loadNotificationsSuccess,
        (state, { notifications, pagination }) =>
            notificationsAdapter.setAll(notifications, {
                ...state,
                loading: false,
                pagination,
            })
    ),
    on(NotificationsActions.loadNotificationsFailure, (state, { error }) => ({
        ...state,
        error,
        loading: false,
    }))
);

export function reducer(state: State | undefined, action: Action) {
    return notificationsReducer(state, action);
}
