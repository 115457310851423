import { Component } from '@angular/core';

@Component({
    selector: 'app-trial-expired',
    templateUrl: './trial-expired.component.html',
    styleUrls: ['./trial-expired.component.css'],
})
export class TrialExpiredComponent {
    constructor() {}
}
