import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { environment } from '@qwyk/hub/environment';

@Injectable({
    providedIn: 'root',
})
export class TasksService {
    /**
     * Gets all the routing guide upload jobs from the server
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getImportJobs(): Observable<any> {
        return this.http.get(
            `${environment.schedulesBackend}/api/tasks/import-jobs`
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getImportJob(id: number): Observable<any> {
        return this.http.get(
            `${environment.schedulesBackend}/api/tasks/import-jobs/${id}`
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getImportJobErrors(id: number, page = 1): Observable<any> {
        return this.http.get(
            `${environment.schedulesBackend}/api/tasks/import-jobs/${id}/errors?page=${page}`
        );
    }

    getImportJobFileLink(
        id: number
    ): Observable<{ url: string; valid_until: Date }> {
        return this.http
            .get<{ url: string; valid_until: Date }>(
                `${environment.schedulesBackend}/api/tasks/import-jobs/${id}/download`
            )
            .pipe(take(1));
    }

    /**
     * Gets all the export jobs from the server
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getExportJobs(): Observable<any> {
        return this.http.get(
            `${environment.schedulesBackend}/api/tasks/export-jobs`
        );
    }

    /**
     * Gets export job download link
     * @param id the id of the job to get a link for
     * @returns observable containing http response
     */
    getExportJobDownloadLink(
        id: number
    ): Observable<{ url: string; valid_until: Date }> {
        return this.http
            .get<{ url: string; valid_until: Date }>(
                `${environment.schedulesBackend}/api/tasks/export-jobs/${id}/download`
            )
            .pipe(take(1));
    }

    constructor(private http: HttpClient) {}
}
