import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'objectToArray',
})
export class ObjectToArrayPipe implements PipeTransform {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    transform(value: any, flat: boolean = false): any {
        if (flat) {
            return Object.keys(value).flatMap(e => value[e]);
        }
        return Object.keys(value).map(e => value[e]);
    }
}
