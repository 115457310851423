import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MomentModule } from 'ngx-moment';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { MultiSelectModule } from 'primeng/multiselect';
import { AutoCompleteModule } from 'primeng/autocomplete';

import { UiModule } from '@qwyk/ui';
import {
    LineBreaksPipe,
    UnitConvertPipe,
    AddressFormComponent,
    BreadcrumbsComponent,
    UserInfoTooltipComponent,
    LoadingIndicatorComponent,
} from '@qwyk/ui-standalone';
import { HubActivitiesModule } from '@qwyk/hub/activities';
import {
    QuotationsServiceBase,
    SharedStoresQuotationsModule,
} from '@qwyk/shared-stores/quotations';
import { HubSettingsUsersWithoutRoutingModule } from '@qwyk/hub/settings/users';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { HubQuotationsOffersWithoutRoutingModule } from '@qwyk/hub/quotations-offers';

import {
    QuotationCreateComponent,
    QuotationDetailComponent,
    QuotationDetailHeaderComponent,
    QuotationListWidgetComponent,
    QuotationNetworkInfoComponent,
    QuotationNetworkRateRequestComponent,
    QuotationOfferDetailComponent,
    QuotationOfferLinesComponent,
    QuotationOffersListComponent,
    QuotationRateRequestsListComponent,
    QuotationRequestDetailsComponent,
    QuotationRequestHistoryComponent,
    QuotationsListComponent,
    QuotationStatusBadgeComponent,
    QuotationViewCargoComponent,
    QuotationViewCargoLineComponent,
} from './components';
import { QuotationDetailPageComponent } from './pages';
import { QuotationsService } from './services/quotations.service';
import { QuotationListSearchFormComponent } from './components/quotation-list-search-form/quotation-list-search-form.component';

export function quotationsServiceFactory(
    http: HttpClient
): QuotationsServiceBase {
    return new QuotationsService(http);
}

@NgModule({
    imports: [
        CommonModule,
        UiModule,
        NgbModule,
        FormsModule,
        TableModule,
        RouterModule,
        MomentModule,
        DropdownModule,
        NgSelectModule,
        LineBreaksPipe,
        CalendarModule,
        InputTextModule,
        TranslateModule,
        UnitConvertPipe,
        FontAwesomeModule,
        InputNumberModule,
        MultiSelectModule,
        AutoCompleteModule,
        ReactiveFormsModule,
        HubActivitiesModule,
        BreadcrumbsComponent,
        AddressFormComponent,
        UserInfoTooltipComponent,
        LoadingIndicatorComponent,
        HubSettingsUsersWithoutRoutingModule,
        HubQuotationsOffersWithoutRoutingModule,
        SharedStoresQuotationsModule.forFeature({
            service: {
                provide: QuotationsServiceBase,
                useFactory: quotationsServiceFactory,
                deps: [HttpClient],
            },
        }),
    ],
    declarations: [
        QuotationsListComponent,
        QuotationOffersListComponent,
        QuotationRateRequestsListComponent,
        QuotationOfferDetailComponent,
        QuotationDetailComponent,
        QuotationOfferLinesComponent,
        QuotationCreateComponent,
        QuotationListWidgetComponent,
        QuotationStatusBadgeComponent,
        QuotationNetworkInfoComponent,
        QuotationNetworkRateRequestComponent,
        QuotationListSearchFormComponent,
        QuotationViewCargoComponent,
        QuotationViewCargoLineComponent,
        QuotationDetailPageComponent,
        QuotationDetailHeaderComponent,
        QuotationRequestDetailsComponent,
        QuotationRequestHistoryComponent,
    ],
    exports: [QuotationListWidgetComponent],
})
export class HubQuotationsWithoutRoutingModule {}
