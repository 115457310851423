<ng-container *ngIf="product$ | async; let product; else: selectProduct">
    <div [@fadeIn] class="p-3">
        <div class="head d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
                <button class="btn btn-sm btn-light" (click)="product$.next(null)">
                    <fa-icon [icon]="['far', 'arrow-alt-left']"></fa-icon>
                </button>
                <fa-icon [icon]="['fad', productIcons[product]]" size="2x" class="mx-3"
                    [ngClass]="'text-' + productColors[product]"></fa-icon>
                <div>
                    <span class="lead">
                        {{ 'hub.schedules.routing-guide.titles.' + product | lowercase | translate }} <span *ngIf="product === 'lcl'"
                            class="badge badge-danger">{{ 'common.deprecated' | translate }}</span></span>
                    <p class="text-muted mb-0">
                        {{ 'hub.schedules.routing-guide.setup-routes' | translate }}
                    </p>
                </div>
            </div>

            <div>
                <div class="btn-group" role="group" aria-label="Basic example">
                    <button class="btn" [ngClass]="'btn-' + productColors[product]" (click)="addRoutingGuideItem()">
                        {{ 'hub.schedules.routing-guide.add-new-route' | translate }}
                    </button>
                    <div ngbDropdown class="btn-group" role="group">
                        <button class="btn" [ngClass]="'btn-outline-' + productColors[product]" id="dropdownBasic1"
                            ngbDropdownToggle>
                            <fa-icon [icon]="['far', 'tools']" fixedWidth="true"
                                [ngClass]="{ 'text-dark': product === 'lcl' }">
                            </fa-icon>
                        </button>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                            <ul class="list-group list-group-flush" style="min-width: 200px;">
                                <button class="list-group-item list-group-item-action"
                                    (click)="openDownloadRoutingGuideModal()">
                                    <fa-icon [icon]="['far', 'file-download']" size="lg" fixedWidth="true"></fa-icon>
                                    {{ 'common.download-to-excel' | translate }}
                                </button>
                                <button class="list-group-item list-group-item-action"
                                    (click)="openUploadRoutingGuideModal()">
                                    <fa-icon [icon]="['far', 'file-upload']" size="lg" fixedWidth="true"></fa-icon>
                                    {{ 'common.upload-from-excel' | translate }}
                                </button>
                                <button class="list-group-item list-group-item-action"
                                    [routerLink]="['/schedules', 'tasks']" [queryParams]="{tab: 'upload'}">
                                    <fa-icon [icon]="['far', 'archive']" size="lg" fixedWidth="true"></fa-icon>
                                    {{ 'hub.schedules.routing-guide.view-upload-logs' | translate }}
                                </button>
                                <button class="list-group-item list-group-item-action" (click)="openSimulationModal()">
                                    <fa-icon [icon]="['far', 'tachometer-alt-fast']" size="lg" fixedWidth="true">
                                    </fa-icon>
                                    {{ 'hub.schedules.routing-guide.simulate' | translate }}...
                                </button>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-5">
            <p-table
                [value]="routingGuideItems"
                dataKey="_id"
                editMode="row"
                class="d-block mb-2"
                [rows]="pageSize"
                [rowTrackBy]="routingGuideTrackBy"
                [totalRecords]="totalItems"
                [loading]="isLoading"
                [rowHover]="true">

                <ng-template pTemplate="header">
                    <tr>
                        <!-- <th style="width: 3em"></th> -->
                        <th>{{'common.quotations.search.origin' | translate}}</th>
                        <th>{{ 'hub.quotations.create.fields.destination' | translate }}</th>
                        <th>{{ 'common.provider' | translate }}</th>
                        <th style="width: 100px"></th>
                    </tr>
                    <tr app-routing-guide-search (filterChanged)="onFilterChanged($event)" [providers]="providers"></tr>
                </ng-template>

                <ng-template pTemplate="body" let-rowData let-expanded="expanded" let-editing="editing"
                    let-ri="rowIndex">
                    <tr class="hoverable">
                        <td>
                            <div class="py-2" [ngClass]="{ strong: expanded }">
                                {{ rowData.origin.display_name }}
                            </div>
                        </td>
                        <td>
                            <div class="py-2" [ngClass]="{ strong: expanded }">
                                {{ rowData.destination.display_name }}
                            </div>
                        </td>
                        <td>
                            <div class="py-2" [ngClass]="{ strong: expanded }">
                                {{ rowData.providers[0].name }} <span *ngIf="rowData.providers.length > 1">{{ 'common.and' | translate }}
                                    {{ rowData.providers.length - 1 }} {{ 'common.more' | translate }}</span>
                            </div>
                        </td>
                        <td class="text-right">
                            <a href="javascript:void(0);" class="mr-2" (click)="updateRoutingGuideItem(rowData)">
                                <fa-icon [icon]="['far', 'edit']" fixedWidth="true"></fa-icon>
                            </a>

                            <a href="javascript:void(0);" class="mr-2" (click)="addRoutingGuideItem(rowData)">
                                <fa-icon [icon]="['far', 'copy']" fixedWidth="true"></fa-icon>
                            </a>

                            <ng-template #deletePopContent>
                                <p>{{ 'hub.schedules.routing-guide.once-delete-an-item-cannot-be-recovered' | translate }}</p>
                                <div>
                                    <button class="btn btn-danger btn-sm mr-1"
                                        (click)="deleteRoutingGuideItem(rowData)">{{ 'common.yes' | translate }}</button>
                                    <button class="btn btn-outline-danger btn-sm">{{ 'common.no' | translate }}</button>
                                </div>
                            </ng-template>

                            <ng-template #deletePopTitle><b>{{ 'hub.schedules.routing-guide.delete-confirmation-message' | translate }}</b></ng-template>
                            <a href="javascript:void(0);" class="mr-2 text-danger" [ngbPopover]="deletePopContent"
                                [popoverTitle]="deletePopTitle" popoverClass="popover-danger">
                                <fa-icon [icon]="['far', 'trash']" fixedWidth="true"></fa-icon>
                            </a>
                        </td>
                    </tr>
                </ng-template>

                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="4" class="text-center p-4">
                            <div *ngIf="!isLoading">
                                <div class="mb-2">{{ 'hub.schedules.routing-guide.no-tradelanes-found' | translate }}</div>
                                <button class="btn" [ngClass]="'btn-' + productColors[product]"
                                    (click)="addRoutingGuideItem()">{{ 'hub.schedules.routing-guide.add-a-new-route' | translate }}</button>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>

            <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="totalItems" [maxSize]="5" size="sm"
                [rotate]="true" class="d-flex justify-content-center" [disabled]="isLoading">
                <ng-template ngbPaginationPrevious>{{ 'common.previous' | translate }}</ng-template>
                <ng-template ngbPaginationNext>{{ 'common.next' | translate }}</ng-template>
            </ngb-pagination>
        </div>
    </div>
</ng-container>

<ng-template #selectProduct>
    <div [@fadeIn] class="p-3">
        <div class="head d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
                <fa-icon [icon]="['fad', 'project-diagram']" size="2x" class="mx-3 text-primary"></fa-icon>
                <div>
                    <span class="lead">{{ 'hub.schedules.routing-guide.title' | translate }}</span>
                    <p class="text-muted mb-0">
                        {{ 'hub.schedules.routing-guide.select-a-product-to-setup' | translate }}
                    </p>
                </div>
            </div>
        </div>

        <div class="row row-cols-4 mt-5">
            <div class="col">
                <div class="card h-100 shadow-sm">
                    <div class="card-body">
                        <div class="text-center">
                            <fa-icon [icon]="['fad', 'plane-alt']" size="3x" class="text-danger"></fa-icon>
                            <div class="lead my-3">{{ 'common.air' | translate }}</div>
                            <div class="text-muted">
                                {{ 'hub.schedules.routing-guide.build-a-pure-air-schedule' | translate }}
                            </div>
                        </div>
                    </div>
                    <div class="card-footer border-0 bg-white text-center">
                        <button class="btn btn-danger" (click)="product$.next('air')">
                            {{ 'common.continue' | translate }}
                        </button>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="card h-100 shadow-sm d-sm-none d-xl-flex">
                    <div class="card-body">
                        <div class="text-center">
                            <fa-icon [icon]="['fad', 'layer-group']" size="3x" class="text-success"></fa-icon>
                            <div class="lead my-3">{{ 'hub.schedules.routing-guide.console' | translate }}</div>
                            <div class="text-muted">
                                {{ 'hub.schedules.routing-guide.the-console-routing-guide' | translate }} <b>{{ 'hub.schedules.routing-guide.these-schedules-are-displayed' | translate }}</b>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer border-0 bg-white text-center">
                        <button class="btn btn-success" (click)="product$.next('csl')">
                            {{ 'common.continue' | translate }}
                        </button>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="card h-100 shadow-sm">
                    <div class="card-body">
                        <div class="text-center">
                            <fa-icon [icon]="['fad', 'ship']" size="3x" class="text-primary"></fa-icon>
                            <div class="lead my-3">FCL</div>
                            <p class="text-muted">
                                {{ 'hub.schedules.routing-guide.fcl-text' | translate }}
                            </p>
                        </div>
                    </div>
                    <div class="card-footer border-0 bg-white text-center">
                        <button class="btn btn-primary" (click)="product$.next('fcl')">
                            {{ 'common.continue' | translate }}
                        </button>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="card h-100 shadow-sm d-sm-none d-xl-flex">
                    <div class="card-body">
                        <div class="text-center">
                            <fa-icon [icon]="['fad', 'pallet-alt']" size="3x" class="text-muted"></fa-icon>
                            <div class="lead my-3">
                                <div>LCL</div>
                                <span class="badge badge-danger">{{ 'common.deprecated' | translate }}</span>
                            </div>
                            <div class="text-muted">
                                {{ 'hub.schedules.routing-guide.lcl-text' | translate }}
                            </div>
                        </div>
                    </div>
                    <div class="card-footer border-0 bg-white text-center">
                        <button class="btn" (click)="product$.next('lcl')">
                            {{ 'common.continue' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-4 text-center justify-content-center d-xl-none d-none d-sm-flex">
            <div class="card shadow-sm " style="width: 33%">
                <div class="card-body">
                    <div class="text-center">
                        <fa-icon [icon]="['fad', 'pallet-alt']" size="3x" class="text-muted"></fa-icon>
                        <div class="lead my-3">
                            <div>LCL</div>
                            <span class="badge badge-danger">{{ 'common.deprecated' | translate }}</span>
                        </div>
                        <div class="text-muted">
                            {{ 'hub.schedules.routing-guide.lcl-text' | translate }}
                        </div>
                    </div>
                </div>
                <div class="card-footer border-0 bg-white text-center">
                    <button class="btn" (click)="product$.next('lcl')">
                        {{ 'common.continue' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>
