/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Output, EventEmitter, Input } from '@angular/core';

import { Provider } from '../../../store/models/providers.models';
import { AlgoliaLocationsService } from '../../../../shared/services/algolia-locations.service';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: '[app-routing-guide-search]',
    templateUrl: './routing-guide-search.component.html',
    styleUrls: ['./routing-guide-search.component.scss'],
})
export class RoutingGuideSearchComponent {
    @Input() providers: Provider[];
    @Output() filterChanged: EventEmitter<{
        origin: any;
        destination: any;
        provider: any;
    } | null> = new EventEmitter();

    _originFilter: any = null;
    _destinationFilter: any = null;
    _providerFilter: any = null;
    searchLocationResults: any[];

    constructor(private algoliaLocations: AlgoliaLocationsService) {}

    searchLocation(event) {
        this.algoliaLocations
            .getLocationSuggestions(event.query)
            .subscribe(results => {
                this.searchLocationResults = results;
            });
    }

    set originFilter(origin) {
        if (!origin) {
            origin = null;
        }

        if (origin !== this._originFilter) {
            this._originFilter = origin;
            this.updateFilter();
        }
    }

    get originFilter() {
        return this._originFilter;
    }

    set destinationFilter(destination) {
        if (!destination) {
            destination = null;
        }
        if (destination !== this._destinationFilter) {
            this._destinationFilter = destination;
            this.updateFilter();
        }
    }

    get destinationFilter() {
        return this._destinationFilter;
    }

    set providerFilter(provider) {
        if (provider !== this._providerFilter) {
            this._providerFilter = provider;
            this.updateFilter();
        }
    }

    get providerFilter() {
        return this._providerFilter;
    }

    getFilterObject() {
        const origin = this.originFilter;
        const destination = this.destinationFilter;
        const provider = this.providerFilter;

        return origin || destination || provider
            ? {
                  origin,
                  destination,
                  provider,
              }
            : null;
    }

    updateFilter() {
        this.filterChanged.emit(this.getFilterObject());
    }
}
