import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'arrayFilter',
})
export class ArrayFilterPipe implements PipeTransform {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    transform(value: any[], ...args: any[]): any[] {
        const fieldName = args[0];
        const fieldValue = args[1];
        const ignoreNull = args[2];

        if (!value) {
            return value;
        }

        if (ignoreNull === true && fieldValue === null) {
            return value;
        }

        return value.filter(e => e[fieldName] == fieldValue);
    }
}
