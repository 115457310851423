import { Component, OnDestroy } from '@angular/core';
import { environment } from '@qwyk/hub/environment';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { EndpointAvailableValidator } from '../../validators/endpointAvailable.validator';
import { SitesService } from '../../services/sites.service';
import { SitesFacade } from '../../+state/sites.facade';
import { Subscription } from 'rxjs';
import { CanComponentDeactivate } from '@qwyk/core';
import { TranslateService } from '@ngx-translate/core';
@Component({
    selector: 'qwyk-site-create',
    templateUrl: './site-create.component.html',
    styleUrls: ['./site-create.component.scss'],
})
export class SiteCreateComponent implements OnDestroy, CanComponentDeactivate {
    public siteAddressPrefix = environment.portalSitesMask.split('{0}')[0];
    public siteAddressSuffix = environment.portalSitesMask.split('{0}')[1];
    public saving$ = this.sites.loading$;
    public error$ = this.sites.error$;
    private savingSubscription: Subscription;

    public form: FormGroup;

    constructor(
        private fb: FormBuilder,
        private sites: SitesFacade,
        private service: SitesService,
        private translate: TranslateService
    ) {
        this.form = this.fb.group({
            name: [null, [Validators.required, Validators.maxLength(40)]],
            endpoint_identifier: [
                null,
                [Validators.required, Validators.maxLength(30)],
                [new EndpointAvailableValidator(this.service)],
            ],
            active: [true],
            description: [null, [Validators.maxLength(191)]],
        });

        this.savingSubscription = this.saving$.subscribe(saving => {
            if (saving) {
                this.form.disable();
            } else {
                this.form.enable();
            }
        });
    }

    ngOnDestroy(): void {
        this.savingSubscription.unsubscribe();
    }

    submit() {
        this.form.markAllAsTouched();
        if (this.form.invalid) {
            return;
        }
        this.form.markAsPristine();
        this.sites.createSite(this.form.value);
    }

    canDeactivate() {
        console.log('check deactivate');
        if (this.form.dirty) {
            return confirm(
                this.translate.instant(
                    'portal.settings.profile.unsaved-changes'
                )
            );
        }
        return true;
    }
}
