/* eslint-disable @typescript-eslint/no-explicit-any */
import { AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { delay, switchMap, debounceTime } from 'rxjs/operators';

export class ValidatorsX {
    /**
     * Hold that the control being validated when another field value equals the value parameter
     * @param field the field to check against
     * @param equals the value that makes the field under validation required
     */
    static requiredWhenEquals(field: string, equals: any): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            console.log(control.root.get(field));
            if (
                control.root &&
                control.root.get(field) &&
                control.root.get(field)?.valid &&
                control.root.get(field)?.value === equals
            ) {
                console.log(control.value);
                if (!control.value) {
                    return { required: { value: control.value } };
                }
            }
            return null;
        };
    }

    static minWhenEquals(
        minLength: number,
        field: string,
        equals: any
    ): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (
                control.root &&
                control.root.get(field) &&
                control.root.get(field)?.valid &&
                control.root.get(field)?.value === equals
            ) {
                if (!control.value || control.value.length < minLength) {
                    return { required: { value: control.value } };
                }
            }
            return null;
        };
    }

    static mustBeSameAs(field: string): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (
                control.root &&
                control.root.get(field) &&
                control.root.get(field)?.valid
            ) {
                if (
                    !control.value ||
                    control.value !== control.root.get(field)?.value
                ) {
                    return { match: { value: control.value } };
                }
            }
            return null;
        };
    }

    /**
     * Holds that the control being validated is required when the other field in the same form group is not null
     * @param field The field to check against
     */
    static requiredWhenNotNull(field: string): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (
                control.root &&
                control.root.get(field) &&
                control.root.get(field)?.valid
            ) {
                // console.log(control.root.get(field).value);
                if (control.root.get(field)?.value != null && !control.value) {
                    return { required: { value: control.value } };
                }
            }
            return null;
        };
    }

    static requiredWhenNull(field: string): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (
                control.root &&
                control.root.get(field) &&
                control.root.get(field)?.valid
            ) {
                // console.log(control.root.get(field).value);
                if (control.root.get(field)?.value === null && !control.value) {
                    return { required: { value: control.value } };
                }
            }
            return null;
        };
    }

    static requiredWhenEqualsAsync(field: string, equals: any): ValidatorFn {
        return (
            control: AbstractControl
        ): Observable<ValidationErrors | null> => {
            return of(control).pipe(
                debounceTime(200),
                delay(200),
                switchMap(ctrl => {
                    return of(this.requiredWhenEquals(field, equals)(ctrl));
                })
            );
        };
    }

    static minWhenEqualsAsync(
        minLength: number,
        field: string,
        equals: any
    ): ValidatorFn {
        return (
            control: AbstractControl
        ): Observable<ValidationErrors | null> => {
            return of(control).pipe(
                debounceTime(200),
                delay(200),
                switchMap(ctrl => {
                    return of(
                        this.minWhenEquals(minLength, field, equals)(ctrl)
                    );
                })
            );
        };
    }

    static requiredWhenNotNullAsync(field: string): ValidatorFn {
        return (
            control: AbstractControl
        ): Observable<ValidationErrors | null> => {
            return of(control).pipe(
                debounceTime(200),
                delay(200),
                switchMap(ctrl => {
                    return of(this.requiredWhenNotNull(field)(ctrl));
                })
            );
        };
    }
}
