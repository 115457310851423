<form (submit)="onSave()" [formGroup]="form">
    <div class="form-row">
        <div class="form-group col-4">
            <label for="carrier">{{ 'hub.quotations.create.fields.carrier' | translate }}<span class="text-danger">*</span></label>
            <div class="d-flex align-items-center justify-content-stretch">
                <div
                    [style.width]="
                        form.get('carrier')?.value?.scac === CARRIER_SCAC_OTHER
                            ? '50%'
                            : '100%'
                    "
                >
                    <p-dropdown
                        (onChange)="onCarrierChanged($event)"
                        [filter]="true"
                        [options]="carriers$ | async"
                        overlayOptions="{
                            hideTransitionOptions: '0ms',
                            showTransitionOptions: '0ms'
                        }"
                        [style]="{ width: '100%' }"
                        dataKey="scac"
                        formControlName="carrier"
                        id="carrier"
                        optionLabel="name"
                        placeholder="{{ 'hub.quotations.create.fields.carrier' | translate }}"
                    >
                        <ng-template let-carrier pTemplate="item">
                            <div
                                class="d-flex align-items-center justify-content-between"
                            >
                                <div>{{ carrier.name }}</div>
                                <div>
                                    <fa-icon
                                        *ngIf="carrier.qwykschedules_code"
                                        [icon]="['far', 'calendar']"
                                        class="ml-1 text-muted"
                                        size="sm"
                                    ></fa-icon>
                                    <fa-icon
                                        *ngFor="
                                            let transport_mode of carrier.transport_modes
                                        "
                                        [icon]="[
                                            'far',
                                            transport_mode | transportModeIcon
                                        ]"
                                        class="ml-1"
                                        size="sm"
                                    >
                                    </fa-icon>
                                </div>
                            </div>
                            <div class="small text-muted">
                                {{ carrier.scac }}
                            </div>
                        </ng-template>
                    </p-dropdown>
                </div>
                <input
                    *ngIf="
                        form.get('carrier')?.value?.scac === CARRIER_SCAC_OTHER
                    "
                    class="w-50"
                    formControlName="carrier_name"
                    id="carrier_name"
                    pInputText
                    placeholder="{{ 'hub.quotations.create.fields.carrier-placeholder' | translate }}"
                    style="margin-left: 1px"
                    type="text"
                />
            </div>
            <arv-validation-messages for="carrier">
                <arv-validation-message key="required">
                    <span class="text-danger">{{ 'common.validation.required' | translate : { field: 'hub.quotations.create.fields.carrier' | translate } }}. {{ 'hub.quotations.details.offer.columns.carrier-message' | translate }}</span>
                </arv-validation-message>
            </arv-validation-messages>
            <arv-validation-messages for="carrier_name">
                <arv-validation-message key="required">
                    <span class="text-danger">{{ 'common.validation.enter-a-field' | translate : { value: 'hub.quotations.create.fields.carrier-placeholder' | translate } }}</span>
                </arv-validation-message>
            </arv-validation-messages>

            <div *ngIf="!isCarrierSet || form.get('carrier_name').value === null">
                <button
                    (click)="onSetCarrierToOrganization(organizationName)"
                    type="button"
                    class="btn btn-link"
                    *ngIf="organizationName$ | async; let organizationName"
                >
                    {{ 'hub.quotations.create.set-to' | translate : { value: organizationName } }}
                </button>
            </div>
        </div>

        <div class="form-group col-4">
            <label for="rate_name">{{ 'hub.quotations.create.fields.rate-id' | translate }}</label>
            <div>
                <input
                    class="w-100"
                    formControlName="rate_name"
                    id="rate_name"
                    pInputText
                    placeholder="{{ 'hub.quotations.create.fields.rate-id-placeholder' | translate }}"
                    type="text"
                />
            </div>
        </div>

        <div
            *ngIf="form.get('load_type').value === 'lcl'"
            class="form-group col-4 p-fluid"
        >
            <label for="transport_mode">{{ 'hub.quotations.create.fields.transport-mode' | translate }}</label>
            <p-dropdown
                [options]="TRANSPORT_MODES"
                formControlName="transport_mode"
                id="transport_mode"
            >
                <ng-template let-option pTemplate="selectedItem">
                    <div class="flex align-items-center gap-2">
                        <div>{{ option.label | translate }}</div>
                    </div>
                </ng-template>
                <ng-template let-option pTemplate="item">
                    <div class="flex align-items-center gap-2">
                        <div>{{ option.label | translate }}</div>
                    </div>
                </ng-template>
            </p-dropdown>
        </div>
    </div>

    <div class="form-row">
        <div class="form-group col p-fluid">
            <label for="pol">{{ 'hub.quotations.create.fields.port-of-loading' | translate }}<span class="text-danger">*</span></label>
            <p-autoComplete
                (completeMethod)="searchLocations($event)"
                [forceSelection]="true"
                [showEmptyMessage]="true"
                [suggestions]="locations$ | async"
                field="display_name"
                formControlName="pol"
                id="pol"
                placeholder="{{ 'hub.quotations.create.fields.port-of-loading' | translate }}"
            >
                <ng-template let-value pTemplate="item">
                    <div>{{ value.display_name }}</div>
                    <div class="small">{{ value.country_name }}</div>
                </ng-template>
            </p-autoComplete>
            <arv-validation-messages for="pol">
                <arv-validation-message key="required">
                    <span class="text-danger">{{ 'common.validation.required' | translate : { field: 'hub.quotations.create.fields.port-of-loading' | translate } }}</span>
                </arv-validation-message>
            </arv-validation-messages>
        </div>

        <div class="form-group col p-fluid">
            <label for="pod">{{ 'hub.quotations.create.fields.port-of-discharge' | translate }}<span class="text-danger">*</span></label>
            <p-autoComplete
                (completeMethod)="searchLocations($event)"
                [forceSelection]="true"
                [showEmptyMessage]="true"
                [suggestions]="locations$ | async"
                field="display_name"
                formControlName="pod"
                id="pod"
                placeholder="{{ 'hub.quotations.create.fields.port-of-discharge' | translate }}"
            >
                <ng-template let-value pTemplate="item">
                    <div>{{ value.display_name }}</div>
                    <div class="small">{{ value.country_name }}</div>
                </ng-template>
            </p-autoComplete>
            <arv-validation-messages for="pod">
                <arv-validation-message key="required">
                    <span class="text-danger">{{ 'common.validation.required' | translate : { field: 'hub.quotations.create.fields.port-of-discharge' | translate } }}</span>
                </arv-validation-message>
            </arv-validation-messages>
        </div>

        <div class="form-group col p-fluid">
            <label for="transit_time">{{ 'hub.quotations.create.fields.transit-time' | translate }}</label>
            <p-inputNumber
                formControlName="transit_time"
                id="transit_time"
                placeholder="{{ 'hub.quotations.create.fields.transit-time-in-days' | translate }}"
                suffix=" day(s)"
            ></p-inputNumber>
            <arv-validation-messages
                for="transit_time"
            ></arv-validation-messages>
        </div>
    </div>

    <div class="form-row">
        <div class="form-group col-4">
            <label for="currency">{{ 'hub.quotations.create.fields.currency' | translate }}<span class="text-danger">*</span></label>
            <p-dropdown
                [disabled]="offerLinesArray.length > 0"
                [filter]="true"
                [options]="currencies$ | async"
                [style]="{ width: '100%' }"
                formControlName="currency"
                id="currency"
                optionLabel="name"
                optionValue="code"
                placeholder="{{ 'hub.quotations.create.fields.currency' | translate }}"
                overlayOptions="{
                    hideTransitionOptions: '0ms',
                    showTransitionOptions: '0ms'
                }"
            ></p-dropdown>
            <arv-validation-messages for="currency">
                <arv-validation-message key="required">
                    <span class="text-danger">{{ 'common.validation.required' | translate : { field: 'hub.quotations.create.fields.currency' | translate } }}</span>
                </arv-validation-message>
            </arv-validation-messages>
        </div>

        <div class="form-group col-4 p-fluid">
            <label for="valid_from">{{ 'hub.quotations.create.fields.valid-from' | translate }}<span class="text-danger">*</span></label>
            <div class="input-group">
                <span class="input-group-prepend">
                    <button
                        (click)="valid_from.toggle()"
                        class="btn btn-light"
                        style="border-color: rgb(161, 161, 161)"
                        type="button"
                    >
                        <fa-icon [icon]="['far', 'calendar']"></fa-icon>
                    </button>
                </span>
                <input
                    id="valid_from"
                    formControlName="valid_from"
                    class="form-control date-picker"
                    #valid_from="ngbDatepicker"
                    (click)="valid_from.toggle()"
                    ngbDatepicker
                    placeholder="YYYY-MM-DD"
                />
            </div>
            <arv-validation-messages for="valid_from">
                <arv-validation-message key="required">
                    <span class="text-danger">{{ 'common.validation.required' | translate : { field: 'hub.quotations.create.fields.valid-from' | translate } }}</span>
                </arv-validation-message>
            </arv-validation-messages>
        </div>

        <div class="form-group col-4 p-fluid">
            <label for="valid_until">{{ 'hub.quotations.create.fields.valid-until' | translate }}<span class="text-danger">*</span></label>
            <div class="input-group">
                <span class="input-group-prepend">
                    <button
                        (click)="valid_until.toggle()"
                        class="btn btn-light"
                        style="border-color: rgb(161, 161, 161)"
                        type="button"
                    >
                        <fa-icon [icon]="['far', 'calendar']"></fa-icon>
                    </button>
                </span>
                <input
                    id="valid_until"
                    formControlName="valid_until"
                    class="form-control date-picker"
                    #valid_until="ngbDatepicker"
                    (click)="valid_until.toggle()"
                    ngbDatepicker
                    placeholder="YYYY-MM-DD"
                />
            </div>
            <arv-validation-messages for="valid_until">
                <arv-validation-message key="required">
                    <span class="text-danger">{{ 'common.validation.required' | translate : { field: 'hub.quotations.create.fields.valid-until' | translate } }}</span>
                </arv-validation-message>
            </arv-validation-messages>
        </div>
    </div>

    <ng-container *ngIf="showMore">
        <div class="form-row">
            <div class="form-group col p-fluid">
                <label for="pol">{{ 'hub.quotations.create.fields.origin-via' | translate }}</label>
                <p-autoComplete
                    (completeMethod)="searchLocations($event)"
                    [forceSelection]="true"
                    [showEmptyMessage]="true"
                    [suggestions]="locations$ | async"
                    field="display_name"
                    formControlName="pol_via"
                    id="pol_via"
                    placeholder="{{ 'hub.quotations.create.fields.origin-via' | translate }}"
                >
                    <ng-template let-value pTemplate="item">
                        <div>{{ value.display_name }}</div>
                        <div class="small">{{ value.country_name }}</div>
                    </ng-template>
                </p-autoComplete>
                <arv-validation-messages for="pol_via"></arv-validation-messages>
            </div>

            <div class="form-group col p-fluid">
                <label for="pod">{{ 'hub.quotations.create.fields.destination-via' | translate }}</label>
                <p-autoComplete
                    (completeMethod)="searchLocations($event)"
                    [forceSelection]="true"
                    [showEmptyMessage]="true"
                    [suggestions]="locations$ | async"
                    field="display_name"
                    formControlName="pod_via"
                    id="pod_via"
                    placeholder="{{ 'hub.quotations.create.fields.destination-via' | translate }}"
                >
                    <ng-template let-value pTemplate="item">
                        <div>{{ value.display_name }}</div>
                        <div class="small">{{ value.country_name }}</div>
                    </ng-template>
                </p-autoComplete>
                <arv-validation-messages for="pod_via"></arv-validation-messages>
            </div>

            <div class="form-group col p-fluid">
                <label for="service">{{ 'hub.quotations.create.fields.service' | translate }}</label>
                <input
                    class="w-100"
                    formControlName="service"
                    id="service"
                    maxlength="255"
                    pInputText
                    placeholder="{{ 'hub.quotations.create.fields.service-placeholder' | translate }}"
                    type="text"
                />
                <arv-validation-messages for="service">
                    <arv-validation-message key="maxlength">
                        <span class="text-danger">{{ 'common.validation.maxLength' | translate : { maxLength: 255 } }}</span>
                    </arv-validation-message>
                </arv-validation-messages>
            </div>
        </div>

        <div class="form-row">
            <div class="form-group col p-fluid">
                <label for="service">{{ 'hub.quotations.create.fields.commodity' | translate }}</label>
                <input
                    class="w-100"
                    formControlName="commodity"
                    id="commodity"
                    maxlength="255"
                    pInputText
                    placeholder="{{ 'hub.quotations.create.fields.commodity-placeholder-alt' | translate }}"
                    type="text"
                />
                <arv-validation-messages for="commodity">
                    <arv-validation-message key="maxlength">
                        <span class="text-danger">{{ 'common.validation.maxLength' | translate : { maxLength: 255 } }}</span>
                    </arv-validation-message>
                </arv-validation-messages>
            </div>
        </div>
    </ng-container>

    <a (click)="showMore = !showMore" href="javascript:void(0);">
        {{ (showMore ? 'common.less' : 'common.more') | translate | titlecase }}
        {{'common.options'|translate}}
    </a>

    <hr/>

    <div class="form-group">
        <label for="offer_lines">{{ 'hub.quotations.create.fields.charge-lines' | translate }}</label>
        <qwyk-offer-lines-table
            (addCharge)="onAddCharge()"
            [(editingRowIndex)]="editingOrderLinesIndex"
            [disabled]="disabled"
            [offerCurrency]="form.get('currency').value"
            [offerLinesArray]="offerLinesArray"
            [quotationId]="form.get('quotation_id').value"
            id="offer_lines"
        ></qwyk-offer-lines-table>
        <div
            *ngIf="
                form.get('offer_lines').errors !== null &&
                form.get('offer_lines').touched
            "
            class="text-danger small"
        >
            {{ 'hub.quotations.create.no-lines-message' | translate }}
        </div>
    </div>

    <div class="form-row">
        <div class="form-group col p-fluid">
            <label for="remarks">{{ 'hub.quotations.create.fields.quotation-remarks' | translate }}</label>
            <textarea
                [autoResize]="true"
                [rows]="5"
                class="w-100"
                formControlName="remarks"
                id="remarks"
                maxLength="3000"
                pInputTextarea
            >
            </textarea>
            <arv-validation-messages for="remarks">
                <arv-validation-message key="maxlength">
                    <span class="text-danger">{{ 'common.validation.maxLength' | translate : { maxLength: 3000 } }}</span>
                </arv-validation-message>
            </arv-validation-messages>
        </div>
    </div>

    <div
        *ngIf="openRateRequests && openRateRequests?.length > 0"
        class="mb-3 border-bottom"
    >
        <hr class="mt-0" />
        <h5>{{ 'hub.quotations.create.fields.rate-requests' | translate }}</h5>
        <p>{{ 'hub.quotations.create.fields.rate-requests-message' | translate }}</p>
        <div class="form-row">
            <div class="form-group col-12 col-md-6 col-xl-4 p-fluid">
                <label for="rate_request_id">{{ 'hub.quotations.create.fields.rate-request' | translate }}</label>
                <p-dropdown
                    overlayOptions="{
                        hideTransitionOptions: '0ms',
                        showTransitionOptions: '0ms'
                    }"
                    [options]="openRateRequests"
                    [showClear]="true"
                    formControlName="rate_request_id"
                    id="rate_request_id"
                    optionValue="id"
                    placeholder="{{ 'hub.quotations.create.fields.rate-request-placeholder' | translate }}"
                >
                    <ng-template let-value pTemplate="selectedItem">
                        {{ value.creator?.name }}: {{ value.reason }}
                    </ng-template>
                    <ng-template let-value pTemplate="item">
                        <div class="font-weight-bold">
                            {{ value.creator?.name }} ({{
                                value.created_at | amTimeAgo
                            }}
                            ): {{ value.reason }}
                        </div>
                        <div [innerHTML]="value.remarks" class="text-truncate" style="max-width: 400px;"></div>
                    </ng-template>
                </p-dropdown>
            </div>
        </div>

        <div *ngIf="hasRateRequestSelected" class="form-row">
            <div class="form-group col-12 col-md-6 col-xl-4 p-fluid">
                <label for="rate_request_response">{{ 'hub.quotations.create.fields.message' | translate }}</label>
                <textarea
                    [required]="true"
                    formControlName="rate_request_response"
                    id="rate_request_response"
                    maxlength="3000"
                    pInputTextarea
                    placeholder="{{ 'hub.quotations.create.fields.message-placeholder' | translate }}"
                    rows="4"
                ></textarea>
                <arv-validation-messages for="rate_request_response">
                    <arv-validation-message key="required">
                        <div class="text-danger small">
                            {{ 'hub.quotations.create.fields.custom-message-placeholder' | translate }}
                        </div>
                    </arv-validation-message>
                    <arv-validation-message key="maxlength">
                        <span class="text-danger">{{ 'common.validation.maxLength' | translate : { maxLength: 3000 } }}</span>
                    </arv-validation-message>
                </arv-validation-messages>
            </div>
        </div>
    </div>

    <div class="form-row">
        <div class="form-group col-12">
            <button [disabled]="disabled" class="btn btn-primary" type="submit">
                {{ 'hub.quotations.create.save-offer' | translate }}
            </button>
            <button
                [disabled]="disabled"
                [routerLink]="['../..']"
                class="btn btn-light ml-2"
                type="button"
            >
                {{ 'common.cancel' | translate }}
            </button>
        </div>
    </div>
</form>
