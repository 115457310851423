import * as carriagesActions from './../actions/carriages.actions';
import { createReducer, Action, on } from '@ngrx/store';
import { CarriagesState } from '../schedules.state';

const initialState: CarriagesState = {
    carriages: null,
    selected: null,
    action: null,
    done: false,
    error: null,
};
const reducer = createReducer(
    initialState,
    on(carriagesActions.loadCarriages, state => ({
        ...state,
        selected: null,
        action: carriagesActions.loadCarriages.type,
        done: false,
        error: null,
    })),
    on(carriagesActions.loadCarriagesSuccess, (state, { response }) => ({
        ...state,
        done: true,
        carriages: response,
    })),
    on(carriagesActions.loadCarriagesFailure, (state, { error }) => ({
        ...state,
        done: true,
        error,
    })),

    on(carriagesActions.setSelectedCarriage, (state, { carriage }) => ({
        ...state,
        selected: carriage,
        action: carriagesActions.setSelectedCarriage.type,
    })),
    on(carriagesActions.getCarriage, state => ({
        ...state,
        selected: null,
        action: carriagesActions.getCarriage.type,
        done: false,
        error: null,
    })),
    on(carriagesActions.getCarriageSuccess, (state, { carriage }) => ({
        ...state,
        selected: carriage,
        done: true,
    })),
    on(carriagesActions.getCarriageFailure, (state, { error }) => ({
        ...state,
        selected: null,
        done: true,
        error,
    })),
    on(carriagesActions.createCarriage, state => ({
        ...state,
        done: false,
        error: null,
        action: carriagesActions.createCarriage.type,
    })),
    on(carriagesActions.updateCarriage, state => ({
        ...state,
        done: false,
        error: null,
        action: carriagesActions.updateCarriage.type,
    })),
    on(carriagesActions.deleteCarriage, state => ({
        ...state,
        done: false,
        error: null,
        action: carriagesActions.deleteCarriage.type,
    })),
    on(
        carriagesActions.createCarriageSuccess,
        carriagesActions.updateCarriageSuccess,
        (state, { carriage }) => ({
            ...state,
            done: true,
            error: null,
            selected: carriage,
        })
    ),
    on(carriagesActions.deleteCarriageSuccess, state => ({
        ...state,
        done: true,
        error: null,
        selected: null,
    })),
    on(
        carriagesActions.createCarriageFailure,
        carriagesActions.updateCarriageFailure,
        carriagesActions.deleteCarriageFailure,
        (state, { error }) => ({
            ...state,
            done: true,
            error,
        })
    )
);

export function carriagesReducer(
    state: CarriagesState | undefined,
    action: Action
) {
    return reducer(state, action);
}
