import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray } from '@angular/forms';
import { MasterDataService } from '@qwyk/core';
import { map, shareReplay } from 'rxjs/operators';
import { Table } from 'primeng/table';
import * as _ from 'lodash';

@Component({
    selector: 'qwyk-offer-lines-table',
    templateUrl: './offer-lines-table.component.html',
    styleUrls: ['./offer-lines-table.component.scss'],
})
export class OfferLinesTableComponent {
    @Input() offerLinesArray: FormArray;
    @Input() offerCurrency: string;
    @Input() quotationId: string;
    @Input() disabled: boolean;

    @Output() addCharge: EventEmitter<void> = new EventEmitter<void>();
    @Output() editingRowIndexChange: EventEmitter<number | null> =
        new EventEmitter();
    public currencies$ = this.masterData.getCurrencies().pipe(shareReplay());
    public chargeCodes$ = this.masterData
        .getRateChargeCodes()
        .pipe(shareReplay());
    public rateCalculations$ = this.masterData.getRateCalculations().pipe(
        map(a => a.filter(e => !e.is_percentage)),
        shareReplay()
    );
    public chargeSegments = [
        {
            value: 'pickup',
            label: 'hub.quotations.details.offer.segments.pickup',
        },
        {
            value: 'origin',
            label: 'hub.quotations.details.offer.segments.origin',
        },
        {
            value: 'freight',
            label: 'hub.quotations.details.offer.segments.freight',
        },
        {
            value: 'destination',
            label: 'hub.quotations.details.offer.segments.destination',
        },
        {
            value: 'delivery',
            label: 'hub.quotations.details.offer.segments.delivery',
        },
    ];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rowGroupMetadata: any;

    constructor(private masterData: MasterDataService) {}

    private _editingRowIndex: number | null = null;

    @Input()
    public get editingRowIndex(): number | null {
        return this._editingRowIndex;
    }

    public set editingRowIndex(editingRowIndex: number | null) {
        this._editingRowIndex = editingRowIndex;
        this.editingRowIndexChange.emit(editingRowIndex);
    }

    public get totalAmount(): number {
        return _.sumBy(this.offerLinesArray.value, 'total_amount_quotation');
    }

    public get totalBuyingAmount(): number {
        return _.sumBy(
            this.offerLinesArray.value,
            'total_buying_amount_quotation'
        );
    }

    public onRowReorder(e, table: Table) {
        table.value.forEach((e, i) => {
            e.get('sort').setValue(i);
        });
    }

    public onAddCharge() {
        this.addCharge.emit();
    }

    public onEditingRow(e) {
        this.editingRowIndex = e.index;
        console.log(e);
    }

    public onFinishEditingRow() {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        setTimeout(_ => {
            this.editingRowIndex = null;
        }, 10);
    }

    public onDeleteRow(index) {
        this.offerLinesArray.removeAt(index);
    }
}
