import {Component, EventEmitter, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {Validators} from "angular-reactive-validation";
import {Portals} from "@qwyk/models";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: 'qwyk-quotation-network-rate-request',
    templateUrl: './quotation-network-rate-request.component.html',
    styleUrls: ['./quotation-network-rate-request.component.scss']
})
export class QuotationNetworkRateRequestComponent implements OnInit, OnDestroy {

    public quotation: Portals.QuotationEntity;
    public error = null;
    public form: FormGroup;
    public reasons = [
        'I need a better rate',
        'I would like a different option',
        'I did not find any rates',
        'Other',
    ];
    public send$: EventEmitter<object> = new EventEmitter<object>();

    constructor(private fb: FormBuilder, private modal: NgbActiveModal) {
    }

    private _busy = false;

    public get busy(): boolean {
        return this._busy;
    }

    public set busy(busy) {
        if (busy !== this._busy) {
            this._busy = busy;
            if (this._busy) {
                this.form.disable();
            } else {
                this.form.enable();
            }
        }
    }

    ngOnInit(): void {
        this.form = this.fb.group({
            reason: [
                null,
                [Validators.required('Select a reason')],
            ],
            remarks: [
                null,
                [
                    Validators.maxLength(
                        255,
                        maxLength => `Enter at most ${maxLength} characters`
                    ),
                ],
            ],
        });
    }

    ngOnDestroy() {
        this.send$.complete();
    }

    /**
     * Emits a send event when the form validation has succeeded
     */
    public onSend() {
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }
        this.send$.emit(this.form.getRawValue());
    }

    /**
     * Dismisses the modal.
     */
    public onCancel() {
        this.modal.dismiss();
    }

}
