import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetch } from '@nx/angular';
import * as SitesActions from './sites.actions';
import { SitesService } from '../services/sites.service';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class SitesEffects {
    loadSites$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SitesActions.loadSites),
            fetch({
                run: action => {
                    return this.service.getSites(action.query).pipe(
                        map(result =>
                            SitesActions.loadSitesSuccess({
                                sites: result.data,
                                pagination: result.meta,
                            })
                        )
                    );
                },

                onError: (action, error) => {
                    console.error(error);
                    return SitesActions.loadSitesFailure({ error });
                },
            })
        )
    );

    createSite$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SitesActions.createSite),
            switchMap(action =>
                this.service.createSite(action.site).pipe(
                    map(site => SitesActions.createSiteSuccess({ site })),
                    catchError(error =>
                        of(SitesActions.createSiteFailure({ error }))
                    )
                )
            )
        )
    );

    createSiteSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(SitesActions.createSiteSuccess),
                tap(action => {
                    this.router.navigate([
                        'administration',
                        'sites',
                        action.site.id,
                    ]);
                })
            ),
        { dispatch: false }
    );

    updateSite$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SitesActions.updateSite),
            switchMap(action =>
                this.service.updateSite(action.site).pipe(
                    map(site => SitesActions.updateSiteSuccess({ site })),
                    catchError(error =>
                        of(SitesActions.updateSiteFailure({ error }))
                    )
                )
            )
        )
    );

    updateSiteSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(SitesActions.updateSiteSuccess),
                tap(() => {
                    this.router.navigate(['administration', 'sites']);
                })
            ),
        { dispatch: false }
    );

    constructor(
        private actions$: Actions,
        private service: SitesService,
        private router: Router
    ) {}
}
