<div class="col-lg-10 offset-lg-1 col-xl-8 offset-xl-2 p-0" [@fadeInOut]>
    <div class="head mt-3 mb-5 d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
            <fa-icon [icon]="['fad', 'tasks-alt']" size="2x" class="text-primary mr-3" fixedWidth="true"></fa-icon>
            <div>
                <span class="lead">Tasks</span>
                <p class="text-muted mb-0">View a history of system tasks such as routing guide down- and uploads.</p>
            </div>
        </div>
    </div>
    <ul #tabSet="ngbNav" class="nav-tabs" ngbNav [activeId]="(queryParams$ | async)?.tab">
        <li ngbNavItem="download">
            <a ngbNavLink>
                Downloads
            </a>
            <ng-template ngbNavContent>
                <ul class="list-group" *ngIf="exportJobs$ | async; let exportJobs; else loading;">
                    <li class="list-group-item text-center text-muted"
                        *ngIf="exportJobs.length === 0 && !exportJobsError">
                        <fa-icon [icon]="['far', 'cloud']" class="mr-1" size="2x"></fa-icon>
                        <div>
                            There is nothing here yet.
                        </div>
                    </li>
                    <li class="list-group-item text-center text-danger" *ngIf="exportJobsError">
                        <fa-icon [icon]="['far', 'exclamation-triangle']" class="mr-1" size="2x"></fa-icon>
                        <div>
                            We're sorry, something went wrong. Try refreshing the page or contact support if the problem
                            persists.
                        </div>
                    </li>
                    <li class="list-group-item d-flex flex-row align-items-center" *ngFor="let task of exportJobs">
                        <div [ngSwitch]="task.type" class="mr-3">
                            <fa-icon *ngSwitchCase="'routing-guide'" [icon]="['fad', 'project-diagram']"
                                     class="text-secondary" [fixedWidth]="true" size="lg">
                            </fa-icon>
                            <fa-icon *ngSwitchCase="'carriages'" [icon]="['fad', 'truck']" class="text-secondary"
                                     [fixedWidth]="true" size="lg"></fa-icon>
                            <fa-icon *ngSwitchDefault [icon]="['fad', 'file-excel']" class="text-secondary"
                                     [fixedWidth]="true" size="lg">
                            </fa-icon>
                        </div>
                        <div class="flex-grow-1">
                            <div>
                                <span [ngSwitch]="task.status">
                                    <fa-icon *ngSwitchCase="'completed'" [icon]="['far', 'check']" class="text-success"
                                             [fixedWidth]="true">
                                    </fa-icon>
                                    <fa-icon *ngSwitchCase="'failed'" [icon]="['far', 'exclamation-triangle']"
                                             class="text-danger" [fixedWidth]="true"></fa-icon>
                                    <fa-icon *ngSwitchDefault [icon]="['far', 'spinner']" pulse="true"
                                             [fixedWidth]="true">
                                    </fa-icon>
                                </span>
                                <span *ngIf="task.type ==='routing-guide'">
                                    {{ task.attributes.product | uppercase }}
                                </span>
                                {{ task.type | slugToTitle }} Download
                            </div>
                            <div [ngSwitch]="task.status" class="small text-muted">
                                <ng-container *ngSwitchCase="'completed'">Completed {{ task.updated_at | amTimeAgo }}
                                </ng-container>
                                <ng-container *ngSwitchCase="'failed'">Failed {{ task.updated_at | amTimeAgo }}
                                </ng-container>
                                <ng-container *ngSwitchDefault>Started {{ task.created_at | amTimeAgo }}</ng-container>
                            </div>
                        </div>
                        <button class="btn btn-outline-primary ml-2 py-1" *ngIf="task.status === 'completed'"
                                ngbTooltip="Download, make sure popups are allowed." (click)="downloadExportJob(task)"
                                [disabled]="task.downloading">
                            <fa-icon [icon]="['far', task.downloading ? 'spinner' : 'file-download']"
                                     [pulse]="task.downloading" size="lg" [fixedWidth]="true">
                            </fa-icon>
                        </button>
                    </li>
                </ul>
                <div class="small text-muted text-center">This overview shows all your downloads of the
                    past 14 days.</div>
            </ng-template>
        </li>
        <li ngbNavItem="upload">
            <a ngbNavLink>
                Uploads
            </a>
            <ng-template ngbNavContent>
                <ul class="list-group" *ngIf="importJobs$ | async; let importJobs; else loading;">
                    <li class="list-group-item text-center text-muted"
                        *ngIf="importJobs.length === 0 && !importJobsError">
                        <fa-icon [icon]="['far', 'cloud']" class="mr-1" size="2x"></fa-icon>
                        <div>
                            There is nothing here yet.
                        </div>
                    </li>
                    <li class="list-group-item text-center text-danger" *ngIf="importJobsError">
                        <fa-icon [icon]="['far', 'exclamation-triangle']" class="mr-1" size="2x"></fa-icon>
                        <div>
                            We're sorry, something went wrong. Try refreshing the page or contact support if the problem
                            persists.
                        </div>
                    </li>
                    <li class="list-group-item list-group-item-action d-flex flex-row align-items-center overflow-hidden"
                        *ngFor="let task of importJobs" [routerLink]="['./import-job', task.id]">
                        <div [ngSwitch]="task.type" class="mr-3">
                            <fa-icon *ngSwitchCase="'routing-guide'" [icon]="['fad', 'project-diagram']"
                                     class="text-secondary" [fixedWidth]="true" size="lg">
                            </fa-icon>
                            <fa-icon *ngSwitchCase="'carriages'" [icon]="['fad', 'truck']" class="text-secondary"
                                     [fixedWidth]="true" size="lg"></fa-icon>
                            <fa-icon *ngSwitchDefault [icon]="['fad', 'file-excel']" class="text-secondary"
                                     [fixedWidth]="true" size="lg">
                            </fa-icon>
                        </div>
                        <div class="flex-grow-1">
                            <div>
                                <span [ngSwitch]="task.status" class="mr-2">
                                    <fa-icon *ngSwitchCase="'completed'" [icon]="['far', 'check']" class="text-success"
                                             [fixedWidth]="true">
                                    </fa-icon>
                                    <fa-icon *ngSwitchCase="'failed'" [icon]="['far', 'exclamation-triangle']"
                                             class="text-danger" [fixedWidth]="true"></fa-icon>
                                    <fa-icon *ngSwitchDefault [icon]="['far', 'spinner']" pulse="true"
                                             [fixedWidth]="true">
                                    </fa-icon>
                                </span>
                                <b>{{ task.type | slugToTitle }}</b>
                            </div>
                            <div class="text-truncate">{{ task.filename }}</div>
                            <div [ngSwitch]="task.status" class="small text-muted">
                                <ng-container *ngSwitchCase="'completed'">Completed {{ task.updated_at | amTimeAgo }}
                                </ng-container>
                                <ng-container *ngSwitchCase="'failed'">Failed {{ task.updated_at | amTimeAgo }}
                                </ng-container>
                                <ng-container *ngSwitchDefault>Started {{ task.created_at | amTimeAgo }}</ng-container>
                            </div>
                        </div>
                    </li>
                </ul>
                <div class="small text-muted text-center">This overview shows all your uploads of the
                    past 14 days.
                </div>
            </ng-template>
        </li>
    </ul>
    <div [ngbNavOutlet]="tabSet"></div>
</div>

<ng-template #loading>
    <ul class="list-group">
        <li class="list-group-item text-center">
            <fa-icon [icon]="['far', 'spinner']" pulse="true" size="2x"></fa-icon>
        </li>
    </ul>
</ng-template>
