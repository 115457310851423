import { environment } from '@qwyk/hub/environment';
import {
    fadeInOutAnimation,
    fadeInDelayAnimation,
    slideDown,
} from '../../helpers/reusableAnimations';
import { versions } from '../../../environments/versions';
import { MenuSideBarService } from '../../services/menu-side-bar.service';
import { Component } from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AuthenticationFacade } from '@qwyk/hub/authentication';
import { filter, map, shareReplay, tap } from 'rxjs/operators';
import { Hub } from '@qwyk/models';
import { MagayaConnectionServiceService } from '@qwyk/core';
import { ToastrService } from 'ngx-toastr';
import { TimeAgoPipe } from 'ngx-moment';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-index-new',
    templateUrl: './index-new.component.html',
    styleUrls: ['./index-new.component.scss'],
    animations: [fadeInOutAnimation, fadeInDelayAnimation, slideDown],
})
export class IndexNewComponent {
    year = new Date().getFullYear();
    versionInfo = versions;
    env = environment;
    magayaConnectionStatus$ = this.magayaConnectionService
        .getMagayaConnectionStatus()
        .pipe(
            tap(status => this.bannerFactory(status)),
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            filter((status: any) => status.severity === 'critical'),
            shareReplay()
        );
    bannerMessage: string;
    hasUserTooltipShown = false;
    public user$ = this.authentication.user$;
    public userMeta$ = this.authentication.userMeta$;
    public feedbackSite: string = this.env.feedbackSite;
    public isMagayaNetwork$ = this.user$.pipe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        map((u: any) => u && u.is_organization_magaya_network)
    );
    private userTooltip: NgbTooltip;
    constructor(
        private toastr: ToastrService,
        private timeagoPipe: TimeAgoPipe,
        private translate: TranslateService,
        public menuSideBar: MenuSideBarService,
        private authentication: AuthenticationFacade,
        private magayaConnectionService: MagayaConnectionServiceService
    ) {}

    logout() {
        this.authentication.logout();
    }

    public tooltipLoaded(tooltip) {
        this.userTooltip = tooltip;
    }

    public resetImpersonation() {
        this.authentication.resetImpersonation();
    }

    private bannerFactory(status: Hub.MagayaConnectionStatus) {
        const title = this.translate.instant(
            'hub.home.magaya-connection-issue'
        );
        const message = `${
            status.message
        }<br /><br /><small>${this.translate.instant(
            'hub.home.last-checked'
        )} ${this.timeagoPipe.transform(
            status.updated_at
        )}<br />(${this.translate.instant(
            'hub.home.updates-every-15-minutes'
        )})</small>`;

        switch (status.severity) {
            case 'high':
                this.toastr.error(message, title, {
                    enableHtml: true,
                    timeOut: 1e4,
                });
                break;
            case 'critical':
                this.toastr.error(message, title, {
                    enableHtml: true,
                    timeOut: 1e4,
                });
                break;
            case 'low':
            case 'medium':
                this.toastr.warning(message, title, {
                    enableHtml: true,
                    timeOut: 1e4,
                });
                break;
        }
    }
}
