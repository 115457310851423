import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ImportJobComponent } from './components/tasks/import-job/import-job.component';
import { CarriagesComponent } from './components/carriages/carriages/carriages.component';
import { ProvidersComponent } from './components/providers/providers/providers.component';
import { TasksIndexComponent } from './components/tasks/tasks-index/tasks-index.component';
import { RoutingGuideComponent } from './components/routing-guide/routing-guide/routing-guide.component';
import { CarriageDetailComponent } from './components/carriages/carriage-detail/carriage-detail.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'routingguide',
        pathMatch: 'full',
    },
    {
        path: 'carriages',
        component: CarriagesComponent,
    },
    {
        path: 'carriages/:id',
        component: CarriageDetailComponent,
    },
    {
        path: 'routingguide',
        component: RoutingGuideComponent,
    },
    {
        path: 'providers',
        component: ProvidersComponent,
    },
    {
        path: 'tasks',
        component: TasksIndexComponent,
    },
    {
        path: 'tasks/import-job/:id',
        component: ImportJobComponent,
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class SchedulesRoutingModule {}
