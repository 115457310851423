import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MomentModule } from 'ngx-moment';
import { StoreModule } from '@ngrx/store';
import { TableModule } from 'primeng/table';
import { EffectsModule } from '@ngrx/effects';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { UiModule } from '@qwyk/ui';

import { SharedModule } from './../shared/shared.module';
import { ControlsModule } from './../controls/controls.module';

import { ProvidersEffects } from './store/effects/providers.effects';
import { CarriagesEffects } from './store/effects/carriages.effects';
import { carriagesReducer } from './store/reducers/carriages.reducer';
import { providersReducer } from './store/reducers/providers.reducer';
import { RoutingGuideEffects } from './store/effects/routing-guide.effects';
import { routingGuideReducer } from './store/reducers/routing-guide.reducer';

import { SchedulesRoutingModule } from './schedules-routing.module';
import { IndexComponent } from './components/index/index.component';
import { ImportJobComponent } from './components/tasks/import-job/import-job.component';
import { ProvidersComponent } from './components/providers/providers/providers.component';
import { CarriagesComponent } from './components/carriages/carriages/carriages.component';
import { TasksIndexComponent } from './components/tasks/tasks-index/tasks-index.component';
import { RoutingGuideComponent } from './components/routing-guide/routing-guide/routing-guide.component';
import { CreateProviderComponent } from './components/providers/create-provider/create-provider.component';
import { CarriageDetailComponent } from './components/carriages/carriage-detail/carriage-detail.component';
import { UploadCarriagesComponent } from './components/carriages/upload-carriages/upload-carriages.component';
import { DownloadCarriagesComponent } from './components/carriages/download-carriages/download-carriages.component';
import { UploadRoutingGuideComponent } from './components/routing-guide/upload-routing-guide/upload-routing-guide.component';
import { RoutingGuideSearchComponent } from './components/routing-guide/routing-guide-search/routing-guide-search.component';
import { DownloadRoutingGuideComponent } from './components/routing-guide/download-routing-guide/download-routing-guide.component';
import { SimulateRoutingGuideComponent } from './components/routing-guide/simulate-routing-guide/simulate-routing-guide.component';
import { UpdateRoutingGuideItemModalComponent } from './components/routing-guide/update-routing-guide-item-modal/update-routing-guide-item-modal.component';

// tslint:disable-next-line: max-line-length
import { NewRoutingGuideItemModalComponent } from './components/routing-guide/new-routing-guide-item-modal/new-routing-guide-item-modal.component';
// tslint:disable-next-line: max-line-length
@NgModule({
    imports: [
        UiModule,
        NgbModule,
        TableModule,
        FormsModule,
        CommonModule,
        SharedModule,
        MomentModule,
        ControlsModule,
        NgSelectModule,
        TranslateModule,
        FontAwesomeModule,
        AutoCompleteModule,
        ReactiveFormsModule,
        SchedulesRoutingModule,
        StoreModule.forFeature('schedules', {
            providers: providersReducer,
            routingGuide: routingGuideReducer,
            carriages: carriagesReducer,
        }),
        EffectsModule.forFeature([
            ProvidersEffects,
            RoutingGuideEffects,
            CarriagesEffects,
        ]),
    ],
    declarations: [
        IndexComponent,
        ProvidersComponent,
        ImportJobComponent,
        CarriagesComponent,
        TasksIndexComponent,
        RoutingGuideComponent,
        CreateProviderComponent,
        CarriageDetailComponent,
        UploadCarriagesComponent,
        DownloadCarriagesComponent,
        UploadRoutingGuideComponent,
        RoutingGuideSearchComponent,
        DownloadRoutingGuideComponent,
        SimulateRoutingGuideComponent,
        NewRoutingGuideItemModalComponent,
        UpdateRoutingGuideItemModalComponent,
    ],
})
export class SchedulesModule {}
