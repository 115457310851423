import {
    AbstractControl,
    AsyncValidator,
    ValidationErrors,
} from '@angular/forms';

import {
    catchError,
    debounceTime,
    delay,
    map,
    switchMap,
} from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { UsersService } from '../services/users.service';

export class UserNotExistsValidator implements AsyncValidator {
    constructor(
        private service: UsersService,
        private translate: TranslateService
    ) {}

    validate(
        control: AbstractControl
    ): Promise<ValidationErrors> | Observable<ValidationErrors> {
        return of(control).pipe(
            debounceTime(200),
            delay(200),
            switchMap(ctrl => {
                return this.service.validateEmailNotExists(ctrl.value).pipe(
                    map(() => {
                        return null;
                    }),
                    catchError(() => {
                        return of({
                            user_not_exists: this.translate.instant(
                                'hub.settings.users.an-user-with-this-email-already-exists'
                            ),
                        });
                    })
                );
            })
        );
    }
}
