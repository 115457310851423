import { AppState } from '../store/app.state';
import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
    CanActivateChild,
} from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
@Injectable({
    providedIn: 'root',
})
export class IsFeatureEnabledGuard implements CanActivate, CanActivateChild {
    // FIXME:
    constructor(private store: Store<AppState>, private router: Router) {}

    canActivate(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        next: ActivatedRouteSnapshot,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        return true;
        // return this.store.select(selectOrganizationFeatures).pipe(
        //     map(features => {
        //         if (!features) {
        //             this.router.navigate(['/']);
        //         }

        //         if (next.data.feature) {
        //             if (
        //                 features.hasOwnProperty(next.data.feature) &&
        //                 features[next.data.feature].value !==
        //                     next.data.featureValue
        //             ) {
        //                 return false;
        //             }
        //         }
        //         return true;
        //     }),
        //     tap(r => {
        //         if (!r) {
        //             return this.router.navigate(['/error/402'], {
        //                 queryParams: {
        //                     errorName: next.data.featureName,
        //                     source: next.url,
        //                 },
        //             });
        //         }
        //     })
        // );
    }

    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this.canActivate(childRoute, state);
    }
}
