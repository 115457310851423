/* eslint-disable @typescript-eslint/no-explicit-any */
import { Provider } from './../models/providers.models';
import { createAction, props } from '@ngrx/store';

export const loadProviders = createAction(
    '[Schedules Providers] Load Providers'
);
export const loadProvidersSuccess = createAction(
    '[Schedules Providers] Load Providers Success',
    props<{ providers: Provider[] }>()
);
export const loadProvidersFailure = createAction(
    '[Schedules Providers] Load Providers Failure'
);

export const createProvider = createAction(
    '[Schedules Providers] Create Provider',
    props<{ provider: Partial<Provider> }>()
);
export const createProviderSuccess = createAction(
    '[Schedules Providers] Create Provider Success',
    props<{ provider: Provider }>()
);
export const createProviderFailure = createAction(
    '[Schedules Providers] Create Provider Failure',
    props<{ error: any }>()
);
export const createProviderCancelled = createAction(
    '[Schedules Providers] Create Provider Cancelled'
);

export const activateProvider = createAction(
    '[Schedules Providers] Activate Provider',
    props<{ provider: Provider }>()
);
export const activateProviderSuccess = createAction(
    '[Schedules Providers] Activate Provider Success'
);
export const activateProviderFailure = createAction(
    '[Schedules Providers] Activate Provider Failure',
    props<{ provider: Provider }>()
);

export const deactivateProvider = createAction(
    '[Schedules Providers] Deactivate Provider',
    props<{ provider: Provider }>()
);
export const deactivateProviderSuccess = createAction(
    '[Schedules Providers] Deactivate Provider Success'
);
export const deactivateProviderFailure = createAction(
    '[Schedules Providers] Deactivate Provider Failure',
    props<{ provider: Provider }>()
);

export const deleteProvider = createAction(
    '[Schedules Providers] Delete Provider',
    props<{ provider: Provider }>()
);
export const deleteProviderSuccess = createAction(
    '[Schedules Providers] Delete Provider Success'
);
export const deleteProviderFailure = createAction(
    '[Schedules Providers] Delete Provider Failure',
    props<{ provider: Provider; error: any }>()
);
