export const MARGIN_APPLICATION_TYPES = [
    {
        value: 'add_charge',
        label: 'hub.administration.margins.applicationtypes.add_charge',
        description:
            'hub.administration.margins.applicationtypes-descriptions.add_charge',
        requiresText: true,
        requiresChargeDescription: false,
    },
    {
        value: 'increase_amount',
        label: 'hub.administration.margins.applicationtypes.increase_amount',
        description:
            'hub.administration.margins.applicationtypes-descriptions.increase_amount',
        requiresText: false,
        requiresChargeDescription: true,
    },
    {
        value: 'increase_percent',
        label: 'hub.administration.margins.applicationtypes.increase_percent',
        description:
            'hub.administration.margins.applicationtypes-descriptions.increase_percent',
        requiresText: false,
        requiresChargeDescription: true,
    },
];

export const MARGIN_MODES = [
    {
        value: 'OCEAN:fcl',
        label: 'hub.shipment.search.filters.product.ocean:fcl',
    },
    {
        value: 'OCEAN:lcl',
        label: 'hub.shipment.search.filters.product.ocean:lcl',
    },
    {
        value: 'AIR:lcl',
        label: 'hub.shipment.search.filters.product.air:lcl',
    },
    {
        value: 'TRUCK:fcl',
        label: 'hub.shipment.search.filters.product.truck:fcl',
    },
    {
        value: 'TRUCK:lcl',
        label: 'hub.shipment.search.filters.product.truck:lcl',
    },
];

export const MARGIN_CHARGE_SEGMENTS = [
    { value: 'pickup', label: 'hub.quotations.create.fields.pickup' },
    { value: 'origin', label: 'common.quotations.search.origin' },
    { value: 'freight', label: 'hub.quotations.details.offer.columns.freight' },
    { value: 'destination', label: 'common.quotations.search.destination' },
    { value: 'delivery', label: 'hub.quotations.create.fields.delivery' },
];
