import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import * as NotificationsActions from './notifications.actions';
import * as NotificationsSelectors from './notifications.selectors';

@Injectable()
export class NotificationsFacade {
    /**
     * Combine pieces of state using createSelector,
     * and expose them as observables through the facade.
     */
    loading$ = this.store.pipe(
        select(NotificationsSelectors.getNotificationsLoading)
    );
    allNotifications$ = this.store.pipe(
        select(NotificationsSelectors.getAllNotifications)
    );
    selectedNotifications$ = this.store.pipe(
        select(NotificationsSelectors.getSelected)
    );
    pagination$ = this.store.pipe(select(NotificationsSelectors.getPagination));
    error$ = this.store.pipe(select(NotificationsSelectors.getError));

    constructor(private store: Store) {}

    /**
     * Use the initialization action to perform one
     * or more tasks in your Effects.
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public loadNotifications(query: any) {
        this.store.dispatch(NotificationsActions.loadNotifications({ query }));
    }
}
