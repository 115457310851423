<div class="d-flex justify-content-between">
    <div>
        {{ activity.title }}
        <qwyk-relative-time-display [value]="activity.created_at" class="text-muted"></qwyk-relative-time-display>
    </div>
    <div>
        <div
            *ngIf="activity.is_internal"
            class="badge badge-outline-light border text-dark badge-pill">
            {{ 'hub.activities.internal' | translate }}
        </div>
    </div>
</div>
