<input type="file" [attr.name]="name" [attr.id]="name" [attr.accept]="accept" class="d-none"
    [disabled]="busy || disabled" (change)="onFileSelected($event)" />
<label [attr.for]="name" class="btn" [ngClass]="labelClass" style="cursor: pointer;">
    <fa-icon [icon]="['far', !busy ? 'cloud-upload' : 'spinner']" [pulse]="busy" class="mr-1"></fa-icon>
    <span *ngIf="busy">{{ 'hub.schedules.routing-guide.uploading-file' | translate }}</span>
    <span *ngIf="!busy && !selectedFile">{{ 'common.choose-a-file' | translate }}</span>
    <span *ngIf="!busy && selectedFile">1 {{ 'hub.schedules.routing-guide.file-selected' | translate }}</span>
</label>
<div class="small text-warning">{{warning}}</div>
<div class="small text-danger">{{error}}</div>
