<table class="table table-borderless mb-0 table-dense">
    <thead class="small text-muted">
        <tr>
            <td>{{ 'common.qty' | translate }} + {{ 'hub.quotations.create.fields.packaging' | translate }} + {{ 'hub.quotations.create.fields.container-type' | translate }}</td>
            <td *ngIf="request.load_type === 'lcl'" class="d-none d-xl-table-cell">
                {{ 'common.dimensions' | translate }}
            </td>
            <td *ngIf="['lcl', 'ltl'].includes(request.load_type) || request.transport_mode === 'AIR'" class="text-right">{{ 'common.quotations.page.summary.chargeable-weight' | translate }}</td>
            <td class="text-right">{{ 'common.quotations.page.summary.total-weight' | translate }}</td>
            <td class="text-right">{{ 'common.quotations.page.summary.total-volume' | translate }}</td>
            <td *ngIf="request.load_type === 'lcl' && request.transport_mode === 'AIR'" class="text-right">
                {{ 'hub.quotations.details.total-vol-weight' | translate }}
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let pkg of request.packages"
            [displayUOM]="displayUOM"
            [pkg]="pkg"
            [request]="request"
            qwyk-quotation-view-cargo-line
        >
        </tr>
    </tbody>

    <tfoot>
        <tr class="border-top">
            <td class="pb-0">{{ request.total_quantity }}</td>
            <td *ngIf="request.load_type === 'lcl'" class="d-none d-xl-table-cell"></td>
            <td *ngIf="['lcl', 'ltl'].includes(request.load_type) || request.transport_mode === 'AIR'" class="text-right pb-0">
                {{ totalChargeableWeight | number: '1.2-2' }}
                {{ displayUOM === 'metric' ? 'kgs' : 'lbs' }}
            </td>
            <td class="text-right pb-0">
                {{ totalWeight | number: '1.2-2' }}
                {{ displayUOM === 'metric' ? 'kgs' : 'lbs' }}
            </td>
            <td class="text-right pb-0">
                {{ totalVolume | number: (displayUOM === 'metric' ? '1.3-3' : '1.0-0') }}
                {{ displayUOM === 'metric' ? 'cbm' : 'cft' }}
            </td>
            <td *ngIf="request.load_type === 'lcl' && request.transport_mode === 'AIR'" class="text-right pb-0">
                {{
                totalVolume / (displayUOM === 'metric' ? 0.006 : 0.096)
                    | number: '1.2-2'
                }}
                {{ displayUOM === 'metric' ? 'kgs' : 'lbs' }}
            </td>
        </tr>
    </tfoot>
</table>

