import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
// noinspection ES6PreferShortImport
import { QuotationOffersFacade } from '../../+state/quotation-offers.facade';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Portals } from '@qwyk/models';
import { QuotationOfferViewBase } from '../quotation-offer-view-base';

@Component({
    selector: 'qwyk-quotations-offer-view',
    templateUrl: './quotations-offer-edit.component.html',
    styleUrls: ['./quotations-offer-edit.component.scss'],
})
export class QuotationsOfferEditComponent
    extends QuotationOfferViewBase
    implements OnDestroy
{
    public selectedQuotationOffer$ =
        this.quotationOffers.selectedQuotationOffers$.pipe(
            map((offer: Portals.QuotationOffer) => {
                offer.valid_until = offer.valid_until
                    ? new Date(offer.valid_until.toString())
                    : null;
                offer.valid_from = offer.valid_from
                    ? new Date(offer.valid_from.toString())
                    : null;
                return offer;
            })
        );
    private actionsSubscription: Subscription;

    constructor(
        protected quotationOffers: QuotationOffersFacade,
        private route: ActivatedRoute
    ) {
        super(quotationOffers);
        this.actionsSubscription = this.route.params.subscribe(params => {
            this.quotationOffers.selectQuotationOffer(params.offerId);
        });
    }

    ngOnDestroy(): void {
        this.actionsSubscription.unsubscribe();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public onUpdate(payload: any): void {
        this.quotationOffers.updateQuotationOffer(
            payload.quotation_id,
            payload.id,
            payload
        );
    }
}
