import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Store } from '@ngrx/store';
import { of, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { AppState } from './../../../../../store/app.state';
import { RoutingGuide } from '../../../store/models/routing-guide.models';
import { RoutingGuideService } from './../../../services/routing-guide.service';
import { selectRoutingGuide } from '../../../store/selectors/routing-guide.selectors';

@Component({
    selector: 'app-upload-routing-guide',
    templateUrl: './upload-routing-guide.component.html',
    styleUrls: ['./upload-routing-guide.component.scss'],
})
export class UploadRoutingGuideComponent implements OnInit, OnDestroy {
    startedUpload = false;
    error = null;
    isBusy = false;
    form: FormGroup;
    private uploadSubscription: Subscription;

    constructor(
        public modal: NgbActiveModal,
        private fb: FormBuilder,
        private service: RoutingGuideService,
        private store: Store<AppState>
    ) {}

    ngOnInit() {
        this.form = this.fb.group({
            routing_guide_file: [null, [Validators.required]],
        });
    }

    ngOnDestroy(): void {
        if (this.uploadSubscription) {
            this.uploadSubscription.unsubscribe();
        }
    }

    startUpload() {
        this.form.markAllAsTouched();

        if (this.form.invalid) {
            return;
        }
        this.isBusy = true;
        this.error = null;
        this.uploadSubscription = this.store
            .select(selectRoutingGuide)
            .pipe(
                switchMap((rg: RoutingGuide | null) => {
                    if (rg) {
                        return this.service.startRoutingGuideUpload(
                            rg,
                            this.form.value
                        );
                    }
                    return of(null);
                })
            )
            .subscribe(
                () => {
                    this.isBusy = false;
                    this.startedUpload = true;
                },
                error => {
                    this.isBusy = false;
                    this.error = error;
                }
            );
    }

    handleFileUpload(event) {
        this.form.get('routing_guide_file')?.markAsTouched();
        this.form.get('routing_guide_file')?.setValue(event.file, {
            emitEvent: true,
            emitModelToViewChange: true,
            emitViewToModelChange: true,
        });
    }
}
