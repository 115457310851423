<div [ngClass]="{'flex-column-reverse': direction !== 'desc'}" class="d-flex flex-column">
    <div
        (deleteComment)="deleteComment.emit($event)"
        (updateComment)="updateComment.emit($event)"
        *ngFor="let activity of activities"
        [activity]="activity"
        [canMutate]="activity.type === 'comment' && activity.user?.id === userId"
        [loading]="loading"
        [saving]="saving"
        class="px-3 py-2"
        qwyk-activity-list-item>
    </div>
    <div *ngIf="canLoadMore" class="px-3">
        <div class="flex-grow-1">
            <button (click)="this.loadMore.emit()" [disabled]="loading"
                    class="btn btn-outline-light text-muted border-0 btn-block btn-sm">
                <fa-icon [icon]="['far',loading ? 'spinner' : 'history']" [pulse]="loading" class="mr-1"></fa-icon>
                {{ 'hub.activities.load-more-history' | translate }}
            </button>
        </div>
    </div>
</div>
