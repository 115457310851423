import { Component, Input, OnDestroy } from '@angular/core';
import { Portals } from '@qwyk/models';
// noinspection ES6PreferShortImport
import { QuotationOffersFacade } from './../../+state/quotation-offers.facade';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SimpleModalComponent } from '@qwyk/ui-standalone';
import { LazyLoadEvent } from 'primeng/api';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Table } from 'primeng/table';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'qwyk-quotations-offer-list',
    templateUrl: './quotations-offers-list.component.html',
    styleUrls: ['./quotations-offers-list.component.scss'],
})
export class QuotationsOffersListComponent implements OnDestroy {
    public quotationOffers$ = this.quotationOffers.allQuotationOffers$;
    public loading$ = this.quotationOffers.loading$;
    public error$ = this.quotationOffers.error$;
    public pagination$ = this.quotationOffers.pagination$;
    public pageSize = 10;
    // Search debouncer.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public search$: Subject<any> = new Subject<any>();
    private searchSubscription: Subscription;

    constructor(
        private modal: NgbModal,
        private translate: TranslateService,
        private quotationOffers: QuotationOffersFacade
    ) {
        this.searchSubscription = this.search$
            .pipe(debounceTime(200))
            .subscribe(query => {
                this.quotationOffers.init(this.quotation.id, query);
            });
    }

    private _quotation: Portals.QuotationEntity;

    public get quotation(): Portals.QuotationEntity {
        return this._quotation;
    }

    @Input()
    public set quotation(quotation: Portals.QuotationEntity) {
        this._quotation = quotation;
        this.search();
    }

    ngOnDestroy(): void {
        this.searchSubscription.unsubscribe();
    }

    public onDelete(quotationOffer: Portals.QuotationOffer): void {
        const modalRef = this.modal.open(SimpleModalComponent, {
            centered: true,
        });

        const component = modalRef.componentInstance as SimpleModalComponent;
        component.header = this.translate.instant(
            'hub.quotations.details.offer.delete-offer'
        );
        component.message = this.translate.instant(
            'hub.quotations.details.offer.delete-message'
        );
        component.confirmAction = this.translate.instant(
            'hub.quotations.details.offer.delete-confirmation-action'
        );
        component.confirmStyle = 'btn-danger';
        component.dismissAction = this.translate.instant(
            'hub.quotations.details.offer.delete-dismiss-action'
        );

        modalRef.result.then(
            () => {
                this.quotationOffers.deleteQuotationOffer(
                    quotationOffer.quotation_id,
                    quotationOffer.id
                );
            },
            () => {}
        );
    }

    public onGeneratePdf(quotationOffer: Portals.QuotationOffer): void {
        this.quotationOffers.generateQuotationOfferPdf(
            quotationOffer.quotation_id,
            quotationOffer.id
        );
    }

    onDismissError() {
        this.quotationOffers.dismissError();
    }

    public loadQuotationsOffersLazy(event: LazyLoadEvent): void {
        setTimeout(() => {
            let page = 1;
            if (event.first && event.rows) {
                page = event.first / event.rows + 1;
            }
            this.search(page);
        }, 50);
    }

    private search(page = 1) {
        const query = {
            pageSize: this.pageSize,
            page,
        };

        this.search$.next(query);
    }

    onToggleRow(offer: Portals.QuotationOffer, table: Table) {
        table.toggleRow(offer);
    }
}
