// noinspection ES6PreferShortImport

import {Component, OnInit} from '@angular/core';
import {NotificationsFacade} from "./../../+state/notifications.facade";

@Component({
    selector: 'qwyk-notifications-page',
    templateUrl: './notifications-page.component.html',
    styleUrls: ['./notifications-page.component.scss']
})
export class NotificationsPageComponent implements OnInit {

    public notifications$ = this.notifications.allNotifications$;
    public loading$ = this.notifications.loading$;
    public error$ = this.notifications.error$;
    public pagination$ = this.notifications.pagination$;
    private query = {page: 1, pageSize: 20};

    constructor(private notifications: NotificationsFacade) {
    }

    ngOnInit(): void {
        this.loadQuotations(1);
    }

    public onPageChange(e): void {
        this.loadQuotations(e);
    }

    private loadQuotations(page: number): void {
        if (page) {
            this.query.page = page;
        }

        this.notifications.loadNotifications(this.query);

    }

}
