<div class="modal-body">
    <div class="lead">{{ 'hub.schedules.routing-guide.update-a-route' | translate }}</div>

    <!-- Dirty hack to get around PrimeNG issue-->
    <input class="d-none" type="text"/>
    <form (submit)="submit()" [formGroup]="itemForm" class="qw-form">
        <div class="form-row">
            <div class="form-group col">
                <label>{{ 'common.quotations.search.origin' | translate }}</label>
                <p-autoComplete (completeMethod)="searchLocation($event)" (onClear)="onLocationClear($event, 'origin')" (onSelect)="onLocationSelect($event, 'origin')"
                                [forceSelection]="true"
                                [suggestions]="searchLocationResults"
                                class="p-fluid"
                                field="display_name"
                                formControlName="origin"
                                inputStyleClass="form-control mw-100"
                                required>
                    <ng-template let-value pTemplate="item">
                        <div [innerHtml]="value.highlight" class="autocomplete-list-location p-2">
                        </div>
                    </ng-template>
                </p-autoComplete>
                <div *ngIf="itemForm.get('origin').invalid && itemForm.get('origin').touched" class="validation-error">
                    {{ 'hub.schedules.routing-guide.please-select-a-place-from-the-list' | translate }}
                </div>
            </div>

            <div class="form-group col">
                <label>{{ 'hub.quotations.create.fields.destination' | translate }}</label>
                <p-autoComplete (completeMethod)="searchLocation($event)" (onClear)="onLocationClear($event, 'destination')" (onSelect)="onLocationSelect($event, 'destination')"
                                [forceSelection]="true"
                                [suggestions]="searchLocationResults"
                                class="p-fluid"
                                field="display_name"
                                formControlName="destination"
                                inputStyleClass="form-control mw-100"
                                required>
                    <ng-template let-value pTemplate="item">
                        <div [innerHtml]="value.highlight" class="autocomplete-list-location p-2">
                        </div>
                    </ng-template>
                </p-autoComplete>
                <div *ngIf="itemForm.get('destination').invalid && itemForm.get('destination').touched"
                     class="validation-error">
                    {{ 'hub.schedules.routing-guide.please-select-a-place-from-the-list' | translate }}
                </div>
            </div>
        </div>

        <div *ngIf="providers$ | async; let providers;" class="form-group">
            <label>{{ 'common.provider-s' | translate }}</label>
            <ng-select
                [items]="providers"
                [multiple]="true"
                bindLabel="name"
                formControlName="providers"
                groupBy="product"
                notFoundText="{{ 'common.no_items_found_short' | translate : { value: 'common.items' | translate | lowercase } }}"
                clearAllText="{{ 'common.clear-all' | translate }}"
                required>
                <ng-template let-item="item" ng-optgroup-tmp>
                    <fa-icon [icon]="['far', productIcons[item.product]]" class="mr-1">
                    </fa-icon>
                    {{ ('hub.schedules.routing-guide.titles.' + item.product | translate) || ('common.none' | translate) }}
                </ng-template>
            </ng-select>
            <div *ngIf="itemForm.get('providers').invalid && itemForm.get('providers').touched"
                 class="validation-error">
                 {{ 'hub.schedules.routing-guide.please-select-at-least-one-provider' | translate }}
            </div>
        </div>

        <div class="p-g p-fluid card bg-light mt-2">
            <ul #tabs="ngbNav" class="nav-tabs" ngbNav>
                <li ngbNavItem="transhipment">
                    <a ngbNavLink>
                        <fa-icon [icon]="['far', 'chart-network']" class="mr-1"></fa-icon>
                        {{ 'hub.schedules.routing-guide.transhipment' | translate }}
                        <fa-icon *ngIf=" tabHasError('transhipment')" [icon]="['far', 'exclamation-triangle']"
                                 class="ml-1 text-danger"></fa-icon>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="p-3 bg-white">
                            <p class="text-muted">
                                {{ 'hub.schedules.routing-guide.configure-a-transhipment-routing' | translate }}
                            </p>
                            <div class="qw-form">
                                <div class="form-row">
                                    <div class="form-group col">
                                        <label>{{ 'hub.schedules.routing-guide.transhipment-port' | translate }}</label>
                                        <p-autoComplete (completeMethod)="searchLocation($event)" (onClear)="onLocationClear($event, 'via')" (onSelect)="onLocationSelect($event, 'via')"
                                                        [forceSelection]="true"
                                                        [suggestions]="searchLocationResults" class="p-fluid"
                                                        field="display_name"
                                                        formControlName="via"
                                                        inputStyleClass="form-control form-control-sm mw-100">
                                            <ng-template let-value pTemplate="item">
                                                <div [innerHtml]="value.highlight"
                                                     class="autocomplete-list-location p-2"></div>
                                            </ng-template>
                                        </p-autoComplete>
                                    </div>

                                    <div *ngIf="itemForm.get('via').value" class="form-group col-3">
                                        <label>{{ 'hub.schedules.routing-guide.connecting-days' | translate | titlecase }}</label>
                                        <input [ngClass]="{'is-invalid': itemForm.get('ts_days').invalid && itemForm.get('ts_days').touched}" class="form-control form-control-sm mw-100" formControlName="ts_days" id="ts_days"
                                               name="ts_days"
                                               type="number"/>
                                        <div class="invalid-feedback">{{ 'common.field-required' | translate }}.</div>
                                    </div>

                                    <div *ngIf="itemForm.get('via').value" class="form-group col">
                                        <label>{{ 'hub.schedules.routing-guide.second-leg-provider' | translate }}</label>
                                        <select *ngIf="providers$ | async; let providers;"
                                                [compareWith]="providerComparer" [ngClass]="{'is-invalid': itemForm.get('second_leg_provider').invalid && itemForm.get('second_leg_provider').touched}"
                                                class="form-control form-control-sm mw-100" formControlName="second_leg_provider" id="second_leg_provider"
                                                name="second_leg_provider"
                                                type="text">
                                            <option [ngValue]="null">{{ 'common.none' | translate }}</option>
                                            <option *ngFor="let p of providers;" [ngValue]="p">{{ p.name }}</option>
                                        </select>
                                        <div class="invalid-feedback">{{ 'common.field-required' | translate }}.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>

                <li ngbNavItem="backup">
                    <a ngbNavLink>
                        <fa-icon [icon]="['far', 'code-branch']" class="mr-1"></fa-icon>
                        {{ 'common.backup' | translate }}
                        <fa-icon *ngIf=" tabHasError('backup')" [icon]="['far', 'exclamation-triangle']"
                                 class="ml-1 text-danger"></fa-icon>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="p-3 bg-white">
                            <p class="text-muted">
                                {{ 'hub.schedules.routing-guide.set-a-backup-provider' | translate }}
                            </p>
                            <div class="qw-form">
                                <div class="form-row">
                                    <div *ngIf="providers$ | async; let providers;" class="form-group col-4">
                                        <label>{{ 'hub.schedules.routing-guide.backup-provider' | translate }}</label>
                                        <select [compareWith]="providerComparer" class="form-control form-control-sm mw-100"
                                                formControlName="secondary_provider" id="secondary_provider"
                                                name="secondary_provider">
                                            <option [ngValue]="null">{{ 'common.none' | translate }}</option>
                                            <option *ngFor="let p of providers;" [ngValue]="p">{{ p.name }}</option>
                                        </select>
                                        <div class="invalid-feedback">{{ 'common.field-required' | translate }}.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>

                <li ngbNavItem="strings">
                    <a ngbNavLink>
                        <fa-icon [icon]="['far', 'route']" class="mr-1"></fa-icon>
                        {{ 'hub.schedules.routing-guide.strings' | translate }}
                        <fa-icon *ngIf=" tabHasError('strings')" [icon]="['far', 'exclamation-triangle']"
                                 class="ml-1 text-danger"></fa-icon>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="p-3 bg-white">
                            <p class="text-muted">
                                {{ 'hub.schedules.routing-guide.filter-carrier-results-by-service-loop-string' | translate }}
                            </p>
                            <div class="qw-form">
                                <div class="form-row">
                                    <div *ngFor="let p of itemForm.get('providers').value" class="form-group col-4">
                                        <label>{{ p.name }} {{ 'hub.schedules.routing-guide.string' | translate }}</label>
                                        <input [(ngModel)]="p.string" [ngModelOptions]="{standalone: true}"
                                               class="form-control form-control-sm"/>
                                    </div>
                                </div>
                                <div *ngIf="itemForm.get('secondary_provider').value; let secondary_provider;"
                                     class="form-row">
                                    <div class="form-group col-4">
                                        <label>{{ secondary_provider.name }} ({{ 'common.backup' | translate | lowercase }}) {{ 'hub.schedules.routing-guide.string' | translate }}</label>
                                        <input [(ngModel)]="itemForm.get('secondary_provider').value.string"
                                               [ngModelOptions]="{standalone: true}"
                                               class="form-control form-control-sm"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>

                <li ngbNavItem="filter">
                    <a ngbNavLink>
                        <fa-icon [icon]="['far', 'filter']" class="mr-1"></fa-icon>
                        {{ 'common.other' | translate }}
                        <fa-icon *ngIf=" tabHasError('other')" [icon]="['far', 'exclamation-triangle']"
                                 class="ml-1 text-danger"></fa-icon>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="p-3 bg-white">
                            <p class="text-muted">
                                {{ 'hub.schedules.routing-guide.filter-the-schedule-results' | translate }}
                            </p>
                            <div class="qw-form">
                                <div class="form-row">
                                    <div class="form-group col-4">
                                        <label>{{ 'hub.quotations.details.offer.columns.routing' | translate }}</label>
                                        <select (change)="onTranshipmentFilterChanged($event)"
                                                class="form-control form-control-sm mw-100"
                                                formControlName="transhipment_filter">
                                            <option [ngValue]="null">{{ 'common.any' | translate }}</option>
                                            <option value="direct">{{ 'hub.schedules.routing-guide.only-show-direct' | translate }}</option>
                                            <option value="via">{{ 'hub.schedules.routing-guide.only-show-via' | translate }}</option>
                                        </select>
                                    </div>
                                    <div *ngIf="itemForm.get('transhipment_filter').value === 'via'"
                                         class="form-group col-8">
                                        <label>{{ 'hub.schedules.routing-guide.via-port' | translate }}</label>
                                        <p-autoComplete (completeMethod)="searchLocation($event)"
                                                        (onClear)="onLocationClear($event, 'transhipment_filter_location')"
                                                        (onSelect)="onLocationSelect($event, 'transhipment_filter_location')"
                                                        [forceSelection]="true"
                                                        [suggestions]="searchLocationResults"
                                                        class="p-fluid"
                                                        field="display_name"
                                                        formControlName="transhipment_filter_location" id="transhipment_filter_location"
                                                        inputStyleClass="form-control form-control-sm mw-100 {{ itemForm.get('transhipment_filter_location').invalid && itemForm.get('transhipment_filter_location').touched ? 'is-invalid' : ''}}"
                                                        name="transhipment_filter_location">
                                            <ng-template let-value pTemplate="item">
                                                <div [innerHtml]="value.highlight"
                                                     class="autocomplete-list-location p-2"></div>
                                            </ng-template>
                                        </p-autoComplete>
                                        <div *ngIf="itemForm.get('transhipment_filter_location').invalid && itemForm.get('transhipment_filter_location').touched"
                                             class="small text-danger">
                                            {{ 'hub.schedules.routing-guide.this-field-is-required-when-routing-is-set-to-only-show-via' | translate }}
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-4">
                                        <label>{{ 'hub.schedules.routing-guide.vessel-name-starting-with' | translate }}</label>
                                        <input class="form-control form-control-sm" formControlName="vessel_starts_with_filter"
                                               id="vessel_starts_with_filter" name="vessel_starts_with_filter"
                                               type="text"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>

                <li ngbNavItem="aliases">
                    <a ngbNavLink>
                        <fa-icon [icon]="['far', 'exchange']" class="mr-1"></fa-icon>
                        {{ 'hub.schedules.routing-guide.aliases' | translate }}
                        <fa-icon *ngIf=" tabHasError('aliases')" [icon]="['far', 'exclamation-triangle']"
                                 class="ml-1 text-danger"></fa-icon>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="p-3 bg-white">
                            <p class="text-muted">
                                {{ 'hub.schedules.routing-guide.set-port-aliases-locodes' | translate }}
                            </p>
                            <div class="qw-form">
                                <div class="form-row">
                                    <div class="form-group col">
                                        <label>{{ 'hub.schedules.routing-guide.primary-provider-origin' | translate }}</label>
                                        <input (change)="onAliasChanged($event)" [ngClass]="{'is-invalid': itemForm.get('origin_provideralias').invalid && itemForm.get('origin_provideralias').touched}"
                                               class="form-control form-control-sm" formControlName="origin_provideralias" id="origin_provideralias"
                                               maxlength="5" minlength="5" name="origin_provideralias"
                                               type="text"/>
                                        <div class="invalid-feedback">
                                            {{ 'hub.schedules.routing-guide.this-must-be-a-locode-of-exactly-5-characters' | translate }}
                                        </div>
                                    </div>
                                    <div class="form-group col">
                                        <label>{{ 'hub.schedules.routing-guide.primary-provider-destination' | translate }}</label>
                                        <input (change)="onAliasChanged($event)"
                                               [ngClass]="{'is-invalid': itemForm.get('destination_provideralias').invalid && itemForm.get('destination_provideralias').touched}" class="form-control form-control-sm"
                                               formControlName="destination_provideralias" id="destination_provideralias"
                                               maxlength="5" minlength="5" name="destination_provideralias"
                                               type="text"/>
                                        <div class="invalid-feedback">
                                            {{ 'hub.schedules.routing-guide.this-must-be-a-locode-of-exactly-5-characters' | translate }}
                                        </div>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col">
                                        <label>{{ 'hub.schedules.routing-guide.backup-provider-origin' | translate }}</label>
                                        <input (change)="onAliasChanged($event)" [ngClass]="{'is-invalid': itemForm.get('origin_secprovideralias').invalid && itemForm.get('origin_secprovideralias').touched}"
                                               class="form-control form-control-sm" formControlName="origin_secprovideralias"
                                               id="origin_secprovideralias"
                                               maxlength="5" minlength="5" name="origin_secprovideralias"
                                               type="text"/>
                                        <div class="invalid-feedback">
                                            {{ 'hub.schedules.routing-guide.this-must-be-a-locode-of-exactly-5-characters' | translate }}
                                        </div>
                                    </div>
                                    <div class="form-group col">
                                        <label>{{ 'hub.schedules.routing-guide.backup-provider-destination' | translate }}</label>
                                        <input (change)="onAliasChanged($event)"
                                               [ngClass]="{'is-invalid': itemForm.get('destination_secprovideralias').invalid && itemForm.get('destination_secprovideralias').touched}" class="form-control form-control-sm"
                                               formControlName="destination_secprovideralias" id="destination_secprovideralias"
                                               maxlength="5" minlength="5" name="destination_secprovideralias"
                                               type="text"/>
                                        <div class="invalid-feedback">
                                            {{ 'hub.schedules.routing-guide.this-must-be-a-locode-of-exactly-5-characters' | translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="tabs"></div>
        </div>

        <div *ngIf="error" class="alert alert-danger mt-4">
            <fa-icon [icon]="['far', 'exclamation-triangle']" class="mr-1"></fa-icon>
            {{ 'hub.schedules.routing-guide.there-was-an-error-saving' | translate }} <i>{{ 'common.status' | translate }}:
            {{error?.status}}</i>
        </div>

        <div *ngIf="validationErrors.length" class="alert alert-warning mt-4" role="alert">
            <h6 class="alert-heading">
                <fa-icon [icon]="['far', 'exclamation-triangle']" class="mr-1"></fa-icon>
                {{ 'common.there-were-validation-errors' | translate }}
            </h6>
            <hr class="mt-0">
            <ul class="mb-0">
                <li *ngFor="let e of validationErrors">{{ e }}</li>
            </ul>
        </div>

        <div class="form-group mt-4 mb-0">
            <button [disabled]="itemForm.disabled" class="btn btn-primary mr-1" type="submit">
                <ng-container *ngIf="!updating; else busyUpdating">
                    {{ 'common.save' | translate }}
                </ng-container>
            </button>
            <button (click)="activeModal.dismiss()" [disabled]="itemForm.disabled" class="btn btn-light"
                type="button">{{ 'common.cancel' | translate }}
            </button>
        </div>
    </form>
</div>

<ng-template #busyUpdating>
    <fa-icon [icon]="['far', 'spinner']" class="mr-1" pulse="true"></fa-icon>
    {{ 'common.saving' | translate }}
</ng-template>
