import { Component, OnDestroy } from '@angular/core';
import { QuotationsFacade } from '@qwyk/shared-stores/quotations';
import { map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
// noinspection ES6PreferShortImport (circular dependency)
import { QuotationOffersFacade } from './../../+state/quotation-offers.facade';
import { QuotationOfferViewBase } from '../quotation-offer-view-base';

@Component({
    selector: 'qwyk-quotation-offer-create',
    templateUrl: './quotation-offer-create.component.html',
    styleUrls: ['./quotation-offer-create.component.scss'],
})
export class QuotationOfferCreateComponent
    extends QuotationOfferViewBase
    implements OnDestroy
{
    quotation$ = this.quotations.selectedQuotations$;
    openRateRequests$ = this.quotation$.pipe(
        map(quotation => quotation.rate_requests),
        map(rate_requests =>
            rate_requests ? rate_requests.filter(e => e.open === true) : []
        )
    );
    quotationOffer$ = this.quotation$.pipe(
        map(quotation => {
            return {
                quotation_id: quotation.id,
                currency: quotation.request.currency,
                load_type: quotation.request.load_type,
                transport_mode: quotation.request.transport_mode,
                valid_from: new Date(),
                pol:
                    quotation.request.origin?.type === 'port'
                        ? {
                              locode: quotation.request.origin.locode,
                              display_name: quotation.request.origin.name,
                          }
                        : null,
                pod:
                    quotation.request.destination?.type === 'port'
                        ? {
                              locode: quotation.request.destination.locode,
                              display_name: quotation.request.destination.name,
                          }
                        : null,
                offer_lines: [],
            };
        })
    );
    private actionsSubscription: Subscription;

    constructor(
        private quotations: QuotationsFacade,
        protected quotationOffers: QuotationOffersFacade,
        private route: ActivatedRoute
    ) {
        super(quotationOffers);
        this.actionsSubscription = this.route.params.subscribe(params => {
            this.quotations.selectQuotation(params.id);
        });
    }

    ngOnDestroy(): void {
        this.actionsSubscription.unsubscribe();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public onCreate(payload: any): void {
        this.quotationOffers.createQuotationOffer(
            payload.quotation_id,
            payload
        );
    }
}
