import { Pipe, PipeTransform } from '@angular/core';
import { Constants } from '@qwyk/core';

@Pipe({
    name: 'loadTypeIcon',
})
export class LoadTypeIconPipe implements PipeTransform {
    private _icons: { [index: string]: string } = Constants.LOAD_TYPE_ICONS;

    transform(value: string): string {
        return this._icons[value];
    }
}
