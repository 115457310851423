// noinspection ES6PreferShortImport (circular dep)
import {QuotationOffersFacade} from './../+state/quotation-offers.facade';

export abstract class QuotationOfferViewBase {
    public loading$ = this.quotationOffers.loading$;
    public error$ = this.quotationOffers.error$;

    protected constructor(protected quotationOffers: QuotationOffersFacade) {
    }

    public onDismissError(): void {
        this.quotationOffers.dismissError();
    }
}
