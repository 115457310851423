<div class="modal-body">
    <div class="lead">Upload carriages</div>
    <p class="text-muted" *ngIf="!startedUpload">
        Upload your previously downloaded carriages to add to or update the existing entries in the system.
    </p>
    <form [formGroup]="form" (submit)="startUpload()" *ngIf="!startedUpload">
        <div class="d-flex flex-row align-items-center">
            <app-media-uploader name="carriages" class="mr-1 flex-grow-1"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                (fileSelected)="handleFileUpload($event)" [disabled]="startedUpload" [maxSize]="10">
            </app-media-uploader>
            <span class="d-inline-block"
                style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis">{{ form.get('carriages_file').value ? form.get('carriages_file').value['name'] : null }}</span>
        </div>
        <div *ngIf="form.get('carriages_file').invalid && form.get('carriages_file').touched" class="small text-danger">
            Please select a valid file.
        </div>

        <p *ngIf="error" class="mt-2 text-danger">
            We're sorry, something went wrong. Please try again or contact support if the problem persists.
        </p>

        <div class="mt-3">
            <button class="btn btn-primary mr-1" [disabled]="isBusy" type="submit">
                <fa-icon [icon]="['far', 'spinner']" pulse="true" *ngIf="isBusy"></fa-icon>
                Start{{ isBusy ? 'ing' : null}} upload
            </button>
            <button class="btn btn-light" (click)="modal.dismiss()" [disabled]="isBusy" type="button">Cancel</button>
        </div>

    </form>

    <p *ngIf="startedUpload" class="mt-2">
        Your upload has started, this may take some time, we will email you when it has been completed.
    </p>



    <ng-container *ngIf="startedUpload">
        <button class="btn btn-primary mr-1" type="button" (click)="modal.close()"
            [routerLink]="['/schedules', 'tasks']" [queryParams]="{tab: 'upload'}">View progress</button>
        <button class="btn btn-light" (click)="modal.close()" type="button">Close</button>
    </ng-container>
</div>