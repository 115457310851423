<ng-container *ngIf="selectedQuotation$ | async; let quotation">
    <div class="mb-3">
        <qwyk-activity-feed-card
            [parent]="{type: 'quotations', id: quotation.id}"
            cardStateKey="dfp.hub.quotations.history.expanded"
        ></qwyk-activity-feed-card>
        <!--        <qwyk-quotation-request-history-->
        <!--                [quotation]="quotation">-->
        <!--        </qwyk-quotation-request-history>-->
    </div>

    <ng-container *ngIf="view$ | async; let view">
        <nav *ngIf="(polling$ | async) === false" class="nav tab-nav">
            <!--        <a [routerLink]="['.']" class="nav-link" [ngClass]="{'active': view === 'overview'}" [queryParams]="{view: 'activity'}">-->
            <!--            <fa-icon [icon]="['far', 'building']" class="mr-1"></fa-icon>-->
            <!--            Activity-->
            <!--        </a>-->
            <a
                [ngClass]="{ active: view === 'offers' }"
                [queryParams]="{ view: 'offers' }"
                [routerLink]="['.']"
                class="nav-link"
            >
                <fa-icon
                    [icon]="['far', 'hand-holding-usd']"
                    class="mr-1"
                ></fa-icon>
                {{ 'hub.quotations.details.offers' | translate }}
                <span class="badge badge-light">{{ quotation.results.length }}</span>
            </a>
            <a
                [ngClass]="{ active: view === 'requests' }"
                [queryParams]="{ view: 'requests' }"
                [routerLink]="['.']"
                class="nav-link"
            >
                <fa-icon [icon]="['far', 'question']" class="mr-1"></fa-icon>
                {{ 'hub.quotations.details.requests' | translate }}
                <span
                    class="badge badge-warning bg-warning text-white font-weight-bold"
                    *ngIf="quotation.open_rate_requests_count > 0"
                    >{{ quotation.open_rate_requests_count }}</span
                >
            </a>
            <a
                [ngClass]="{ active: view === 'shipments' }"
                [queryParams]="{ view: 'shipments' }"
                [routerLink]="['.']"
                class="nav-link"
            >
                <fa-icon [icon]="['far', 'box-alt']" class="mr-1"></fa-icon>
                {{ 'hub.common.shipments' | translate }}
                <span class="badge badge-light">{{ quotation.booked_shipments.length }}</span>
            </a>
            <a
                *ngIf="quotation.is_network_quotation"
                [ngClass]="{ active: view === 'network-quotation' }"
                [queryParams]="{ view: 'network-quotation' }"
                [routerLink]="['.']"
                class="nav-link"
            >
                <fa-icon
                    [icon]="['far', 'chart-network']"
                    class="mr-1"
                ></fa-icon>
                {{ 'hub.quotations.details.network-quotation' | translate }}
            </a>
        </nav>

        <div [hidden]="view !== 'offers'" id="offers">
            <div class="card shadow-sm mb-4 border-top-0">
                <div *ngIf="polling$ | async" class="d-flex">
                    <div class="progress flex-grow-1" style="height: 30px">
                        <div
                            aria-valuemax="100"
                            aria-valuemin="0"
                            aria-valuenow="75"
                            class="progress-bar progress-bar-striped progress-bar-animated"
                            role="progressbar"
                            style="width: 100%; height: 30px"
                        >
                            {{ 'hub.quotations.details.loading-offers' | translate }}
                        </div>
                    </div>
                    <div>
                        <button
                            [routerLink]="['.']"
                            class="btn btn-light btn-sm"
                        >
                            {{ 'common.cancel' | translate }}
                        </button>
                    </div>
                </div>
                <qwyk-quotations-offer-list
                    [quotation]="quotation"
                ></qwyk-quotations-offer-list>
                <!--                <qwyk-quotation-offers-list (deleteOffer)="onDeleteOffer($event)"-->
                <!--                                            (downloadOffer)="onDownloadOffer($event)"-->
                <!--                                            [displayBuyingInfo]="displayOfferBuyingInfo"-->
                <!--                                            [editable]="(polling$ | async) !== true" [offers]="quotation.results"-->
                <!--                                            [quotation]="quotation">-->
                <!--                </qwyk-quotation-offers-list>-->

                <!--                <div class="card-body py-3">-->
                <!--                    <button [disabled]="polling$ | async" [routerLink]="['offer']" class="btn btn-primary btn-sm">-->
                <!--                        <fa-icon [icon]="['far', 'plus']" class="mr-1"></fa-icon>-->
                <!--                        Create new offer-->
                <!--                    </button>-->
                <!--                </div>-->
            </div>
        </div>

        <div [hidden]="view !== 'requests'" id="requests">
            <div class="card shadow-sm mb-4 border-top-0">
                <qwyk-quotation-rate-requests-list
                        [rateRequests]="quotation.rate_requests"
                >
                </qwyk-quotation-rate-requests-list>
            </div>
        </div>

        <div [hidden]="view !== 'shipments'" id="shipments">
            <div class="card shadow-sm border-bottom-0" id="bookedShipments">
                <p-table [value]="quotation.booked_shipments">
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="width: 3em"></th>
                            <th>{{ 'common.reference' | translate }}</th>
                            <th>{{ 'common.sort_options.status' | translate }}</th>
                            <th>{{ 'common.operator' | translate }}</th>
                        </tr>
                    </ng-template>

                    <ng-template let-shipment pTemplate="body">
                        <tr>
                            <td></td>
                            <td>
                                <a [routerLink]="['/shipments', shipment.id]">
                                    {{
                                        shipment.organization_shipment_reference ||
                                        ('common.not-assigned' | translate)
                                    }}
                                </a>
                            </td>
                            <td>
                                {{ shipment.status?.actual_label }}
                            </td>
                            <td>
                                <span
                                    [ngClass]="{
                                        'text-underline-dashed':
                                            shipment.operator
                                    }"
                                    [ngbPopover]="
                                        shipment.operator
                                            ? operatorUserPopoverContent
                                            : null
                                    "
                                    placement="bottom"
                                    triggers="mouseenter:mouseleave"
                                >
                                    {{
                                        shipment.operator?.name ||
                                        ('common.not-assigned' | translate)
                                    }}
                                </span>
                                <ng-template #operatorUserPopoverContent>
                                    <qwyk-user-info-tooltip
                                        *ngIf="shipment.operator"
                                        [user]="shipment.operator"
                                    >
                                    </qwyk-user-info-tooltip>
                                </ng-template>
                            </td>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td class="text-center" colspan="4">
                                {{ 'hub.quotations.details.no-shipments-booked' | translate }}.
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>

        <div
            *ngIf="view === 'network-quotation' && quotation.is_network_quotation"
        >
            <div class="card border-bottom-0 shadow-sm">
                <div class="card-body p-4">
                    <h5 class="font-weight-bold m-0">{{ 'hub.quotations.details.network-quotation' | translate }}</h5>
                </div>
                <qwyk-quotation-network-info
                    [quotation]="quotation"
                ></qwyk-quotation-network-info>
            </div>
        </div>
    </ng-container>
</ng-container>
