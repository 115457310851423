import { Component, OnDestroy } from '@angular/core';
import { PathService } from '@qwyk/core';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { interval, of, Subscription } from 'rxjs';
import { QuotationsFacade } from '@qwyk/shared-stores/quotations';
import { ActivatedRoute, Router } from '@angular/router';
import { QuotationsService } from '../../services/quotations.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationFacade } from '@qwyk/hub/authentication';
import { Portals } from '@qwyk/models';
import { saveAs } from 'file-saver';
import { SimpleModalComponent } from '@qwyk/ui-standalone';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { QuotationOffersFacade } from '@qwyk/hub/quotations-offers';

@Component({
    selector: 'qwyk-quotation-detail-page',
    templateUrl: './quotation-detail-page.component.html',
    styleUrls: ['./quotation-detail-page.component.scss'],
})
export class QuotationDetailPageComponent implements OnDestroy {
    public selectedQuotation$ = this.quotations.selectedQuotations$;
    public busy: boolean;
    public isPolling = false;
    public url$;

    public isMasterAdmin$ = this.auth.user$.pipe(map(e => e.is_master_admin));
    private actionsSubscription: Subscription;
    private queryParamsSubscription: Subscription;

    constructor(
        private quotations: QuotationsFacade,
        private quotationOffers: QuotationOffersFacade,
        private route: ActivatedRoute,
        private service: QuotationsService,
        private router: Router,
        private modal: NgbModal,
        pathService: PathService,
        private auth: AuthenticationFacade
    ) {
        this.url$ = pathService.path$;
        this.actionsSubscription = this.route.params.subscribe(params => {
            this.quotations.selectQuotation(params.id);
            this.queryParamsSubscription = this.route.queryParamMap
                .pipe(
                    switchMap(query => {
                        if (query.has('poll') && query.get('poll') === 'true') {
                            this.isPolling = true;
                            return interval(5 * 1e3);
                        } else {
                            return of(false);
                        }
                    })
                )
                .subscribe(query => {
                    if (query !== false) {
                        if (query > (120 * 1e3) / (5 * 1e3) && this.isPolling) {
                            this.isPolling = false;
                            this.router.navigate(['.'], {
                                relativeTo: this.route,
                            });
                        } else {
                            this.isPolling = true;
                            this.quotations.loadQuotation(params.id);
                        }
                    } else {
                        this.isPolling = false;
                    }
                });
        });
    }

    ngOnDestroy() {
        this.actionsSubscription.unsubscribe();
        this.queryParamsSubscription.unsubscribe();
        this.quotationOffers.reset();
        this.quotations.resetSelectedQuotation();
    }

    download(quotation: Portals.QuotationEntity) {
        this.busy = true;
        const subscription = this.service
            .downloadQuotation(quotation.id)
            .pipe(take(1))
            .subscribe(
                response => {
                    subscription.unsubscribe();
                    saveAs(response, `quotation-${quotation.id}.pdf`);
                    this.busy = false;
                },
                () => {
                    subscription.unsubscribe();
                    this.busy = false;
                    alert(
                        'Something went wrong, please try again or contact support if the problem persists.'
                    );
                }
            );
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
    publish(quotation: Portals.QuotationEntity, _modal: any) {
        const modalRef = this.modal.open(SimpleModalComponent, {
            centered: true,
        });
        modalRef.componentInstance.header = 'Publish quotation';
        modalRef.componentInstance.message =
            'This will publish the quotation and make it visible to your customer in their portal. Do you wish to continue?';
        modalRef.componentInstance.dismissAction = 'No';
        modalRef.componentInstance.confirmAction = 'Yes';

        modalRef.result.then(
            () => {
                this.busy = true;
                const subscription = this.service
                    .publishQuotation(quotation)
                    .pipe(
                        take(1),
                        tap(updateQuotation =>
                            this.quotations.quotationUpdated(updateQuotation)
                        )
                    )
                    .subscribe(
                        () => {
                            this.busy = false;
                            subscription.unsubscribe();
                        },
                        () => {
                            this.busy = false;
                            subscription.unsubscribe();
                            alert(
                                'Something went wrong, please try again or contact support if the problem persists.'
                            );
                        }
                    );
            },
            () => {}
        );
    }

    close(quotation: Portals.QuotationEntity) {
        this.busy = true;
        this.service
            .closeQuotation(quotation)
            .pipe(
                take(1),
                tap(updatedQuotation =>
                    this.quotations.quotationUpdated(updatedQuotation)
                )
            )
            .subscribe(
                () => {
                    this.busy = false;
                },
                () => {
                    this.busy = false;
                    alert(
                        'Something went wrong, please try again or contact support if the problem persists.'
                    );
                }
            );
    }

    delete(quotation: Portals.QuotationEntity) {
        const modalRef = this.modal.open(SimpleModalComponent, {
            centered: true,
        });
        modalRef.componentInstance.header = 'Delete quotation';
        modalRef.componentInstance.message =
            'Are you sure you want to delete this quotation? This cannot be undone.';
        modalRef.componentInstance.dismissAction = 'No';
        modalRef.componentInstance.confirmAction = 'Yes';
        modalRef.componentInstance.confirmStyle = 'btn-danger';

        modalRef.result.then(
            () => {
                this.busy = true;
                const subscription = this.service
                    .deleteQuotation(quotation)
                    .pipe(take(1))
                    .subscribe(
                        () => {
                            subscription.unsubscribe();
                            this.router.navigate(['..'], {
                                relativeTo: this.route,
                            });
                        },
                        () => {
                            this.busy = false;
                            subscription.unsubscribe();
                            alert(
                                'Something went wrong, please try again or contact support if the problem persists.'
                            );
                        }
                    );
            },
            () => {}
        );
    }
}
