import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {CustomerUsersListComponent, CustomerUserViewComponent } from './components';
import {CustomerUserExistsGuard} from './guards/customer-user-exists.guard';
import {CanComponentDeactivateGuard} from '@qwyk/core';
import {HubAdministrationCustomerUsersWithoutRoutingModule} from './hub-administration-customer-users-without-routing.module';
import {CanCreateOrUpdatePortalUsersGuard} from '@qwyk/hub/authentication';
import {MagayaUserGuard} from './guards/magaya-user.guard';

@NgModule({
    imports: [
        CommonModule,

        RouterModule.forChild([
            {
                path: '',
                pathMatch: 'full',
                component: CustomerUsersListComponent,
            },
            {
                path: 'new',
                component: CustomerUserViewComponent,
                canActivate: [CanCreateOrUpdatePortalUsersGuard],
                canDeactivate: [CanComponentDeactivateGuard],
            },
            {
                path: ':id',
                component: CustomerUserViewComponent,
                canActivate: [CustomerUserExistsGuard, MagayaUserGuard],
                canDeactivate: [CanComponentDeactivateGuard],
            },
        ]),

        HubAdministrationCustomerUsersWithoutRoutingModule,
    ],
    providers: [],
    declarations: [],
})
export class HubAdministrationCustomerUsersModule {}
