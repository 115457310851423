import {Component, Input} from '@angular/core';
import {Portals} from '@qwyk/models';
import {LocalStorageService} from '@qwyk/core';

@Component({
    selector: 'qwyk-quotation-request-history',
    templateUrl: './quotation-request-history.component.html',
    styleUrls: ['./quotation-request-history.component.scss']
})
export class QuotationRequestHistoryComponent {
    @Input() public quotation: Portals.QuotationEntity;
    private readonly expandedStorageKey = 'dfp.hub.quotations.history.expanded';

    constructor(private localStorage: LocalStorageService) {
        this._expanded = this.localStorage.getBool(this.expandedStorageKey, true);
    }

    private _expanded: boolean;

    get expanded(): boolean {
        return this._expanded;
    }

    set expanded(value: boolean) {
        this._expanded = value;
        this.localStorage.setBool(this.expandedStorageKey, value);
    }
}
