/* eslint-disable @typescript-eslint/no-explicit-any */
import { DomSanitizer } from '@angular/platform-browser';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormGroup, Validators, FormBuilder } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { Validators as ReactiveValidators } from 'angular-reactive-validation';

import {
    Constants,
    MasterDataService,
    CanComponentDeactivate,
} from '@qwyk/core';
import { Hub } from '@qwyk/models';
import { environment } from '@qwyk/hub/environment';

import { SitesService } from '../../services/sites.service';
import { EndpointAvailableValidator } from '../../validators/endpointAvailable.validator';

@Component({
    selector: 'qwyk-site-detail',
    templateUrl: './site-detail.component.html',
    styleUrls: ['./site-detail.component.scss'],
})
export class SiteDetailComponent implements OnInit, CanComponentDeactivate {
    @Output() save: EventEmitter<Hub.Site> = new EventEmitter();
    public form: FormGroup;
    public siteAddressPrefix = environment.portalSitesMask.split('{0}')[0];
    public siteAddressSuffix = environment.portalSitesMask.split('{0}')[1];
    public colorsArray = Constants.CUSTOMIZABLE_COLORS_ARRAY;
    public fontsArray = Constants.AVAILABLE_FONTS;
    public fontWeightsArray = Constants.FONT_WEIGHTS;
    public locationTypes = Constants.LOCATION_TYPES;
    public additionals = Constants.ADDITIONALS.sort((a, b) =>
        a.label > b.label ? 1 : -1
    );
    public currencies$ = this.masterData.getCurrencies();

    constructor(
        private fb: FormBuilder,
        private service: SitesService,
        private santizer: DomSanitizer,
        private translate: TranslateService,
        private masterData: MasterDataService
    ) {}

    private _site: Hub.Site;
    public get site(): Hub.Site {
        return this._site;
    }

    @Input()
    public set site(site: Hub.Site) {
        this._site = site;
        this.initializeForm();
    }

    private _saving = false;
    public get saving(): boolean {
        return this._saving;
    }

    @Input()
    public set saving(saving: boolean) {
        setTimeout(() => {
            if (saving) {
                this.form.disable();
            } else {
                this.form.enable();
                ['cname', 'enable_cname', 'id'].forEach(key =>
                    this.form.get(key).disable()
                );
            }
        }, 50);
        this._saving = saving;
    }

    private _error: any = null;
    public get error(): any {
        return this._error;
    }

    @Input()
    public set error(error: any) {
        this._error = error;
    }

    public get homepageLinksArray(): FormArray {
        return this.form.get('homepage_links') as FormArray;
    }

    private _pattern =
        /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/i;

    public ngOnInit(): void {
        this.form
            .get('landingpage_header_label')
            .valueChanges.subscribe(res => {
                if (res) {
                    this._makeHomePageLinksRequired();
                } else {
                    const isDefaultCustomLinkRowEmpty =
                        this.isDefaultCustomLinkRowEmpty();
                    if (isDefaultCustomLinkRowEmpty) {
                        this.removeRequiredFromHomepageLinks(0);
                    } else {
                        this.form
                            .get('landingpage_header_label')
                            .setValidators([Validators.required]);
                    }
                }
            });

        this.form.get('homepage_links').valueChanges.subscribe(rows => {
            const anyValue = rows.some(row => {
                return Object.values(row).some(value => value);
            });
            if (anyValue) {
                this.form
                    .get('landingpage_header_label')
                    .setValidators([Validators.required]);
                this.form
                    .get('landingpage_header_label')
                    .updateValueAndValidity({ emitEvent: false });
                this._makeHomePageLinksRequired();
            } else {
                const headerLabelCtrl = this.form.get(
                    'landingpage_header_label'
                );
                if (headerLabelCtrl.value) {
                    this._makeHomePageLinksRequired();
                } else {
                    headerLabelCtrl.clearValidators();
                    headerLabelCtrl.updateValueAndValidity({
                        emitEvent: false,
                    });
                    this.removeRequiredFromHomepageLinks(0);
                }
            }
        });
    }

    canDeactivate() {
        if (this.form.dirty) {
            return confirm(
                this.translate.instant(
                    'portal.settings.profile.unsaved-changes'
                )
            );
        }
        return true;
    }

    private _makeHomePageLinksRequired(): void {
        for (const control of this.homepageLinksArray.controls) {
            control.get('label').setValidators([Validators.required]);
            control
                .get('url')
                .setValidators([
                    Validators.required,
                    Validators.pattern(this._pattern),
                ]);
            control.get('approve').setValidators([Validators.requiredTrue]);
            control.get('label').updateValueAndValidity({ emitEvent: false });
            control.get('url').updateValueAndValidity({ emitEvent: false });
            control.get('approve').updateValueAndValidity({ emitEvent: false });
        }
    }

    removeRequiredFromHomepageLinks(idx: number): void {
        this.homepageLinksArray.controls[idx].get('label').clearValidators();
        this.homepageLinksArray.controls[idx].get('url').clearValidators();
        this.homepageLinksArray.controls[idx].get('approve').clearValidators();
        this.homepageLinksArray.controls[idx]
            .get('label')
            .updateValueAndValidity({ emitEvent: false });
        this.homepageLinksArray.controls[idx]
            .get('url')
            .updateValueAndValidity({ emitEvent: false });
        this.homepageLinksArray.controls[idx]
            .get('approve')
            .updateValueAndValidity({ emitEvent: false });
    }

    setColorToDefault(control) {
        this.form
            .get(control + '_color')
            .setValue(Constants.CUSTOMIZABLE_COLORS_DEFAULTS[control]);
    }

    isDefaultCustomLinkRowEmpty(): boolean {
        const formValues = this.form.getRawValue();
        const defaultCustomLinkRow = formValues.homepage_links[0];
        return (
            formValues.homepage_links.length === 1 &&
            Object.values(defaultCustomLinkRow).every(
                x => x === null || x === '' || !x
            )
        );
    }

    public onSave() {
        this.form.markAllAsTouched();
        if (this.form.invalid) {
            return;
        }
        this.form.markAsPristine();

        const formValues = this.form.getRawValue();

        const isDefaultCustomLinkRowEmpty = this.isDefaultCustomLinkRowEmpty();

        if (
            (!formValues.homepage_links.length &&
                !formValues.landingpage_header_label) ||
            isDefaultCustomLinkRowEmpty
        ) {
            formValues.homepage_links = null;
        } else {
            formValues.homepage_links = {
                links: formValues.homepage_links,
                header_label: formValues.landingpage_header_label,
            };
        }

        this.save.next(formValues);
    }

    public addHomepageLink(data = null, isDefaultRow = false) {
        const arr = this.form.get('homepage_links') as FormArray;

        const group = this.fb.group({
            label: [null, [Validators.required, Validators.maxLength(40)]],
            url: [
                null,
                [Validators.required, Validators.pattern(this._pattern)],
            ],
            approve: [null, [Validators.requiredTrue]],
            tooltip: [null, [Validators.maxLength(255)]],
        });

        group.markAsUntouched();
        group.get('label').markAsUntouched();
        group.get('url').markAsUntouched();
        group.get('approve').markAsUntouched();

        this.form
            .get('landingpage_header_label')
            .setValidators([Validators.required]);

        if (data) {
            group.patchValue(data);
        } else {
            if (isDefaultRow) {
                this.form.get('landingpage_header_label').clearValidators();
                group.get('label').clearValidators();
                group.get('url').clearValidators();
                group.get('approve').clearValidators();
                group.updateValueAndValidity();
            }
        }

        this.form.get('landingpage_header_label').markAsTouched();
        this.form.get('landingpage_header_label').updateValueAndValidity();

        arr.push(group);
    }

    public removeHomepageLink(idx: number) {
        if (this.homepageLinksArray.length === 1) {
            this.homepageLinksArray.controls[0].reset();
            this.removeRequiredFromHomepageLinks(0);
            this.form.get('landingpage_header_label').clearValidators();
            this.form.get('landingpage_header_label').updateValueAndValidity();
        } else {
            this.homepageLinksArray.removeAt(idx);
        }
    }

    public uploadFile(e) {
        if (e) {
            this.form
                .get(`${e.control_name}_url`)
                .setValue(
                    this.santizer.bypassSecurityTrustResourceUrl(e.preview_url)
                );
            this.form.get(`${e.control_name}_file`).setValue(e.file);
        }
    }

    private initializeForm() {
        const s = this.site;
        this.form = this.fb.group({
            id: { value: s.id, disabled: true },
            name: [s.name, [Validators.required, Validators.maxLength(40)]],
            description: [s.description, [Validators.maxLength(191)]],
            active: [s.active],
            endpoint_identifier: [
                s.endpoint_identifier,
                [Validators.required, Validators.maxLength(30)],
                [new EndpointAvailableValidator(this.service, s.id)],
            ],
            alternative_homepage: [
                s.alternative_homepage,
                [Validators.maxLength(255), Validators.pattern(this._pattern)],
            ],
            enable_cname: { value: s.enable_cname, disabled: true },
            cname: { value: s.cname, disabled: true },
            primary_color: [
                s.primary_color,
                [
                    Validators.required,
                    Validators.maxLength(7),
                    Validators.pattern(/^#[0-9a-fA-F]{6}$/i),
                ],
            ],
            secondary_color: [
                s.secondary_color,
                [
                    Validators.required,
                    Validators.maxLength(7),
                    Validators.pattern(/^#[0-9a-fA-F]{6}$/i),
                ],
            ],
            success_color: [
                s.success_color,
                [
                    Validators.required,
                    Validators.maxLength(7),
                    Validators.pattern(/^#[0-9a-fA-F]{6}$/i),
                ],
            ],
            info_color: [
                s.info_color,
                [
                    Validators.required,
                    Validators.maxLength(7),
                    Validators.pattern(/^#[0-9a-fA-F]{6}$/i),
                ],
            ],
            warning_color: [
                s.warning_color,
                [
                    Validators.required,
                    Validators.maxLength(7),
                    Validators.pattern(/^#[0-9a-fA-F]{6}$/i),
                ],
            ],
            danger_color: [
                s.danger_color,
                [
                    Validators.required,
                    Validators.maxLength(7),
                    Validators.pattern(/^#[0-9a-fA-F]{6}$/i),
                ],
            ],
            light_color: [
                s.light_color,
                [
                    Validators.required,
                    Validators.maxLength(7),
                    Validators.pattern(/^#[0-9a-fA-F]{6}$/i),
                ],
            ],
            dark_color: [
                s.dark_color,
                [
                    Validators.required,
                    Validators.maxLength(7),
                    Validators.pattern(/^#[0-9a-fA-F]{6}$/i),
                ],
            ],
            font_sans_serif: [s.font_sans_serif, [Validators.required]],
            font_weight_normal: [s.font_weight_normal, [Validators.required]],
            font_headers: [s.font_headers, [Validators.required]],
            font_weight_headings: [
                s.font_weight_headings,
                [Validators.required],
            ],
            page_title: [
                s.page_title,
                [Validators.required, Validators.maxLength(100)],
            ],
            navbar_brand_title: [
                s.navbar_brand_title,
                [Validators.required, Validators.maxLength(191)],
            ],
            mailing_from_name: [
                s.mailing_from_name,
                [Validators.required, Validators.maxLength(60)],
            ],
            mailing_company_name: [
                s.mailing_company_name,
                [Validators.required, Validators.maxLength(60)],
            ],
            notifications_reply_to_address: [
                s.notifications_reply_to_address,
                [Validators.email, Validators.maxLength(191)],
            ],
            terms_and_conditions_label: [
                s.terms_and_conditions_label,
                [Validators.required, Validators.maxLength(30)],
            ],
            terms_and_conditions_url: [
                s.terms_and_conditions_url,
                [
                    Validators.required,
                    Validators.maxLength(255),
                    Validators.pattern(this._pattern),
                ],
            ],
            privacy_policy_label: [
                s.privacy_policy_label,
                [Validators.required, Validators.maxLength(30)],
            ],
            privacy_policy_url: [
                s.privacy_policy_url,
                [
                    Validators.required,
                    Validators.maxLength(255),
                    Validators.pattern(this._pattern),
                ],
            ],
            cookie_policy_label: [
                s.cookie_policy_label,
                [Validators.required, Validators.maxLength(30)],
            ],
            cookie_policy_url: [
                s.cookie_policy_url,
                [
                    Validators.required,
                    Validators.maxLength(255),
                    Validators.pattern(this._pattern),
                ],
            ],
            booking_terms_url: [
                s.booking_terms_url,
                [Validators.maxLength(255), Validators.pattern(this._pattern)],
            ],
            ga_tracking_id: [s.ga_tracking_id, [Validators.maxLength(15)]],
            logo_url: [s.logo_url, [Validators.required]],
            contrast_logo_url: [s.contrast_logo_url],
            favicon_url: [s.favicon_url, [Validators.required]],
            homepage_hero_url: [s.homepage_hero_url, [Validators.required]],
            logo_file: [null],
            contrast_logo_file: [null],
            favicon_file: [null],
            homepage_hero_file: [null],
            homepage_links: this.fb.array([], [Validators.maxLength(5)]),
            homepage_links_color: [
                s.homepage_links_color,
                [Validators.required],
            ],
            more_info_link: [
                s.more_info_link,
                [Validators.pattern(this._pattern)],
            ],
            landingpage_header_label: [s.homepage_links?.header_label],

            new_landingpage_optin: [s.new_landingpage_optin],
            new_landingpage_title_text: [
                s.new_landingpage_title_text,
                [Validators.maxLength(191)],
            ],
            new_landingpage_teaser_text: [
                s.new_landingpage_teaser_text,
                [Validators.maxLength(2000)],
            ],
            new_landingpage_show_navbar: [s.new_landingpage_show_navbar],
            new_landingpage_dark_navbar: [s.new_landingpage_dark_navbar],
            landingpage_default_origin_type: [
                s.landingpage_default_origin_type,
                [Validators.required],
            ],
            landingpage_default_destination_type: [
                s.landingpage_default_destination_type,
                [Validators.required],
            ],
            landingpage_default_additionals: [
                s.landingpage_default_additionals || [],
            ],
            landingpage_component_order: [
                s.landingpage_component_order || ['quotations', 'login'],
            ],
            landingpage_default_currency: [s.landingpage_default_currency],
            landingpage_require_port_entry_for_door: [
                s.landingpage_require_port_entry_for_door,
            ],
            landingpage_quote_expiration_leeway: [
                s.landingpage_quote_expiration_leeway,
                [
                    ReactiveValidators.required(
                        this.translate.instant('common.required')
                    ),
                    ReactiveValidators.min(0),
                ],
            ],
            show_rate_carrier_info: [s.show_rate_carrier_info],
            is_quotes_hide_schedules: [s.is_quotes_hide_schedules],
            is_quotes_quickbook_enabled: [s.is_quotes_quickbook_enabled],
        });

        (this.form.get('homepage_links') as FormArray).clear();
        if (s.homepage_links?.links) {
            s.homepage_links.links.forEach(e => this.addHomepageLink(e));
        }
        if (
            !s.homepage_links ||
            (s.homepage_links && !s.homepage_links.links) ||
            (s.homepage_links && !s.homepage_links.links.length)
        ) {
            this.addHomepageLink(null, true);
        }
    }
}
