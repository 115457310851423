import { Component, Input, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { Portals } from '@qwyk/models';
import { Constants, LocalStorageService } from '@qwyk/core';

@Component({
    selector: 'qwyk-quotation-request-details',
    templateUrl: './quotation-request-details.component.html',
    styleUrls: ['./quotation-request-details.component.scss'],
})
export class QuotationRequestDetailsComponent implements OnInit {
    @Input() quotation!: Portals.QuotationEntity;
    public incoTermsMap = Constants.INCO_TERMS.reduce(
        (prev, current) =>
            Object.assign(prev, { [current.code]: current.name }),
        {}
    );
    public displayUOM = 'metric';
    private readonly expandedStorageKey = 'dfp.hub.quotations.request.expanded';

    constructor(
        private translate: TranslateService,
        private localStorage: LocalStorageService
    ) {
        this._expanded = this.localStorage.getBool(
            this.expandedStorageKey,
            true
        );
    }

    private _expanded = true;

    get expanded(): boolean {
        return this._expanded;
    }

    set expanded(value: boolean) {
        this._expanded = value;
        this.localStorage.setBool(this.expandedStorageKey, value);
    }

    private _additionals: string[];

    public get additionals(): string {
        return this._additionals.join(' &bull; ');
    }

    ngOnInit() {
        const additionals = { ...this.quotation.request.additionals };
        additionals['freight_charges'] =
            this.quotation.request.require_freight_charges;
        additionals['origin_charges'] =
            this.quotation.request.require_origin_charges;
        additionals['destination_charges'] =
            this.quotation.request.require_destination_charges;
        this._additionals = Object.entries(additionals)
            .filter(e => e[1])
            .map(e => e[0])
            .sort((a, b) => (a > b ? 1 : -1))
            .map(e => {
                const replacement = e.trim().split('_').join('-');
                return this.translate.instant(
                    'hub.quotations.additionals.' + replacement
                );
            });
    }
}
