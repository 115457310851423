import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'isArray',
})
export class IsArrayPipe implements PipeTransform {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
    transform(value: any, ...args: any[]): any {
        return Array.isArray(value);
    }
}
