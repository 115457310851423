<div class="modal-body">
    <div class="lead">{{ 'hub.schedules.routing-guide.add-a-new-route' | translate }}</div>

    <!-- Dirty hack to get around PrimeNG issue-->
    <input type="text" class="d-none" />
    <form class="qw-form" [formGroup]="itemForm" (submit)="submit()">
        <div class="form-group">
            <label>{{ 'common.quotations.search.origin' | translate }}</label>
            <p-autoComplete formControlName="origin" [forceSelection]="true" class="p-fluid"
                [suggestions]="searchLocationResults" (completeMethod)="searchLocation($event)" field="display_name"
                inputStyleClass="form-control  mw-100" required (onSelect)="onLocationSelect($event, 'origin')">
                <ng-template let-value pTemplate="item">
                    <div class="autocomplete-list-location p-2" [innerHtml]="value.highlight"></div>
                </ng-template>
            </p-autoComplete>
            <div class="validation-error" *ngIf="itemForm.get('origin').invalid && itemForm.get('origin').touched">
                {{ 'hub.schedules.routing-guide.please-select-a-place-from-the-list' | translate }}
            </div>
        </div>

        <div class="form-group">
            <label>{{ 'hub.quotations.create.fields.destination' | translate }}</label>
            <p-autoComplete formControlName="destination" [forceSelection]="true" class="p-fluid"
                [suggestions]="searchLocationResults" (completeMethod)="searchLocation($event)" field="display_name"
                inputStyleClass="form-control mw-100" required (onSelect)="onLocationSelect($event, 'destination')">
                <ng-template let-value pTemplate="item">
                    <div class="autocomplete-list-location p-2" [innerHtml]="value.highlight"></div>
                </ng-template>
            </p-autoComplete>
            <div class="validation-error"
                *ngIf="itemForm.get('destination').invalid && itemForm.get('destination').touched">
                {{ 'hub.schedules.routing-guide.please-select-a-place-from-the-list' | translate }}
            </div>
        </div>

        <div class="form-group" *ngIf="providers$ | async; let providers;">
            <label>{{ 'common.provider-s' | translate }}</label>
            <ng-select
                [items]="providers"
                bindLabel="name"
                [multiple]="true"
                formControlName="providers"
                groupBy="product"
                notFoundText="{{ 'common.no_items_found_short' | translate : { value: 'common.items' | translate | lowercase } }}"
                clearAllText="{{ 'common.clear-all' | translate }}"
                required>
                <ng-template ng-optgroup-tmp let-item="item">
                    <fa-icon [icon]="['far', productIcons[item.product]]" class="mr-1">
                    </fa-icon>
                    {{ ('hub.schedules.routing-guide.titles.' + item.product | translate) || ('common.none' | translate) }}
                </ng-template>
            </ng-select>
            <div class="validation-error"
                *ngIf="itemForm.get('providers').invalid && itemForm.get('providers').touched">
                {{ 'hub.schedules.routing-guide.please-select-at-least-one-provider' | translate }}
            </div>
        </div>

        <div class="alert alert-danger mt-4" *ngIf="error">
            <fa-icon [icon]="['far', 'exclamation-triangle']" class="mr-1"></fa-icon>
            {{ 'hub.schedules.routing-guide.there-was-an-error-saving' | translate }} <i>{{ 'common.status' | translate }}:
                {{error?.status}}</i>
        </div>
        <div class="alert alert-warning mt-4" role="alert" *ngIf="validationErrors.length > 0">
            <h6 class="alert-heading">
                <fa-icon [icon]="['far', 'exclamation-triangle']" class="mr-1"></fa-icon> {{ 'common.there-were-validation-errors' | translate }}
            </h6>
            <hr class="mt-0">
            <ul class="mb-0">
                <li *ngFor="let e of validationErrors">{{ e }}</li>
            </ul>
        </div>

        <div class="form-group mt-4 mb-0">
            <button class="btn btn-primary mr-1" type="submit" [disabled]="itemForm.disabled">
                <ng-container *ngIf="!creating; else busyCreating">
                    {{ 'common.save' | translate }}
                </ng-container>
            </button>
            <button class="btn btn-light" type="button" (click)="activeModal.dismiss()"
                [disabled]="itemForm.disabled">{{ 'common.cancel' | translate }}</button>
        </div>
    </form>
</div>

<ng-template #busyCreating>
    <fa-icon [icon]="['far', 'spinner']" pulse="true" class="mr-1"></fa-icon> {{ 'common.saving' | translate }}
</ng-template>
