import { ToastrService } from 'ngx-toastr';
import {
    loadProviders,
    loadProvidersSuccess,
    loadProvidersFailure,
    activateProvider,
    activateProviderSuccess,
    activateProviderFailure,
    deactivateProvider,
    deactivateProviderSuccess,
    deactivateProviderFailure,
    deleteProvider,
    deleteProviderSuccess,
    deleteProviderFailure,
    createProvider,
    createProviderSuccess,
    createProviderFailure,
} from './../actions/providers.actions';
import { ProvidersService } from '../../services/providers.service';
import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { mergeMap, map, catchError, tap, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class ProvidersEffects {
    /**
     * Loads providers from the server and dispatches Success or Failure
     */
    loadProviders$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadProviders),
            mergeMap(() =>
                this.service.getProviders().pipe(
                    map(providers => loadProvidersSuccess({ providers })),
                    catchError(() => of(loadProvidersFailure()))
                )
            )
        )
    );

    /**
     * Displays an error toast on providers load failure
     */
    loadProvidersFailure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(loadProvidersFailure),
                tap(() => {
                    this.toastrService.error(
                        'Try again by refreshing the page or contact support if the problem persists.',
                        'Error while loading providers'
                    );
                })
            ),
        { dispatch: false }
    );

    /**
     * Sends an create provider request to the server and dispatches Success or Failure
     */
    createProvider$ = createEffect(() =>
        this.actions$.pipe(
            ofType(createProvider),
            switchMap(action =>
                this.service.createProvider(action.provider).pipe(
                    map(provider => createProviderSuccess({ provider })),
                    catchError(error => of(createProviderFailure({ error })))
                )
            )
        )
    );

    /**
     * Sends an activate provider request to the server and dispatches Success or Failure
     */
    activateProvider$ = createEffect(() =>
        this.actions$.pipe(
            ofType(activateProvider),
            mergeMap(action =>
                this.service.activateProvider(action.provider).pipe(
                    map(() => activateProviderSuccess()),
                    catchError(() =>
                        of(
                            activateProviderFailure({
                                provider: action.provider,
                            })
                        )
                    )
                )
            )
        )
    );

    /**
     * Displays an error toast on provider activation failure
     */
    activateProviderFailure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(activateProviderFailure),
                tap(() => {
                    this.toastrService.error(
                        'Try again, refresh the page, or contact support if the problem persists.',
                        'Error while activating provider'
                    );
                })
            ),
        { dispatch: false }
    );

    /**
     * Sends a activate provider request to the server and dispatches Success or Failure
     */
    deactivateProvider$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deactivateProvider),
            mergeMap(action =>
                this.service.deactivateProvider(action.provider).pipe(
                    map(() => deactivateProviderSuccess()),
                    catchError(() =>
                        of(
                            deactivateProviderFailure({
                                provider: action.provider,
                            })
                        )
                    )
                )
            )
        )
    );

    /**
     * Displays an error toast on provider deactivation failure
     */
    deactivateProviderFailure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(deactivateProviderFailure),
                tap(() => {
                    this.toastrService.error(
                        'Try again, refresh the page, or contact support if the problem persists.',
                        'Error while deactivating provider'
                    );
                })
            ),
        { dispatch: false }
    );

    /**
     * Sends a delete provider request to the server and dispatches Success or Failure
     */
    deleteProvider$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteProvider),
            mergeMap(action =>
                this.service.deleteProvider(action.provider).pipe(
                    map(() => deleteProviderSuccess()),
                    catchError(error =>
                        of(
                            deleteProviderFailure({
                                provider: action.provider,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    /**
     * Displays an error toast on provider deactivation failure
     */
    deleteProviderFailure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(deleteProviderFailure),
                tap(action => {
                    const err = action.error;
                    if (
                        err instanceof HttpErrorResponse &&
                        (err as HttpErrorResponse).status === 403
                    ) {
                        this.toastrService.warning(
                            // tslint:disable-next-line: quotemark
                            "You don't have the required permissions to delete this provider.",
                            'Unable to delete provider'
                        );
                        return;
                    }

                    this.toastrService.error(
                        'Try again, refresh the page, or contact support if the problem persists.',
                        'Error while deleting provider'
                    );
                })
            ),
        { dispatch: false }
    );

    constructor(
        private actions$: Actions,
        private service: ProvidersService,
        private toastrService: ToastrService
    ) {}
}
