import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import {
    NgbTooltipModule,
    NgbDropdownModule,
} from '@ng-bootstrap/ng-bootstrap';
import { QuillModule } from 'ngx-quill';
import { MomentModule } from 'ngx-moment';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { UiModule } from '@qwyk/ui';

import { components, exportableComponents } from './components';

@NgModule({
    imports: [
        CommonModule,
        UiModule,
        FormsModule,
        MomentModule,
        TranslateModule,
        NgbTooltipModule,
        FontAwesomeModule,
        NgbDropdownModule,
        QuillModule.forRoot({
            modules: {
                syntax: false,
                toolbar: [
                    [
                        'bold',
                        'italic',
                        'underline',
                        'strike',
                        'blockquote',
                        'link',
                    ],
                    [{ list: 'ordered' }, { list: 'bullet' }],
                    ['clean'],
                ],
            },
        }),
    ],
    declarations: components,
    exports: exportableComponents,
})
export class ActivitiesUiModule {}
