<p-table
    [sortOrder]="-1"
    [value]="rateRequests"
    dataKey="id"
    sortField="created_at"
>
    <ng-template pTemplate="header">
        <tr>
            <th style="width: 120px">{{ 'common.sort_options.status' | translate }}</th>
            <th>{{ 'hub.quotations.details.request' | translate }}</th>
            <th>{{ 'common.by' | translate | titlecase }}</th>
            <th>{{ 'common.date' | translate }}</th>
            <th style="max-width: 225px"></th>
        </tr>
    </ng-template>

    <ng-template let-rateRequest pTemplate="body">
        <tr [ngClass]="{ 'border-bottom-0': !rateRequest.open }">
            <td
                class="col-md-1"
                [attr.rowspan]="rateRequest.open ? 1 : 2"
                style="font-size: 1.2em"
            >
                <span *ngIf="rateRequest.open" class="badge badge-warning"
                    >{{ 'hub.quotations.status.open' | translate }}</span
                >
                <span *ngIf="!rateRequest.open" class="badge badge-success"
                    >{{ 'hub.quotations.status.closed' | translate }}</span
                >
            </td>
            <td class="col-md-6">
                <div>{{ rateRequest.reason }}</div>
                <div>{{ rateRequest.remarks }}</div>
            </td>
            <td class="col-md-1">
                <span
                    [ngClass]="{ 'text-underline-dashed': rateRequest.creator }"
                    [ngbPopover]="
                        rateRequest.creator ? creatorUserPopoverContent : null
                    "
                    placement="bottom"
                    triggers="mouseenter:mouseleave"
                >
                    {{ rateRequest.creator?.name || ('common.not-assigned' | translate) }}
                </span>
                <ng-template #creatorUserPopoverContent>
                    <qwyk-user-info-tooltip
                        *ngIf="rateRequest.creator"
                        [user]="rateRequest.creator"
                    >
                    </qwyk-user-info-tooltip>
                </ng-template>
                <span
                    *ngIf="!outbound && rateRequest.is_network_request"
                    class="badge badge-light badge-pill"
                    >{{ 'hub.quotations.details.network-request' | translate }}</span
                >
            </td>
            <td class="col-md-1">{{ rateRequest.created_at | amTimeAgo }}</td>
            <td class="text-right col-md-3">
                <ng-container *ngIf="!outbound && rateRequest.open">
                    <button
                        [routerLink]="['./offers/new']"
                        [queryParams]="{ request_id: rateRequest.id }"
                        class="btn btn-primary btn-sm"
                        ngbTooltip="{{ 'hub.quotations.details.offer.create-new' | translate }}"
                    >
                        {{ 'hub.quotations.details.offer.create-new' | translate }}
                    </button>
                    <button
                        (click)="closeRequest(rateRequest)"
                        class="btn btn-outline-danger btn-sm ml-2"
                        ngbTooltip="{{ 'hub.quotations.details.offer.close-tooltip' | translate }}"
                    >
                        {{ 'common.close' | translate }}
                    </button>
                </ng-container>
            </td>
        </tr>

        <tr *ngIf="!rateRequest.open" class="border-top-0">
            <td class="border-top-0">{{ rateRequest.response_message }}</td>
            <td class="border-top-0">
                <span
                    [ngClass]="{
                        'text-underline-dashed': rateRequest.responder
                    }"
                    [ngbPopover]="
                        rateRequest.responder
                            ? responderUserPopoverContent
                            : null
                    "
                    placement="bottom"
                    triggers="mouseenter:mouseleave"
                >
                    {{ rateRequest.responder?.name || ('common.not-assigned' | translate) }}
                </span>
                <ng-template #responderUserPopoverContent>
                    <qwyk-user-info-tooltip
                        *ngIf="rateRequest.responder"
                        [user]="rateRequest.responder"
                    >
                    </qwyk-user-info-tooltip>
                </ng-template>
            </td>
            <td class="border-top-0">
                {{ rateRequest.updated_at | amTimeAgo }}
            </td>
            <td class="border-top-0"></td>
        </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
        <tr>
            <td class="text-center" colspan="5">
                {{ (outbound ? 'hub.quotations.details.no-requests-created' : 'hub.quotations.details.no-requests-received') | translate }}.
            </td>
        </tr>
    </ng-template>
</p-table>
