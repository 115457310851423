import { Component, OnInit } from '@angular/core';
import { QuotationsFacade } from '@qwyk/shared-stores/quotations';
import { QuotationsService } from '../../services/quotations.service';
import { map, take, tap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { LocalStorageService } from '@qwyk/core';
import { saveAs } from 'file-saver';

@Component({
    selector: 'qwyk-quotation-detail',
    templateUrl: './quotation-detail.component.html',
    styleUrls: ['./quotation-detail.component.scss'],
})
export class QuotationDetailComponent implements OnInit {
    public selectedQuotation$ = this.quotations.selectedQuotations$;
    public polling$ = this.route.queryParamMap.pipe(
        map(p => p.has('poll') && p.get('poll') === 'true')
    );
    public view$ = this.route.queryParamMap.pipe(
        map(params => {
            if (params.has('view')) {
                return params.get('view');
            }
            return 'offers';
        })
    );

    constructor(
        private quotations: QuotationsFacade,
        private service: QuotationsService,
        private route: ActivatedRoute,
        private localStorage: LocalStorageService
    ) {}

    private _displayOfferBuyingInfo = false;

    public get displayOfferBuyingInfo(): boolean {
        return this._displayOfferBuyingInfo;
    }

    public set displayOfferBuyingInfo(value: boolean) {
        this._displayOfferBuyingInfo = value;
        this.localStorage.setBool(
            'quotations.quotation-detail.display-offer-buying-info',
            value
        );
    }

    ngOnInit() {
        this.displayOfferBuyingInfo = this.localStorage.getBool(
            'quotations.quotation-detail.display-offer-buying-info'
        );
    }

    onDeleteOffer(e) {
        const subscription = this.service
            .deleteQuotationOffer(e.quotation, e.offer)
            .pipe(
                take(1),
                tap(quotation => this.quotations.quotationUpdated(quotation))
            )
            .subscribe(
                () => {
                    subscription.unsubscribe();
                },
                () => {
                    subscription.unsubscribe();
                    alert(
                        'Something went wrong, please try again or contact support if the problem persists.'
                    );
                }
            );
    }

    onDownloadOffer(e) {
        const subscription = this.service
            .downloadQuotation(e.quotation.id, e.offer.id)
            .pipe(take(1))
            .subscribe(
                response => {
                    subscription.unsubscribe();
                    saveAs(response, `quotation-${e.quotation.id}.pdf`);
                },
                () => {
                    subscription.unsubscribe();
                    alert(
                        'Something went wrong, please try again or contact support if the problem persists.'
                    );
                }
            );
    }
}
