import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {EntityExistsGuard} from '@qwyk/core';
import {Observable, of} from 'rxjs';
import * as fromCustomerUsers from '../+state/customer-users.reducer';
import * as customerUsersSelectors from '../+state/customer-users.selectors';
import * as CustomerUsersActions from '../+state/customer-users.actions';
import {CustomerUsersService} from '../services/customer-users.service';
import {catchError, map, take, tap} from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class CustomerUserExistsGuard extends EntityExistsGuard<string> {
    constructor(
        protected store: Store<fromCustomerUsers.CustomerUsersPartialState>,
        protected service: CustomerUsersService,
        protected router: Router
    ) {
        super();
    }

    protected hasEntityInStore(id: string): Observable<boolean> {
        return this.store.pipe(
            select(customerUsersSelectors.getCustomerUsersEntities),
            map(entities => !!entities[id]),
            take(1)
        );
    }

    protected hasEntityInApi(
        id: string,
        next: ActivatedRouteSnapshot
    ): Observable<boolean> {
        return this.service.getCustomerUser(id).pipe(
            map(customerUser =>
                CustomerUsersActions.loadCustomerUserSuccess({customerUser})
            ),
            tap(action => this.store.dispatch(action)),
            map(customerUser => !!customerUser),
            catchError(error => {
                this.router.navigate([`/error/${error.status}`], {
                    queryParams: {
                        source: next.pathFromRoot.map(p => p.url).join('/'),
                        errorName: error.statusText,
                    },
                });
                return of(false);
            })
        );
    }

    protected getEntityKey(next: ActivatedRouteSnapshot): string {
        return next.params['id'];
    }
}
