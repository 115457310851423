<quill-editor
    #ctrl="ngModel"
    (ngModelChange)="htmlChange.emit($event)"
    [maxLength]="1000"
    [ngModel]="html"
    [readOnly]="readonly"
    [styles]="{'min-height': '100px'}"
    [trimOnValidation]="true"
    placeholder="{{ 'hub.activities.write-a-comment' | translate }}"
    [formats]="quillFormats"
    ngDefaultControl
></quill-editor>
<p *ngIf="ctrl.invalid" class="small text-danger">{{ 'common.validation.exceeded-length' | translate }}</p>
