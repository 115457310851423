import { Component, Input } from '@angular/core';
import { Portals } from '@qwyk/models';
import * as _ from 'lodash';
import { UnitConvertPipe } from '@qwyk/ui-standalone';

@Component({
    selector: 'qwyk-quotation-view-cargo',
    templateUrl: './quotation-view-cargo.component.html',
    styleUrls: ['./quotation-view-cargo.component.scss'],
})
export class QuotationViewCargoComponent {
    @Input() request: Portals.QuotationRequest;
    @Input() public displayUOM: 'metric' | 'imperial' = 'metric';
    private convert = new UnitConvertPipe();

    constructor() {}

    public get totalWeight(): number {
        return _.sum(
            this.request.packages.map(p =>
                this.convert.transform(
                    p.quantity * p.unit_weight,
                    'mass',
                    p.uom,
                    this.displayUOM
                )
            )
        );
    }

    public get totalVolume(): number {
        return _.sum(
            this.request.packages.map(p =>
                this.convert.transform(
                    p.quantity * p.unit_volume,
                    'volume',
                    p.uom,
                    this.displayUOM
                )
            )
        );
    }

    public get totalChargeableWeight(): number {
        return this.convert.transform(
            this.request.total_chargeable_weight_kgs,
            'mass',
            'metric',
            this.displayUOM
        );
    }
}
