<div class="bg-white min-vh-100">
    <div class="container-fluid py-3 pb-5">
        <div class="row justify-content-center">
            <div class="col-12 col-lg-9 col-xxxl-6">
                <div class="small text-muted mb-3 border-bottom pb-2">
                    <a class="text-muted" [routerLink]="['/']">Qwyk</a>
                    &rsaquo;
                    <a class="text-muted" [routerLink]="['./../../']">{{ 'portal.menu.side-menu.settings' | translate }}</a>
                    &rsaquo;
                    <a class="text-muted" [routerLink]="['./../']">{{ 'hub.settings.users.hub-users' | translate }}</a>
                    &rsaquo;
                    <a class="text-muted font-weight-bold" [routerLink]="['./']"
                        *ngIf="selectedUser$ | async; let user; else newUser">{{ user.email }}</a>
                    <ng-template #newUser> <a class="text-muted font-weight-bold" [routerLink]="['./']">{{ 'common.new' | translate }}</a>
                    </ng-template>
                </div>

                <div class="d-flex flex-row justify-content-between mb-5">
                    <h3 class="font-weight-normal m-0">
                        <ng-container *ngIf="(selectedUser$ | async) === false">{{ 'common.new' | translate }} </ng-container>{{ 'hub.settings.users.hub-user' | translate }}
                    </h3>
                </div>

                <qwyk-user-detail *ngIf="selectedUser$ | async; let user; else create"
                                  [currentUser]="user$ | async"
                                  [user]="user" [error]="error$ | async"
                                  [saving]="loading$ | async"
                                  (update)="onUpdate($event)"
                                  (updateHubUserStatus)="onUpdateHubUserStatus($event)"
                >
                </qwyk-user-detail>

                <ng-template #create>
                    <qwyk-user-create (create)="onCreate($event)" [error]="error$ | async" [saving]="loading$ | async">
                    </qwyk-user-create>
                </ng-template>
            </div>
        </div>
    </div>
</div>
