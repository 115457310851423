/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAction, props } from '@ngrx/store';
import { common, Portals } from '@qwyk/models';

export const loadQuotationOffers = createAction(
    '[Hub/Quotations/Offers] Load QuotationOffers',
    props<{ quotationId: string; query: any }>()
);

export const loadQuotationOffersSuccess = createAction(
    '[Hub/Quotations/Offers] Load QuotationOffers Success',
    props<{
        quotationOffers: Portals.QuotationOffer[];
        pagination: common.PaginationMeta;
    }>()
);

export const loadQuotationOffersFailure = createAction(
    '[Hub/Quotations/Offers] Load QuotationOffers Failure',
    props<{ error: any }>()
);

export const loadQuotationOfferSuccess = createAction(
    '[Hub/Quotations/Offers] Load QuotationsOffer Success',
    props<{ quotationOffer: Portals.QuotationOffer }>()
);

export const selectQuotationOffer = createAction(
    '[Hub/Quotations/Offers] Select QuotationOffer',
    props<{ selectedId: string }>()
);

export const createQuotationOffer = createAction(
    '[Hub/Quotations/Offers] Create QuotationOffer',
    props<{ quotationId: string; payload: Portals.QuotationOffer }>()
);
export const createQuotationOfferSuccess = createAction(
    '[Hub/Quotations/Offers] Create QuotationsOffer Success',
    props<{ quotationOffer: Portals.QuotationOffer }>()
);
export const createQuotationOfferFailure = createAction(
    '[Hub/Quotations/Offers] Create QuotationOffer Failure',
    props<{ error: any }>()
);

export const updateQuotationOffer = createAction(
    '[Hub/Quotations/Offers] Update QuotationOffer',
    props<{
        quotationId: string;
        quotationOfferId: string;
        payload: Portals.QuotationOffer;
    }>()
);
export const updateQuotationOfferSuccess = createAction(
    '[Hub/Quotations/Offers] Update QuotationsOffer Success',
    props<{ quotationOffer: Portals.QuotationOffer }>()
);
export const updateQuotationOfferFailure = createAction(
    '[Hub/Quotations/Offers] Update QuotationOffer Failure',
    props<{ error: any }>()
);

export const deleteQuotationOffer = createAction(
    '[Hub/Quotations/Offers] Delete QuotationOffer',
    props<{ quotationId: string; quotationOfferId: string }>()
);
export const deleteQuotationOfferSuccess = createAction(
    '[Hub/Quotations/Offers] Delete QuotationsOffer Success',
    props<{ quotationOfferId: string }>()
);
export const deleteQuotationOfferFailure = createAction(
    '[Hub/Quotations/Offers] Delete QuotationOffer Failure',
    props<{ error: any }>()
);

export const generateQuotationOfferPdf = createAction(
    '[Hub/Quotations/Offers] Generate QuotationOffer PDF',
    props<{ quotationId: string; quotationOfferId: string }>()
);
export const generateQuotationOfferPdfSuccess = createAction(
    '[Hub/Quotations/Offers] Generate QuotationsOffer PDF Success',
    props<{ blob: Blob }>()
);
export const generateQuotationOfferPdfFailure = createAction(
    '[Hub/Quotations/Offers] Generate QuotationOffer PDF Failure',
    props<{ error: any }>()
);

export const dismissError = createAction(
    '[Hub/Quotations/Offers] Dismiss Error'
);

export const reset = createAction('[Hub/Quotations/Offers] Reset');
