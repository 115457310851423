import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetch } from '@nx/angular';
import * as QuotationOffersActions from './quotation-offers.actions';
import { QuotationOffersService } from '../services/quotation-offers.service';
import { map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { saveAs } from 'file-saver';
import { QuotationsFacade } from '@qwyk/shared-stores/quotations';

@Injectable()
export class QuotationOffersEffects {
    init$ = createEffect(() =>
        this.actions$.pipe(
            ofType(QuotationOffersActions.loadQuotationOffers),
            fetch({
                run: action => {
                    return this.quotationOffersService
                        .getQuotationOffers(action.quotationId, action.query)
                        .pipe(
                            map(response =>
                                QuotationOffersActions.loadQuotationOffersSuccess(
                                    {
                                        quotationOffers: response.data,
                                        pagination: response.meta,
                                    }
                                )
                            )
                        );
                },

                onError: (action, error) => {
                    console.error(error);
                    return QuotationOffersActions.loadQuotationOffersFailure({
                        error,
                    });
                },
            })
        )
    );

    createQuotationOffer$ = createEffect(() =>
        this.actions$.pipe(
            ofType(QuotationOffersActions.createQuotationOffer),
            fetch({
                run: action => {
                    return this.quotationOffersService
                        .createQuotationOffer(
                            action.quotationId,
                            action.payload
                        )
                        .pipe(
                            map(quotationOffer =>
                                QuotationOffersActions.createQuotationOfferSuccess(
                                    {
                                        quotationOffer,
                                    }
                                )
                            )
                        );
                },

                onError: (action, error) => {
                    console.error(error);
                    return QuotationOffersActions.createQuotationOfferFailure({
                        error,
                    });
                },
            })
        )
    );

    updateQuotationOffer$ = createEffect(() =>
        this.actions$.pipe(
            ofType(QuotationOffersActions.updateQuotationOffer),
            fetch({
                run: action => {
                    return this.quotationOffersService
                        .updateQuotationOffer(
                            action.quotationId,
                            action.quotationOfferId,
                            action.payload
                        )
                        .pipe(
                            map(quotationOffer =>
                                QuotationOffersActions.updateQuotationOfferSuccess(
                                    {
                                        quotationOffer,
                                    }
                                )
                            )
                        );
                },

                onError: (action, error) => {
                    console.error(error);
                    return QuotationOffersActions.updateQuotationOfferFailure({
                        error,
                    });
                },
            })
        )
    );

    deleteQuotationOffer$ = createEffect(() =>
        this.actions$.pipe(
            ofType(QuotationOffersActions.deleteQuotationOffer),
            fetch({
                run: action => {
                    return this.quotationOffersService
                        .deleteQuotationOffer(
                            action.quotationId,
                            action.quotationOfferId
                        )
                        .pipe(
                            map(() =>
                                QuotationOffersActions.deleteQuotationOfferSuccess(
                                    {
                                        quotationOfferId:
                                            action.quotationOfferId,
                                    }
                                )
                            )
                        );
                },

                onError: (action, error) => {
                    console.error(error);
                    return QuotationOffersActions.deleteQuotationOfferFailure({
                        error,
                    });
                },
            })
        )
    );

    generateQuotationOfferPdf$ = createEffect(() =>
        this.actions$.pipe(
            ofType(QuotationOffersActions.generateQuotationOfferPdf),
            tap(() => {
                this.toastr.info('Your PDF is being generated...');
            }),
            fetch({
                run: action => {
                    return this.quotationOffersService
                        .generateQuotationOfferPdf(
                            action.quotationId,
                            action.quotationOfferId
                        )
                        .pipe(
                            map(blob =>
                                QuotationOffersActions.generateQuotationOfferPdfSuccess(
                                    { blob }
                                )
                            )
                        );
                },

                onError: (action, error) => {
                    console.error(error);
                    return QuotationOffersActions.generateQuotationOfferPdfFailure(
                        {
                            error,
                        }
                    );
                },
            })
        )
    );

    generateShipmentPdfSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(QuotationOffersActions.generateQuotationOfferPdfSuccess),
                map(action => {
                    const filename = prompt('Save as', 'quotation.pdf');
                    if (null !== filename) {
                        saveAs(action.blob, filename);
                    }
                })
            ),
        { dispatch: false }
    );

    createQuotationOfferSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(QuotationOffersActions.createQuotationOfferSuccess),
                map(action => {
                    this.quotations.loadQuotation(
                        action.quotationOffer.quotation_id
                    );
                })
            ),
        { dispatch: false }
    );

    cruQuotationOfferSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    QuotationOffersActions.createQuotationOfferSuccess,
                    QuotationOffersActions.updateQuotationOfferSuccess
                ),
                map(action => {
                    this.router.navigate([
                        '/',
                        'quotations',
                        action.quotationOffer.quotation_id,
                    ]);
                })
            ),
        { dispatch: false }
    );

    constructor(
        private actions$: Actions,
        private quotationOffersService: QuotationOffersService,
        private quotations: QuotationsFacade,
        private router: Router,
        private toastr: ToastrService
    ) {}
}
