import {Component, OnDestroy, OnInit} from '@angular/core';
import {QuotationsFacade} from '@qwyk/shared-stores/quotations';
import {map} from 'rxjs/operators';

@Component({
    selector: 'qwyk-quotation-list-widget',
    templateUrl: './quotation-list-widget.component.html',
    styleUrls: ['./quotation-list-widget.component.scss'],
})
export class QuotationListWidgetComponent implements OnInit, OnDestroy {
    quotationsLoading$ = this.quotations.loading$;
    pagination$ = this.quotations.pagination$;
    quotations$ = this.quotations.allQuotations$.pipe(
        map(quotations => {
            if (!quotations) {
                return null;
            }
            return quotations.slice(0, 5);
        })
    );

    constructor(private quotations: QuotationsFacade) {
    }

    ngOnInit(): void {
        this.quotations.loadQuotations({
            pageSize: 5,
            status: 'request',
        });
    }

    ngOnDestroy() {
        this.quotations.resetQuery();
    }
}
