/* eslint-disable @typescript-eslint/no-explicit-any */
import { CarriagesResponse, Carriage } from './../models/carriages.models';
import { createAction, props } from '@ngrx/store';

export const loadCarriages = createAction(
    '[Schedules Carriages] Load Carriages',
    props<{ page: number; query?: any }>()
);
export const loadCarriagesSuccess = createAction(
    '[Schedules Carriages] Load Carriages Success',
    props<{ response: CarriagesResponse }>()
);
export const loadCarriagesFailure = createAction(
    '[Schedules Carriers] Load Carriages Failure',
    props<{ error: any }>()
);

export const setSelectedCarriage = createAction(
    '[Schedules Carriages] Set Selected Carriage',
    props<{ carriage: Carriage }>()
);

export const getCarriage = createAction(
    '[Schedules Carriages] Get Carriage',
    props<{ id: number }>()
);
export const getCarriageSuccess = createAction(
    '[Schedules Carriages] Get Carriage Success',
    props<{ carriage: Carriage }>()
);
export const getCarriageFailure = createAction(
    '[Schedules Carriages] Get Carriage Failure',
    props<{ error: any }>()
);

export const createCarriage = createAction(
    '[Schedules Carriage] Create Carriage',
    props<{ carriage: Carriage }>()
);
export const createCarriageSuccess = createAction(
    '[Schedules Carriage] Create Carriage Success',
    props<{ carriage: Carriage }>()
);
export const createCarriageFailure = createAction(
    '[Schedules Carriage] Create Carriage Failure',
    props<{ error: any }>()
);

export const updateCarriage = createAction(
    '[Schedules Carriage] Update Carriage',
    props<{ carriage: Carriage }>()
);
export const updateCarriageSuccess = createAction(
    '[Schedules Carriage] Update Carriage Success',
    props<{ carriage: Carriage }>()
);
export const updateCarriageFailure = createAction(
    '[Schedules Carriage] Update Carriage Failure',
    props<{ error: any }>()
);

export const deleteCarriage = createAction(
    '[Schedules Carriage] Delete Carriage',
    props<{ carriage: Carriage }>()
);
export const deleteCarriageSuccess = createAction(
    '[Schedules Carriage] Delete Carriage Success'
);
export const deleteCarriageFailure = createAction(
    '[Schedules Carriage] Delete Carriage Failure',
    props<{ error: any }>()
);
