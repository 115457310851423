<div *ngIf="error$ | async" class="px-4">
    <ngb-alert (closed)="onDismissError()" type="danger">
        {{ 'common.generic-error-alt' | translate }}
    </ngb-alert>
</div>

<p-table
    #table
    (onLazyLoad)="loadQuotationsOffersLazy($event)"
    [first]="
        (pagination$ | async)?.per_page *
        ((pagination$ | async)?.current_page - 1)
    "
    [lazy]="true"
    [loading]="(loading$ | async) === true"
    [paginator]="(pagination$ | async)?.total > pageSize"
    [rowHover]="true"
    [rows]="(pagination$ | async)?.per_page"
    [totalRecords]="(pagination$ | async)?.total"
    [value]="quotationOffers$ | async"
    dataKey="id"
>
    <ng-template pTemplate="header">
        <tr>
            <th class="border-left-0" style="width: 50px"></th>
            <th>
                <div>{{ 'hub.quotations.details.offer.columns.carrier' | translate }}</div>
                <div class="font-weight-normal">{{ 'hub.quotations.details.offer.columns.rate-id' | translate }}</div>
            </th>
            <th>
                <div>{{ 'hub.quotations.details.offer.columns.routing' | translate }}</div>
                <div class="font-weight-normal">{{ 'hub.quotations.details.offer.columns.validity' | translate }}</div>
            </th>
            <th class="text-right" style="width: 180px">{{ 'hub.quotations.details.offer.columns.buy-rate' | translate }}</th>
            <th class="text-right" style="width: 180px">{{ 'hub.quotations.details.offer.columns.sell-rate' | translate }}</th>
            <th class="text-right" style="width: 180px">
                <div>{{ 'hub.quotations.details.offer.columns.margin' | translate }}</div>
                <div class="font-weight-normal">%</div>
            </th>
            <th class="border-right-0" style="width: 80px"></th>
        </tr>
    </ng-template>

    <ng-template let-expanded="expanded" let-quotationOffer pTemplate="body">
        <tr
            (delete)="onDelete($event)"
            (generatePdf)="onGeneratePdf($event)"
            (toggleExpand)="onToggleRow($event, table)"
            [expanded]="expanded"
            [quotationOffer]="quotationOffer"
            qwyk-quotations-offer-list-item
        ></tr>
    </ng-template>

    <ng-template let-quotationOffer pTemplate="rowexpansion">
        <tr>
            <td colspan="7">
                <qwyk-quotation-offer-list-expansion-item
                    [quotationOffer]="quotationOffer"
                >
                </qwyk-quotation-offer-list-expansion-item>
            </td>
        </tr>
    </ng-template>

    <ng-template pTemplate="summary">
        <tr>
            <td colspan="7">
                <button
                    [routerLink]="['./', 'offers', 'new']"
                    class="btn btn-outline-primary"
                >
                    <fa-icon [icon]="['far', 'plus']" class="mr-1"></fa-icon>
                    {{ 'hub.quotations.details.offer.create-new' | translate }}
                </button>
            </td>
        </tr>
        <tr *ngIf="pagination$ | async; let pagination">
            <td class="py-3" colspan="7">
                {{ pagination.from }} - {{ pagination.to }} {{ 'common.of' | translate }}
                {{ pagination.total }}
            </td>
        </tr>
    </ng-template>

    <ng-template pTemplate="loadingbody">
        <tr>
            <td class="text-center py-3" colspan="7">{{ 'common.loading' | translate }}...</td>
        </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
        <tr>
            <td
                class="text-center py-3 border-left-0 border-right-0"
                colspan="7"
            >
                <div>{{ 'hub.quotations.details.offer.no-offers' | translate }}</div>
                <button
                    [routerLink]="['./', 'offers', 'new']"
                    class="btn btn-outline-primary btn-sm mt-3"
                >
                    <fa-icon [icon]="['far', 'plus']" class="mr-1"></fa-icon>
                    {{ 'hub.quotations.details.offer.create-new' | translate }}
                </button>
            </td>
        </tr>
    </ng-template>
</p-table>
