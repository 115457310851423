<div class="card-header bg-white p-4 border-0" *ngIf="title">
    <h5 class="card-title m-0">{{ title }}</h5>
</div>

<ul class="list-group list-group-flush border-0">
    <ng-container *ngIf="(usersLoading$ | async) !== true">
        <ng-container *ngIf="users$ | async; let usersInfo">
            <li *ngFor="let user of usersInfo"
                class="list-group-item d-flex align-items-center border-0 p-4"
                [ngStyle]="{'cursor': !isMagayaNetwork ? 'pointer': 'default'}"
                [routerLink]="!isMagayaNetwork ? ['/administration', 'portal-users', user.id] : [null]"
                [ngClass]="!isMagayaNetwork ? 'list-group-item-action' :''"
            >
                <div class="mr-4">
                    <img [src]="'https://www.gravatar.com/avatar/' + (user.email | md5) + '?d=https%3A%2F%2Fui-avatars.com%2Fapi%2F/' + (user.name | urlEncode) + '/64/097aff/ffffff/2//false/true/true'"
                         alt="{{ 'common.avatar' | translate }}" style="width: 24px; height: 24px;" class="rounded-circle border"/>
                </div>
                <div class="flex-grow-1">
                    <h6 class="h6 m-0">{{ user.name }}</h6>
                    <div class="text-truncate">
                        {{ user.email }}
                        <fa-icon *ngIf="showApprovalStatus && user.email_verified" [icon]="['far', 'check']"
                            class="text-success" ngbTooltip="{{ 'hub.home.email-verified' | translate }}">
                        </fa-icon>
                        <fa-icon *ngIf="showApprovalStatus && !user.email_verified" [icon]="['far', 'question']"
                            class="text-warning" ngbTooltip="{{ 'hub.home.email-verification-pending' | translate }}">
                        </fa-icon>
                    </div>
                </div>
                <div class="ml-2 flex-shrink-0" *ngIf="showApprovalStatus">
                    <a [routerLink]="['/administration', 'portal-users', user.id]" class="text-success mr-2">
                        <fa-icon [icon]="['far', 'check-circle']" size="lg"></fa-icon>
                    </a>
                    <a [routerLink]="['/administration', 'portal-users', user.id]" class="text-danger">
                        <fa-icon [icon]="['far', 'times-circle']" size="lg"></fa-icon>
                    </a>
                </div>
            </li>
            <li class="list-group-item border-0 text-muted p-4"
                *ngIf="usersInfo.length === 0 && (usersLoading$ | async) !== true">
                <ng-container *ngTemplateOutlet="nothingFoundTemplate"></ng-container>
            </li>
            <ng-container *ngIf="pagination$ | async; let paging">
                <li class="list-group-item border-0 text-right" *ngIf="!pagination && paging.total > paging.to">
                    <a [routerLink]="['/administration', 'portal-users']" title="Sites">
                        {{ 'common.view' | translate }} {{ paging.total - paging.to }} {{ 'common.more' | translate }}
                        <fa-icon [icon]="['far', 'arrow-right']" class="ml-1"></fa-icon>
                    </a>
                </li>
                <li class="list-group-item" *ngIf="pagination && paging?.total >= paging?.to">
                    <ngb-pagination class="d-flex justify-content-center" [collectionSize]="paging.total" size="sm"
                        [pageSize]="paging.per_page" [page]="paging.current_page" [disabled]="usersLoading$ | async"
                        [maxSize]="5" (pageChange)="onPageChange($event)">
                    </ngb-pagination>
                </li>
            </ng-container>
        </ng-container>
    </ng-container>

    <li class="list-group-item border-0 p-4" *ngIf="(usersLoading$ | async) === true">
        <qwyk-loading-indicator [horizontal]="true" size="sm"></qwyk-loading-indicator>
    </li>
</ul>
