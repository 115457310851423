import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CarriagesService } from '../../services/carriages.service';
import * as carriagesActions from '../actions/carriages.actions';
import { map, catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class CarriagesEffects {
    loadCarriages$ = createEffect(() =>
        this.actions$.pipe(
            ofType(carriagesActions.loadCarriages),
            switchMap(action =>
                this.service.getCarriages(action.page, action.query).pipe(
                    map(response =>
                        carriagesActions.loadCarriagesSuccess({ response })
                    ),
                    catchError(error =>
                        of(carriagesActions.loadCarriagesFailure({ error }))
                    )
                )
            )
        )
    );

    getCarriage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(carriagesActions.getCarriage),
            switchMap(action =>
                this.service.getCarriage(action.id).pipe(
                    map(carriage =>
                        carriagesActions.getCarriageSuccess({ carriage })
                    ),
                    catchError(error =>
                        of(carriagesActions.getCarriageFailure({ error }))
                    )
                )
            )
        )
    );

    createCarriage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(carriagesActions.createCarriage),
            switchMap(action =>
                this.service.createCarriage(action.carriage).pipe(
                    map(carriage =>
                        carriagesActions.createCarriageSuccess({ carriage })
                    ),
                    catchError(error =>
                        of(carriagesActions.createCarriageFailure({ error }))
                    )
                )
            )
        )
    );

    updateCarriage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(carriagesActions.updateCarriage),
            switchMap(action =>
                this.service.updateCarriage(action.carriage).pipe(
                    map(carriage =>
                        carriagesActions.updateCarriageSuccess({ carriage })
                    ),
                    catchError(error =>
                        of(carriagesActions.updateCarriageFailure({ error }))
                    )
                )
            )
        )
    );

    deleteCarriage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(carriagesActions.deleteCarriage),
            switchMap(action =>
                this.service.deleteCarriage(action.carriage).pipe(
                    map(() => carriagesActions.deleteCarriageSuccess()),
                    catchError(error =>
                        of(carriagesActions.deleteCarriageFailure({ error }))
                    )
                )
            )
        )
    );

    constructor(private actions$: Actions, private service: CarriagesService) {}
}
