import { Component, Input } from '@angular/core';
import { Hub } from '@qwyk/models';

@Component({
    selector: 'qwyk-customer-user-status-badge',
    templateUrl: './customer-user-status-badge.component.html',
    styleUrls: ['./customer-user-status-badge.component.scss'],
})
export class CustomerUserStatusBadgeComponent {
    approved: boolean;
    deactivated: boolean;
    emailVerified: boolean;
    private _user: Hub.CustomerUser;

    @Input() set user(user: Hub.CustomerUser) {
        this._user = user;
        this.approved = user.approved;
        this.deactivated = user.deactivated;
        this.emailVerified = user.email_verified;
    }
}
