<div class="card-header bg-white p-4 border-0">
    <h5 class="card-title m-0">{{ 'hub.home.your-sites' | translate }}</h5>
</div>

<ul class="list-group list-group-flush border-0">
    <ng-container *ngIf="sites$ | async; let siteInfo">
        <li *ngFor="let site of siteInfo.sites" class="list-group-item d-flex align-items-center border-0 p-4">
            <div class="mr-4">
                <img [src]="site.favicon_url" alt="{{ 'hub.shipment.sections.milestones.fields.icon' | translate }}" style="max-width: 24px; max-height: 24px;" />
            </div>
            <div class="flex-grow-1">
                <div>
                    <a class="m-0 h6 text-dark" [routerLink]="['/administration', 'sites', site.id]">{{ site.name }}</a>
                </div>
                <a *ngIf="site.enable_cname" [href]="'https://' + site.cname"
                    [attr.title]="site.name">{{ site.cname }}</a>
                <a *ngIf="!site.enable_cname" [href]="site.endpoint_identifier  | valueMask: portalSitesMask"
                    [attr.title]="site.name" target="_blank">
                    {{ site.endpoint_identifier  | valueMask: portalSitesMaskStripped}}
                </a>
            </div>
            <div class="ml-2">
                <a [href]=" (site.enable_cname ? ('https://' + site.cname) : (site.endpoint_identifier  | valueMask: portalSitesMask))"
                    [attr.title]="site.name" target="_blank" ngbTooltip="{{ 'hub.home.visit-this-site' | translate }}">
                    <fa-icon [icon]="['far', 'external-link']"></fa-icon>
                </a>
            </div>
        </li>
        <li class="list-group-item border-0 text-muted p-4" *ngIf="siteInfo.sites.length === 0">
            <div>{{ 'hub.home.no-sites' | translate }}</div>
            <a [routerLink]="['/administration', 'sites', 'new']" title="{{ 'hub.menu.side-menu.sites' | translate}}">
                {{ 'hub.home.create-a-site' | translate }}
                <fa-icon [icon]="['far', 'arrow-right']" class="ml-1"></fa-icon>
            </a>
        </li>
        <li class="list-group-item border-0 text-right" *ngIf="siteInfo.more > 0">
            <a [routerLink]="['/administration', 'sites']" title="{{ 'hub.menu.side-menu.sites' | translate }}">
                {{ 'common.view' | translate }} {{ siteInfo.more }} {{ 'common.more' | translate }}
                <fa-icon [icon]="['far', 'arrow-right']" class="ml-1"></fa-icon>
            </a>
        </li>
    </ng-container>

    <li class="list-group-item border-0 p-4" *ngIf="(sitesLoading$ | async) === true">
        <qwyk-loading-indicator [horizontal]="true" size="sm"></qwyk-loading-indicator>
    </li>
</ul>
