import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {Route, RouterModule} from '@angular/router';
import {QuotationExistsGuard} from '@qwyk/shared-stores/quotations';
import {QuotationCreateComponent, QuotationDetailComponent, QuotationsListComponent,} from './components';
import {HubQuotationsWithoutRoutingModule} from './hub-quotations-without-routing.module';
import {QuotationDetailPageComponent} from './pages';

export const hubQuotationsRoutes: Route[] = [
    {
        path: '',
        redirectTo: 'list',
        pathMatch: 'prefix'
    },
    {
        path: 'list',
        component: QuotationsListComponent,
        data: {title: 'Quotations'},
    },
    {
        path: 'new',
        component: QuotationCreateComponent,
        data: { title: 'New Quotation' },
    },
    {
        path: 'new/:id/copy',
        component: QuotationCreateComponent,
        data: { title: 'New Quotation' },
        canActivate: [QuotationExistsGuard],
    },
    {
        path: ':id',
        component: QuotationDetailPageComponent,
        canActivate: [QuotationExistsGuard],
        children: [
            {
                path: '',
                component: QuotationDetailComponent,
                data: {title: 'Quotation'},
            },
            {
                path: 'offers',
                loadChildren: () =>
                    import('@qwyk/hub/quotations-offers').then(
                        module => module.HubQuotationsOffersModule
                    ),
            },
        ],
    },
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(hubQuotationsRoutes),
        HubQuotationsWithoutRoutingModule,
    ],
})
export class HubQuotationsModule {}
