import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { AuthenticationFacade } from '@qwyk/hub/authentication';
import { PortalsTranslateService } from '@qwyk/portals/translation';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    public user$ = this._auth.user$;

    private userSubscription: Subscription;

    constructor(
        private readonly _auth: AuthenticationFacade,
        private readonly _translate: PortalsTranslateService
    ) {}

    ngOnInit(): void {
        this.userSubscription = this.user$.subscribe(user => {
            if (user.organization_features?.language_picker?.value) {
                this._translate.init(user.language);
            } else {
                this._translate.init('en');
            }
        });
    }

    ngOnDestroy(): void {
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
    }
}
