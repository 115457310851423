import { Component, Input } from '@angular/core';
import {
    ActivitiesEntity,
    ActivitiesFacade,
    CommentActivityPayload,
    HasActivities,
} from '@qwyk/shared-stores/activities';
import { AuthenticationFacade } from '@qwyk/hub/authentication';

@Component({
    selector: 'qwyk-activities-feed',
    templateUrl: './activities-feed.component.html',
    styleUrls: ['./activities-feed.component.scss'],
})
export class ActivitiesFeedComponent {
    public loading$ = this.activities.loading$;
    public saving$ = this.activities.saving$;
    public pagination$ = this.activities.pagination$;
    public error$ = this.activities.error$;
    public activities$ = this.activities.allActivities$;
    public currentUser$ = this.auth.user$;
    @Input() public direction?: 'asc' | 'desc';
    private readonly _pageSize = 10;

    constructor(
        private activities: ActivitiesFacade,
        private auth: AuthenticationFacade
    ) {}

    private _filter?: 'all' | 'event' | 'comment';

    get filter(): 'all' | 'event' | 'comment' {
        return this._filter ?? 'all';
    }

    @Input() set filter(value: 'all' | 'event' | 'comment') {
        this._filter = value;
        this.onFilterChanged(value);
    }

    private _parent!: HasActivities;

    get parent(): HasActivities {
        return this._parent;
    }

    @Input() set parent(value: HasActivities) {
        this._parent = value;
        this.onParentChanged(value);
    }

    public onCreateComment(payload: CommentActivityPayload): void {
        this.activities.createComment(this.parent, payload);
    }

    public onUpdateComment(e: {
        activity: ActivitiesEntity;
        payload: CommentActivityPayload;
    }) {
        this.activities.updateComment(this.parent, e.activity, e.payload);
    }

    public onDeleteComment(activity: ActivitiesEntity) {
        this.activities.deleteComment(this.parent, activity);
    }

    onLoadMore() {
        this.activities.loadMore(this._parent);
    }

    private onParentChanged(value: HasActivities): void {
        this.activities.load(value, {
            page_size: this._pageSize,
            type: this._filter,
        });
    }

    private onFilterChanged(value: 'all' | 'event' | 'comment'): void {
        if (this._parent) {
            this.activities.load(this._parent, {
                page_size: this._pageSize,
                type: value,
            });
        }
    }
}
