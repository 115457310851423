import { CarriagesService } from './../../../services/carriages.service';
import { Component, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-download-carriages',
    templateUrl: './download-carriages.component.html',
    styleUrls: ['./download-carriages.component.scss'],
})
export class DownloadCarriagesComponent implements OnDestroy {
    startedDownload = false;
    private downloadCarriagesSubscription: Subscription;
    isBusy: boolean;
    error: boolean;
    constructor(
        public modal: NgbActiveModal,
        private service: CarriagesService
    ) {}

    ngOnDestroy(): void {
        if (this.downloadCarriagesSubscription) {
            this.downloadCarriagesSubscription.unsubscribe();
        }
    }

    startDownload() {
        this.isBusy = true;
        this.error = false;
        this.downloadCarriagesSubscription = this.service
            .startCarriagesDownload()
            .subscribe(
                () => {
                    this.isBusy = false;
                    this.startedDownload = true;
                },
                () => {
                    this.isBusy = false;
                    this.error = true;
                }
            );
    }
}
