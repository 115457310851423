import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {HubNotificationsWithoutRoutingModule} from "./hub-notifications-without-routing.module";
import {NotificationsPageComponent} from "./components";

@NgModule({
    imports: [
        HubNotificationsWithoutRoutingModule,
        RouterModule.forChild([
            {path: '', pathMatch: 'full', component: NotificationsPageComponent}
        ]),

    ],
})
export class HubNotificationsModule {
}
