/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    map,
    shareReplay,
    switchMap,
    tap,
} from 'rxjs/operators';
import { AlgoliaLocationsService, MasterDataService } from '@qwyk/core';
import { Portals } from '@qwyk/models';
import { Observable, of, Subject } from 'rxjs';
import { Validators } from 'angular-reactive-validation';
import {
    NgbDateAdapter,
    NgbDateNativeUTCAdapter,
} from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationFacade } from '@qwyk/hub/authentication';

@Component({
    selector: 'qwyk-quotations-offer-form',
    templateUrl: './quotations-offer-form.component.html',
    styleUrls: ['./quotations-offer-form.component.scss'],
    providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeUTCAdapter }],
})
export class QuotationsOfferFormComponent implements OnInit {
    public form: FormGroup;

    public readonly TODAY = new Date();
    public readonly CARRIER_SCAC_OTHER = 'OTHR';

    public currencies$ = this.masterData.getCurrencies().pipe(shareReplay());
    public carriers$ = this.masterData.getCarriers().pipe(shareReplay());

    public locationsInput$ = new Subject<string>();
    public locationsLoading: boolean;
    public locations$: Observable<any[]>;

    public editingOrderLinesIndex = null;
    public organizationName$ = this.authentication.user$.pipe(
        map(u => (u ? u.organization_name : null))
    );
    public isCarrierSet = true;

    public readonly TRANSPORT_MODES = [
        { value: 'AIR', label: 'common.transport-modes.air' },
        { value: 'OCEAN', label: 'common.transport-modes.ocean' },
        { value: 'TRUCK', label: 'common.transport-modes.truck' },
    ];

    @Output() public save: EventEmitter<any> = new EventEmitter<any>();
    @Input() public openRateRequests: Portals.QuotationRateRequest[];

    public showMore = false;

    private _disabled: boolean;

    get disabled(): boolean {
        return this._disabled;
    }

    @Input()
    set disabled(value: boolean) {
        this._disabled = value;
        if (value) {
            this.form.disable();
        } else {
            this.form.enable();
        }
    }

    private _quotationOffer: Portals.QuotationOffer;

    constructor(
        private fb: FormBuilder,
        private masterData: MasterDataService,
        private algolia: AlgoliaLocationsService,
        private authentication: AuthenticationFacade
    ) {
        this.form = this.fb.group({
            id: [null],
            quotation_id: [null],
            carrier: [null, [Validators.required()]],
            carrier_name: [null, [Validators.required()]],
            rate_name: [null],
            transport_mode: [null, [Validators.required]],
            load_type: [null, [Validators.required]],
            commodity: [null, [Validators.maxLength(255)]],
            service: [null, [Validators.maxLength(255)]],
            pol: [null, [Validators.required()]],
            pol_via: [null, []],
            pod: [null, [Validators.required()]],
            pod_via: [null, []],
            currency: [null, [Validators.required()]],
            valid_from: [null, [Validators.required()]],
            valid_until: [
                null,
                [Validators.required(), this.validUntilValidator],
            ],
            transit_time: [null],
            offer_lines: this.fb.array(
                [],
                [
                    Validators.min(1, min => `Please add at least ${min} line`),
                    Validators.required('Please add at least 1 line.'),
                ]
            ),
            remarks: [null, [Validators.maxLength(3000)]],
            rate_request_id: [null],
            rate_request_response: [null, [Validators.maxLength(3000)]],
        });
    }

    @Input()
    set quotationOffer(quotationOffer: Portals.QuotationOffer) {
        if (!quotationOffer) {
            return;
        }
        this._quotationOffer = quotationOffer;
        this.onQuotationOfferChanged(quotationOffer);
    }

    public get hasRateRequestSelected(): boolean {
        return this.form.get('rate_request_id')?.value !== null;
    }

    public get offerLinesArray(): FormArray {
        return this.form.get('offer_lines') as FormArray;
    }

    ngOnInit(): void {
        // const defaults = ['currency', 'load_type', 'transport_mode'];
        // defaults.forEach(k => {
        //     if (this.route.snapshot.queryParamMap.has(k)) {
        //         this.form.get(k).setValue(this.route.snapshot.queryParamMap.get(k));
        //     }
        // })
        this.loadLocations();

        if (
            this.form.get('carrier_name').value === null ||
            this.form.get('carrier').value === null
        ) {
            this.isCarrierSet = false;
        }
    }

    public onAddCharge() {
        const newIndex = this.offerLinesArray.length;
        this.offerLinesArray.push(this.createOfferLineGroup(newIndex));
        this.editingOrderLinesIndex = newIndex;
    }

    public searchLocations(e) {
        this.locationsInput$.next(e.query);
    }

    public onCarrierChanged(e) {
        if (!e?.value || e.value.scac === this.CARRIER_SCAC_OTHER) {
            this.form.get('carrier_name').setValue(null);
        } else {
            this.form.get('carrier_name').setValue(e.value.name);
        }
    }

    public onSave(): void {
        this.form.markAllAsTouched();
        if (this.form.invalid) {
            return;
        }

        const payload = {
            ...this.form.value,
        };

        if (payload.valid_from) {
            payload.valid_from;
        }

        this.save.emit(this.form.value);
    }

    public onSetCarrierToOrganization(organizationName) {
        this.form.get('carrier').setValue({
            scac: this.CARRIER_SCAC_OTHER,
            name: organizationName,
        });
        this.form.get('carrier_name').setValue(organizationName);
    }

    private onQuotationOfferChanged(quotationOffer: Portals.QuotationOffer) {
        const mutatedValue: any = { ...quotationOffer };

        this.form.patchValue(mutatedValue);
        this.offerLinesArray.clear();
        mutatedValue.offer_lines.forEach((offerLine, index) => {
            const group = this.createOfferLineGroup(index);
            group.patchValue(offerLine);
            this.offerLinesArray.push(group);
        });
        this.form.markAsPristine();
    }

    private createOfferLineGroup(sort = 0): FormGroup {
        return this.fb.group({
            id: [-1],
            sort: [sort],
            segment: [null, Validators.required()],
            charge_code: [null, Validators.required()],
            charge_description: [null, Validators.required()],
            base_currency: [null, Validators.required()],
            base_rate: [0, Validators.required()],
            base_minimum: [0, Validators.required()],
            base_buying_rate: [0, Validators.required()],
            base_buying_minimum: [0, Validators.required()],
            base_markup_rate: [0, Validators.required()],
            base_calculation: [null, Validators.required()],
            total_amount: [0, Validators.required()],
            total_amount_quotation: [0, Validators.required()],
            total_buying_amount: [0, Validators.required()],
            total_buying_amount_quotation: [0, Validators.required()],
            exchange_rate: [1, Validators.required()],
            remarks: [
                null,
                [
                    Validators.maxLength(
                        200,
                        maxLength => `The maximum length is ${maxLength}`
                    ),
                ],
            ],
        });
    }

    private loadLocations() {
        this.locations$ = this.locationsInput$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            tap(() => (this.locationsLoading = true)),
            switchMap(term =>
                this.algolia.getLocationSuggestions(term).pipe(
                    catchError(() => of([])),
                    tap(() => (this.locationsLoading = false))
                )
            )
        );
    }

    private validUntilValidator(validUntil: FormControl) {
        const dateValidUntil = new Date(
            validUntil.root.get('valid_until')?.value
        ).setHours(0, 0, 0, 0);
        const dateToday = new Date().setHours(0, 0, 0, 0);
        if (dateValidUntil < dateToday) {
            return {
                invalidDate: {
                    message: `Valid until date cannot be before today's date`,
                },
            };
        }
        const dateValidFrom = new Date(
            validUntil.root.get('valid_from')?.value
        ).setHours(0, 0, 0, 0);
        if (dateValidUntil < dateValidFrom) {
            return {
                invalidDate: {
                    message:
                        'Valid until date cannot be before valid from date',
                },
            };
        }
    }
}
