import { Component, Input, EventEmitter, Output } from '@angular/core';

export interface FileSelectedEvent {
    control_name: string;
    preview_url: string;
    file: File;
}

@Component({
    selector: 'app-media-uploader',
    templateUrl: './media-uploader.component.html',
    styleUrls: ['./media-uploader.component.scss'],
})
export class MediaUploaderComponent {
    @Input() name: string;
    @Input() accept: string;
    @Input() labelClass = 'btn-primary';
    @Input() maxSize: number;
    @Input() suggestedMaxRatio: number;
    @Input() disabled = false;
    @Output() fileSelected: EventEmitter<FileSelectedEvent> =
        new EventEmitter();

    busy = false;
    selectedFile = null;
    warning: string | null;
    error: string | null;

    constructor() {}

    onFileSelected(event) {
        if (this.busy) {
            // Already busy, abort.
            return;
        }

        this.error = null;
        this.warning = null;
        const file =
            event.target.files.length > 0 ? event.target.files[0] : null;

        if (!file) {
            // No file selected, abort
            return;
        }

        // Check is the file size does not exceed the maximum (in megabytes)
        if (this.maxSize && file.size > this.maxSize * 1e6) {
            this.error = `This file is too large, maximum size: ${this.maxSize} Mb`;
            return;
        }
        this.busy = true;

        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
            this.selectedFile = file;
            this.busy = false;

            if (file.type.match(/image\/*/) != null && this.suggestedMaxRatio) {
                // Verify the recommended Width to Height ratio.
                const image = new Image();
                if (reader.result) {
                    image.src = reader.result.toString();
                }
                image.onload = () => {
                    if (image.width / image.height > this.suggestedMaxRatio) {
                        this.warning = `The width to height ratio of this image is larger than the recommended ${this.suggestedMaxRatio}:1`;
                    }
                };
            }
            // Emit event so we can use this file.
            this.fileSelected.emit({
                control_name: this.name,
                preview_url:
                    file.type.match(/image\/*/) !== null && reader.result
                        ? reader.result.toString()
                        : '',
                file,
            });
        };
    }
}
