<div class="min-vh-100 text-center bg-dark text-white">
    <div class="text-center bg-primary p-4">
        <img src="https://cdn.qwyk.io/images/newlogos/SVG/logo_white.svg" alt="Qwyk Logo" height="80">
    </div>
    <div class="container d-flex align-items-center flex-column p-4">

        <h2 class="mb-5">Welcome back!</h2>
        <h4 class="mb-3 font-weight-normal">Your trial period of QwykPortals has ended.</h4>
        <p style="max-width: 400px;" class="mb-4">
            We hope you enjoyed testing our product and would be happy to activate your account on one of our plans.
            Please get in touch with <a href="mailto:sales@qwyk.io"
                class="text-white font-weight-bold text-underline">sales@qwyk.io</a> to
            activate a subscription.
        </p>
        <a href="mailto:sales@qwyk.io" class="btn btn-secondary shadow">Contact Qwyk</a>
    </div>
</div>
