/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';

import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { environment } from '@qwyk/hub/environment';

import {
    selectUpdateItemInfo,
    selectAvailableProviders,
} from '../../../store/selectors/routing-guide.selectors';
import { AppState } from './../../../../../store/app.state';
import { ValidatorsX } from './../../../../../helpers/ValidatorsX';
import { PRODUCT_ICONS } from './../../../../../constants/productMappings';
import { RoutingGuideItem } from '../../../store/models/routing-guide.models';
import { updateRoutingGuideItem } from '../../../store/actions/routing-guide.actions';
import { AlgoliaLocationsService } from './../../../../shared/services/algolia-locations.service';

@Component({
    selector: 'app-update-routing-guide-item-modal',
    templateUrl: './update-routing-guide-item-modal.component.html',
    styleUrls: ['./update-routing-guide-item-modal.component.scss'],
})
export class UpdateRoutingGuideItemModalComponent implements OnInit, OnDestroy {
    private _routingGuideItem: RoutingGuideItem;
    providers$ = this.store.select(selectAvailableProviders);
    searchLocationResults: any[];
    error: any;
    validationErrors: any[] = [];
    productIcons = PRODUCT_ICONS;
    itemForm: FormGroup = this.createEmptyFormGroup();
    private infoSubscription: Subscription;
    updating = false;
    DEBUG = environment.debug;

    constructor(
        public activeModal: NgbActiveModal,
        private store: Store<AppState>,
        private algoliaLocations: AlgoliaLocationsService,
        private fb: FormBuilder
    ) {}

    ngOnInit() {
        this.infoSubscription = this.store
            .select(selectUpdateItemInfo)
            .subscribe(info => {
                if (this.updating && !info.updating && !info.error) {
                    // Update has been completed without error
                    this.activeModal.close();
                }

                this.updating = info.updating;
                this.error = info.error;

                if (this.error && this.error.status === 422) {
                    // Validation errors have occured, concat all so we can display them properly
                    this.validationErrors = [].concat(
                        ...Object.keys(this.error.error).map(
                            k => this.error.error[k]
                        )
                    );
                    this.error = null;
                }

                // Re-enable the form when we're not creating
                if (!info.updating && this.itemForm.disabled) {
                    this.itemForm.enable();
                }
            });
    }

    ngOnDestroy(): void {
        if (this.infoSubscription) {
            this.infoSubscription.unsubscribe();
        }
    }

    /**
     * Handles the form submission and dispatches the changes when the form validates.
     */
    submit() {
        this.validationErrors = [];

        for (const control in this.itemForm.controls) {
            // eslint-disable-next-line no-prototype-builtins
            if (this.itemForm.controls.hasOwnProperty(control)) {
                this.itemForm.controls[control].markAsTouched();
            }
        }

        if (this.itemForm.invalid) {
            return;
        }

        this.itemForm.disable();

        const routingGuideItem = {
            ...this._routingGuideItem,
            ...this.itemForm.value,
        };
        this.store.dispatch(
            updateRoutingGuideItem({
                routingGuideItem,
            })
        );
    }

    /**
     * Provider comparer of update routing guide item modal component
     */
    providerComparer = (a, b) => {
        if (!a || !b) {
            return null;
        }
        return a.id === b.id;
        // tslint:disable-next-line: semicolon
    };

    /**
     * Sets the location in the form once it has been selected.
     * @param event the select event containing the selected location.
     * @param formControlName the form control to set it on.
     */
    onLocationSelect(event, formControlName) {
        this.itemForm.get(formControlName)?.setValue({
            locode: event.locode,
            display_name: event.display_name,
            country_code: event.country_code,
        });
    }

    /**
     * Handle clearing a location from a formcontrol
     * @param event the event
     * @param formControlName the form control to clear
     */
    onLocationClear(event, formControlName) {
        this.itemForm.get(formControlName)?.patchValue(null);
        if (formControlName === 'via') {
            this.itemForm.get('ts_days')?.patchValue(null);
            this.itemForm.get('second_leg_provider')?.patchValue(null);
        }
    }

    /**
     * Handle changing of the transhipment filter
     * @param event the event containing info on which control this fired.
     */
    onTranshipmentFilterChanged(event) {
        if (event.target.value !== 'via') {
            this.itemForm.get('transhipment_filter_location')?.patchValue(null);
        }
    }

    /**
     * Handless changtes to an alias so we know if we need to set it to null.
     * @param event the target of the alias
     */
    onAliasChanged(event) {
        if (event.target.value === '') {
            this.itemForm.get(event.target.id)?.patchValue(null);
        }
    }

    /**
     * Sets routing guide item on this dialog, called externally.
     * Sets the form controls to the expected values.
     */
    set routingGuideItem(routingGuideItem: RoutingGuideItem) {
        this._routingGuideItem = routingGuideItem;

        this.itemForm.get('origin')?.setValue(routingGuideItem.origin);
        this.itemForm
            .get('destination')
            ?.setValue(routingGuideItem.destination);
        this.itemForm.get('providers')?.setValue(routingGuideItem.providers);

        this.itemForm.get('via')?.setValue(routingGuideItem.via || null);
        this.itemForm
            .get('ts_days')
            ?.setValue(routingGuideItem.ts_days || null);
        this.itemForm
            .get('second_leg_provider')
            ?.setValue(routingGuideItem.second_leg_provider || null);

        this.itemForm
            .get('secondary_provider')
            ?.setValue(routingGuideItem.secondary_provider || null);

        this.itemForm
            .get('transhipment_filter')
            ?.setValue(routingGuideItem.transhipment_filter || null);
        this.itemForm
            .get('transhipment_filter_location')
            ?.setValue(routingGuideItem.transhipment_filter_location || null);
        this.itemForm
            .get('vessel_starts_with_filter')
            ?.setValue(routingGuideItem.vessel_starts_with_filter || null);

        this.itemForm
            .get('origin_provideralias')
            ?.setValue(routingGuideItem.origin_provideralias || null);
        this.itemForm
            .get('destination_provideralias')
            ?.setValue(routingGuideItem.destination_provideralias || null);
        this.itemForm
            .get('origin_secprovideralias')
            ?.setValue(routingGuideItem.origin_secprovideralias || null);
        this.itemForm
            .get('destination_secprovideralias')
            ?.setValue(routingGuideItem.destination_secprovideralias || null);
    }

    /**
     * Shortcut function for controls on this form
     * @param name the name of the control
     * @returns  a control on this form.
     */
    c(name: string) {
        return this.itemForm.get(name);
    }

    /**
     * Returns whether a specific tab contains any errors.
     * @param tab the tab we want to evaluate
     * @returns boolean
     */
    tabHasError(tab: string): boolean {
        const via = this.c('via');
        const ts_days = this.c('ts_days');
        const second_leg_provider = this.c('second_leg_provider');
        const secondary_provider = this.c('secondary_provider');
        const transhipment_filter = this.c('transhipment_filter');
        const transhipment_filter_location = this.c(
            'transhipment_filter_location'
        );
        const vessel_starts_with_filter = this.c('vessel_starts_with_filter');
        const origin_provideralias = this.c('origin_provideralias');
        const destination_provideralias = this.c('destination_provideralias');
        const origin_secprovideralias = this.c('origin_secprovideralias');
        const destination_secprovideralias = this.c(
            'destination_secprovideralias'
        );

        switch (tab) {
            case 'transhipment': {
                return (
                    (!!via && via.invalid && via.touched) ||
                    (!!ts_days && ts_days.invalid && ts_days.touched) ||
                    (!!second_leg_provider &&
                        second_leg_provider.invalid &&
                        second_leg_provider.touched)
                );
            }
            case 'backup': {
                return (
                    !!secondary_provider &&
                    secondary_provider.invalid &&
                    secondary_provider.touched
                );
            }
            case 'other': {
                return (
                    (!!transhipment_filter &&
                        transhipment_filter.invalid &&
                        transhipment_filter.touched) ||
                    (!!transhipment_filter_location &&
                        transhipment_filter_location.invalid &&
                        transhipment_filter_location.touched) ||
                    (!!vessel_starts_with_filter &&
                        vessel_starts_with_filter.invalid &&
                        vessel_starts_with_filter.touched)
                );
            }
            case 'aliases': {
                return (
                    (!!origin_provideralias &&
                        origin_provideralias.invalid &&
                        origin_provideralias.touched) ||
                    (!!destination_provideralias &&
                        destination_provideralias.invalid &&
                        destination_provideralias.touched) ||
                    (!!origin_secprovideralias &&
                        origin_secprovideralias.invalid &&
                        origin_secprovideralias.touched) ||
                    (!!destination_secprovideralias &&
                        destination_secprovideralias.invalid &&
                        destination_secprovideralias.touched)
                );
            }
        }
        return false;
    }

    /**
     * Handles searching for locations
     * @param event the event emitted by the typeahead
     */
    searchLocation(event) {
        const searchSubscription = this.algoliaLocations
            .getLocationSuggestions(event.query)
            .subscribe(results => {
                this.searchLocationResults = results;
                searchSubscription.unsubscribe();
            });
    }

    /**
     * Creates empty form group
     * @returns empty form group
     */
    private createEmptyFormGroup(): FormGroup {
        return this.fb.group({
            origin: [null, [Validators.required]],
            destination: [null, [Validators.required]],
            providers: [[], [Validators.required]],

            via: [null],
            ts_days: [null, ValidatorsX.requiredWhenNotNull('via')],
            second_leg_provider: [null, ValidatorsX.requiredWhenNotNull('via')],

            secondary_provider: [null],

            transhipment_filter: [null],
            transhipment_filter_location: [
                null,
                [],
                [
                    ValidatorsX.requiredWhenEqualsAsync(
                        'transhipment_filter',
                        'via'
                    ),
                ],
            ],
            vessel_starts_with_filter: [null],

            origin_provideralias: [
                null,
                [Validators.maxLength(5), Validators.minLength(5)],
            ],
            destination_provideralias: [
                null,
                [Validators.maxLength(5), Validators.minLength(5)],
            ],
            origin_secprovideralias: [
                null,
                [Validators.maxLength(5), Validators.minLength(5)],
            ],
            destination_secprovideralias: [
                null,
                [Validators.maxLength(5), Validators.minLength(5)],
            ],
        });
    }
}
