<form [formGroup]="formGroup">
    <p-table formArrayName="offer_lines" [value]="formArray.controls" editMode="row" [editingRowKeys]="editingRows"
        dataKey="value.id">
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 140px;" pSortableColumn="value.segment">
                    Segment
                    <p-sortIcon field="value.segment"></p-sortIcon>
                </th>
                <th>Charge description</th>
                <th style="width: 110px;">Currency</th>
                <th style="width: 100px;" class="text-right">Rate</th>
                <th>Calculation</th>
                <th style="width: 100px;" class="text-right">Minimum</th>
                <th style="width: 90px;" class="text-right">Total</th>
                <th style="width: 97px;"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-index="rowIndex" let-editing="editing">
            <tr [formGroupName]="index" [pEditableRow]="rowData">
                <td>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <ng-select [items]="chargeSegments" bindLabel="label" bindValue="value" [clearable]="false"
                                formControlName="segment"></ng-select>
                            <!-- <p-dropdown [options]="chargeSegments" dataKey="value" formControlName="segment"
                            [style]="{'width':'100%'}">
                        </p-dropdown> -->
                        </ng-template>
                        <ng-template pTemplate="output">
                            <fa-icon [icon]="['far', 'exclamation-circle']" class="text-danger"
                                *ngIf="rowData.get('segment').invalid">
                            </fa-icon>
                            {{ rowData.get('segment').value | titlecase }}

                        </ng-template>
                    </p-cellEditor>
                </td>
                <td>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <div class="input-group">
                                <ng-select [items]="chargeCodes$ | async" formControlName="charge_code"
                                    [clearable]="false" (change)="onChargeCodeChanged($event, index)"
                                    bindLabel="description" bindValue="code">
                                </ng-select>
                                <input class="form-control" formControlName="charge_description"
                                    *ngIf="rowData.get('charge_code').value === 'OTH'" />
                            </div>
                        </ng-template>
                        <ng-template pTemplate="output">
                            <fa-icon [icon]="['far', 'exclamation-circle']" class="text-danger"
                                *ngIf="rowData.get('charge_code').invalid">
                            </fa-icon>
                            {{ rowData.get('charge_description').value }}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <ng-select [items]="currencies$ | async" bindLabel="code" bindValue="code"
                                [clearable]="false" formControlName="base_currency"></ng-select>

                        </ng-template>
                        <ng-template pTemplate="output">
                            <fa-icon [icon]="['far', 'exclamation-circle']" class="text-danger"
                                *ngIf="rowData.get('base_currency').invalid">
                            </fa-icon>
                            {{ rowData.get('base_currency').value }}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td class="text-right">
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input type="number" formControlName="base_rate" class="form-control">
                        </ng-template>
                        <ng-template pTemplate="output">
                            <fa-icon [icon]="['far', 'exclamation-circle']" class="text-danger"
                                *ngIf="rowData.get('base_rate').invalid">
                            </fa-icon>
                            {{ rowData.get('base_rate').value | number:'1.2-2' }}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <ng-select [items]="rateCalculations$ | async" formControlName="base_calculation"
                                [clearable]="false" bindLabel="name" [compareWith]="rateCalculationComparer">
                            </ng-select>
                        </ng-template>
                        <ng-template pTemplate="output">
                            <fa-icon [icon]="['far', 'exclamation-circle']" class="text-danger"
                                *ngIf="rowData.get('base_calculation').invalid">
                            </fa-icon>
                            {{ rowData.get('base_calculation').value?.name }}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td class="text-right">
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input type="number" formControlName="base_minimum" class="form-control">
                        </ng-template>
                        <ng-template pTemplate="output">
                            <fa-icon [icon]="['far', 'exclamation-circle']" class="text-danger"
                                *ngIf="rowData.get('base_minimum').invalid">
                            </fa-icon>
                            {{ rowData.get('base_minimum').value  | number:'1.2-2'}}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td class="text-right">
                    <ng-container [ngSwitch]="rowTotalCurrency">
                        <span *ngSwitchCase="'row'">
                            {{ rowData.get('total_amount').value  | currency:this.rowData.get('base_currency').value}}
                        </span>
                        <span *ngSwitchCase="'offer'">
                            {{ rowData.get('total_amount_quotation').value  | currency:this.formGroup.get('header.currency').value}}
                        </span>
                    </ng-container>
                </td>
                <td>
                    <ng-container *ngIf="!isEditingRows">
                        <button class="btn btn-outline-primary btn-sm mr-1 py-0" *ngIf="!editing"
                            (click)="onEditOfferLine(index, rowData.get('id').value, rowData)" pInitEditableRow>
                            <fa-icon [icon]="['far', 'pen']" [fixedWidth]="true"></fa-icon>
                        </button>

                        <button class="btn btn-outline-danger btn-sm py-0" *ngIf="!editing"
                            (click)="onRemoveOfferLine(index)">
                            <fa-icon [icon]="['far', 'trash']" [fixedWidth]="true"></fa-icon>
                        </button>
                    </ng-container>
                    <ng-container *ngIf="editing">
                        <button class="btn btn-outline-success btn-sm mr-1" *ngIf="editing"
                            (click)="onSaveOfferLine(index, rowData.get('id').value, rowData)" pSaveEditableRow
                            [disabled]="rowData.invalid">
                            <fa-icon [icon]="['far', 'check']" [fixedWidth]="true"></fa-icon>
                        </button>

                        <button class="btn btn-outline-danger btn-sm"
                            (click)="onCancelEditOfferLine(index, rowData.get('id').value, rowData)" pCancelEditableRow>
                            <fa-icon [icon]="['far', 'times']" [fixedWidth]="true"></fa-icon>
                        </button>
                    </ng-container>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    {{ rowData.value | json }}
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="footer">
            <tr>
                <td colspan="6">
                    <div class="form-group m-0">
                        Total currency:
                        <div class="form-check form-check-inline">

                            <input class="form-check-input" type="radio" name="rowTotalsCurrency"
                                [(ngModel)]="rowTotalCurrency" id="rowTotalsCurrencyRow" value="row"
                                [ngModelOptions]="{standalone: true}">
                            <label class="form-check-label" for="rowTotalsCurrencyRow">Row</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="rowTotalsCurrency"
                                [(ngModel)]="rowTotalCurrency" id="rowTotalsCurrencyOffer" value="offer"
                                [ngModelOptions]="{standalone: true}">
                            <label class="form-check-label" for="rowTotalsCurrencyOffer">Offer</label>
                        </div>
                    </div>
                </td>
                <td class="text-right">{{ totalAmount | currency: this.formGroup.get('header.currency').value }}</td>
                <td></td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="8" class="text-center">
                    <div>No charges found.</div>
                    <button class="btn btn-primary btn-sm mt-2" (click)="onAddChargeLine()"
                        [disabled]="!formGroup.get('header').valid">
                        <fa-icon [icon]="['far', 'plus']" class="mr-1"></fa-icon>Add a charge
                    </button>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <div class="mt-3">
        <button class="btn btn-primary btn-sm" (click)="onAddChargeLine()" *ngIf="!isEditingRows"
            [disabled]="!formGroup.get('header').valid">
            <fa-icon [icon]="['far', 'plus']" class="mr-1"></fa-icon>
            Add a charge
        </button>
    </div>
</form>
