import { Injectable } from '@angular/core';
import { HubUsersServiceBase } from '@qwyk/shared-stores/hub-users';
import { Hub, common } from '@qwyk/models';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@qwyk/hub/environment';
import { map, shareReplay, take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class UsersService implements HubUsersServiceBase {
    constructor(private http: HttpClient) {}

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getHubUsers(query: any): Observable<common.Pagination<Hub.HubUser>> {
        let httpQuery = null;
        if (query) {
            httpQuery = new HttpParams({ fromObject: query });
        }
        return this.http
            .get<common.Pagination<Hub.HubUser>>(
                `${environment.backendServer}/api/hub/organization/users?${httpQuery}`
            )
            .pipe(take(1));
    }

    public getHubUser(id: number): Observable<Hub.HubUser> {
        return this.http
            .get<{ data: Hub.HubUser }>(
                `${environment.backendServer}/api/hub/organization/users/${id}`
            )
            .pipe(
                map(response => response.data),
                take(1)
            );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public createHubUser(payload: any): Observable<Hub.HubUser> {
        return this.http
            .post<{ data: Hub.HubUser }>(
                `${environment.backendServer}/api/hub/organization/users`,
                payload
            )
            .pipe(
                map(response => response.data),
                take(1)
            );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public updateHubUser(id: number, payload: any): Observable<Hub.HubUser> {
        return this.http
            .patch<{ data: Hub.HubUser }>(
                `${environment.backendServer}/api/hub/organization/users/${id}`,
                payload
            )
            .pipe(
                map(response => response.data),
                take(1)
            );
    }

    public deactivateHubUser(id: number): Observable<Hub.HubUser> {
        return this.http
            .post<{ data: Hub.HubUser }>(
                `${environment.backendServer}/api/hub/organization/users/${id}/deactivate`,
                {}
            )
            .pipe(
                map(response => response.data),
                take(1)
            );
    }

    public activateHubUser(id: number): Observable<Hub.HubUser> {
        return this.http
            .post<{ data: Hub.HubUser }>(
                `${environment.backendServer}/api/hub/organization/users/${id}/activate`,
                {}
            )
            .pipe(
                map(response => response.data),
                take(1)
            );
    }

    public getRoles(): Observable<Hub.Role[]> {
        return this.http
            .get<{ data: Hub.Role[] }>(
                `${environment.backendServer}/api/hub/administration/roles`
            )
            .pipe(
                take(1),
                map(response => response.data),
                shareReplay()
            );
    }

    public validateEmailNotExists(email: string): Observable<void> {
        return this.http
            .get<void>(
                `${environment.backendServer}/api/hub/organization/users/validate/${email}`
            )
            .pipe(take(1));
    }

    public validateAuth0NotExists(email: string): Observable<void> {
        return this.http
            .get<void>(
                `${environment.backendServer}/api/hub/organization/users/validate-auth0/${email}`
            )
            .pipe(take(1));
    }
}
