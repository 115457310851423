import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MomentModule } from 'ngx-moment';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { CopyTextComponent } from './components/copy-text/copy-text.component';
import { ReportActivateDirective } from './directives/report-activate.directive';
import { DateTimeAgoComponent } from './components/date-time-ago/date-time-ago.component';
import { MediaUploaderComponent } from './components/media-uploader/media-uploader.component';
import { ErrorIndicatorComponent } from './components/error-indicator/error-indicator.component';
import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component';

@NgModule({
    imports: [
        NgbModule,
        CommonModule,
        MomentModule,
        TranslateModule,
        FontAwesomeModule,
    ],
    declarations: [
        LoadingIndicatorComponent,
        ErrorIndicatorComponent,
        MediaUploaderComponent,
        ReportActivateDirective,
        DateTimeAgoComponent,
        CopyTextComponent,
    ],
    exports: [
        LoadingIndicatorComponent,
        ErrorIndicatorComponent,
        MediaUploaderComponent,
        ReportActivateDirective,
        DateTimeAgoComponent,
        CopyTextComponent,
    ],
})
export class SharedModule {}
