import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PageTitleService {
    private baseTitle = 'Magaya Digital Freight Portal HUB';
    private eventSubscription: Subscription;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private title: Title
    ) {}

    public init() {
        if (this.eventSubscription) {
            this.eventSubscription.unsubscribe();
        }

        this.eventSubscription = this.router.events
            .pipe(
                filter(e => e instanceof NavigationEnd),
                map(() => this.getActivatedRoute()),
                mergeMap(route => route.data)
            )
            .subscribe(output => {
                if (output.title) {
                    this.title.setTitle(`${output.title} | ${this.baseTitle}`);
                } else {
                    this.title.setTitle(this.baseTitle);
                }
            });
    }

    private getActivatedRoute(): ActivatedRoute {
        let route = this.router.routerState.root;
        while (route.firstChild) {
            route = route.firstChild;
        }
        return route;
    }
}
