import { Subscription } from 'rxjs';
import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-resource-error-page',
    templateUrl: './resource-error-page.component.html',
    styleUrls: ['./resource-error-page.component.scss'],
})
export class ResourceErrorPageComponent implements OnDestroy {
    public errorCode = '-1';
    public resource: string;
    public errorName;

    private pSubscription: Subscription;
    private qpSubscription: Subscription;

    constructor(private route: ActivatedRoute) {
        this.pSubscription = this.route.params.subscribe(p => {
            this.errorCode = p.errorCode;
        });
        this.qpSubscription = this.route.queryParams.subscribe(qp => {
            this.resource = qp.source;
            this.errorName = qp.errorName;
        });
    }

    ngOnDestroy(): void {
        if (this.pSubscription) {
            this.pSubscription.unsubscribe();
        }

        if (this.qpSubscription) {
            this.qpSubscription.unsubscribe();
        }
    }

    public get icon() {
        let icon = 'exclamation-triangle';
        switch (this.errorCode) {
            case '402':
                icon = '';
                break;
            case '403':
                icon = 'do-not-enter';
                break;
            case '404':
                icon = 'search-location';
                break;
        }
        return ['fad', icon];
    }

    public get label() {
        switch (this.errorCode) {
            case '403':
                return 'No access to resource';
            case '404':
                return 'Resource not found';
            default:
                return 'Error';
        }
    }

    public get description() {
        switch (this.errorCode) {
            case '403':
                return 'Your access to this resource has been denied, because you are missing the required permissions to access it.';
            case '404':
                return 'The resource you were looking for could not be found, please try again or change your parameters.';
            default:
                return 'An error occured, please try again later or contact support if the problem persists';
        }
    }
}
