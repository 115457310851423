import { AppState } from './../../../../store/app.state';
import { UserService } from './../../services/user.service';
import { Injectable } from '@angular/core';
import { of, forkJoin } from 'rxjs';
import { Store } from '@ngrx/store';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import * as userActions from '../actions/user.actions';
import {
    catchError,
    map,
    withLatestFrom,
    first,
    tap,
    switchMap,
} from 'rxjs/operators';
import { selectUserState } from '../selectors/user.selectors';
import { ToastrService } from 'ngx-toastr';
import * as Sentry from '@sentry/angular';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AuthenticationActions } from '@qwyk/hub/authentication';

@Injectable()
export class UserEffects {
    loadUserData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(userActions.loadUserData),
            withLatestFrom(this.store$.select(selectUserState)),
            switchMap(([, userState]) => {
                const observables = {};
                observables['user'] = this.service.getUser().pipe(first());
                if (!userState.timezones.length) {
                    observables['timezones'] = this.service
                        .getTimeZones()
                        .pipe(first());
                }
                return forkJoin(observables).pipe(
                    map(result => userActions.loadUserDataSuccess(result)),
                    catchError(() => of(userActions.loadUserDataFailure()))
                );
            })
        )
    );

    loadUserDataSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(userActions.loadUserDataSuccess),
                tap(action => {
                    if (action.user) {
                        Sentry.setUser({
                            id: action.user.id.toString(),
                            username: action.user.name,
                            email: action.user.email,
                            organization: action.user.organization_name,
                        });
                    }
                })
            ),
        { dispatch: false }
    );

    loadUserDataFailure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(userActions.loadUserDataFailure),
                tap(() => {
                    this.toastrService.error(
                        'Try again by refreshing the page or contact support if the problem persists.',
                        'Error while loading data'
                    );
                })
            ),
        { dispatch: false }
    );

    loadUserActivities$ = createEffect(() =>
        this.actions$.pipe(
            ofType(userActions.loadActivities),
            switchMap(action =>
                this.service
                    .getUserActivities(action.page, action.pageSize)
                    .pipe(
                        map(activitiesInfo =>
                            userActions.loadActivitiesSuccess({
                                activitiesInfo,
                                append: action.append,
                            })
                        ),
                        catchError(() =>
                            of(userActions.loadActivitiesFailure())
                        )
                    )
            )
        )
    );

    updateUserProfile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(userActions.updateUserProfile),
            switchMap(action =>
                this.service.updateUser(action.userData).pipe(
                    map(user => userActions.updateUserProfileSuccess({ user })),
                    catchError(error =>
                        of(userActions.updateUserProfileFailure({ error }))
                    )
                )
            )
        )
    );

    updateUserProfileSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(userActions.updateUserProfileSuccess),
            switchMap(action =>
                of(AuthenticationActions.loadUserSuccess({ user: action.user }))
            )
        )
    );

    constructor(
        private actions$: Actions,
        private service: UserService,
        private store$: Store<AppState>,
        private toastrService: ToastrService
    ) {}
}
