<div class="card shadow-sm" style="margin-top: -1rem;">
    <div *ngIf="!editing" class="card-body p-3">
        <div class="d-flex justify-content-between mb-2">
            <div>
                {{ activity.user?.name }}
                <qwyk-relative-time-display
                        [value]="activity.created_at"
                        class="text-muted ml-1">
                </qwyk-relative-time-display>
                <span *ngIf="activity.created_at !== activity.updated_at" class="text-muted small ml-2">Edited</span>
            </div>
            <div class="d-flex align-items-center">
                <span class="badge badge-pill badge-outline-light border text-dark">Internal</span>
                <button (click)="editing = true" *ngIf="canMutate" [disabled]="loading || saving"
                        class="btn btn-outline-light btn-sm border-0 text-dark ml-1"
                        ngbTooltip="Edit">
                    <fa-icon [icon]="['far', 'edit']"></fa-icon>
                </button>
                <button (click)="onDeleteComment()" *ngIf="canMutate" [disabled]="loading || saving"
                        class="btn btn-outline-light btn-sm border-0 text-dark ml-1">
                    <fa-icon [icon]="['far', 'trash']"></fa-icon>
                </button>
            </div>
        </div>
        <div [innerHTML]="activity.content"></div>
    </div>
    <div *ngIf="editing" class="card-body p-3">
        <qwyk-create-comment
                (cancel)="editing = false"
                (save)="onUpdateComment($event)"
                [disabled]="saving || loading"
                [html]="activity.content || ''"
                [saving]="saving"
                [showCancel]="true"
                saveButtonText="Save comment"
        ></qwyk-create-comment>
    </div>
</div>
