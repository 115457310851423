import {UserState} from './../user.state';
import {createFeatureSelector, createSelector} from '@ngrx/store';

const selectFeature = createFeatureSelector<UserState>('user');

export const selectUserState = createSelector(
    selectFeature,
    (state: UserState) => state
);

export const selectUser = createSelector(
    selectFeature,
    (state: UserState) => state.user
);

export const selectTimezones = createSelector(
    selectFeature,
    (state: UserState) => state.timezones
);

export const selectActivities = createSelector(
    selectFeature,
    (state: UserState) => {
        return {
            info: state.activitiesInfo,
            loading: state.loadingActivities,
            error: state.activitiesError,
        };
    }
);
