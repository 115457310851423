<div class="modal-header bg-light border-0 p-4">
    <h4 class="modal-title" id="modal-basic-title">{{ 'hub.home.qwykportals-help-center' | translate }}</h4>
    <button type="button" (click)="modal.dismiss()" class="close border border-0" attr.aria-label="{{ 'common.close' | translate }}">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<span ngbAutofocus></span>
<div class="modal-body bg-light text-center p-4">
    <div class="row row-cols-4">
        <div class="col">
            <div class="card h-100">
                <div class="card-header bg-white border-0">
                    <h4 class="font-weight-normal">{{ 'portal.schedules.search.documentation' | translate }}</h4>
                </div>
                <div class="card-body flex-grow-1">
                    <fa-icon [icon]="['fad', 'books']" class="text-secondary" size="5x"></fa-icon>
                    <p class="mt-5">
                        {{ 'hub.home.help-modal.documentation-body' | translate }}
                    </p>
                </div>
                <div class="card-footer bg-white border-0">
                    <a class="btn btn-outline-primary" href="https://docs.qwykportals.com" target="_blank">{{ 'hub.home.help-modal.read-the-docs' | translate }}</a>
                </div>
            </div>
        </div>

        <div class="col">
            <div class="card h-100">
                <div class="card-header bg-white border-0">
                    <h4 class="font-weight-normal">{{ 'hub.home.help-modal.demo-videos' | translate }}</h4>
                </div>
                <div class="card-body flex-grow-1">
                    <fa-icon [icon]="['fad', 'film']" class="text-primary" size="5x"></fa-icon>
                    <p class="mt-5">
                        {{ 'hub.home.help-modal.demo-videos-body' | translate }}
                    </p>
                </div>
                <div class="card-footer bg-white border-0">
                    <a class="btn btn-outline-primary" href="https://training.qwykportals.com" target="_blank">{{ 'hub.home.help-modal.view-tutorials' | translate }}</a>
                </div>
            </div>
        </div>

        <div class="col">
            <div class="card h-100">
                <div class="card-header bg-white border-0">
                    <h4 class="font-weight-normal">{{ 'hub.home.help-modal.suggestions' | translate }}</h4>
                </div>
                <div class="card-body flex-grow-1">
                    <fa-icon [icon]="['fad', 'bullhorn']" class="text-secondary" size="5x"></fa-icon>
                    <p class="mt-5">
                        {{ 'hub.home.help-modal.suggestions-body' | translate }}
                    </p>
                </div>
                <div class="card-footer bg-white border-0">
                    <a class="btn btn-outline-primary" href="{{feedbackSite}}" target="_blank">{{ 'hub.home.help-modal.send-suggestion' | translate }}</a>
                </div>
            </div>
        </div>

        <div class="col">
            <div class="card h-100">
                <div class="card-header bg-white border-0">
                    <h4 class="font-weight-normal">{{ 'hub.home.help-modal.support-team' | translate }}</h4>
                </div>
                <div class="card-body flex-grow-1">
                    <fa-icon [icon]="['fad', 'user-headset']" class="text-primary" size="5x"></fa-icon>
                    <p class="mt-5">
                        {{ 'hub.home.help-modal.support-team-body' | translate }}
                    </p>
                </div>
                <div class="card-footer bg-white border-0">
                    <a class="btn btn-outline-primary" href="mailto:{{supportEmail}}">{{ 'hub.menu.top-menu.contact-support' | translate }}</a>
                </div>
            </div>
        </div>
    </div>

    <div class="text-right mt-3 small">
        <a href="javascript:void(0);" (click)="modal.dismiss()">{{ 'common.close' | translate }}</a>
    </div>
</div>
