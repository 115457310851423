<div class="card shadow-sm">
    <div class="card-header bg-white border-0 d-flex align-items-center justify-content-between">
        <div class="h5 font-weight-normal m-0">
            Activity
        </div>
        <qwyk-expand-collapse-button
                (clicked)="expanded = !expanded"
                [expanded]="expanded"
                iconSize="lg">
        </qwyk-expand-collapse-button>
    </div>
    <qwyk-activities-feed
            [hidden]="!expanded"
            [parent]="{type: 'quotations', id: quotation.id}"
    ></qwyk-activities-feed>
</div>
