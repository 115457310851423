import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import {
    IsLoggedInGuard,
    IsMasterAdminGuard,
    VerifyFeaturesGuard,
    CheckTrialExpiredGuard,
    VerifyAllowedRoleGuard,
} from '@qwyk/hub/authentication';
import { Constants } from '@qwyk/core';

import { IsFeatureEnabledGuard } from './guards/is-feature-enabled.guard';
import { HomepageComponent } from './components/homepage/homepage.component';
import { IndexNewComponent } from './components/index-new/index-new.component';
import { TrialExpiredComponent } from './components/trial-expired/trial-expired.component';
import { ResourceErrorPageComponent } from './components/resource-error-page/resource-error-page.component';

const routes: Routes = [
    {
        path: 'callback',
        pathMatch: 'full',
        loadChildren: () =>
            import('@qwyk/hub/authentication').then(
                M => M.HubAuthenticationModule
            ),
    },
    {
        path: 'onboarding',
        loadChildren: () =>
            import('./modules/onboarding/onboarding.module').then(
                m => m.OnboardingModule
            ),
    },
    {
        path: '',
        component: IndexNewComponent,
        canActivate: [IsLoggedInGuard, CheckTrialExpiredGuard],
        canActivateChild: [IsLoggedInGuard, CheckTrialExpiredGuard],
        children: [
            {
                path: '',
                component: HomepageComponent,
            },
            {
                path: 'user',
                loadChildren: () =>
                    import('./modules/user/user.module').then(
                        m => m.UserModule
                    ),
            },
            {
                path: 'radar',
                loadChildren: () =>
                    import('@qwyk/hub/radar').then(m => m.HubRadarModule),
                canActivate: [VerifyAllowedRoleGuard],
                data: {
                    allowedRoles: [
                        Constants.Role.OWNER,
                        Constants.Role.ADMIN,
                        Constants.Role.OPERATOR,
                        Constants.Role.SHIPMENTS_OPERATOR,
                    ],
                },
            },
            {
                path: 'shipments',
                loadChildren: () =>
                    import('@qwyk/hub/shipments').then(
                        m => m.HubShipmentsModule
                    ),
                canActivate: [IsFeatureEnabledGuard, VerifyAllowedRoleGuard],
                data: {
                    feature: 'portal',
                    featureValue: true,
                    featureName: 'User Portal',
                    allowedRoles: [
                        Constants.Role.OWNER,
                        Constants.Role.ADMIN,
                        Constants.Role.OPERATOR,
                        Constants.Role.SHIPMENTS_OPERATOR,
                    ],
                },
            },
            {
                path: 'quotations',
                loadChildren: () =>
                    import('@qwyk/hub/quotations').then(
                        M => M.HubQuotationsModule
                    ),
                canActivate: [IsFeatureEnabledGuard, VerifyAllowedRoleGuard],
                data: {
                    feature: 'portal',
                    featureValue: true,
                    featureName: 'User Portal',
                    allowedRoles: [
                        Constants.Role.OWNER,
                        Constants.Role.ADMIN,
                        Constants.Role.OPERATOR,
                        Constants.Role.QUOTATIONS_OPERATOR,
                    ],
                },
            },
            {
                path: 'invoices',
                loadChildren: () =>
                    import('@qwyk/hub/invoices').then(M => M.HubInvoicesModule),
                canActivate: [IsFeatureEnabledGuard, VerifyAllowedRoleGuard],
                data: {
                    feature: 'portal',
                    featureValue: true,
                    featureName: 'User Portal',
                    allowedRoles: [
                        Constants.Role.OWNER,
                        Constants.Role.ADMIN,
                        Constants.Role.OPERATOR,
                    ],
                },
            },
            {
                path: 'schedules',
                loadChildren: () =>
                    import('./modules/schedules/schedules.module').then(
                        m => m.SchedulesModule
                    ),
                data: {
                    allowedRoles: [Constants.Role.OWNER, Constants.Role.ADMIN],
                },
                canActivate: [VerifyAllowedRoleGuard],
            },
            {
                path: 'rates',
                loadChildren: () =>
                    import('@qwyk/hub/rate-explorer').then(
                        m => m.HubRateExplorerModule
                    ),
                data: {
                    allowedRoles: [
                        Constants.Role.OWNER,
                        Constants.Role.ADMIN,
                        Constants.Role.OPERATOR,
                    ],
                },
                canActivate: [VerifyAllowedRoleGuard],
            },
            {
                path: 'notifications',
                loadChildren: () =>
                    import('@qwyk/hub/notifications').then(
                        m => m.HubNotificationsModule
                    ),
            },
            {
                path: 'console',
                canActivate: [IsMasterAdminGuard],
                loadChildren: () =>
                    import('@qwyk/hub/console').then(m => m.HubConsoleModule),
            },
            {
                path: 'portal',
                redirectTo: 'administration',
            },
            {
                path: 'administration',
                loadChildren: () =>
                    import('@qwyk/hub/administration').then(
                        m => m.HubAdministrationModule
                    ),
                data: {
                    allowedRoles: [Constants.Role.OWNER, Constants.Role.ADMIN],
                },
                canActivate: [VerifyAllowedRoleGuard],
            },
            {
                path: 'settings',
                loadChildren: () =>
                    import('@qwyk/hub/settings').then(m => m.HubSettingsModule),
                data: {
                    allowedRoles: [Constants.Role.OWNER],
                },
                canActivate: [VerifyAllowedRoleGuard],
            },
            {
                path: 'conversations',
                loadChildren: () =>
                    import('@qwyk/hub/messaging').then(
                        m => m.HubMessagingModule
                    ),
                data: {
                    allowedRoles: [
                        Constants.Role.OWNER,
                        Constants.Role.ADMIN,
                        Constants.Role.OPERATOR,
                    ],
                    requiredFeature: ['disable_global_chat'],
                },
                canActivate: [VerifyAllowedRoleGuard, VerifyFeaturesGuard],
            },
            {
                path: 'error/:errorCode',
                component: ResourceErrorPageComponent,
            },
        ],
    },
    {
        path: '404',
        redirectTo: '/',
    },
    {
        path: 'trial-expired',
        component: TrialExpiredComponent,
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            anchorScrolling: 'enabled',
            scrollPositionRestoration: 'enabled',
            paramsInheritanceStrategy: 'always',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
