<div class="m-5 text-muted" *ngIf="errorCode !== '402'">

    <h3 class="mt-2">
        <fa-icon [icon]="icon" class="text-danger mr-3" [fixedWidth]="true"></fa-icon>{{label}}
    </h3>
    <p class="mt-4">{{ description }}</p>
    <hr>
    <small>
        <div>Resource ID: {{ resource }}</div>
        <div>Error Code: {{ errorCode }} {{ errorName }}</div>
    </small>
    <hr>
    <a [routerLink]="resource">Try again</a>
</div>

<div class="m-5 text-muted text-center" *ngIf="errorCode === '402'">
    <fa-icon [icon]="['fad', 'rocket-launch']" class="text-warning" size="4x"></fa-icon>
    <h4 class="mt-5">
        The {{ errorName }} feature is not enabled for your account
    </h4>
    <p class="">This feature requires configuration by Qwyk, contact sales to get it set up.</p>
    <a class="btn btn-outline-primary mt-4"
        href="mailto:sales@qwyk.io?Subject=Enabling {{errorName}} (resource: {{resource}})">
        Contact sales
    </a>
    <!-- <small>
        <div>Resource ID: {{ resource }}</div>
        <div>Error Code: {{ errorCode }} {{ errorName }}</div>
    </small> -->

</div>