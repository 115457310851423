<span
    *ngIf="!emailVerified"
    class="badge badge-outline-warning mr-1"
    ngbTooltip="{{ 'hub.administration.portal-users.email-not-yet-verified' | translate }}"
>
    <fa-icon [icon]="['fas', 'envelope']"></fa-icon> {{ 'common.email' | translate }}
</span>
<span
    *ngIf="!approved"
    class="badge badge-outline-danger mr-1"
    ngbTooltip="{{ 'hub.administration.portal-users.approval-is-pending' | translate }}"
>
    <fa-icon [icon]="['fas', 'shield-check']"></fa-icon> {{ 'hub.administration.portal-users.approval' | translate }}
</span>
<span
    *ngIf="deactivated"
    class="badge badge-danger mr-1"
    ngbTooltip="{{ 'hub.administration.portal-users.user-was-rejected-or-deactivated' | translate }}"
>
    <fa-icon [icon]="['fas', 'ban']"></fa-icon> {{ 'common.deactivated' | translate }}
</span>
<span
    *ngIf="!deactivated && approved"
    class="badge badge-outline-success mr-1"
    ngbTooltip="{{ 'hub.administration.portal-users.user-is-active' | translate }}"
>
    <fa-icon [icon]="['fas', 'check-circle']"></fa-icon> {{ 'common.activated' | translate }}
</span>
