import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Portals } from '@qwyk/models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SimpleModalComponent } from '@qwyk/ui-standalone';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'qwyk-quotation-offers-list',
    templateUrl: './quotation-offers-list.component.html',
    styleUrls: ['./quotation-offers-list.component.scss'],
})
export class QuotationOffersListComponent {
    @Input() quotation: Portals.QuotationEntity;
    @Input() offers: Portals.QuotationOffer[];
    @Input() editable = true;
    @Output() deleteOffer: EventEmitter<{
        quotation: Portals.QuotationEntity;
        offer: Portals.QuotationOffer;
    }> = new EventEmitter();

    @Output() downloadOffer: EventEmitter<{
        quotation: Portals.QuotationEntity;
        offer: Portals.QuotationOffer;
    }> = new EventEmitter();

    @Input() displayBuyingInfo = true;

    public highlightOfferId = null;

    constructor(private modal: NgbModal, private route: ActivatedRoute) {
        if (route.snapshot.queryParamMap.has('ho')) {
            this.highlightOfferId = route.snapshot.queryParamMap.get('ho');
        }
    }

    public onDeleteOffer(
        quotation: Portals.QuotationEntity,
        offer: Portals.QuotationOffer
    ) {
        const modalRef = this.modal.open(SimpleModalComponent, {
            centered: true,
        });
        modalRef.componentInstance.header = 'Delete offer';
        modalRef.componentInstance.message =
            'Are you sure you want to delete this offer? This cannot be undone.';
        modalRef.componentInstance.dismissAction = 'No';
        modalRef.componentInstance.confirmAction = 'Yes';
        modalRef.componentInstance.confirmStyle = 'btn-danger';

        modalRef.result.then(
            () => {
                this.deleteOffer.emit({ quotation, offer });
            },
            () => {}
        );
    }

    public onDownloadOfferPdf(
        quotation: Portals.QuotationEntity,
        offer: Portals.QuotationOffer
    ): void {
        this.downloadOffer.emit({ quotation, offer });
    }
}
