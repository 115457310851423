import { createAction, props } from '@ngrx/store';
import { NotificationsEntity } from './notifications.models';
import { common } from '@qwyk/models';

export const loadNotifications = createAction(
    '[Hub/Notifications] Init',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    props<{ query: any }>()
);

export const loadNotificationsSuccess = createAction(
    '[Hub/Notifications] Load Notifications Success',
    props<{
        notifications: NotificationsEntity[];
        pagination: common.PaginationMeta;
    }>()
);

export const loadNotificationsFailure = createAction(
    '[Hub/Notifications] Load Notifications Failure',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    props<{ error: any }>()
);
