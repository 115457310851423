import { SimulateRoutingGuideComponent } from './../simulate-routing-guide/simulate-routing-guide.component';
import { UploadRoutingGuideComponent } from './../upload-routing-guide/upload-routing-guide.component';
import { DownloadRoutingGuideComponent } from '../download-routing-guide/download-routing-guide.component';
import { Provider } from '../../../store/models/providers.models';

import { selectRoutingGuideState } from '../../../store/selectors/routing-guide.selectors';
import { AppState } from '../../../../../store/app.state';
import { AlgoliaLocationsService } from '../../../../shared/services/algolia-locations.service';
import {
    PRODUCT_ICONS,
    PRODUCT_COLORS,
} from '../../../../../constants/productMappings';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription, Observable } from 'rxjs';
import { fadeInAnimation } from '../../../../../helpers/reusableAnimations';
import { Store } from '@ngrx/store';
import {
    loadRoutingGuide,
    loadRoutingGuideItems,
    deleteRoutingGuideItem,
} from '../../../store/actions/routing-guide.actions';
import {
    RoutingGuideState,
    RoutingGuideStateItemsInfo,
} from '../../../store/schedules.state';
import {
    RoutingGuideItem,
    RoutingGuide,
} from '../../../store/models/routing-guide.models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NewRoutingGuideItemModalComponent } from '../new-routing-guide-item-modal/new-routing-guide-item-modal.component';
import { UpdateRoutingGuideItemModalComponent } from '../update-routing-guide-item-modal/update-routing-guide-item-modal.component';

@Component({
    selector: 'app-routing-guide',
    templateUrl: './routing-guide.component.html',
    styleUrls: ['./routing-guide.component.scss'],
    animations: [fadeInAnimation],
})
export class RoutingGuideComponent implements OnInit, OnDestroy {
    product$: BehaviorSubject<string> = new BehaviorSubject('');
    routingGuideState$: Observable<RoutingGuideState> = this.store.select(
        selectRoutingGuideState
    );
    productIcons = PRODUCT_ICONS;
    productColors = PRODUCT_COLORS;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    searchLocationResults: any[];
    itemsInfo: RoutingGuideStateItemsInfo;
    routingGuideItems: RoutingGuideItem[] = [];
    providers: Provider[];

    clonedRoutingGuideItems: RoutingGuideItem[] = [];
    productSubscription: Subscription;
    routingGuideStateSubscription: Subscription;
    routingGuide: RoutingGuide | null;
    isLoading = false;

    private _page = 1;
    pageSize = 20;
    totalItems: number | undefined = 0;
    filterSet = null;

    constructor(
        private modalService: NgbModal,
        private store: Store<AppState>,
        private algoliaLocations: AlgoliaLocationsService
    ) {}

    ngOnInit() {
        // Set a subscription to observe the routing guide state
        this.routingGuideStateSubscription = this.routingGuideState$.subscribe(
            state => {
                if (state.loadingRoutingGuide || state.deletingItem != null) {
                    this.isLoading = true;
                } else {
                    this.isLoading = false;
                }

                this.routingGuide = state.routingGuide;

                this.providers = state.providers;
                this.itemsInfo = state.itemsInfo;

                // Only get the currently requested page
                if (this.itemsInfo && this.itemsInfo.meta) {
                    this.totalItems = this.itemsInfo.meta.total;
                }

                this.routingGuideItems = this.itemsInfo.data.slice(
                    (this.page - 1) * this.pageSize,
                    (this.page - 1) * this.pageSize + this.pageSize
                );
                if (this.filterSet) {
                    this.onFilterChanged(this.filterSet);
                }
            }
        );

        // If the product changes, we'll request for a new routing guide
        this.productSubscription = this.product$.subscribe(product => {
            if (product) {
                this.store.dispatch(
                    loadRoutingGuide({ product, pageSize: this.pageSize })
                );
            }
        });
    }

    ngOnDestroy(): void {
        // Unsubscribe all subscriptions
        if (this.productSubscription) {
            this.productSubscription.unsubscribe();
        }

        if (this.routingGuideStateSubscription) {
            this.routingGuideStateSubscription.unsubscribe();
        }
    }

    /**
     * Handles the filter from routing-guide-search being changed.
     * @param event The event data containing the filter information
     */
    onFilterChanged(event) {
        if (!event) {
            // Filter is unset, so we reset everything.
            this.filterSet = null;
            this.clonedRoutingGuideItems = [];
            this._page = 1; // we use the private member here to prevent already reloading the items
            this.loadRoutingGuideItemsLazy();
            return;
        }
        // Filter is set so we'll apply it.
        this.filterSet = event;

        this.routingGuideItems = this.itemsInfo.data.filter(item => {
            if (event.origin && event.origin.locode !== item.origin.locode) {
                return false;
            }

            if (
                event.destination &&
                event.destination.locode !== item.destination.locode
            ) {
                return false;
            }

            if (event.provider) {
                const providerIds = item.providers.map(e => e.id);
                if (!providerIds.includes(event.provider.id)) {
                    return false;
                }
            }

            return true;
        });
        // Make a clone to help with paging
        this.clonedRoutingGuideItems = [...this.routingGuideItems];

        // Set the totalItems so the pager is correct.
        this.totalItems = this.routingGuideItems.length;

        // Set the page to 1, this will also help to recalc the page lengths etc.
        this.page = 1;
    }

    /**
     * Provides the trackBy item for the table.
     */
    routingGuideTrackBy = (_, item) => {
        return item._id;
        // tslint:disable-next-line: semicolon
    };

    /**
     * Gets current page
     */
    get page() {
        return this._page;
    }

    /**
     * Sets current page
     */
    set page(page: number) {
        // Don't do all this stuff if the page number doesn't change
        if (page !== this._page) {
            this._page = page;
            this.loadRoutingGuideItemsLazy();
        }
    }

    /**
     * Handles lazy loading of paged items
     */
    loadRoutingGuideItemsLazy() {
        // Handle paging when a filter has been set.
        if (this.filterSet != null) {
            this.routingGuideItems = this.clonedRoutingGuideItems.slice(
                (this.page - 1) * this.pageSize,
                (this.page - 1) * this.pageSize + this.pageSize
            );
            return;
        }

        this.totalItems = this.itemsInfo?.meta?.total;

        // Check if the requested page already exists in the dictionary of paged items
        if (this.itemsInfo.data.length > (this.page - 1) * this.pageSize) {
            this.routingGuideItems = this.itemsInfo.data.slice(
                (this.page - 1) * this.pageSize,
                (this.page - 1) * this.pageSize + this.pageSize
            );
            return;
        }
        this.store.dispatch(
            loadRoutingGuideItems({
                routingGuideId: this.routingGuide?.id,
                page: this.page,
                pageSize: this.pageSize,
            })
        );
    }

    /**
     * Hamdles searching for locations
     * @param event the event emitted by the typeahead
     */
    searchLocation(event) {
        const searchSubscription = this.algoliaLocations
            .getLocationSuggestions(event.query)
            .subscribe(results => {
                this.searchLocationResults = results;
                searchSubscription.unsubscribe();
            });
    }

    /**
     * Shows the dialog for adding a new routing guide item.
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    addRoutingGuideItem(rowData: any | null = null) {
        const modalRef = this.modalService.open(
            NewRoutingGuideItemModalComponent,
            {
                centered: true,
                backdrop: 'static',
                keyboard: false,
            }
        );
        if (rowData) {
            modalRef.componentInstance.rowData = rowData;
        }
        // We want to go to the first page so the new item is in view
        modalRef.result.then(
            () => {
                this.page = 1;
            },
            () => {}
        );
    }

    /**
     * Shows the dialog for updating a routing guide item
     * @param routingGuideItem the routing guide item to be updated.
     */
    updateRoutingGuideItem(routingGuideItem: RoutingGuideItem) {
        const modalRef = this.modalService.open(
            UpdateRoutingGuideItemModalComponent,
            {
                centered: true,
                backdrop: 'static',
                keyboard: false,
                size: 'lg',
            }
        );

        modalRef.componentInstance.routingGuideItem = routingGuideItem;
    }

    /**
     * Deletes routing guide item from the server
     * @param routingGuideItem the routing guide item to be deleted
     */
    deleteRoutingGuideItem(routingGuideItem: RoutingGuideItem) {
        this.store.dispatch(deleteRoutingGuideItem({ routingGuideItem }));
    }

    openDownloadRoutingGuideModal() {
        this.modalService.open(DownloadRoutingGuideComponent, {
            centered: true,
            // backdrop: 'static',
            // keyboard: false,
            // size: 'lg',
        });
    }

    openUploadRoutingGuideModal() {
        this.modalService.open(UploadRoutingGuideComponent, {
            centered: true,
        });
    }

    openSimulationModal() {
        const modalRef = this.modalService.open(SimulateRoutingGuideComponent, {
            centered: true,
            size: 'lg',
        });

        if (this.routingGuide) {
            modalRef.componentInstance.setProduct(this.routingGuide.product);
        }
    }
}
