/* eslint-disable @typescript-eslint/no-explicit-any */
import * as carriagesActions from '../../../store/actions/carriages.actions';
import * as carriagesSelectors from '../../../store/selectors/carriages.selectors';
import { ActivatedRoute, Router } from '@angular/router';
import { AlgoliaLocationsService } from './../../../../shared/services/algolia-locations.service';
import { AppState } from './../../../../../store/app.state';
import { Carriage } from '../../../store/models/carriages.models';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DAYS_OF_WEEK } from './../../../../../constants/days';
import { fadeInAnimation } from './../../../../../helpers/reusableAnimations';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { ValidatorsX } from './../../../../../helpers/ValidatorsX';
import { pairwise, startWith, debounceTime } from 'rxjs/operators';

@Component({
    selector: 'app-carriage-detail',
    templateUrl: './carriage-detail.component.html',
    styleUrls: ['./carriage-detail.component.scss'],
    animations: [fadeInAnimation],
})
export class CarriageDetailComponent implements OnInit, OnDestroy {
    constructor(
        private route: ActivatedRoute,
        private store: Store<AppState>,
        private fb: FormBuilder,
        private algoliaLocations: AlgoliaLocationsService,
        private router: Router
    ) {
        this.initializeFormControls();
    }

    //#region Public variables
    public tempLocation: any = null;
    public form: FormGroup;
    public searchLocationResults: any[];
    public departureDays = DAYS_OF_WEEK;
    //#endregion

    //#region Observables
    private selectedCarriage$: Observable<Carriage | null>;
    public loading$: Observable<boolean> = this.store.select(
        carriagesSelectors.isGettingCarriage
    );
    public loadingError$: Observable<boolean> = this.store.select(
        carriagesSelectors.getCarriageError
    );
    public saving$: Observable<boolean> = this.store.select(
        carriagesSelectors.isSavingCarriage
    );
    public deleting$: Observable<boolean> = this.store.select(
        carriagesSelectors.isDeletingCarriage
    );
    public saveCompleted$: Observable<boolean> = this.store.select(
        carriagesSelectors.saveCarriageCompleted
    );
    public errorSaving$: Observable<any> = this.store.select(
        carriagesSelectors.saveCarriageFailure
    );
    //#endregion

    //#region Subscriptions
    private carriageSubscription: Subscription;
    private paramsSubscription: Subscription;
    private saveCompletedSubscription: Subscription;
    private errorSavingSubscription: Subscription;
    private scheduleTypeWatchSubscription!: Subscription;
    private timeWatchSubscription: Subscription;
    //#endregion

    //#region  Private variables
    private id: number;
    //#endregion

    /**
     * Fired once when the component is initialized.
     */
    public ngOnInit() {
        this.paramsSubscription = this.route.params.subscribe(params => {
            if (!params.id || params.id === 'new') {
                // If id is not present or equals `new` then we'll create a new carriage.
                return this.initializeNewCarriage();
            } else {
                // If the id has been set we'll try to get the Store's selected carriage.
                this.id = +params.id;
                this.selectedCarriage$ = this.store.select(
                    carriagesSelectors.getSelectedCarriage
                );
                this.carriageSubscription = this.selectedCarriage$.subscribe(
                    carriage => {
                        if (!carriage || carriage.id !== this.id) {
                            // If the carriage is not set or the id doesn't match the url parameter id.
                            // then dispatch to get the carriage belonging to this id.
                            this.store.dispatch(
                                carriagesActions.getCarriage({ id: this.id })
                            );
                        } else {
                            // Set a timeout so we have time to unsub
                            setTimeout(() => {
                                // We've found the correct carriage so we'll initialize the form with it.
                                this.initializeExistingCarriage(carriage);
                                this.carriageSubscription.unsubscribe();
                            }, 100);
                        }
                    }
                );
            }
        });

        this.saveCompletedSubscription = this.saveCompleted$.subscribe(val => {
            if (val) {
                this.form.enable();
                this.router.navigate(['../'], { relativeTo: this.route });
            }
        });

        this.errorSavingSubscription = this.errorSaving$.subscribe(error => {
            if (error) {
                this.form.enable();
            }
        });
    }

    /**
     * Fired once when the component gets destroyed
     * Cleans up all subscriptions.
     */
    public ngOnDestroy(): void {
        if (this.paramsSubscription) {
            this.paramsSubscription.unsubscribe();
        }

        if (this.carriageSubscription) {
            this.carriageSubscription.unsubscribe();
        }

        if (this.saveCompletedSubscription) {
            this.saveCompletedSubscription.unsubscribe();
        }

        if (this.errorSavingSubscription) {
            this.errorSavingSubscription.unsubscribe();
        }

        if (this.scheduleTypeWatchSubscription) {
            this.scheduleTypeWatchSubscription.unsubscribe();
        }

        if (this.timeWatchSubscription) {
            this.timeWatchSubscription.unsubscribe();
        }
    }

    /**
     * Handles the form submit action.
     */
    public submit(): void {
        // Make sure each control is validated.
        Object.keys(this.form.controls).forEach(field => {
            const control = this.form.get(field);
            control?.markAsTouched({ onlySelf: true });
        });

        // If the form doesn't validate don't proceed.
        if (this.form.invalid) {
            return;
        }

        const carriage: Carriage = this.form.value;

        //  Disable to form to avoid further user input. (after we get the value as form seems to get PENDING state..)
        this.form.disable();

        // Make sure we're unsubscribed so the model doesn't start refreshing.
        if (this.carriageSubscription) {
            this.carriageSubscription.unsubscribe();
        }

        if (!carriage.id) {
            // If the ID is null or not set then we're creating a new carriage.
            this.store.dispatch(carriagesActions.createCarriage({ carriage }));
        } else {
            // Else we update an existing carriage.
            this.store.dispatch(carriagesActions.updateCarriage({ carriage }));
        }
    }

    /**
     * Dispatches the delete carriage action.
     */
    public deleteCarriage(): void {
        const carriage: Carriage = this.form.value;
        if (!carriage.id) {
            return;
        }
        this.form.disable();
        this.store.dispatch(carriagesActions.deleteCarriage({ carriage }));
    }

    /**
     * Handles searching for locations
     * @param event the event emitted by the typeahead
     */
    public searchLocation(event): void {
        const searchSubscription = this.algoliaLocations
            .getLocationSuggestions(event.query)
            .subscribe(results => {
                this.searchLocationResults = results;
                searchSubscription.unsubscribe();
            });
    }

    /**
     * Handles a location getting selected in one of the autocomplete controls
     * @param event The select event emitted by the control
     * @param formControlName The formcontrol this was fired on.
     */
    public onLocationSelect(event, formControlName): void {
        if (formControlName === 'destinations') {
            const values = this.form.get('destinations')?.value || [];
            if (!values.includes(event.locode)) {
                values.push(event.locode);
                this.form.get('destinations')?.setValue(values);
            }

            setTimeout(() => {
                this.tempLocation = null;
            }, 100);

            return;
        }
        this.form.get(formControlName)?.setValue({
            locode: event.locode,
            display_name: event.display_name,
        });
    }

    /**
     * Removes an element from the form's list of destinations.
     * @param destination The value of the destination to remove.
     */
    public removeDestination(destination: string): void {
        const values: string[] = this.form.get('destinations')?.value || [];
        if (values.includes(destination)) {
            values.splice(values.indexOf(destination), 1);
        }
        this.form.get('destinations')?.setValue(values);
    }

    /**
     * Handles a clear request on one of the autocomplete controls
     * @param event The clear event
     * @param formControlName The control on which the event fired.
     */
    public onLocationClear(event, formControlName): void {
        this.form.get(formControlName)?.patchValue(null);
    }

    /**
     * Initializes the form with controls, defaults, and validators.
     */
    private initializeFormControls(): void {
        this.form = this.fb.group({
            id: [null],
            schedule_type: ['pre', [Validators.required]],
            origin: [null, [Validators.required]],
            destination: [null, [Validators.required]],
            schedule_mode: ['fixed', [Validators.required]],
            time: [null, [Validators.required, Validators.min(0)]],
            time_days: ['cal', [Validators.required]],
            date: [
                'lrd',
                [],
                [ValidatorsX.requiredWhenEqualsAsync('schedule_mode', 'fixed')],
            ],
            dep_days: [
                [],
                [],
                [
                    ValidatorsX.minWhenEqualsAsync(
                        1,
                        'schedule_mode',
                        'schedule'
                    ),
                ],
            ],
            vessel_name: [null, [Validators.required]],
            carrier_name: [null, [Validators.required]],
            destinations: [
                ['*'],
                [Validators.required, Validators.minLength(1)],
            ],
        });
        this.setupTimeWatcher();
    }

    /**
     * Initializes this component with a new carriage
     */
    private initializeNewCarriage(): void {
        this.setupScheduleTypeWatcher();
    }

    /**
     * Initializes this component with a found carriage.
     * @param carriage The carriage to initialize the form with
     */
    private initializeExistingCarriage(carriage: Carriage) {
        const f = this.form;
        f.get('id')?.setValue(carriage.id);
        f.get('schedule_type')?.setValue(carriage.schedule_type);
        f.get('origin')?.setValue(carriage.origin);
        f.get('destination')?.setValue(carriage.destination);
        f.get('schedule_mode')?.setValue(carriage.schedule_mode);
        f.get('time')?.setValue(carriage.time);
        f.get('time_days')?.setValue(carriage.time_days);
        f.get('date')?.setValue(carriage.date);
        f.get('dep_days')?.setValue(carriage.dep_days || []);
        f.get('vessel_name')?.setValue(carriage.vessel_name);
        f.get('carrier_name')?.setValue(carriage.carrier_name);
        f.get('destinations')?.setValue(carriage.destinations);

        this.setupScheduleTypeWatcher();
    }

    /**
     * Resets 'date' to an acceptable value on a 'schedule_type' change.
     */
    private setupScheduleTypeWatcher() {
        const scheduleTypeField = this.form.get('schedule_type');

        if (scheduleTypeField) {
            this.scheduleTypeWatchSubscription = scheduleTypeField.valueChanges
                .pipe(startWith(''), pairwise())
                .subscribe(([prev, next]: [any, any]) => {
                    if (prev !== next) {
                        if (next === 'pre') {
                            this.form.get('date')?.setValue('lrd');
                        } else {
                            this.form.get('date')?.setValue('eta');
                        }
                    }
                });
        }
    }

    /**
     * Ensures 'time' is always an integer.
     */
    private setupTimeWatcher() {
        const timeField = this.form.get('time');

        if (timeField) {
            this.timeWatchSubscription = timeField.valueChanges
                .pipe(debounceTime(500), startWith(0), pairwise())
                .subscribe(([prev, next]: [number, number]) => {
                    if (prev !== next) {
                        if (!isNaN(next) && !Number.isInteger(next)) {
                            this.form.get('time')?.setValue(Math.round(next));
                        }
                    }
                });
        }
    }
}
