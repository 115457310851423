import { createReducer, on, Action } from '@ngrx/store';
import {
    loadRoutingGuide,
    loadRoutingGuideItems,
    loadRoutingGuideItemsSuccess,
    loadRoutingGuideItemsFailure,
    createRoutingGuideItem,
    createRoutingGuideItemSuccess,
    createRoutingGuideItemFailed,
    updateRoutingGuideItem,
    updateRoutingGuideItemSuccess,
    updateRoutingGuideItemFailure,
    deleteRoutingGuideItemSuccess,
    deleteRoutingGuideItemFailure,
    deleteRoutingGuideItemStarted,
} from '../actions/routing-guide.actions';
import {
    loadRoutingGuideFailure,
    loadRoutingGuideSuccess,
} from './../actions/routing-guide.actions';
import { RoutingGuideState } from '../schedules.state';

export const initialState: RoutingGuideState = {
    routingGuide: null,
    loadingRoutingGuide: false,
    routingGuideLoadError: false,
    itemsInfo: {
        data: [],
        meta: null,
        links: null,
    },
    providers: [],

    creatingItem: false,
    creatingError: null,

    updatingItem: false,
    updatingError: null,

    deletingItem: null,
    deletingIndex: null,
};

const reducer = createReducer(
    initialState,
    on(loadRoutingGuide, state => ({
        ...state,
        routingGuide: null,
        providers: [],
        loadingRoutingGuide: true,
        routingGuideLoadError: false,
        itemsInfo: initialState.itemsInfo,
    })),
    on(loadRoutingGuideSuccess, (state, { routingGuide, providers }) => ({
        ...state,
        loadingRoutingGuide: false,
        routingGuide,
        providers,
    })),
    on(loadRoutingGuideFailure, state => ({
        ...state,
        loadingRoutingGuide: false,
        routingGuide: null,
        providers: [],
        routingGuideLoadError: true,
    })),
    on(loadRoutingGuideItems, state => ({
        ...state,
        loadingRoutingGuide: true,
        routingGuideLoadError: false,
    })),
    on(loadRoutingGuideItemsSuccess, (state, { items }) => {
        const dataClone = [...state.itemsInfo.data, ...items.data];
        return {
            ...state,
            loadingRoutingGuide: false,
            itemsInfo: {
                data: dataClone,
                meta: items.meta,
                links: items.links,
            },
        };
    }),
    on(loadRoutingGuideItemsFailure, state => ({
        ...state,
        loadingRoutingGuide: false,
        routingGuideLoadError: true,
        itemsInfo: initialState.itemsInfo,
    })),
    on(createRoutingGuideItem, state => ({
        ...state,
        creatingItem: true,
        creatingError: null,
    })),
    on(createRoutingGuideItemSuccess, (state, { createdRoutingGuideItem }) => {
        // Do some funky stuff to add the new item to the top of the first page
        const dataClone = [...state.itemsInfo.data];
        dataClone.unshift(createdRoutingGuideItem); // We don't worry about the page size right now, the page will just be 1 item bigger.

        return {
            ...state,
            creatingItem: false,
            itemsInfo: { ...state.itemsInfo, data: dataClone },
        };
    }),
    on(createRoutingGuideItemFailed, (state, { error }) => ({
        ...state,
        creatingItem: false,
        creatingError: error,
    })),
    on(updateRoutingGuideItem, state => ({
        ...state,
        updatingItem: true,
        updatingError: null,
    })),
    on(updateRoutingGuideItemSuccess, (state, { updatedRoutingGuideItem }) => {
        // Update the routing guide item at the correct page in the collection.
        const dataClone = [...state.itemsInfo.data];
        const index = dataClone.findIndex(e => {
            return e._id === updatedRoutingGuideItem._id;
        });

        if (index >= 0) {
            dataClone[index] = updatedRoutingGuideItem;
        }

        return {
            ...state,
            updatingItem: false,
            updatingError: null,
            itemsInfo: { ...state.itemsInfo, data: dataClone },
        };
    }),
    on(updateRoutingGuideItemFailure, (state, { error }) => ({
        ...state,
        updatingItem: false,
        updatingError: error,
    })),
    on(deleteRoutingGuideItemStarted, (state, { routingGuideItem }) => {
        const dataClone = [...state.itemsInfo.data];
        const index = dataClone.findIndex(e => {
            return e._id === routingGuideItem._id;
        });

        dataClone.splice(index, 1);

        return {
            ...state,
            deletingItem: routingGuideItem,
            deletingIndex: index,
            itemsInfo: { ...state.itemsInfo, data: dataClone },
        };
    }),
    on(deleteRoutingGuideItemSuccess, state => ({
        ...state,
        deletingItem: null,
        deletingIndex: null,
    })),
    on(deleteRoutingGuideItemFailure, state => {
        const dataClone = [...state.itemsInfo.data];
        if (state.deletingIndex && state.deletingItem) {
            dataClone.splice(state.deletingIndex, 0, state.deletingItem);
        }

        return {
            ...state,
            deletingItem: null,
            deletingIndex: null,
            itemsInfo: { ...state.itemsInfo, data: dataClone },
        };
    })
);

export function routingGuideReducer(
    state: RoutingGuideState | undefined,
    action: Action
) {
    return reducer(state, action);
}
