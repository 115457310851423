import { Component } from '@angular/core';
import { MagayaEntity } from '../../models';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'qwyk-search-magaya-entity-modal',
    templateUrl: './search-magaya-entity-modal.component.html',
    styleUrls: ['./search-magaya-entity-modal.component.scss'],
})
export class SearchMagayaEntityModalComponent {
    constructor(private modal: NgbActiveModal) {}

    public onSelectEntity(magayaEntity: MagayaEntity): void {
        this.modal.close(magayaEntity);
    }

    public onCancel(): void {
        this.modal.dismiss();
    }
}
