<td class="pt-0">
    {{ pkg.quantity }} x
    {{ pkg.quantity < 2 ? pkg.packaging.description :
    pkg.packaging.description_plural }} {{pkg.container_type_name}}</td>
<td *ngIf="request.load_type === 'lcl'"
    class="pt-0 d-none d-xl-table-cell">
    {{
    pkg.unit_length
        | unitConvert: 'length' : pkg.uom : displayUOM
        | number: '1.0-0'
    }}
    x
    {{
    pkg.unit_width
        | unitConvert: 'length' : pkg.uom : displayUOM
        | number: '1.0-0'
    }}
    x
    {{
    pkg.unit_height
        | unitConvert: 'length' : pkg.uom : displayUOM
        | number: '1.0-0'
    }}
    {{ displayUOM === 'metric' ? 'cm' : 'in' }}
</td>
<td *ngIf="['lcl', 'ltl'].includes(request.load_type) || request.transport_mode === 'AIR'" class="text-right pt-0">
    {{
        chargeableWeight | number: '1.2-2'
    }}
    {{ displayUOM === 'metric' ? 'kgs' : 'lbs' }}
</td>
<td class="text-right pt-0">
    {{ totalWeight | number: '1.2-2' }}
    {{ displayUOM === 'metric' ? 'kgs' : 'lbs' }}
</td>
<td class="text-right pt-0">
    {{
    totalVolume | number: (displayUOM === 'metric' ? '1.3-3' : '1.0-0')
    }}
    {{ displayUOM === 'metric' ? 'cbm' : 'cft' }}
</td>
<td *ngIf="request.load_type === 'lcl' && request.transport_mode === 'AIR'"
    class="text-right pt-0">
    {{
    totalVolume / (displayUOM === 'metric' ? 0.006 : 0.096) | number: '1.2-2'
    }}
    {{ displayUOM === 'metric' ? 'kgs' : 'lbs' }}
</td>

