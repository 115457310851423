import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '@qwyk/hub/environment';

@Component({
    selector: 'app-help-modal',
    templateUrl: './help-modal.component.html',
    styleUrls: ['./help-modal.component.scss'],
})
export class HelpModalComponent {
    env = environment;
    public feedbackSite: string = this.env.feedbackSite;
    public supportEmail: string = this.env.supportEmail;

    constructor(public modal: NgbActiveModal) {}
}
