import { createReducer, on, Action } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import * as SitesActions from './sites.actions';
import { Hub, common } from '@qwyk/models';

export const SITES_FEATURE_KEY = 'sites';

export interface State extends EntityState<Hub.Site> {
    selectedId?: string | number; // which Sites record has been selected
    loaded: boolean; // has the Sites list been loaded
    loading: boolean;
    error?: string | null; // last none error (if any)
    pagination?: common.PaginationMeta;
}

export interface SitesPartialState {
    readonly [SITES_FEATURE_KEY]: State;
}

export const sitesAdapter: EntityAdapter<Hub.Site> = createEntityAdapter<
    Hub.Site
>();

export const initialState: State = sitesAdapter.getInitialState({
    // set initial required properties
    loaded: false,
    loading: false,
});

const sitesReducer = createReducer(
    initialState,
    on(SitesActions.loadSites, state => ({
        ...state,
        loaded: false,
        loading: true,
        error: null,
    })),
    on(SitesActions.loadSitesSuccess, (state, { sites, pagination }) =>
        sitesAdapter.setAll(sites, {
            ...state,
            loaded: true,
            loading: false,
            pagination,
        })
    ),
    on(SitesActions.loadSitesFailure, (state, { error }) => ({
        ...state,
        error,
    })),
    on(SitesActions.loadSiteSuccess, (state, { site }) =>
        sitesAdapter.addOne(site, state)
    ),
    on(SitesActions.selectSite, (state, { id }) => ({
        ...state,
        selectedId: id,
        error: null,
    })),
    on(SitesActions.createSite, SitesActions.updateSite, state => ({
        ...state,
        loading: true,
        error: null,
    })),
    on(SitesActions.createSiteSuccess, (state, { site }) =>
        sitesAdapter.addOne(site, {
            ...state,
            loading: false,
        })
    ),
    on(SitesActions.updateSiteSuccess, (state, { site }) =>
        sitesAdapter.updateOne(
            { id: site.id, changes: site },
            {
                ...state,
                loading: false,
            }
        )
    ),
    on(
        SitesActions.createSiteFailure,
        SitesActions.updateSiteFailure,
        (state, { error }) => ({
            ...state,
            loading: false,
            error,
        })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return sitesReducer(state, action);
}
