<div [ngClass]="{'comment': activity.type === 'comment'}"
     class="d-flex align-items-baseline relative activity-list-item-container">

    <div class="line border-left"></div>
    <div class="mr-3">
        <div class="icon border rounded-circle bg-light d-flex align-items-center justify-content-center">
            <fa-icon [fixedWidth]="true" [icon]="['far', icon]" class="text-muted" size="lg"></fa-icon>
        </div>
    </div>
    <div class="flex-grow-1">
        <ng-container [ngSwitch]="activity.type">
            <qwyk-activity-comment-list-item
                (delete)="deleteComment.emit($event)"
                (update)="updateComment.emit($event)"
                *ngSwitchCase="'comment'"
                [activity]="activity"
                [canMutate]="canMutate"
                [loading]="loading"
                [saving]="saving">
            </qwyk-activity-comment-list-item>
            <qwyk-activity-event-list-item
                *ngSwitchDefault
                [activity]="activity">
            </qwyk-activity-event-list-item>
        </ng-container>
    </div>
</div>
