import { Component, Input } from '@angular/core';
import { Portals } from '@qwyk/models';
import { UnitConvertPipe } from '@qwyk/ui-standalone';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'tr[qwyk-quotation-view-cargo-line]',
    templateUrl: './quotation-view-cargo-line.component.html',
    styleUrls: ['./quotation-view-cargo-line.component.scss'],
})
export class QuotationViewCargoLineComponent {
    @Input() pkg: Portals.QuotationPackage;
    @Input() displayUOM;
    @Input() request: Portals.QuotationRequest;

    private convert = new UnitConvertPipe();

    constructor() {}

    public get totalWeight(): number {
        return this.convert.transform(
            this.pkg.quantity * this.pkg.unit_weight,
            'mass',
            this.pkg.uom,
            this.displayUOM
        );
    }

    public get totalVolume(): number {
        return this.convert.transform(
            this.pkg.quantity * this.pkg.unit_volume,
            'volume',
            this.pkg.uom,
            this.displayUOM
        );
    }

    public get chargeableWeight(): number {
        return this.convert.transform(
            this.pkg.total_chargeable_weight_kgs,
            'mass',
            this.pkg.uom,
            this.displayUOM
        );
    }
}
