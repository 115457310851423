import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {NgModel} from '@angular/forms';
import {distinctUntilChanged} from 'rxjs/operators';
import {Subscription} from 'rxjs';

@Component({
    selector: 'qwyk-comment-input',
    templateUrl: './comment-input.component.html',
    styleUrls: ['./comment-input.component.scss']
})
export class CommentInputComponent implements OnInit, OnDestroy {

    @Input() public html = '';
    @Output() htmlChange: EventEmitter<string> = new EventEmitter<string>();
    @Input() public valid = true;
    @Input() public readonly = false;
    @Output() public validChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @ViewChild('ctrl', {static: true}) ctrl?: NgModel;

    private statusSubscription!: Subscription;
    public readonly quillFormats: string[] = [
        'bold',
        'italic',
        'link',
        'strike',
        'underline',
        'blockquote',
        'list',
    ];

    ngOnInit(): void {
        if (this.ctrl && this.ctrl.statusChanges) {
            this.statusSubscription = this.ctrl.statusChanges
                .pipe(
                    distinctUntilChanged(),
                )
                .subscribe(e => this.validChange.emit(e === 'VALID'));
        }
    }

    ngOnDestroy(): void {
        if (this.statusSubscription) {
            this.statusSubscription.unsubscribe();
        }
    }


}
