/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAction, props } from '@ngrx/store';
import { Hub, common } from '@qwyk/models';

export const loadSites = createAction(
    '[Hub/Management/Sites] Load Sites',
    props<{ query: any }>()
);

export const loadSitesSuccess = createAction(
    '[Hub/Management/Sites] Load Sites Success',
    props<{ sites: Hub.Site[]; pagination: common.PaginationMeta }>()
);

export const loadSitesFailure = createAction(
    '[Hub/Management/Sites] Load Sites Failure',
    props<{ error: any }>()
);

export const loadSiteSuccess = createAction(
    '[Hub/Management/Sites] Load Site Success',
    props<{ site: Hub.Site }>()
);

export const selectSite = createAction(
    '[Hub/Management/Sites] Select Site',
    props<{ id: number }>()
);

export const createSite = createAction(
    '[Hub/Management/Sites] Create Site',
    props<{ site: Partial<Hub.Site> }>()
);
export const createSiteSuccess = createAction(
    '[Hub/Management/Sites] Create Site Success',
    props<{ site: Hub.Site }>()
);
export const createSiteFailure = createAction(
    '[Hub/Management/Sites] Create Site Failure',
    props<{ error: any }>()
);

export const updateSite = createAction(
    '[Hub/Management/Sites] Update Site',
    props<{ site: any }>()
);
export const updateSiteSuccess = createAction(
    '[Hub/Management/Sites] Update Site Success',
    props<{ site: Hub.Site }>()
);
export const updateSiteFailure = createAction(
    '[Hub/Management/Sites] Update Site Failure',
    props<{ error: any }>()
);
