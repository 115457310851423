import { Injectable } from '@angular/core';
import { EntityExistsGuard } from '@qwyk/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import * as QuotationOffersFeature from './../+state/quotation-offers.reducer';
import * as QuotationOffersSelectors from './../+state/quotation-offers.selectors';
import { select, Store } from '@ngrx/store';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { QuotationOffersService } from '../services/quotation-offers.service';
import * as QuotationOffersActions from './../+state/quotation-offers.actions';

@Injectable({
    providedIn: 'root',
})
export class QuotationOfferExistsGuard extends EntityExistsGuard<string> {
    constructor(
        private store: Store<QuotationOffersFeature.QuotationOffersPartialState>,
        private service: QuotationOffersService,
        private router: Router
    ) {
        super();
    }

    protected getEntityKey(next: ActivatedRouteSnapshot): string {
        return next.params['offerId'];
    }

    protected hasQuotationOfferEntityInApi(
        quotationId: string,
        offerId: string,
        next: ActivatedRouteSnapshot
    ): Observable<boolean> {
        return this.service.getQuotationOffer(quotationId, offerId).pipe(
            map(quotationOffer =>
                QuotationOffersActions.loadQuotationOfferSuccess({
                    quotationOffer,
                })
            ),
            tap(action => this.store.dispatch(action)),
            map(quotationOffer => !!quotationOffer),
            catchError(error => {
                this.router.navigate([`/error/${error.status}`], {
                    queryParams: {
                        source: next.pathFromRoot.map(p => p.url).join('/'),
                        errorName: error.statusText,
                    },
                });
                return of(false);
            })
        );
    }

    protected hasEntity(
        id: string,
        next: ActivatedRouteSnapshot
    ): Observable<boolean> {
        return this.hasEntityInStore(id).pipe(
            switchMap(inStore => {
                if (inStore) {
                    return of(inStore);
                }
                return this.hasQuotationOfferEntityInApi(
                    next.params['id'],
                    id,
                    next
                );
            })
        );
    }

    protected hasEntityInStore(id: string): Observable<boolean> {
        return this.store.pipe(
            select(QuotationOffersSelectors.getQuotationOffersEntities),
            map(entities => !!entities[id]),
            take(1)
        );
    }

    protected hasEntityInApi(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        id: string,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        next: ActivatedRouteSnapshot
    ): Observable<boolean> {
        throw new Error('Not implemented');
    }
}
