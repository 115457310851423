<qwyk-comment-input [(html)]="html" [(valid)]="htmlValid" [readonly]="disabled"></qwyk-comment-input>
<div class="mt-3 d-flex align-items-center">
    <button (click)="onSubmit()" [disabled]="!sanitizedHtml || !htmlValid || disabled"
            [ngClass]="{'btn-outline-primary': (!sanitizedHtml || !htmlValid || disabled) && !saving}"
            class="btn btn-primary">
        <fa-icon *ngIf="saving" [icon]="['far', 'spinner']" [pulse]="true" class="mr-1"></fa-icon>
        {{ saveButtonText | translate }}
    </button>
    <button
        (click)="cancel.emit()"
        *ngIf="showCancel"
        [disabled]="disabled"
        class="btn btn-light ml-3">
        {{ 'common.cancel' | translate }}
    </button>
    <div class="form-check form-check-inline ml-3" ngbTooltip="{{ 'hub.activities.comments-internal-tooltip' | translate }}">
        <input [(ngModel)]="isInternal" class="form-check-input" disabled id="inlineCheckbox1" type="checkbox"
               value="option1">
        <label class="form-check-label" for="inlineCheckbox1">{{ 'hub.activities.internal' | translate }}</label>
    </div>
</div>

